import React from "react";
import axios from "axios";
import { logError } from "../Toastconfig";
import useAlert from "../../hooks/alertHook";

export default function Deletenews({ news, getallnews }) {
  const { alertModal, displayAlert, dismissAlert } = useAlert();
  const baseURL = process.env.REACT_APP_BASE_URL;
  function handletoggle() {
    displayAlert(
      "danger",
      "",
      "Voulez-vous supprimer cette actualité ?",
      "Delete",
      () => {
        senData();
        getallnews();
        dismissAlert();
      },
      "cancel",
      dismissAlert
    );
  }
  function senData() {
    axios
      .post(`${baseURL}/news/delete/${news.id}`)
      .then((resp) => {
        if (resp.data.error) return;
        getallnews();
        displayAlert("success", "", "actualité supprimée");
        setTimeout(() => {
          dismissAlert();
        }, 2500);
      })
      .catch((error) => {
        logError();
      });
  }
  return (
    <>
      <div>
        <button className=" btn btn-update">
          <i
            className="fa-solid fa-trash-can red"
            title="delete this news"
            onClick={handletoggle}
          ></i>
        </button>
        {alertModal}
      </div>
    </>
  );
}
