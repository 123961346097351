  import React, { useState } from "react";
  import CardCompetitions from "./CardCompetitions";
  import CardTeams from "./CardTeams";
  import CardPlayers from "./CardPlayers";
import { useHasPermission } from "../hooks/checkPermissions";

  const AllBoosted = () => {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const [cardTeamss, setcardTeams] = useState([]);
    const [cardPlayerss, setcardPlayers] = useState([]);
    const [CardCompetitionss, setCardCompetitions] = useState([]);
    const {checkPermissions} = useHasPermission();
   
    return (
      <>
            {checkPermissions("teams") && (
        <div className="col-lg-12">
          <CardTeams cardTeamss={cardTeamss} />
        </div>
  )}
          {checkPermissions("players") && (
               <div className="col-lg-12">
                 <CardPlayers cardPlayerss={cardPlayerss} />
               </div>
         )}
            {checkPermissions("competitions") && (
        <div className="col-lg-12">
          <CardCompetitions CardCompetitionss={CardCompetitionss} />
        </div>
 )} 

      </>
    );
  };
  export default AllBoosted;
