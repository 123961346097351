import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import useAlert from "../../hooks/alertHook";
import { Navigate, useNavigate } from "react-router-dom";
import { logError } from "../Toastconfig";

// to initialize errors message
const initFormErrors = {
  name: [""],
  Permissions: [""],
};

export default function EditRole({ Role, getallroles }) {
  const navigate = useNavigate();

  const baseURL = process.env.REACT_APP_BASE_URL;
  // modal props
  const [permissions, setPermissions] = useState([]);
  const [show, setShow] = useState(false);
  const [publicc, setPublic] = useState(Role.public);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setErrorMessage(initFormErrors);
  };
  // Role props
  const [name, setname] = useState("");
  const [errorMessage, setErrorMessage] = useState(initFormErrors);
  // error messages
  const msg = "Field is required";
  const msg1 = "Please enter a vild value";
  const [isSubscribed, setIsSubscribed] = useState("no");
  const { alertModal, displayAlert, dismissAlert } = useAlert();

  const handleChange = () => {
    if (publicc === "no") {
      setPublic("yes");
    } else {
      setPublic("no");
    }
  };

  useEffect(() => {
    if (Role === undefined) return;
    setname(Role.name);
  }, [Role]);

  // function to validate the inputs entry
  function validateform() {
      displayAlert(
        "danger",
        "",
        "Voulez-vous confirmer les permissions de ce rôle ?",
        "confirmer",
        () => {
          const data = {
            name: name,
            public: publicc,
            Permissions: permissions,
          };
          axios
            .post(`${baseURL}/roles/update/${Role.id}`, data)
            .then((resp) => {
          setErrorMessage(initFormErrors);
              if (resp.data.error) return;
              displayAlert("success", "", resp.data.message);
              handleClose();
              setTimeout(() => {
                dismissAlert();
              }, 2500);
              getallroles();
              window.location.reload();
            })
            .catch((error) => {
              const errors = error.response.data;
              dismissAlert();
              logError();
              setErrorMessage(errors);
            });
        },
        "cancel",
        dismissAlert
      );
  }

  function GetPlayerById() {
    if (Role.id === undefined) return;
    axios.get(`${baseURL}/roles/getRoleById/${Role.id}`).then((resp) => {
      if (resp.data.error) return;
      setPermissions(resp.data.data.permissions.Permissions);
    });
  }
  useEffect(() => {
    GetPlayerById();
  }, []);

  const CheckHandler = (e) => {
    const value = e.target.value;
    setPermissions((prev) =>
      permissions?.includes(value)
        ? prev.filter((cur) => cur !== value)
        : [...prev, e.target.value]
    );
  };

  return (
    <div>
      <>
        <button className=" btn btn-update" onClick={handleShow}>
          <i className="fa-solid fa-pen-to-square" title="Modifier ce role"></i>
        </button>
        <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" show={show} onHide={handleClose}>
        <Modal.Header className="style_modalHeader">
          <div><Modal.Title className="style_modalTitle">
          Modifier role
          </Modal.Title>
          </div>
          </Modal.Header>
          <Modal.Body className="mt-3">
            <section className="">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 mb-3">
                    <div className="form-outline">
                      <label className="form-label" for="Name">
                        Nom :
                      </label>
                      <input
                        type="text"
                        id="name"
                      placeholder="Nom"
                        value={name}
                        className="form-control form-control"
                        onChange={(e) => setname(e.target.value)}
                        required
                      />
                      <div className="input-error">
                        <span className="text-danger">{errorMessage.name}</span>
                      </div>
                      <div className="form-check mt-4">
                        <input
                          className="form-check-input"
                          checked={publicc === "yes"}
                          type="checkbox"
                          value={isSubscribed}
                          id="flexCheckDefault"
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckDefault"
                        >
                          Public
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <h5 className="fw-bold mb-3">Liste des permissions :</h5>
                  <div className="col-lg-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        checked={permissions?.includes("players")}
                        type="checkbox"
                        value="players"
                        id="flexCheckDefault"
                        onChange={(e) => {
                          CheckHandler(e);
                        }}
                      />
                      <label className="form-check-label">
                        Consulter joueurs
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="teams"
                        checked={permissions?.includes("teams")}
                        id="flexCheckDefault"
                        onChange={(e) => {
                          CheckHandler(e);
                        }}
                      />
                      <label className="form-check-label">
                        Consulter équipes
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="competitions"
                        checked={permissions?.includes("competitions")}
                        id="flexCheckDefault"
                        onChange={(e) => {
                          CheckHandler(e);
                        }}
                      />
                      <label className="form-check-label">
                        Consulter compétitions
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="news"
                        checked={permissions?.includes("news")}
                        id="flexCheckDefault"
                        onChange={(e) => {
                          CheckHandler(e);
                        }}
                      />
                      <label className="form-check-label">
                        Consulter Actualitées
                      </label>
                    </div>
                  </div>
                </div>
                <hr></hr>
                <div className="row mt-4">
                  <div className="col-lg-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={permissions?.includes("admin_players")}
                        value="admin_players"
                        id="flexCheckDefault"
                        onChange={(e) => {
                          CheckHandler(e);
                        }}
                      />
                      <label className="form-check-label text-nowrap">
                        Gestion des joueurs
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="admin_teams"
                        id="flexCheckDefault"
                        checked={permissions?.includes("admin_teams")}
                        onChange={(e) => {
                          CheckHandler(e);
                        }}
                      />
                      <label className="form-check-label text-nowrap">
                        Gestion des équipes
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="admin_competitions"
                        id="flexCheckDefault"
                        checked={permissions?.includes("admin_competitions")}
                        onChange={(e) => {
                          CheckHandler(e);
                        }}
                      />
                      <label className="form-check-label text-nowrap">
                        Gestion des compétitions
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="admin_news"
                        checked={permissions?.includes("admin_news")}
                        id="flexCheckDefault"
                        onChange={(e) => {
                          CheckHandler(e);
                        }}
                      />
                      <label className="form-check-label text-nowrap">
                        Gestion des Actualitées
                      </label>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="admin_roles"
                        checked={permissions?.includes("admin_roles")}
                        id="flexCheckDefault"
                        onChange={(e) => {
                          CheckHandler(e);
                        }}
                      />
                      <label className="form-check-label text-nowrap">
                        Gestion des Roles
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value="admin_users"
                        checked={permissions?.includes("admin_users")}
                        id="flexCheckDefault"
                        onChange={(e) => {
                          CheckHandler(e);
                        }}
                      />
                      <label className="form-check-label text-nowrap">
                        Gestion des Utilisateurs
                      </label>
                    </div>
                  </div>
                  <div className="input-error">
                        <span className="text-danger">{errorMessage.Permissions}</span>
                      </div>
                </div>
              </div>
            </section>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center mb-4 border-0">
            <Button
              variant="primary"
              className="btn-modal"
              onClick={validateform}
            >
              Enregistrer
            </Button>
            <Button
              variant="outline-secondary"
              className="btn-modal"
              onClick={handleClose}
            >
              Annuler
            </Button>
          </Modal.Footer>
        </Modal>
      </>
      {alertModal}
    </div>
  );
}
