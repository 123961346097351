import React from "react";
import "./news.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { logError } from "../../components/Toastconfig";

export default function AllNews() {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [newslist, setNewslist] = useState([]);
  const [filtredlist, setFiltredlist] = useState([]);

  useEffect(function () {
    getallnews();
  }, []);

  function getallnews() {
    axios
      .get(`${baseURL}/news/all`)
      .then((res) => {
        setNewslist(res.data.data);
        setFiltredlist(res.data.data);
      })
      .catch((err) => {
        logError();
      });
  }

  function filterbycontry(text) {
    const items = newslist;
    switch (text) {
      case "all":
        setFiltredlist(newslist);
        break;
      default:
        const results = items.filter(
          (item) =>
            item.contry &&
            item.contry.toLowerCase().includes(text.toLowerCase())
        );
        setFiltredlist(results);
        break;
    }
  }
  return (
    <>
      <section className="py-3 px-2">
        <div className="container-fluid">
          <div className="row ">
            <div className="col-lg-8 col-sm-12">
              <div className="row">
                <h4 className=" text-nowrap"> Top news:</h4>
              </div>
              <div className="row bg-white me-2 py-2">
                <div className="col-lg-6 col-sm-12 py-sm-2 px-3">
                  {newslist
                    .filter((item, index) => index < 2)
                    .map((news, index) => (
                      <div
                        key={index}
                        className={
                          index === 0
                            ? "row rounded border mt-4"
                            : "row rounded border mt-3"
                        }
                      >
                        <div className="col-5 p-0">
                          <Link
                            className="link_news"
                            to={`/news/details/${news.id}`}
                          >
                            <img
                              className=" imgNews rounded-2"
                              src={`${baseURL + "/new/getFile" + news.media}`}
                              alt="media"
                            />
                          </Link>
                        </div>
                        <div className="col-7">
                          <div className="row news_grey_style p-2 pt-3">
                            {news.createdat.substring(0, 10)}
                          </div>
                          <div className="row card_title p-2">
                            <Link
                              className="link_news"
                              style={index === 0 ? { color: "#216FF5" } : {}}
                              to={`/news/details/${news.id}`}
                            >
                              {news.title.substring(0, 30)}...
                            </Link>
                          </div>
                          <div className="d-flex flex-row-reverse p-2">
                            <div className="news_grey_style">
                            Enregistrer
                              <button className="btn btn-sm ms-1">
                                <i className="fa-sharp fa-regular fa-bookmark"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="col-lg-6 col-sm-12 py-2 px-3">
                  {newslist
                    .filter((item, index) => index >= 2 && index < 5)
                    .map((news, index) => (
                      <div className="row  mt-2" key={index}>
                        <div className="col-3 p-0">
                          <Link
                            className="link_news"
                            to={`/news/details/${news.id}`}
                          >
                            <img
                              className="rounded-3 imgNewsrounded"
                              src={`${baseURL + "/new/getFile" + news.media}`}
                              alt="media"
                            />
                          </Link>
                        </div>
                        <div className="col-9">
                          <div className="row card_title_sec p-2">
                            <Link
                              className="link_news"
                              to={`/news/details/${news.id}`}
                            >
                              {news.title.substring(0, 60)}...
                            </Link>
                          </div>
                          <div className="d-flex flex-row-reverse p-2">
                            <div className="news_grey_style">
                            Enregistrer
                              <button className="btn btn-sm ms-1">
                                <i className="fa-sharp fa-regular fa-bookmark"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
              <div className="row p-4 overflow-auto">
                <div className="col-12 d-flex justify-content-start">
                  <div className="">
                    <button
                      type="button"
                      onClick={() => filterbycontry("all")}
                      className="btn bg-white btn_filter border rounded-1 btn_filter px-3  me-4 text-nowrap"
                    >
                      All news
                    </button>
                  </div>
                  <div className="">
                    <button
                      type="button"
                      onClick={() => filterbycontry("england")}
                      className="btn border rounded-1 btn_filter px-3 bg-white mx-4"
                    >
                      England
                    </button>
                  </div>
                  <div className="">
                    <button
                      type="button"
                      onClick={() => filterbycontry("france")}
                      className="btn border rounded-1 btn_filter bg-white px-3 mx-4"
                    >
                      France
                    </button>
                  </div>
                  <div className="">
                    <button
                      type="button"
                      onClick={() => filterbycontry("italie")}
                      className="btn border rounded-1 btn_filter px-3 bg-white mx-4"
                    >
                      Italie
                    </button>
                  </div>
                  <div className="">
                    <button
                      type="button"
                      onClick={() => filterbycontry("spain")}
                      className="btn border rounded-1 btn_filter px-3 bg-white mx-4"
                    >
                      Spain
                    </button>
                  </div>
                </div>
              </div>
              <div className="row bg-white me-2 py-2">
                <div className="col-lg-6 col-sm-12 py-2 px-3">
                  {filtredlist
                    .filter((item, index) => index < 2)
                    .map((news, index) => (
                      <div
                        key={index}
                        className={
                          index === 0
                            ? "row rounded border mt-4"
                            : "row rounded border mt-3"
                        }
                      >
                        <div className="col-5 p-0">
                          <Link
                            className="link_news"
                            to={`/news/details/${news.id}`}
                          >
                            <img
                              className="rounded-2 imgNews"
                              src={`${baseURL + "/new/getFile" + news.media}`}
                              alt="media"
                            />
                          </Link>
                        </div>
                        <div className="col-7">
                          <div className="row news_grey_style p-2 pt-3">
                            {news.createdat.substring(0, 10)}
                          </div>
                          <div className="row card_title p-2">
                            <Link
                              className="link_news"
                              to={`/news/details/${news.id}`}
                            >
                              {news.title.substring(0, 30)}...
                            </Link>
                          </div>
                          <div className="d-flex flex-row-reverse p-2">
                            <div className="news_grey_style">
                            Enregistrer
                              <button className="btn btn-sm ms-1">
                                <i className="fa-sharp fa-regular fa-bookmark"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
                <div className="col-lg-6 col-sm-12 py-2 px-3">
                  {filtredlist
                    .filter((item, index) => index >= 2 && index < 5)
                    .map((news, index) => (
                      <div key={index} className="row  mt-2">
                        <div className="col-3 p-0">
                          <Link
                            className="link_news"
                            to={`/news/details/${news.id}`}
                          >
                            <img
                              className="rounded-3 imgNewsrounded"
                              src={`${baseURL + "/new/getFile" + news.media}`}
                              alt="media"
                            />
                          </Link>
                        </div>
                        <div className="col-9">
                          <div className="row card_title_sec p-2">
                            <Link
                              className="link_news"
                              to={`/news/details/${news.id}`}
                            >
                              {news.title.substring(0, 60)}...
                            </Link>
                          </div>
                          <div className="d-flex flex-row-reverse p-2">
                            <div className="news_grey_style">
                            Enregistrer
                              <button className="btn btn-sm ms-1">
                                <i className="fa-sharp fa-regular fa-bookmark"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-12 mt-2 ">
              <div
                className="row p-2 bg-white text_news"
              >
              Actualités Plus Lus
              </div>
              <div
                className="row  p-2 bg-white mt-4 text_news"
              >
              Actualités Enregistrées
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
