import React from "react";
import axios from "axios";
import { logError } from "../Toastconfig";
import useAlert from "../../hooks/alertHook";
export default function StatutUser({ User, getall }) {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { alertModal, displayAlert, dismissAlert } = useAlert();
  function handletoggle() {
    let message = User.isEnabled
      ? "Voulez-vous désactiver cet utilisateur "
      : "Voulez-vous activer cet utilisateur ";
    let status = User.isEnabled
      ? "danger" : "info";
    displayAlert(status, "", message, "Confirmer", () => {
      senData();
    }, "cancel", dismissAlert)
  }
  function senData() {
    const data = {
      isEnabled: !User.isEnabled,
    };
    axios
      .post(`${baseURL}/users/toggleStatus/${User.id}`, data)
      .then((resp) => {
        if (resp.data.error) return;
        let message = User.isEnabled
        ? "L'utilisateur a été désactivé avec succès."
        : "L'utilisateur a été activé avec succès";
      displayAlert("success", "", message);
      setTimeout(() => {
        dismissAlert();
      }, 2500);
      getall();
      })
      .catch((error) => {
        logError();
      });
  }
  return (
    <div>
      <button className=" btn btn-update">
        <i
          className={
            User.isEnabled
              ? "fa-solid fa-eye green"
              : "fa-solid fa-eye-slash red"
          }
          title={
            User.isEnabled ? "désactiver utilisateur" : "activer utilisateur"
          }
          onClick={handletoggle}
        ></i>
      </button>
      {alertModal}
    </div>
  );
}
