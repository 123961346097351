import React from "react";
import "./news.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import { logError } from "../../components/Toastconfig";

export default function NewsDetails() {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { newsId } = useParams();
  const [news, setNews] = useState({
    title: "",
    content: "",
    media: "",
    createdAt: "",
    updatedAt: "",
  });

  useEffect(() => {
    getnews();
  }, []);

  function getnews() {
    if (newsId === undefined) return;
    axios
      .get(`${baseURL}/news/${newsId}`)
      .then((res) => {
        setNews(res.data.data);
      })
      .catch((err) => {
        logError();
      });
  }
  return (
    <>
      <section className="p-4">
        <div className="container-fluid">
          <div className="row ">
            <div className="col-8 ">
              <div className="row">
                <img
                  className="imgNewsDetails"
                  src={`${baseURL + "/new/getFile" + news.media?.substring(1)}`}
                  alt="media"
                />
              </div>
              <div className="row d-flex flex-column">
                <div className="d-flex flex-row-reverse">
                  <div className="news_grey_style p-2 mt-2">
                  </div>
                </div>
                <div className="d-flex flex-row-reverse">
                  <div className="news_grey_style p-2 mt-2">
                  Enregistrer
                    <button className="btn btn-sm ms-1">
                      <i className="fa-sharp fa-regular fa-bookmark"></i>
                    </button>
                  </div>
                </div>
                <div className="p-2 news_grey_style">
                  {news.createdAt?.substring(0, 10)}
                </div>
              </div>
              <div className="row pt-2">
                <h4 className="news_title">{news.title}</h4>
                <div>{parse(news.content)}</div>
              </div>
              <div className="row p-3 newstextDetails">
                <div className="bg-white">Actualités Connexes</div>
              </div>
            </div>
            <div className="col-4 ">
              <div className="row p-2 bg-white newstextDetails">Actualités Plus Lus</div>
              <div className="row  p-2 bg-white mt-4 newstextDetails">
              Actualités Enregistrées
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
