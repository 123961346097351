import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import { Link } from "react-router-dom";
import { logError } from "../../components/Toastconfig";
import EmptyData from "../../components/emptyData";
import LoaderLoading from "../LoaderLoading";

const MatchesByplayerIdAdmin = ({ playerId }) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [matches, setMatches] = useState([]);
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = matches.slice(indexOfFirstItem, indexOfLastItem);
  const [isLoading, setIsLoading] = useState(true);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(matches.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  function getallMatchesByplayerId() {
    if (playerId === undefined) return;
    axios
      .get(`${baseURL}/calendarMatches/players/${playerId}`)
      .then((res) => {
        const sortedMatches = res.data.data.sort((a, b) => {
          return a.journeyMatch.journey - b.journeyMatch.journey;
        });
        setMatches(sortedMatches);
      })
      .catch((err) => {
        logError();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  useEffect(() => {
    getallMatchesByplayerId();
  }, []);

  return (
    <>
      {isLoading ? (
        <LoaderLoading />
      ) : (
        <div>
          <div className="">
            {currentItems.length > 0 ? (
              <div className="bg-color">
                {currentItems.map((match) => (
                  <div className="cardMatch row text-nowrap bg-white my-2 p-lg-3 p-md-3 p-0 d-flex justify-content-center">
                    <div className="col-lg-6 row">
                      <div className="col-5 text-center">
                        <Link
                          className="style_text_deco"
                          to={`/admin/oneTeam/${match.teamHome.id}`}
                        >
                          <span className="pe-3 currentTeamName">
                            {match.teamHome.acronyme}
                          </span>
                          <img
                            width="33"
                            height="33"
                            src={`${baseURL}/team/getFile${match.teamHome.logo.slice(
                              1
                            )}`}
                          />
                        </Link>
                      </div>
                      <div className="col-2 text-center">
                        <p className=" mx-2 pt-1">-</p>
                      </div>

                      <div className="col-5 text-center">
                        <Link
                          className="style_text_deco"
                          to={`/admin/oneTeam/${match.teamAway.id}`}
                        >
                          <img
                            width="33"
                            height="33"
                            src={`${baseURL}/team/getFile${match.teamAway.logo.slice(
                              1
                            )}`}
                            className="me-3 "
                          />
                          <span className="currentTeamName">
                            {match.teamAway.acronyme}
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div class="col-lg-3 text-center py-lg-0 py-md-3 py-3">
                      <img
                        width="29"
                        height="29"
                        src="./assets/imgs/stadium_icon.png"
                        className="me-3"
                      />
                      <span className="pe-md-2">
                        {match.teamHome.stade_Name.length > 9
                          ? `${match.teamHome.stade_Name.slice(0, 9)}...`
                          : match.teamHome.stade_Name}
                      </span>
                    </div>
                    <div className="col-lg-3  text-center py-lg-0 py-md-3 py-3">
                      <div className=" d-flex justify-content-center">
                        <div className="">
                          <i
                            class="fa-solid fa-calendar-days fa-xl ms-md-1"
                            style={{ color: "#adb1b8" }}
                          ></i>
                        </div>
                        <div className="">
                          <span className="ms-2">
                            {match.date ? match.date : "Date indisponible"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="ms-2">
                <EmptyData />
              </div>
            )}
          </div>
          <ul className="pagination">
            {pageNumbers.map((number) => (
              <span
                key={number}
                className={`page-number paginations ${
                  number === currentPage ? "active" : ""
                }`}
                onClick={() => setCurrentPage(number)}
              >
                {number}
              </span>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default MatchesByplayerIdAdmin;
