import React from "react";
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
import Users from "../pages/Users";
import Roles from "../pages/Roles";
import Login from "../pages/Login";
import Register from "../pages/Register";
import Home from "../pages/Home";
import Competitions from "../pages/Competitions";
import OneTeam from "../pages/OneTeam";
import CreateRole from "../components/role/CreateRole.js";
import CreateCompetitions from "../components/competitions/CreateCompetitions";
import { Welcome } from "../components/Welcome";
import Team from "../pages/Team";
import TeamOfSimpleUser from "../pages/simple_user_pages/TeamOfSimpleUser";
import OneTeamOfSimpleUser from "../pages/simple_user_pages/OneTeamOfSimpleUser";
import CreatePlayer from "../pages/players/CreatePlayer";
import Players from "../pages/players/Players";
import Loader from "../components/loader";
import UpdatePlayer from "../pages/players/UpdatePlayer";
import PlayerDetails from "../pages/players/PlayerDetails";
import OneCompetition from "../pages/OneCompetition";
import Layout from "../components/Layout";
import News from "../pages/news/News";
import NewsDetails from "../pages/news/NewsDetails";
import AllNews from "../pages/news/AllNews";
import SearchPlayers from "../pages/players/SearchPlayers";
import Players_Of_SimpleUser from "../pages/simple_user_pages/Players_Of_SimpleUser";
import Competitions_of_simpleUser from "../pages/simple_user_pages/Competitions_of_simpleUser";
import PlayerDetails_of_simpleUser from "../pages/simple_user_pages/PlayerDetails_of_simpleUser";
import NewsOfSimpleUser from "../pages/simple_user_pages/NewsOfSimpleUser";
import CreateNews from "../pages/news/CreateNews";
import Updatenews from "../pages/news/Updatenews";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainerSettings } from "../components/Toastconfig";
import OneCompetitionOfSimpleUser from "../pages/simple_user_pages/OneCompetitionOfSimpleUser";
import NotAllowed from "../pages/NotAllowed";
import AllBoosted from "../pages/AllBoosted";
import MatchDetails from "../components/journeys/MatchDetails.js";
import { useHasPermission } from "../hooks/checkPermissions";
import UserDetails from "./user/UserDetails";
import MatchDetailsSimpleUser from "../pages/simple_user_pages/MatchDetailsSimpleUser";
import MatchesStatistics from "../pages/simple_user_pages/MatchesStatistics";
import NewsDetailsMatchSimpleUser from "../pages/simple_user_pages/NewsDetailsMatchSimpleUser";
import NewsDetailsMatch from "./journeys/NewsDetailsMatch";
import ForgetPassword from "../pages/ForgetPassword";
import ResetPassword from "../pages/ResetPassword";
import ConfirmResetSend from "../pages/ConfirmResetSend";

const RoutesList = ({ user, isLoggedIn, setIsLoggedIn }) => {
  const { checkPermissions } = useHasPermission();
  return (
    <HashRouter>
      {isLoggedIn === "no" ? (
        <Routes>
          <Route path="/" element={<Welcome />} />
          <Route
            path="/login"
            element={<Login setIsLoggedIn={setIsLoggedIn} />}
          />
          <Route path="/register" element={<Register />} />
          <Route path="/confirmResetSend" element={< ConfirmResetSend/>} />
          <Route path="/resetPassword/:resetToken" element={< ResetPassword/>} />
          <Route path="/forgetPassword" element={< ForgetPassword />} />
          <Route path="*" element={<Navigate to="/login" />} />
        </Routes>
      ) : user && isLoggedIn === "yes" ? (
        <div>
          {/* set Toast container  */}
          <ToastContainer {...ToastContainerSettings} />
          <>
            <Layout>
              <Routes>
                {checkPermissions("competitions") ? (
                  <>
                    <Route
                      path="/competitions"
                      element={<Competitions_of_simpleUser />}
                    />
                    <Route
                      path="/competitions/MatchStatistics"
                      element={<MatchesStatistics />}
                    />
                    <Route
                      exact
                      path="/competitions/create"
                      component={CreateCompetitions}
                    ></Route>
                    <Route
                      path="/oneCompetition/:id"
                      element={<OneCompetitionOfSimpleUser />}
                    />
                    <Route
                      path="/oneCompetition/matches/matchDetails/:matchId"
                      element={<MatchDetailsSimpleUser />}
                    />
                      <Route
                      path="/oneCompetition/matches/matchDetails/newDetails/:newsId"
                      element={<NewsDetailsMatchSimpleUser />}
                    />
                  </>
                ) : (
                  <Route path="/*" element={<NotAllowed />} />
                )}
                {checkPermissions("teams") ? (
                  <>
                    <Route
                      path="/oneTeam/:id"
                      element={<OneTeamOfSimpleUser />}
                    />
                    <Route path="/teams" element={<TeamOfSimpleUser />} />
                  </>
                ) : (
                  <Route path="/*" element={<NotAllowed />} />
                )}
                {checkPermissions("players") ? (
                  <>
                    <Route
                      path="/players"
                      element={<Players_Of_SimpleUser />}
                    />
                    <Route path="/players/search" element={<SearchPlayers />} />
                    :
                    <Route
                      path="/player/details/:playerId"
                      element={<PlayerDetails_of_simpleUser />}
                    />
                  </>
                ) : (
                  <Route path="/*" element={<NotAllowed />} />
                )}
                {checkPermissions("news") ? (
                  <>
                    <Route path="/news" element={<NewsOfSimpleUser />} />
                    <Route
                      path="/news/details/:newsId"
                      element={<NewsDetails />}
                    />
                    <Route path="/news/all" element={<AllNews />} />
                  </>
                ) : (
                  <Route path="/*" element={<NotAllowed />} />
                )}
                {checkPermissions("admin_roles") ? (
                  <>
                    <Route path="/admin/roles" element={<Roles />} />
                    <Route
                      path="/admin/roles/create"
                      element={<CreateRole />}
                    />
                  </>
                ) : (
                  <Route path="/*" element={<NotAllowed />} />
                )}
                {checkPermissions("admin_users") ? (
                  <>
                    <Route path="/admin/users" element={<Users />} />
                    <Route path="/admin/allBoosted" element={<AllBoosted />} />
                  </>
                ) : (
                  <Route path="/*" element={<NotAllowed />} />
                )}
                {checkPermissions("admin_players") ? (
                  <>
                    <Route path="/admin/players" element={<Players />} />
                    <Route
                      path="/admin/player/details/:playerId"
                      element={<PlayerDetails />}
                    />
                    <Route
                      path="/admin/players/create"
                      element={<CreatePlayer />}
                    />
                    <Route
                      path="/admin/players/update/:playerId"
                      element={<UpdatePlayer />}
                    />
                  </>
                ) : (
                  <Route path="/*" element={<NotAllowed />} />
                )}
                {checkPermissions("admin_competitions") ? (
                  <>
                    <Route
                      path="/admin/competitions"
                      element={<Competitions />}
                    />
                    <Route
                      path="/admin/oneCompetition/:id"
                      element={<OneCompetition />}
                    />
                    <Route
                      path="/admin/oneCompetition/matches/matchDetails/:matchId"
                      element={<MatchDetails />}
                    />
                      <Route
                      path="admin/oneCompetition/matches/matchDetails/newDetails/:newsId"
                      element={<NewsDetailsMatch />}
                    />
                  </>
                ) : (
                  <Route path="/*" element={<NotAllowed />} />
                )}
                {checkPermissions("admin_teams") ? (
                  <>
                    <Route path="/admin/oneTeam/:id" element={<OneTeam />} />
                    <Route path="/admin/teams" element={<Team />} />
                  </>
                ) : (
                  <Route path="/*" element={<NotAllowed />} />
                )}
                {checkPermissions("admin_news") ? (
                  <>
                    <Route path="/admin/news" element={<News />} />
                    <Route
                      path="/admin/news/update/:newsId"
                      element={<Updatenews />}
                    />
                    <Route
                      path="/admin/news/details/:newsId"
                      element={<NewsDetails />}
                    />

                    <Route
                      path="/admin/news/create/"
                      element={<CreateNews />}
                    />
                  </>
                ) : (
                  <Route path="/*" element={<NotAllowed />} />
                )}
                <Route path="/admin/users/:userId" element={<UserDetails />} />
                <Route path="/test" element={<CreateRole />} />
                <Route path="/login" element={<Navigate to="/" />} />
                <Route path="/register" element={<Navigate to="/" />} />
                <Route path="/" element={<Home />} />
              </Routes>
            </Layout>
          </>
        </div>
      ) : (
        <section>
          <div className="container pt-5">
            <div className="row">
              <div className="col-lg-12 pt-5 text-center">
                <Loader />
                <h4 className="fw-bold">loading...</h4>
              </div>
            </div>
          </div>
        </section>
      )}
    </HashRouter>
  );
};

export default RoutesList;
