import { useState } from "react";
import Alert from "../libs/alert/Alert";

export default function useAlert() {
  const [alertModal, setAlertModal] = useState("");
  const displayAlert = (
    status = "info",
    title = "something went wrong",
    content = "",
    confirmBtnTitle = "",
    confirmBtnAction = () => {},
    cancelBtnTitle = "",
    cancelBtnAction = () => {}
  ) => {
    setAlertModal(
      <Alert
        status={status}
        title={title}
        content={content}
        confirmBtnTitle={confirmBtnTitle}
        confirmBtnAction={confirmBtnAction}
        cancelBtnTitle={cancelBtnTitle}
        cancelBtnAction={cancelBtnAction}
        dismissBtnAction={dismissAlert}
      />
    );
  };

  const dismissAlert = () => {
    setAlertModal("");
  };

  return { displayAlert, dismissAlert, alertModal };
}
