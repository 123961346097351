import React from "react";
import { ThreeDots } from "react-loader-spinner";
const LoaderLoading = () => {
  return (
    <div className="d-flex justify-content-center align-items-center">
    <div className="ms-2">
      <ThreeDots color="#216ff5" height={50} width={50} />
    </div>
  </div>
  );
};

export default LoaderLoading;
