import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import axios from "axios";
import useAlert from "../../hooks/alertHook";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// initialize errors message
const initFormErrors = {
  name: [""],
  Permissions:[""],
};
export default function CreateRole({ getallroles }) {
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [permissions, setPermissions] = useState([]);

  // Role props
  const [name, setname] = useState("");
  const [errorMessage, setErrorMessage] = useState(initFormErrors);
  const { alertModal, displayAlert, dismissAlert } = useAlert();

 
  const [isSubscribed, setIsSubscribed] = useState("no");

  const handleChange = (event) => {
    if (event.target.checked) {
      setIsSubscribed("yes");
    } else {
      setIsSubscribed("no");
    }
  };
  function senData() {
    {
      const data = {
        name: name,
        public: isSubscribed,
        Permissions: permissions,
      };
      axios
        .post(`${baseURL}/roles/create`, data)
        .then((resp) => {
          setErrorMessage(initFormErrors);
          if (resp.data.error) return;
          displayAlert("success", "", resp.data.message);
          setTimeout(() => {
            dismissAlert();
            navigate("/admin/roles");
          }, 2500);
          getallroles();
        })
        .catch((error) => {
          const errors = error.response.data;
          setErrorMessage(errors);
        });
    }
  };
  const CheckHandler = (e) => {
    const value = e.target.value;
    setPermissions((prev) =>
      permissions?.includes(value)
        ? prev.filter((cur) => cur !== value)
        : [...prev, e.target.value]
    );
  }
  return (
    <div className="justify-content-center d-flex">
      <>
        <div className="card create-role-style p-1">
          <h1 className="fw-bold mt-5 text-center">Créer un role</h1>
          <div className="row d-flex justify-content-center ">
            <div className="col-md-10 ">
              <label className="form-label fw-bold" for="Name">
                Nom du role:
              </label>
              <input
                type="text"
                id="name"
                className="form-control form-control"
                placeholder="Nom du role"
                onChange={(e) => setname(e.target.value)}
                required
              />
              <div className="input-error">
                <span className="text-danger">{errorMessage.name}</span>
              </div>
              <div className="form-check mt-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value={isSubscribed}
                  id="flexCheckDefault"
                  onChange={handleChange}
                />
                <label className="form-check-label" for="flexCheckDefault">
                  Public
                </label>
              </div>
              <div className="row mt-4">
                <h5 className="fw-bold mb-3">
                  Liste des permissions(Utilisateur simple) :
                </h5>
                <div className="col-lg-6">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="players"
                      id="flexCheckDefault"
                      onChange={(e) => {
                        CheckHandler(e);
                      }}
                    />
                    <label className="form-check-label text-nowrap">
                      Consulter joueurs
                    </label>
                  </div>
                </div>
                <div className="col-lg-6">
                
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="teams"
                      id="flexCheckDefault"
                      onChange={(e) => {
                        CheckHandler(e);
                      }}
                    />
                    <label className="form-check-label text-nowrap">
                      Consulter équipes
                    </label>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-lg-6">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="news"
                      id="flexCheckDefault"
                      onChange={(e) => {
                        CheckHandler(e);
                      }}
                    />
                    <label className="form-check-label text-nowrap">
                      Consulter Actualitées
                    </label>
                  </div>
                </div>
                <div className="col-lg-6">
                
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="competitions"
                      id="flexCheckDefault"
                      onChange={(e) => {
                        CheckHandler(e);
                      }}
                    />
                    <label className="form-check-label text-nowrap">
                      Consulter Compétition
                    </label>
                  </div>
                </div>
              </div>
              <div className="row mt-4">
                <h5 className="fw-bold mb-3">
                  Liste des permissions (Admin) :
                </h5>
                <div className="col-lg-6">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="admin_players"
                      id="flexCheckDefault"
                      onChange={(e) => {
                        CheckHandler(e);
                      }}
                    />
                    <label className="form-check-label text-nowrap">
                      Gestion des joueurs
                    </label>
                  </div>
                </div>
                <div className="col-lg-6">
                
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="admin_teams"
                      id="flexCheckDefault"
                      onChange={(e) => {
                        CheckHandler(e);
                      }}
                    />
                    <label className="form-check-label text-nowrap">
                      Gestion des équipes
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="admin_competitions"
                      id="flexCheckDefault"
                      onChange={(e) => {
                        CheckHandler(e);
                      }}
                    />
                    <label className="form-check-label text-nowrap">
                      Gestion des compétitions
                    </label>
                  </div>
                </div>
                <div className="col-lg-6">
                
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="admin_news"
                      id="flexCheckDefault"
                      onChange={(e) => {
                        CheckHandler(e);
                      }}
                    />
                    <label className="form-check-label text-nowrap">
                      Gestion des Actualitées
                    </label>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="admin_roles"
                      id="flexCheckDefault"
                      onChange={(e) => {
                        CheckHandler(e);
                      }}
                    />
                    <label className="form-check-label text-nowrap">
                      Gestion des Roles
                    </label>
                  </div>
                </div>
                <div className="col-lg-6">
                
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="admin_users"
                      id="flexCheckDefault"
                      onChange={(e) => {
                        CheckHandler(e);
                      }}
                    />
                    <label className="form-check-label text-nowrap">
                      Gestion des Utilisateurs
                    </label>
                  </div>
                </div>
              </div>
              <div className="input-error">
                <span className="text-danger">{errorMessage.Permissions}</span>
              </div>
              <div className="row my-5 ">
                <div className="col-lg-12 d-flex justify-content-center ">
                  <Button
                     type="button"
                     className="btn btn-primary"
                    onClick={senData}
                  >
                    Enregistrer
                  </Button>
                  <Link to={`/admin/roles`}>
                    <Button variant="outline-secondary" className="btn btn-outline-secondary mx-3"
>
                      Annuler
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
      {alertModal}
    </div>
  );
}
