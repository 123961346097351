import React, { useState, useEffect } from "react";
import axios from "axios";
import { logError } from "../components/Toastconfig";
import EmptyData from "../components/emptyData";
import Table from "react-bootstrap/Table";
import UpdateBoostedTeam from "../components/Teams/UpdateBoostedTeam";
import useAlert from "../hooks/alertHook";
const CardTeams = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [cardTeamss, setcardTeams] = useState();
  const { alertModal, displayAlert, dismissAlert } = useAlert();

  function getAllTeams() {
    axios
      .get(`${baseURL}/teams/getAllTeamsBoosted`)
      .then((res) => {
        setcardTeams(res.data);
      })
      .catch((err) => {
        logError();
      });
  }
  useEffect(function () {
    getAllTeams();
  }, []);
  async function updateStatus_team(id) {
    displayAlert(
      "danger",
      "",
      "Voulez-vous confirmer l'annulation de boost?",
      "Supprimer",
      () => {
        axios
          .get(`${baseURL}/teams/updateBoostedTeamStatus/${id}`)
          .then((resp) => {
            if (resp.data.error) return;
            displayAlert("sucess", "", "Le boost d'equipe est annulé");
            setTimeout(() => {
              dismissAlert();
              getAllTeams();
            }, 2500);
          })
          .catch((error) => {
            dismissAlert();
            logError();
            displayAlert("danger", "", error.response.data.message);
          });
      },
      "cancel",
      dismissAlert
    );
  }

  return (
    <section className="pt-5 ">
      <div className="row mx-0">
        <div className="col-4">
          <h4 className="text-nowrap">Les Equipes Boostés</h4>
        </div>
      </div>
      <section>
        <div className="row mt-4 mx-0">
          <div className="table-responsive ">
            <Table className="table" responsive="sm" hover>
              <thead className="style_thead">
                <tr>
                  <th className="py-4 style_tab style_th"></th>
                  <th className="py-4 style_tab style_th text-nowrap">
                    Nom Des équipes Boostés
                  </th>
                  <th className="py-4 style_tab style_th text-nowrap">
                    Date de début boost
                  </th>
                  <th className="py-4 style_tab style_th text-nowrap">
                    Date De fin boost
                  </th>
                  <th className="py-4 style_tab style_th text-nowrap">
                    Status
                  </th>
                  <th className="py-4 style_tab style_th text-nowrap">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className=" style_tbody">
                {cardTeamss?.map((team, key) => (
                  <tr key={key} className="border-b style_tbody text-nowrap">
                    <td>
                      <img
                        className="rounded-circle"
                        width="35"
                        height="35"
                        src={`${
                          baseURL +
                          "/team/getFile" +
                          team.team.logo.substring(1)
                        }`}
                        alt="Image Title"
                      />
                    </td>
                    <td className="py-4 text-nowrap Boosted">
                      {team.team.name}
                    </td>
                    <td className="py-4 text-nowrap">
                      {team.date_Debut_Boost}
                    </td>
                    <td className="py-4 text-nowrap">{team.date_Fin_Boost}</td>
                    <td className="py-4">
                      {team.status == "in_progress" ? (
                        <div className="badge p-2 text-center status_prog">
                          En Cours
                        </div>
                      ) : team.status == "expired" ? (
                        <div className="badge p-2 text-center status_expired">
                          Expiré
                        </div>
                      ) : (
                        <div className="badge p-2 text-center status_cancel">
                          Annulé
                        </div>
                      )}
                    </td>
                    <td className="py-4 text-nowrap ">
                    {team.status === "canceled" || team.status === "expired" ? (
                        ""
                      ) : (
                        <div className="d-flex">
                          <UpdateBoostedTeam
                            TeamId={team.team.id}
                            team={team}
                          />
                          <button
                            className=" btn btn-update"
                            onClick={() => {
                              updateStatus_team(team.id);
                            }}
                          >
                            <i
                              class="bi bi-slash-circle"
                              title="canceled boosted team"
                            ></i>
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </section>
      {alertModal}
    </section>
  );
};

export default CardTeams;
