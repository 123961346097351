import React from "react";
import SideBar from "./SideBar";
import { TogglerContextAPI } from "../context/TogglerContextAPI";
import HeaderBar from "./HeaderBar";
import LayoutMain from "./LayoutMain";

const Layout = ({ children }) => {
    return (
        <TogglerContextAPI>
        <div className="d-flex">
            <HeaderBar/>
            <SideBar/>
            <LayoutMain children={children}/>
        </div>
        </TogglerContextAPI>
    );
};
export default Layout;