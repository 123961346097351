import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import useAlert from "../../hooks/alertHook";

const initFormErrors = {
  comp_name: [""],
  comp_slug: [""],
  country: [""],
  date_debut: [""],
  file: [""],
  type: [""],
};
const UpdateCompetitions = ({
  setcreateCompetitionsModalFromChild,
  competitionId,
  getAllCompetitions,
  getOneCompetition
}) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [competitions, setCompetitions] = React.useState();
  const [formerrors, setFormErrors] = React.useState(initFormErrors);
  const uploadedImage = React.useRef(null);

  const [comp_name, setComp_name] = React.useState("");
  const [comp_slug, setComp_slug] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [date_debut, setDate_debut] = React.useState("");
  const [logo, setLogo] = React.useState("");
  const [type, setType] = React.useState("");
  const [logo1, setLogo1] = React.useState("");
  const [previewURL, setPreviewURL] = useState(null);
  const { alertModal, displayAlert, dismissAlert } = useAlert();

  React.useEffect(() => {
    if (competitionId === undefined) return;
    axios.get(`${baseURL}/competitions/${competitionId}`).then((resp) => {
      if (resp.data.error) return;
      const CompetitionData = resp.data.data;
      setComp_name(CompetitionData.comp_name);
      setComp_slug(CompetitionData.comp_slug);
      setType(CompetitionData.type);
      setCountry(CompetitionData.country);
      setDate_debut(CompetitionData.date_debut);
      setLogo(CompetitionData.logo);
    });
  }, [competitionId]);

  function sendData() {
    const formData = new FormData();
    formData.append("comp_name", comp_name);
    formData.append("comp_slug", comp_slug);
    formData.append("country", country);
    formData.append("date_debut", date_debut);
    formData.append("type", type);
    formData.append("logo", logo1);

    axios
      .post(`${baseURL}/competitions/${competitionId}`, formData)
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        displayAlert("success", "", resp.data.message);
        setTimeout(() => {
          dismissAlert();
        }, 2500);
        if (getAllCompetitions) {
          getAllCompetitions();
        }
        if (getOneCompetition) {
          getOneCompetition();
        }
        setcreateCompetitionsModalFromChild("");
      })
      .catch((error) => {
        setFormErrors(error.response.data);
      });
  }

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setLogo1(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewURL(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const [countries, setCountries] = React.useState([]);
  function getCountries() {
    axios.get(`${baseURL}/countries/allCountries`).then((resp) => {
      if (resp.data.error) return;
      resp.data.data.map((item, index) => {
        return setCountries((prevState) => [
          ...prevState,
          { value: item.id, label: item.name },
        ]);
      });
    });
  }

  useEffect(() => {
    getCountries();
  }, []);
  return (
    <div>
      <div className="">
        <div
          className="modal modal1 fade show style_block"
          id="role_modal"
          tabIndex="-1"
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog ">
            <div className="modal-content ">
              <div className="">
                <div className="row pb-4 pt-4 style_header_modal m-0">
                  <div className="col-lg-11">
                    <h3 className="text-center text-white ">
                      Modifier competition
                    </h3>
                  </div>
                </div>
              </div>
              <div className="modal-body ">
                <div className="row mb-4">
                  <div className="col-lg-6">
                    <h6>Nom de compétition</h6>
                    <input
                      value={comp_name != null && comp_name}
                      type="text"
                      className="form-control"
                      id="role_name"
                      placeholder="Nom de compétition"
                      onChange={(e) => setComp_name(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {formerrors.comp_name}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <h6>Abréviation de compétition</h6>
                    <input
                      value={comp_slug != null && comp_slug}
                      type="text"
                      className="form-control"
                      id="role_name"
                      placeholder="Abréviation de compétition"
                      onChange={(e) => setComp_slug(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {formerrors.comp_slug}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-6">
                    <h6>Pays</h6>
                    <Select
                      value={countries.filter(
                        (option) => option.value === country
                      )}
                      options={countries}
                      isSearchable
                      onChange={(e) => {
                        setCountry(e.value);
                      }}
                    />
                    <div className="input-error">
                      <span className="text-danger">{formerrors.country}</span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <h6>Date de début de compétition</h6>
                    <input
                      value={date_debut != null && date_debut}
                      type="date"
                      className="form-control"
                      id="role_name"
                      placeholder="Abréviation de compétition"
                      onChange={(e) => setDate_debut(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {formerrors.date_debut}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-6">
                    <h6>Type</h6>
                    <select
                      name="type"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                      className="form-control"
                    >
                      <option value="championnat">Championnat</option>
                      <option value="coupe">Coupe</option>
                    </select>
                    <div className="input-error">
                      <span className="text-danger">{formerrors.type}</span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <h6>Logo</h6>
                    <input
                      // value={logo != null && logo}
                      required
                      type="file"
                      className="form-control"
                      placeholder="Logo de compétition"
                      onChange={(e) => {
                        handleImageUpload(e);
                      }}
                    />
                    <div className="input-error">
                      <span className="text-danger">{formerrors.file}</span>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-6">
                    {previewURL ? (
                      <img src={previewURL} className="updateImg" />
                    ) : (
                      <img
                        src={
                          baseURL + "/competition/getFile/" + logo.substring(1)
                        }
                        className="updateImg"
                      />
                    )}
                  </div>
                </div>
                <div className="input-error">
                  <span className="text-danger">{formerrors.p}</span>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => setcreateCompetitionsModalFromChild("")}
                >
                  Annuler
                </button>
                <button
                  type="button"
                  onClick={sendData}
                  className="btn btn-primary"
                >
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
        {alertModal}
      </div>
    </div>
  );
};

export default UpdateCompetitions;
