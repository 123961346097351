import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import "react-phone-input-2/lib/style.css";
import { logError } from "../Toastconfig";
import { Link } from "react-router-dom";
import EmptyData from "../emptyData";
import Select from "react-select";
import useAlert from "../../hooks/alertHook";
import LoaderLoading from "../LoaderLoading";
import UpdateMatchStats from "../calendarMatches/UpdateMatchStats";

const initFormErrors = {
  date: [""],
};
const AllJourneys = ({ CompetitionId, competitionDetails }) => {
  const today = new Date().toISOString().substr(0, 10);
  const { alertModal, displayAlert, dismissAlert } = useAlert();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [journeys, setJourneys] = useState([]);
  const [selectedJourney, setSelectedJourney] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [addMatchStatsModal, setAddMatchStatsModal] = React.useState();
  function getALLJourneys() {
    axios
      .get(`${baseURL}/calendarMatches/competitions/${CompetitionId}/journeys`)
      .then((res) => {
        setJourneys(res.data.data);
        setSelectedJourney(res.data.data[0]);
      })
      .catch((err) => {
        logError();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  useEffect(() => {
    getALLJourneys();
  }, []);
  const handleJourneyChange = (selectedOption) => {
    const selectedJourney = journeys.find(
      (journey) => journey.id === selectedOption.value
    );
    setSelectedJourney(selectedJourney);
    setFormErrors("");
  };
  const [formerrors, setFormErrors] = React.useState(initFormErrors);
  const [errorMsg, setErrorMsg] = useState("");
  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post(`${baseURL}/calendarMatches/create`, {
        competitionId: CompetitionId.toString(),
      })
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) {
          setErrorMsg(resp.data.message);
        } else {
          setErrorMsg("");
          displayAlert("success", "", resp.data.message);
          getALLJourneys();
          setTimeout(() => {
            dismissAlert();
          }, 2500);
        }
      })
      .catch((error) => {
        logError();
        setFormErrors(error.response.data);
        setErrorMsg(error.response.data.message);
      });
  };
  const [dateMatches, setDateMatches] = useState([]);

  useEffect(() => {
    if (
      selectedJourney &&
      selectedJourney.journeyMatch &&
      selectedJourney.journeyMatch.length > 0
    ) {
      const dates = selectedJourney.journeyMatch
        .filter((match) => match.date !== null)
        .map((match) => match.date);
      if (dates.length > 0) {
        const nullDates = Array(
          selectedJourney.journeyMatch.length - dates.length
        ).fill(null);
        setDateMatches([...dates, ...nullDates]);
      } else {
        setDateMatches(Array(selectedJourney.journeyMatch.length).fill(null));
      }
    } else {
      setDateMatches([null]);
    }
  }, [selectedJourney]);
  function handletoggle(match, index) {
    displayAlert(
      "info",
      "",
      "Voulez-vous modifier ce match ?",
      "Confirmer",
      (change) => {
        handleUpdate(match, index);
        dismissAlert();
      },
      "cancel",
      dismissAlert
    );
  }
  const handleUpdate = (match, index) => {
    const matchId = match.id;
    const data = {
      date: dateMatches[index],
    };
    axios
      .put(`${baseURL}/calendarMatches/${matchId}`, data)
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        displayAlert("success", "", "Match mis à jours  avec succès !");
        setTimeout(() => {
          dismissAlert();
        }, 2500);
        getALLJourneys();
      })
      .catch((error) => {
        logError();
        const errorMsg = error.response.data.date;
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          [match.id]: errorMsg,
        }));
      });
  };
  return (
    <>
      {isLoading ? (
        <LoaderLoading />
      ) : (
        <div>
          <div className="row">
            {journeys.length === 0 &&
              competitionDetails.type === "championnat" && (
                <div className="col-12 col-md-2 pt-3">
                  <Button variant="primary" onClick={handleSubmit}>
                    Génerer Matches
                  </Button>
                  <div className="input-error">
                    <span className="text-danger">{errorMsg}</span>
                  </div>
                  {alertModal}
                </div>
              )}
            <div className="col-12 col-md-3">
              {journeys.length > 0 && (
                <>
                  <label className="text-muted fw-bolder">
                    <small>Numéro de journée</small>
                  </label>
                  <Select
                    options={journeys.map((journey) => ({
                      value: journey.id,
                      label: `Journée ${journey.journey}`,
                    }))}
                    value={{
                      value: selectedJourney?.id,
                      label: `Journée ${selectedJourney?.journey}`,
                    }}
                    onChange={handleJourneyChange}
                  />
                </>
              )}
            </div>
          </div>

          <div>
            <div className="bg-color">
              {selectedJourney?.journeyMatch
                .sort((a, b) => {
                  if (a.date === null) {
                    return 1;
                  } else if (b.date === null) {
                    return -1;
                  } else {
                    return new Date(a.date) - new Date(b.date); // sort by date ascending
                  }
                })
                .map((match, index) => (
                  <div className="row text-nowrap bg-white my-2 p-3 d-flex justify-content-center">
                    <div className="col-lg-5 col-sm-12 row my-2">
                      <div className="col-md-3 col-sm-12 text-center ">
                        <p>journée {selectedJourney.journey}</p>
                      </div>
                      <div className="col-md-3 col-sm-5  text-center">
                        <Link
                          className="style_text_deco"
                          to={`/admin/oneTeam/${match.homeTeam.id}`}
                        >
                          <img
                            width="33"
                            height="33"
                            src={`${baseURL}/team/getFile${match.homeTeam.logo.slice(
                              1
                            )}`}
                            className="me-3"
                          />
                          <span className="currentTeamName">
                            {match.homeTeam.acronyme}
                          </span>
                        </Link>
                      </div>
                      <div className="col-md-2 col-sm-2  text-center">
                        <p className="mx-2 pt-1">-</p>
                      </div>

                      <div className="col-md-4 col-sm-5  text-center">
                        <Link
                          className="style_text_deco"
                          to={`/admin/oneTeam/${match.awayTeam.id}`}
                        >
                          <img
                            width="33"
                            height="33"
                            src={`${baseURL}/team/getFile${match.awayTeam.logo.slice(
                              1
                            )}`}
                            className="me-3"
                          />
                          <span className="currentTeamName">
                            {match.awayTeam.acronyme}
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div class="col-lg-2 col-sm-12  text-center my-2 ">
                      <img
                        width="29"
                        height="29"
                        src="./assets/imgs/stadium_icon.png"
                        className="me-3"
                      />
                      <span>
                        {match.homeTeam.stade_Name.length > 9
                          ? `${match.homeTeam?.stade_Name.slice(0, 9)}...`
                          : match.homeTeam?.stade_Name}
                      </span>
                    </div>
                    <div className="col-lg-5 col-sm-12 my-2 ">
                      <div className="row d-flex justify-content-center">
                        <div className="col-1 pt-1 ">
                          <i
                            class="fa-solid fa-calendar-days fa-xl"
                            style={{ color: "#adb1b8" }}
                          ></i>
                        </div>
                        <div className="col-7">
                          <input
                            type="date"
                            min={today}
                            className="form-control"
                            id="date"
                            value={
                              dateMatches[index] !== null
                                ? dateMatches[index]
                                : ""
                            }
                            placeholder="Date"
                            onChange={(e) => {
                              const newDateMatches = [...dateMatches];
                              newDateMatches[index] = e.target.value;
                              setDateMatches(newDateMatches);
                            }}
                          />
                          <div className="input-error">
                            <span className="text-danger">
                              {formerrors[match.id]}
                            </span>
                          </div>
                        </div>
                        <div className="col-4 d-flex justify-content-evenly align-items-center">
                          <button onClick={() => handletoggle(match, index)}>
                            <i className="fa-solid fa-pen-to-square"></i>
                          </button>
                          <Link
                            to={`/admin/oneCompetition/matches/matchDetails/${match.id}`}
                          >
                            <button type="button" className="btn p-0 m-0">
                              <i
                                className="fa-solid fa-circle-info"
                                title="Details match"
                              ></i>
                            </button>
                          </Link>
                          <button
                            type="button"
                            className="btn"
                            onClick={() =>
                              setAddMatchStatsModal(
                                <UpdateMatchStats
                                  setUpdateMatchStatsModalFromChild={
                                    setAddMatchStatsModal
                                  }
                                  id={match.id}
                                />
                              )
                            }
                          >
                            <i className="fa-solid fa-chart-simple statsIcon"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
      {alertModal}
      {addMatchStatsModal}
    </>
  );
};

export default AllJourneys;
