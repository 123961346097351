import React from "react";
import RegisterFormStep1 from "./RegisterFormStep1";
import SimpleUser from "./SimpleUser";
import { Link } from "react-router-dom";

const RegisterType = ({ setChildComponent }) => {
  return (
    <section className="container-fluid bg-main">
      <div className="container py-5 h-100 ">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col">
            <div className="card card_style border-0 h-100">
              <div className="row g-0">
                <div className="col-md-6 col-lg-6 d-none d-md-block bg-log">
                  <div className="pt-5 px-3">
                    <img src="img/logo.png"></img>
                    <h2 className="my-4 title white">
                      La meilleure plateforme pour trouver le meilleur joueur !
                    </h2>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 d-flex align-items-center">
                  <div className="card-body text-black ">
                    <div className="">
                      <h2 className="title black mt-4 mb-1 pb-1 text-center">
                        Hey ! Content de te voir
                      </h2>
                      <h4 className="black mt-4 mb-5 pb-1">
                        Terminez ce que vous avez commencé, de nouvelles
                        statistiques sont ici
                      </h4>
                    </div>

                    <div className="d-grid gap-2 col-6 mx-auto">
                      <button
                        type="button"
                        className="btn btn-primary mt-5 mb-3 fs-5"
                        onClick={() => {
                          setChildComponent(
                            <RegisterFormStep1
                              setChildComponent={setChildComponent}
                            />
                          );
                        }}
                      >
                        Sociéte
                      </button>

                      <button
                        type="button"
                        className=" btn btn-light fs-5 buttonconection"
                        onClick={() => {
                          setChildComponent(
                            <SimpleUser setChildComponent={setChildComponent} />
                          );
                        }}
                      >
                        Utilisateur Simple
                      </button>
                    </div>

                    <div className="text-center pt-md-4 mx-md-4  mt-5">
                      <p className="text-black">
                        Vous avez déjà un compte? Cliquez ici pour{" "}
                        <Link to="/login" className="btn-click">
                        S'authentifier
                        </Link>
                      </p>
                    </div>

                    <div className=" text-center px-md-4 mx-md-4  mt-2">
                      <a href="#">
                        <i className="fa-brands fa-facebook-f p-2 "></i>
                      </a>
                      <a href="#">
                        <i className="fa-brands fa-instagram p-2"></i>
                      </a>
                      <a href="#">
                        <i className="fa-brands fa-youtube p-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegisterType;
