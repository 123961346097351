import axios from "axios";
import React, { useEffect, useState } from "react";
import useAlert from "../../hooks/alertHook";

const UpdatePlayerstatsOfMatch = ({
  matchId,
  playerId,
  GetPlayerStatsOfMatchBymatchId,
  setUpdateMatchStatsModalFromChild,
  playersStatsData,
}) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const initFormErrors = {
    minutesPlayed: [""],
    goals: [""],
    assists: [""],
    touches: [""],
    accPasses: [""],
    keyPasses: [""],
    crossesAcc: [""],
    longBallsAcc: [""],
    bigChancesCreated: [""],
    shotsOnTarget: [""],
    shotsOffTarget: [""],
    shotsBlocked: [""],
    dribbleAttemptsSucc: [""],
    groundDuelsWon: [""],
    aerialDuelsWon: [""],
    possessionLost: [""],
    fouls: [""],
    wasFouled: [""],
    offsides: [""],
    clearances: [""],
    blockedShots: [""],
    interceptions: [""],
    tackles: [""],
    dribbledPast: [""],
  };
  const [minutesPlayed, setminutesPlayed] = useState(
    playersStatsData.minutesPlayed
  );
  const [goals, setgoals] = useState(playersStatsData.goals);
  const [selected, setselected] = useState("no");
  const [assists, setassists] = useState(playersStatsData.assists);
  const [touches, settouches] = useState(playersStatsData.touches);
  const [accPasses, setaccPasses] = useState(playersStatsData.accPasses);
  const [keyPasses, setkeyPasses] = useState(playersStatsData.keyPasses);
  const [crossesAcc, setcrossesAcc] = useState(playersStatsData.crossesAcc);
  const [longBallsAcc, setlongBallsAcc] = useState(
    playersStatsData.longBallsAcc
  );
  const [bigChancesCreated, setbigChancesCreated] = useState(
    playersStatsData.bigChancesCreated
  );
  const [shotsOnTarget, setshotsOnTarget] = useState(
    playersStatsData.shotsOnTarget
  );
  const [shotsOffTarget, setshotsOffTarget] = useState(
    playersStatsData.shotsOffTarget
  );
  const [shotsBlocked, setshotsBlocked] = useState(
    playersStatsData.shotsBlocked
  );
  const [dribbleAttemptsSucc, setdribbleAttemptsSucc] = useState(
    playersStatsData.dribbleAttemptsSucc
  );
  const [groundDuelsWon, setgroundDuelsWon] = useState(
    playersStatsData.groundDuelsWon
  );
  const [aerialDuelsWon, setaerialDuelsWon] = useState(
    playersStatsData.aerialDuelsWon
  );
  const [possessionLost, setpossessionLost] = useState(
    playersStatsData.possessionLost
  );
  const [fouls, setfouls] = useState(playersStatsData.fouls);
  const [wasFouled, setwasFouled] = useState(playersStatsData.wasFouled);
  const [offsides, setoffsides] = useState(playersStatsData.offsides);
  const [clearances, setclearances] = useState(playersStatsData.clearances);
  const [blockedShots, setblockedShots] = useState(
    playersStatsData.blockedShots
  );
  const [interceptions, setinterceptions] = useState(
    playersStatsData.interceptions
  );
  const [tackles, settackles] = useState(playersStatsData.tackles);
  const [dribbledPast, setdribbledPast] = useState(
    playersStatsData.dribbledPast
  );
  const [show, setShow] = useState(false);
  const [selectedPlayerId, setSelectedPlayerId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(initFormErrors);
  const { alertModal, displayAlert, dismissAlert } = useAlert();

  function UpdateStatsPlayer() {
    axios
      .put(
        `${baseURL}/calendarMatches/UpdatePlayerStatsMatch/${matchId}/${playerId}`,
        {
          minutesPlayed: minutesPlayed,
          goals: goals,
          assists: assists,
          touches: touches,
          accPasses: accPasses,
          keyPasses: keyPasses,
          crossesAcc: crossesAcc,
          longBallsAcc: longBallsAcc,
          bigChancesCreated: bigChancesCreated,
          shotsOnTarget: shotsOnTarget,
          shotsOffTarget: shotsOffTarget,
          shotsBlocked: shotsBlocked,
          dribbleAttemptsSucc: dribbleAttemptsSucc,
          groundDuelsWon: groundDuelsWon,
          aerialDuelsWon: aerialDuelsWon,
          possessionLost: possessionLost,
          fouls: fouls,
          wasFouled: wasFouled,
          offsides: offsides,
          clearances: clearances,
          blockedShots: blockedShots,
          interceptions: interceptions,
          tackles: tackles,
          dribbledPast: dribbledPast,
        }
      )
      .then((resp) => {
        setErrorMessage(initFormErrors);
        if (resp.data.error) return;
        displayAlert("success", "", resp.data.message);
        setTimeout(() => {
          dismissAlert();
          GetPlayerStatsOfMatchBymatchId();
          setUpdateMatchStatsModalFromChild();
        }, 2500);
      })
      .catch((error) => {
        const errors = error.response.data;
        setErrorMessage(errors);
      });
  }
  return (
    <>
    <div>
      <div
        className=" modal_stats modal modal1 fade show style_block"
        id="role_modal"
        tabIndex="-1"
        aria-modal="true"
        role="dialog"
      >
        <div className="modal-dialog ">
          <div className="modal-content ">
            <div>
              <div className="row pb-4 pt-4 style_header_modal m-0">
                <div className="col-11">
                  <h3 className="text-center text-white ">
                    Modifier statistiques de Joueur
                  </h3>
                </div>
              </div>
            </div>
            <div className="modal-body ">
              <div>
                <div className="row mb-4">
                  <div className="col-lg-3">
                    <label className="fw-bold">
                      minutes jouées par match :
                    </label>
                    <input
                      value={minutesPlayed != null && minutesPlayed}
                      type="number"
                      pattern="[0-9]*"
                      className="form-control"
                      placeholder="Matchs joués"
                      onChange={(e) => setminutesPlayed(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessage.minutesPlayed}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <label className="fw-bold">Buts :</label>
                    <input
                      value={goals != null && goals}
                      type="number"
                      className="form-control"
                      placeholder="buts"
                      onChange={(e) => setgoals(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">{errorMessage.goals}</span>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <label className="fw-bold">Assists :</label>
                    <input
                      value={assists != null && assists}
                      type="number"
                      className="form-control"
                      placeholder="Assists"
                      onChange={(e) => setassists(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessage.assists}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <label className="fw-bold">Tirs cadrés par match : </label>
                    <input
                      value={shotsOnTarget != null && shotsOnTarget}
                      type="number"
                      className="form-control"
                      placeholder="Tirs cadrés par match"
                      onChange={(e) => setshotsOnTarget(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessage.shotsOnTarget}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-3">
                    <label className="fw-bold">
                      Tirs non cadrés par match :
                    </label>
                    <input
                      value={shotsOffTarget != null && shotsOffTarget}
                      type="number"
                      className="form-control"
                      placeholder="Tirs non cadrés par match"
                      onChange={(e) => setshotsOffTarget(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessage.shotsOffTarget}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <label className="fw-bold">Tirs Blockés par match : </label>
                    <input
                      value={shotsBlocked != null && shotsBlocked}
                      type="number"
                      className="form-control"
                      placeholder="Tirs Blockés par match"
                      onChange={(e) => setshotsBlocked(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">{errorMessage.shotsBlocked}</span>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <label className="fw-bold">Grandes occasions crées :</label>
                    <input
                      value={bigChancesCreated != null && bigChancesCreated}
                      type="number"
                      className="form-control"
                      placeholder="Grandes occasions crées"
                      onChange={(e) => setbigChancesCreated(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">{errorMessage.bigChancesCreated}</span>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <label className="fw-bold">Dribbles réuissies : </label>
                    <input
                      value={dribbleAttemptsSucc != null && dribbleAttemptsSucc}
                      type="number"
                      className="form-control"
                      placeholder="Dribbles réuissies"
                      onChange={(e) => setdribbleAttemptsSucc(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">{errorMessage.dribbleAttemptsSucc}</span>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-3">
                    <label className="fw-bold">Duels au sol gagnés :</label>
                    <input
                      value={groundDuelsWon != null && groundDuelsWon}
                      type="number"
                      className="form-control"
                      placeholder="Duels au sol gagnés"
                      onChange={(e) => setgroundDuelsWon(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessage.groundDuelsWon}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <label className="fw-bold">Duels aériens gagnés :</label>
                    <input
                      value={aerialDuelsWon != null && aerialDuelsWon}
                      type="number"
                      className="form-control"
                      placeholder="Duels aériens gagnés"
                      onChange={(e) => setaerialDuelsWon(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessage.aerialDuelsWon}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <label className="fw-bold">Possession perdue : </label>
                    <input
                      value={possessionLost != null && possessionLost}
                      type="number"
                      className="form-control"
                      placeholder="Possession perdue"
                      onChange={(e) => setpossessionLost(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessage.possessionLost}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <label className="fw-bold">Fautes : </label>
                    <input
                      value={fouls != null && fouls}
                      type="number"
                      className="form-control"
                      placeholder="Fautes"
                      onChange={(e) => setfouls(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">{errorMessage.fouls}</span>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-3">
                    <label className="fw-bold">Touches : </label>
                    <input
                      value={touches != null && touches}
                      type="number"
                      className="form-control"
                      placeholder="Touches"
                      onChange={(e) => settouches(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessage.touches}
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <label className="fw-bold">Passe-clés : </label>
                    <input
                      value={keyPasses != null && keyPasses}
                      type="number"
                      className="form-control"
                      placeholder="Passe-clés"
                      onChange={(e) => setkeyPasses(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessage.keyPasses}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <label className="fw-bold">Fautes gangés :</label>
                    <input
                      value={wasFouled != null && wasFouled}
                      type="number"
                      className="form-control"
                      placeholder="Fautes gangés"
                      onChange={(e) => setwasFouled(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessage.wasFouled}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <label className="fw-bold">Dégagements par match : </label>
                    <input
                      value={clearances != null && clearances}
                      type="number"
                      className="form-control"
                      placeholder="Dégagements par match"
                      onChange={(e) => setclearances(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessage.clearances}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-3">
                    <label className="fw-bold">Hors-jeu :</label>
                    <input
                      value={offsides != null && offsides}
                      type="number"
                      className="form-control"
                      placeholder="Hors-jeu"
                      onChange={(e) => setoffsides(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessage.offsides}
                      </span>
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <label className="fw-bold">Interceptions par match :</label>
                    <input
                      value={interceptions != null && interceptions}
                      type="number"
                      className="form-control"
                      placeholder="Interceptions par match"
                      onChange={(e) => setinterceptions(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessage.interceptions}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <label className="fw-bold">Tacles par match :</label>
                    <input
                      value={tackles != null && tackles}
                      type="number"
                      className="form-control"
                      placeholder="Tacles par match"
                      onChange={(e) => settackles(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessage.tackles}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <label className="fw-bold">Dribble par match :</label>
                    <input
                      value={dribbledPast != null && dribbledPast}
                      type="number"
                      className="form-control"
                      placeholder="Dribble par match"
                      onChange={(e) => setdribbledPast(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessage.dribbledPast}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-4">
                    <label className="fw-bold">Passes (ACC) : </label>
                    <input
                      value={accPasses != null && accPasses}
                      type="number"
                      pattern="[0-9]*"
                      className="form-control"
                      placeholder="Passes "
                      onChange={(e) => setaccPasses(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessage.accPasses}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <label className="fw-bold">Centres(ACC) :</label>
                    <input
                      value={crossesAcc != null && crossesAcc}
                      type="number"
                      className="form-control"
                      placeholder="centres"
                      onChange={(e) => setcrossesAcc(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessage.crossesAcc}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <label className="fw-bold">Passes longues(ACC) :</label>
                    <input
                      value={longBallsAcc != null && longBallsAcc}
                      type="number"
                      className="form-control"
                      placeholder="Passes longues"
                      onChange={(e) => setlongBallsAcc(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessage.longBallsAcc}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => setUpdateMatchStatsModalFromChild("")}
              >
                Annuler
              </button>
              <button
                type="button"
                className="btn btn-primary"
                onClick={UpdateStatsPlayer}
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show"></div>
      {alertModal}
    </div>
    </>

  );
};

export default UpdatePlayerstatsOfMatch;
