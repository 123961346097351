import React, { useState, useEffect,useContext } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import useAlert from "../../hooks/alertHook";
import Select from "react-select";
import { UserContext } from "../../context/UserContext";

// to initialize errors message
const initFormErrors = {
  name: [""],
  email: [""],
  adress: [""],
  CodePostal: [""],
  file: [""],
  type: [""],
  RoleId: [""],
  siret_num: [""],
};
export default function UpdateUser({ User, getall, avatarUser }) {
  const baseURL = process.env.REACT_APP_BASE_URL;
  // modal props
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setname("");
    setemail("");
    setadress("");
    setCodePostal("");
    settype("");
    setsiret_num("");
    setErrorMessage(initFormErrors);
  };
  // user props
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [adress, setadress] = useState("");
  const [CodePostal, setCodePostal] = useState("");
  const [type, settype] = useState("");
  const [RoleId, setRoleId] = useState();
  const [siret_num, setsiret_num] = useState("");
  const [errorMessage, setErrorMessage] = useState(initFormErrors);
  const { alertModal, displayAlert, dismissAlert } = useAlert();
  const [avatar, setAvatar] = useState(null);
  const {user, setUser } = useContext(UserContext);

  function GetRoleById(roleId) {
    if (roleId === undefined) return Promise.resolve([]); // Return an empty array if roleId is undefined
    return axios.get(`${baseURL}/roles/getRoleById/${roleId}`)
      .then((resp) => {
        if (resp.data.error) return []; // Return an empty array if there is an error
        return resp.data.data.permissions.Permissions || []; // Return permissions or an empty array if undefined
      })
      .catch(() => []); 
  } 
  useEffect(() => {
    GetRoleById();
  }, []);
  useEffect(() => {
    if (User === undefined) return;
    getuser();
    // eslint-disable-next-line
  }, [User]);

  function getuser() {
    setname(User.name);
    setemail(User.email);
    setadress(User.adress);
    setCodePostal(User.CodePostal);
    settype(User.type);
    setRoleId(User.RoleId);
    setsiret_num(User.siret_num);
    setAvatar(User.avatar)
  }
  function senData() {
    const formData = new FormData();
    formData.append('name', name);
    formData.append('email', email);
    formData.append('adress', adress);
    formData.append('CodePostal', CodePostal);
    formData.append('type', type);
    formData.append('RoleId', RoleId.toString());
    formData.append('siret_num', siret_num);
    formData.append('avatar', avatar);
    axios
      .post(`${baseURL}/users/update/${User.id}`, formData)
      .then((resp) => {
        setErrorMessage(initFormErrors);
        if (resp.data.error) return;
         displayAlert("success","",resp.data.message );
         setTimeout(() => {
          dismissAlert();
       },2500);
         // Check if the updated user is the currently logged-in user
      if (user.id === resp.data.data.id) {
        const selectedRole = roles.find((role) => role.value === RoleId);
        const selectedRoleId = selectedRole ? selectedRole.label : null;

        GetRoleById(RoleId).then((permissions) => {
          const updatedUserData = {
            ...user,
            name: resp.data.data.name,
            email: resp.data.data.email,
            adress: resp.data.data.adress,
            CodePostal: resp.data.data.CodePostal,
            type: resp.data.data.type,
            roleId: resp.data.data.RoleId,
            role: selectedRoleId,
            permissions: permissions,
            siret_num: resp.data.data.siret_num,
            avatar: resp.data.data.avatar,
          };

          // Update the specific fields in the user object
          setUser(updatedUserData);
        });
      }
      
      getall();
      handleClose();
    })
    .catch((error) => {
      const errors = error.response.data;
      setErrorMessage(errors);
    });
}
  const [roles, setroles] = useState([]);
  useEffect(() => {
    getallroles();
  }, []);
  function getallroles() {
    axios
      .get(`${baseURL}/roles/`)
      .then((res) => {
        res.data.data.map((item, index) => {
          return setroles((prevState) => [
            ...prevState,
            { value: item.id, label: item.name },
          ]);
        });
      })
      .catch((err) => {});
  } 
  return (
    <>
      <button className=" btn btn-update" onClick={handleShow}>
        <i className="fa-solid fa-pen-to-square" title="Update this user"></i>
      </button>
      <Modal size="lg" aria-labelledby="contained-modal-title-vcenter"
 show={show} onHide={handleClose}>
        <Modal.Header className="style_modalHeader">
        <div>
          <Modal.Title className="style_modalTitle">
        Modifier Utilisateur
          </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body className="mt-3">
          <section className="">
            <div className="container">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="form-outline">
                    <label className="form-label" for="Name">
                      Nom :
                    </label>
                    <input
                      type="text"
                      id="name"
                      placeholder="Nom"
                      value={name}
                      className="form-control form-control"
                      onChange={(e) => setname(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">{errorMessage.name}</span>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 mb-3">
                  <div className="form-outline">
                    <label className="form-label" for="Email">
                      Email :
                    </label>
                    <input
                      type="email"
                      placeholder="Email"
                      id="email"
                      value={email}
                      className="form-control form-control"
                      onChange={(e) => setemail(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">{errorMessage.email}</span>
                    </div>
                  </div>
                </div>
                {type === "professionel" ? (
                  <div className="col-md-6 mb-3">
                    <div className="form-outline">
                      <label className="form-label" for="Adresse">
                        Numéro Siret :
                      </label>
                      <input
                        type="text"
                        id="adresse"
                        placeholder="Numéro Siret"
                        value={siret_num != null ? siret_num : ""}
                        className="form-control form-control"
                        onChange={(e) => setsiret_num(e.target.value)}
                        required
                      />
                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessage.siret_num}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                <div className="col-md-6 mb-3">
                  <div className="form-outline">
                    <label className="form-label" for="Adresse">
                      Adresse :
                    </label>
                    <input
                      type="text"
                      id="adresse"
                      value={adress}
                      placeholder="Adresse"
                      className="form-control form-control"
                      onChange={(e) => setadress(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">{errorMessage.adress}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="form-outline">
                    <label className="form-label" for="code_postale">
                      Code Postal :
                    </label>
                    <input
                      type="text"
                      id="code_postale"
                      placeholder="Code postal"
                      value={CodePostal}
                      className="form-control form-control"
                      onChange={(e) => setCodePostal(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessage.CodePostal}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <h6 className="mb-2 pb-1">Choisir un role : </h6>
                  <div className="input-error">
                    <span className="text-danger">{errorMessage.RoleName}</span>
                  </div>
                  <Select
                    options={roles}
                    isSearchable
                    placeholder="roles"
                    value={roles.filter((option) => option.value === RoleId)}
                    onChange={(e) => setRoleId(e.value)}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <h6 className="mb-2 pb-1">Type : </h6>
                  <div className="input-error">
                    <span className="text-danger">{errorMessage.type}</span>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      value="personnel"
                      checked={type === "personnel"}
                      onChange={(e) => settype(e.target.value)}
                    />
                    <label className="form-check-label" for="personnel">
                      Personnel
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      value="professionel"
                      checked={type === "professionel"}
                      onChange={(e) => settype(e.target.value)}
                    />
                    <label className="form-check-label" for="professionel">
                      Professionel
                    </label>
                  </div>
                </div>
                  <div className="col-md-6 mb-3">
                  <h6 className="mb-2 pb-1">Avatar : </h6>
                 
                  <input
                  required
                  id="avatar"
                  accept="image/*"
                  name="avatar"
                  type="file"
                  className="form-control"
                  onChange={(e) => {
                  
                    setAvatar(e.target.files[0]);
                  }}
                />
                <div className="input-error">
                    <span className="text-danger">{errorMessage.file}</span>
                  </div>
                </div><div className="col-lg-6">
                  </div>
              </div>
            </div>
          </section>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center mb-4 border-0">
          <Button
            variant="primary"
            className="btn-modal"
            onClick={senData}
          >
            Enregistrer
          </Button>
          <Button
            variant="outline-secondary"
            className="btn-modal"
            onClick={handleClose}
          >
            Annuler
          </Button>
        </Modal.Footer>
      </Modal>
      {alertModal}
    </>
  );
}
