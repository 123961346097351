import React from "react";
import "./../../pages/news/news.css";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import parse from "html-react-parser";
import { logError } from "../../components/Toastconfig";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ReactQuill from "react-quill";
import useAlert from "../../hooks/alertHook";

export default function NewsDetailsMatch() {
  const initFormErrors = {
    title: [""],
    content: [""],
    file:[""]
  };
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { newsId } = useParams();
  const [news, setNews] = useState({
    title: "",
    content: "",
    media: "",
    createdAt: "",
    updatedAt: "",
  });

  useEffect(() => {
    getnews();
  }, []);

  function getnews() {
    if (newsId === undefined) return;
    axios
      .get(`${baseURL}/calendarMatches/matchDetails/news/${newsId}`)
      .then((res) => {
        setNews(res.data.data);
        settitle(res.data.data.title);
        setContent(res.data.data.content);
        setmedia(res.data.data.media);
        setFormErrors(initFormErrors);
      })
      .catch((err) => {
        logError();
      });
  }
  const [title, settitle] = useState("");
  const [media, setmedia] = useState("");
  const [content, setContent] = useState("");
  const [formErrors, setFormErrors] = useState(initFormErrors);
  const { alertModal, displayAlert, dismissAlert } = useAlert();

  const uploadedImage = React.useRef(null);
  const [imageUploaded, setImageUploaded] = React.useState(false);

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
        setImageUploaded(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }],
    ["image", "link"],
    [{ font: [] }],
    [{ align: [] }],

    ["clean"],
  ];
  function sendData() {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    formData.append("media", media);
    axios
      .put(`${baseURL}/calendarMatches/matchDetails/news/update/${news.id}`, formData)
      .then((resp) => {
        if (resp.data.error) {
          setFormErrors(resp.data.message);
          return;
        }
        displayAlert("success", "", resp.data.message);
        setTimeout(() => {
          dismissAlert();
          handleClose();
          getnews();

       },2500);
      })
      .catch((error) => {
        setFormErrors(error.response.data);
      });
  }
  return (
    <>
         <section className="p-4">
        <div className="text-end p-2 ">
        <button  type="button" className="btn btn-dark btn-sm text-end" onClick={handleShow} > Modifier </button>
        </div>
        <div className="container-fluid">
          <div className="row ">
            <div className="col-8 ">
              <div className="row">
                <img
                  className="imgNewsDetails"
                  src={`${baseURL + "/competition/getFile" + news.media?.substring(1)}`}
                  alt="media"
                />
              </div>
              <div className="row d-flex flex-column">
                <div className="d-flex flex-row-reverse">
                  <div className="news_grey_style p-2 mt-2">
                  </div>
                </div>
                <div className="d-flex flex-row-reverse">
                  <div className="news_grey_style p-2 mt-2">
                  Enregistrer
                    <button className="btn btn-sm ms-1">
                      <i className="fa-sharp fa-regular fa-bookmark"></i>
                    </button>
                  </div>
                </div>
                <div className="p-2 news_grey_style">
                  {news.createdAt?.substring(0, 10)}
                </div>
              </div>
              <div className="row pt-2">
                <h4 className="news_title">{news.title}</h4>
                <div>{parse(news.content)}</div>
              </div>
              <div className="row p-3 newstextDetails">
                <div className="bg-white">Actualités Connexes</div>
              </div>
            </div>
            <div className="col-4 ">
              <div className="row p-2 bg-white newstextDetails">Actualités Plus Lus</div>
              <div className="row  p-2 bg-white mt-4 newstextDetails">
              Actualités Enregistrées
              </div>
            </div>
          </div>
        </div>
        <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="style_modalHeader">
          <div>
            <Modal.Title className="style_modalTitle">
            Modifier actualité
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
        <div className="m-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="row mb-1">
                        <div className="col-lg-6">
                          <h6>Titre</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Titre"
                          value={title}
                            onChange={(e) => settitle(e.target.value)}
                            required
                          />
                          <div className="input-error">
                            <span className="text-danger">{formErrors.title}</span>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <h6>Couverture </h6>
                          <input
                            required
                            type="file"
                            className="form-control"
                            placeholder="actualité media"
                            onChange={(e) => {
                              handleImageUpload(e);
                              setmedia(e.target.files[0]);
                            }}
                          />
                             <div className="input-error">
                            <span className="text-danger">{formErrors.file}</span>
                          </div>
                        </div>
                      </div>
                      <div className="mb-3">
                        <h6>Contenu </h6>
                        <ReactQuill
                          theme="snow"
                          modules={{
                            toolbar: toolbarOptions,
                          }}
                          value={content}
                          placeholder="Contenu..."
                          onChange={(value) => {
                            setContent(value);
                          }}
                        />
                            <div className="input-error">
                            <span className="text-danger">{formErrors.content}</span>
                          </div>
                      </div>
                      <div className="row mb-1">
              <div className="col-lg-6">
                {!imageUploaded && (
                  <img
                    className="UpdateImgNews"
                    src={`${
                      baseURL + "/competition/getFile" + news.media?.substring(1)
                    }`}
                    alt="media"
                  />
                )}
                <img
                  ref={uploadedImage}
                  className="UpdateImgNews"

                />
              </div>
            </div>
                    </div>
                  </div>
                </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={sendData}>
            Confirmer
          </Button>
          <Button
            variant="secondary"
            className="style_modalTitle"
            onClick={handleClose}
          >
            Annuler
          </Button>
        </Modal.Footer>
      </Modal>
    {alertModal}
      </section>
    </>
  );
}
