import React from "react";
import "react-phone-input-2/lib/style.css";
import { Link } from "react-router-dom";

const DisplayLastTransfers = ({ transfers }) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  return (
    <>
      {transfers && transfers.length === 0 ? null : (
        <section className="pt-2">
          <div className="card CardShadow p-1 d-flex flex-column gap-2">
            <h5 className=" mt-2">Derniers Transferts</h5>
            {transfers?.map((transfer, key) => (
              <Link
                className="style_text_deco"
                to={`/player/details/${transfer.playerId}`}
                key={key}
              >
                <div className="p-2 mb-2 row">
                  <div className="col-3">
                    <img
                      className="team-logo"
                      src={
                        baseURL +
                        "/team/getFile" +
                        transfer.team_fromLogo?.substring(1)
                      }
                    />
                  </div>
                  <div className="col-1">
                    <i className="bi bi-chevron-double-right"></i>
                  </div>
                  <div className="col-3">
                    <div className="">
                      <img
                        className="player-avatar me-2"
                        src={
                          baseURL +
                          "/players/getFile" +
                          transfer.playerAvatar?.substring(1)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-1">
                    <i className="bi bi-chevron-double-right"></i>
                  </div>
                  <div className="col-3">
                    <img
                      className="team-logo"
                      src={
                        baseURL +
                        "/team/getFile" +
                        transfer.team_toLogo?.substring(1)
                      }
                    />
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </section>
      )}
    </>
  );
};

export default DisplayLastTransfers;
