import React, { useState, useEffect } from "react";
import axios from "axios";
import { logError } from "../components/Toastconfig";
import EmptyData from "../components/emptyData";
import Table from "react-bootstrap/Table";
import UpdateBoostPlayer from "../components/players/UpdateBoostPlayer";
import useAlert from "../hooks/alertHook";

const CardPlayers = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { alertModal, displayAlert, dismissAlert } = useAlert();

  const [cardPlayerss, setcardPlayers] = useState();
  useEffect(function () {
    getAllPlayers();
  }, []);
  function getAllPlayers() {
    axios
      .get(`${baseURL}/players/getAllPlayersBoosted`)
      .then((res) => {
        setcardPlayers(res.data);
      })
      .catch((err) => {
        logError();
      });
  }
  async function updateStatus_player(id) {
    displayAlert(
      "danger",
      "",
      "Voulez-vous confirmer l'annulation de boost?",
      "Supprimer",
      () => {
        axios
          .get(`${baseURL}/players/updateBoostedPlayerStatus/${id}`)
          .then((resp) => {
            if (resp.data.error) return;
            displayAlert("sucess", "", "Le boost de joueur est annulé");
            setTimeout(() => {
              dismissAlert();
              getAllPlayers();
            }, 2500);
          })
          .catch((error) => {
            dismissAlert();
            logError();
            displayAlert("danger", "", error.response.data.message);
          });
      },
      "cancel",
      dismissAlert
    );
  }

  return (
    <section className="pt-5 ">
      <div className="row mx-0">
        <div className="col-4">
          <h4 className="text-nowrap">Les Joueurs Boostés</h4>
        </div>
      </div>
      <section>
        <div className="row mt-4 mx-0">
          <div className="table-responsive ">
            <Table className="table" responsive="sm" hover>
              <thead className="style_thead">
                <tr>
                  <th className="py-4 style_tab style_th"></th>
                  <th className="py-4 style_tab style_th text-nowrap">
                    Nom Des joueurs Boostés
                  </th>
                  <th className="py-4 style_tab style_th text-nowrap">
                    Date de début boost
                  </th>
                  <th className="py-4 style_tab style_th text-nowrap">
                    Date De fin boost
                  </th>
                  <th className="py-4 style_tab style_th text-nowrap">
                    Status
                  </th>
                  <th className="py-4 style_tab style_th text-nowrap">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="style_tbody">
                {cardPlayerss?.map((player, key) => (
                  <tr key={key} className="border-b style_tbody text-nowrap">
                    <td>
                      {player.player.currentplayer === null ? (
                        <img
                          className="ms-2"
                          src="./assets/imgs/emptyTeam.png"
                          width="35"
                          height="35"
                        />
                      ) : (
                        <img
                          className="rounded-circle"
                          width="35"
                          height="35"
                          src={`${
                            baseURL +
                            "/players/getFile" +
                            player.player.avatar?.substring(1)
                          }`}
                        />
                      )}
                    </td>
                    <td className="py-4 text-nowrap Boosted">
                      {player.player.firstName} {player.player.lastName}
                    </td>
                    <td className="py-4 text-nowrap">
                      {player.date_Debut_Boost}
                    </td>
                    <td className="py-4 text-nowrap">
                      {player.date_Fin_Boost}
                    </td>
                    <td className="py-4">
                      {player.status == "in_progress" ? (
                        <div className="badge p-2 text-center status_prog ">
                          En Cours
                        </div>
                      ) : player.status == "canceled" ? (
                        <div className="badge p-2 text-center  status_cancel">
                          Annulé
                        </div>
                      ) : (
                        <div className="badge p-2 text-center status_expired">
                          expiré
                        </div>
                      )}
                    </td>
                    <td className="py-4 text-nowrap ">
                      {player.status == "canceled" || player.status === "expired"  ? (
                        ""
                      ) : (
                        <div className="d-flex">
                          <UpdateBoostPlayer
                            PlayerId={player.player.id}
                            player={player}
                          />
                          <button
                            className=" btn btn-update"
                            onClick={() => {
                              updateStatus_player(player.id);
                            }}
                          >
                            <i
                              class="bi bi-slash-circle"
                              title="canceled boosted player"
                            ></i>
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </section>
      {alertModal}
    </section>
  );
};

export default CardPlayers;
