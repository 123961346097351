import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../pages/players/player.css";
import PlayerStats from "../../components/players/PlayerStats";
import TransferHistory from "../../components/transfers/TransferHistory";
import VideoPlayer_of_simpleUser from "../../components/players/VideoPlayer_of_simpleUser";
import useAlert from "../../hooks/alertHook";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PlayerObservations from "../../components/players/PlayerObservations";
import MatchesByplayerId from "../../components/players/MatchsByPlayerId";
import VideoPlayerByMatch from "../../components/players/VideoPlayerByMatch";

const commentPerRow = 2;

const PlayerDetails_of_simpleUser = () => {
  const [firstName, setFirstname] = useState("");
  const [currentTeam, setCurrentTeam] = useState();

  const [child, setChild] = useState();
  const [child2, setChild2] = useState();
  const [showMore, setShowMore] = useState(false);
  const [description, setDescription] = useState("");
  const [lastName, setLastname] = useState("");
  const [deleteObservationModal, setdeleteobservationsModal] = React.useState();
  const [updateObservationModal, setupdateobservationsModal] = React.useState();
  const [birthday, setBirthday] = useState("");
  const [adresse, setAdresse] = useState("");
  const [country, setCountry] = React.useState("");
  const [countryname, setCountryname] = React.useState("");
  const [observation, setObservation] = React.useState("");
  const [nat_team, setNat_team] = useState("");
  const [team, setTeam] = useState("");
  const [teamId, setTeamId] = useState("");
  const [isPartner, setIsPartner] = useState("");
  const [avatar, setAvatar] = useState("");
  const [isLiked, setIsLiked] = useState("");
  const [positions, setPositions] = useState("");
  const [date_partner_debut, setDate_partner_debut] = useState("");
  const [date_partner_fin, setDate_partner_fin] = useState("");
  const navigate = useNavigate();
  const [isCheckedGK, setIscheckedGK] = useState(false);
  const [isCheckeddef1, setIscheckeddef1] = useState(false);
  const [isCheckeddef2, setIscheckeddef2] = useState(false);
  const [isCheckedard, setIscheckedard] = useState(false);
  const [isCheckedarg, setIscheckedarg] = useState();
  const [isCheckedmd, setIscheckedmd] = useState(false);
  const [isCheckedmod, setIscheckedmod] = useState(false);
  const [isCheckedmog, setIscheckedmog] = useState(false);
  const [isCheckedaid, setIscheckedaid] = useState(false);
  const [isCheckedaig, setIscheckedaig] = useState(false);
  const [isCheckedav, setIscheckedav] = useState(false);
  const { playerId } = useParams();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [totalPlayed, settotalPlayed] = useState();
  const [startedgames, setstartedgames] = useState();
  const [minutesPerGame, setminutesPerGame] = useState();
  const [teamOfTheWeek, setteamOfTheWeek] = useState();
  const [goals, setgoals] = useState();
  const [scoringFrequency, setscoringFrequency] = useState();
  const [goalsPerGame, setgoalsPerGame] = useState();
  const [shotsPerGame, setshotsPerGame] = useState();
  const [shotsOnTargetPerGame, setshotsOnTargetPerGame] = useState();
  const [bigChancesMissed, setbigChancesMissed] = useState();
  const [goalConversion, setgoalConversion] = useState();
  const [freeKickGoals, setfreeKickGoals] = useState();
  const [freeKickConversion, setfreeKickConversion] = useState();
  const [goalsInsideBox, setgoalsInsideBox] = useState();
  const [goalsOutsideBox, setgoalsOutsideBox] = useState();
  const [HeadedGoals, setHeadedGoals] = useState();
  const [leftFootGoals, setleftFootGoals] = useState();
  const [rightFootGoals, setrightFootGoals] = useState();
  const [penaltyWon, setpenaltyWon] = useState();
  const [assists, setassists] = useState();
  const [touches, settouches] = useState();
  const [bigChancesCreated, setbigChancesCreated] = useState();
  const [keyPasses, setkeyPasses] = useState();
  const [accPerGame, setaccPerGame] = useState();
  const [accOwnHalf, setaccOwnHalf] = useState();
  const [accOppositionHalf, setaccOppositionHalf] = useState();
  const [accLongBalls, setaccLongBalls] = useState();
  const [accChippedPasses, setaccChippedPasses] = useState();
  const [accCrosses, setaccCrosses] = useState();
  const [interceptionsPerGame, setinterceptionsPerGame] = useState();
  const [tacklesPerGame, settacklesPerGame] = useState();
  const [possessionWon, setpossessionWon] = useState();
  const [dribbledPastPerGame, setdribbledPastPerGame] = useState();
  const [clearancesPerGame, setclearancesPerGame] = useState();
  const [errorLedToShot, seterrorLedToShot] = useState();
  const [errorLedToGoal, seterrorLedToGoal] = useState();
  const [penaltiesCommitted, setpenaltiesCommitted] = useState();
  const [succDribbles, setsuccDribbles] = useState();
  const [totalDuelsWon, settotalDuelsWon] = useState();
  const [groundDuelsWon, setgroundDuelsWon] = useState();
  const [aerialDuelsWon, setaerialDuelsWon] = useState();
  const [possessionLost, setpossessionLost] = useState();
  const [fouls, setfouls] = useState();
  const [wasFouled, setwasFouled] = useState();
  const [offsides, setoffsides] = useState();
  const [yellowCard, setyellowCard] = useState();
  const [yellowRedCard, setyellowRedCard] = useState();
  const [redCards, setredCards] = useState();
  const [addPlayerModal, setAddPlayerModal] = React.useState();
  const [playersData, setPlayersData] = React.useState();
  const [commentaires, setCommentaires] = React.useState([]);
  const [playersId, setPlayersId] = React.useState();
  const [teamTest, setTeamTest] = React.useState();
  const [newrate, setNewrate] = useState();
  const { alertModal, displayAlert, dismissAlert } = useAlert();
  const [expanded, setExpanded] = React.useState("matches");
  const [attack, setattack] = useState();
  const [deffence, setdeffence] = useState();
  const [Tactic, settactic] = useState();
  const [creativity, setcreativity] = useState();
  const [tecnique, settecnique] = useState();
  function GetPlayerById() {
    if (playerId === undefined) return;
    axios.get(`${baseURL}/players/${playerId}`).then((resp) => {
      if (resp.data.error) return;
      const PlayerData = resp.data.data;
      setPlayersId(PlayerData.id);
      setCountryname(PlayerData.countryname);

      setCurrentTeam(PlayerData.currentTeam);
      setNewrate(PlayerData.rate);
      setTeamTest(PlayerData.team);
      setIsLiked(PlayerData.isLiked);
      setFirstname(PlayerData.firstName);
      setLastname(PlayerData.lastName);
      setBirthday(PlayerData.birthday);
      setAdresse(PlayerData.adresse);
      setNat_team(PlayerData.national_team);
      setIsPartner(PlayerData.isPartner);
      setCountry(PlayerData.countryId);
      setDate_partner_debut(PlayerData.date_partner_debut);
      setDate_partner_fin(PlayerData.date_partner_fin);
      setPositions(PlayerData.positions);
      setAvatar(PlayerData.avatar);
      setIscheckedGK(PlayerData.positions.includes("GoalKeeper"));
      setIscheckedarg(PlayerData.positions.includes("Back_left"));
      setIscheckedard(PlayerData.positions.includes("Back_Right"));
      setIscheckeddef1(PlayerData.positions.includes("Libero_Left"));
      setIscheckeddef2(PlayerData.positions.includes("Libero_Right"));
      setIscheckedmod(
        PlayerData.positions.includes("Right_Offensive_midfielder")
      );
      setIscheckedmog(
        PlayerData.positions.includes("Left_Offensive_midfielder")
      );
      setIscheckedmd(PlayerData.positions.includes("defensive_midfielder"));
      setIscheckedaid(PlayerData.positions.includes("Right_Winger"));
      setIscheckedaig(PlayerData.positions.includes("Left_Winger"));
      setIscheckedav(PlayerData.positions.includes("Center_forward"));
    });
  }
  const [playervideos, setplayervideos] = useState([]);
  const [playervideosByMatch, setplayervideosByMatch] = useState([]);
  function getPlayerVideos() {
    if (playerId === undefined) return;
    axios
      .get(`${baseURL}/players/playerDetails/videos/${playerId}`)
      .then((response) => {
        if (response.data.error) {
          return;
        }
        setplayervideos(response.data.data);
      });
  }

  const [teams, setTeams] = useState("");

  function getTeams() {
    axios.get(`${baseURL}/teams/read`).then((resp) => {
      if (resp.data.error) return;
      let teamsList = resp.data.data.map((item, index) => {
        return { value: item.id, label: item.name, avatar: item.logo };
      });

      setTeams(teamsList);
    });
  }
  useEffect(() => {
    getTeams();
    GetPlayerById();
    getPlayerVideos();
  }, []);

  async function likeDislike(playerId, isLiked) {
    let message =
      isLiked == "yes"
        ? "voulez vous supprimer ce joueur de la liste des favories?"
        : "voulez vous ajouter ce joueur à la liste des favories";
    let status = isLiked == "yes" ? "danger" : "info";
    displayAlert(
      status,
      "",
      message,
      "Confirmer",
      () => {
        axios
          .post(`${baseURL}/players/like`, {
            playerId: playerId,
          })
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              GetPlayerById();
              dismissAlert();
            }
          });
      },
      "cancel",
      dismissAlert
    );
  }
  var year = birthday.slice(0, 4);
  var today = new Date();
  var age = today.getFullYear() - year;

  const dataPos = {
    GoalKeeper: "GK",
    Back_Right: "AD",
    Back_left: "AG",
    Libero_Left: "LG",
    Libero_Right: "LD",
    defensive_midfielder: "MDF",
    Right_Offensive_midfielder: "MOD",
    Left_Offensive_midfielder: "MOG",
    Right_Winger: "AID",
    Left_Winger: "AIG",
    Center_forward: "AV",
  };

  function GetPlayerStatsByPlayerId() {
    if (playerId === undefined) return;
    axios.get(`${baseURL}/players/playerStats/${playerId}`).then((resp) => {
      if (resp.data.error) return;
      const PlayerData = resp.data.data;
      setPlayersData(resp.data.data);
      settotalPlayed(PlayerData.totalPlayed);
      setstartedgames(PlayerData.startedgames);
      setminutesPerGame(PlayerData.minutesPerGame);
      setteamOfTheWeek(PlayerData.teamOfTheWeek);
      setgoals(PlayerData.goals);
      setscoringFrequency(PlayerData.scoringFrequency);
      setgoalsPerGame(PlayerData.goalsPerGame);
      setshotsPerGame(PlayerData.shotsPerGame);
      setshotsOnTargetPerGame(PlayerData.shotsOnTargetPerGame);
      setbigChancesMissed(PlayerData.bigChancesMissed);
      setgoalConversion(PlayerData.goalConversion);
      setfreeKickGoals(PlayerData.freeKickGoals);
      setfreeKickConversion(PlayerData.freeKickConversion);
      setgoalsInsideBox(PlayerData.goalsInsideBox);
      setgoalsOutsideBox(PlayerData.goalsOutsideBox);
      setHeadedGoals(PlayerData.HeadedGoals);
      setleftFootGoals(PlayerData.leftFootGoals);
      setrightFootGoals(PlayerData.rightFootGoals);
      setpenaltyWon(PlayerData.penaltyWon);
      setassists(PlayerData.assists);
      settouches(PlayerData.touches);
      setbigChancesCreated(PlayerData.bigChancesCreated);
      setkeyPasses(PlayerData.keyPasses);
      setaccPerGame(PlayerData.accPerGame);
      setaccOwnHalf(PlayerData.accOwnHalf);
      setaccOppositionHalf(PlayerData.accOppositionHalf);
      setaccLongBalls(PlayerData.accLongBalls);
      setaccChippedPasses(PlayerData.accChippedPasses);
      setaccCrosses(PlayerData.accCrosses);
      setinterceptionsPerGame(PlayerData.interceptionsPerGame);
      settacklesPerGame(PlayerData.tacklesPerGame);
      setpossessionWon(PlayerData.possessionWon);
      setdribbledPastPerGame(PlayerData.dribbledPastPerGame);
      setclearancesPerGame(PlayerData.clearancesPerGame);
      seterrorLedToShot(PlayerData.errorLedToShot);
      seterrorLedToGoal(PlayerData.errorLedToGoal);
      setpenaltiesCommitted(PlayerData.penaltiesCommitted);
      setsuccDribbles(PlayerData.succDribbles);
      settotalDuelsWon(PlayerData.totalDuelsWon);
      setgroundDuelsWon(PlayerData.groundDuelsWon);
      setaerialDuelsWon(PlayerData.aerialDuelsWon);
      setpossessionLost(PlayerData.possessionLost);
      setfouls(PlayerData.fouls);
      setwasFouled(PlayerData.wasFouled);
      setoffsides(PlayerData.offsides);
      setyellowCard(PlayerData.yellowCard);
      setyellowRedCard(PlayerData.yellowRedCard);
      setredCards(PlayerData.redCards);
      setdeffence(PlayerData.deffence);
      setattack(PlayerData.attack);
      setcreativity(PlayerData.creativity);
      settactic(PlayerData.Tactic);
      settecnique(PlayerData.tecnique);
    });
  }
  useEffect(() => {
    GetPlayerStatsByPlayerId();
  }, []);
  let Buttons = document.querySelectorAll(".stats button");

  // loop through the buttons using for..of
  for (let button of Buttons) {
    // listen for a click event
    button.addEventListener("click", (e) => {
      // et = event target
      const et = e.target;
      // slect active class
      const active = document.querySelector(".active");
      // check for the button that has active class and remove it
      if (active) {
        active.classList.remove("active");
      }
      // add active class to the clicked element
      et.classList.add("active");

      // select all classes with the name content
      let allContent = document.querySelectorAll(".content");

      // loop through all content classes
      for (let content of allContent) {
        // display the content if the class has the same data-attribute as the button
        if (
          content.getAttribute("data-number") ===
          button.getAttribute("data-number")
        ) {
          content.style.display = "block";
        }
        // if it's not equal then hide it.
        else {
          content.style.display = "none";
        }
      }
    });
  }

  function GetObservationsByPlayerId() {
    if (playerId === undefined) return;
    axios
      .get(`${baseURL}/players/getObservationsByPlayerId/${playerId}`)
      .then((resp) => {
        if (resp.data.error) return;
        setCommentaires(resp.data.data.map((item) => item));
      });
  }
  useEffect(() => {
    GetObservationsByPlayerId();
  }, []);

  const [next, setNext] = useState(commentPerRow);
  const handleMoreImage = () => {
    setNext(next + commentPerRow);
  };
  const [showMatches, setShowMatches] = useState(true);
  const toggleMatches = () => {
    setShowMatches(true);
  };
  const toggleTransfers = () => {
    setShowMatches(false);
  };
  const [showStats, setShowStats] = useState(true);
  const [showVideos, setShowVideos] = useState(false);
  const [showVideosPerMatch, setShowVideosPerMatch] = useState(false);
  const [showVideosPlayer, setShowVideosPlayer] = useState(false);
  const [showObs, setShowObs] = useState(false);

  const toggleStats = () => {
    setShowStats(true);
    setShowVideos(false);
    setShowObs(false);
  };

  const toggleObs = () => {
    setShowStats(false);
    setShowVideos(false);
    setShowObs(true);
  };

  const toggleVideos = () => {
    setShowStats(false);
    setShowVideos(true);
    setShowObs(false);
  };
  const toggleVideosPerMatch = () => {
    setShowStats(false);
    setShowVideos(true);
    setShowVideosPerMatch(true);
    setShowVideosPlayer(false);
    setShowObs(false);
  };
  const toggleVideosPlayers = () => {
    setShowStats(false);
    setShowVideos(true);
    setShowVideosPerMatch(false);
    setShowVideosPlayer(true);
    setShowObs(false);
  };
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  return (
    <div>
      <div className="container-fluid mt-5 px-3">
        <div className="row">
          <div className="col-lg-12 pe-lg-4">
            <div className="d-flex justify-content-end w-100 pe-lg-5 py-2">
              <button
                type="button"
                className="btn btn-outline-secondary "
                onClick={() => {
                  navigate("/players");
                }}
              >
                Retour
              </button>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-lg-4 ">
            <div className="row">
              <div className="col-md-6 col-lg-12 col-xxl-4 ">
                <img
                  className="img_player_detail-S_user"
                  src={`${baseURL + "/players/getFile" + avatar.substring(1)}`}
                />
              </div>
              <div className="col-md-6 col-lg-12 col-xxl-8 ">
                <div className="mt-2 ">
                  <h3 className="text-nowrap align-items-center">
                    {lastName} {firstName}
                  </h3>
                </div>
                <div className="row px-md-1">
                  <div className="d-flex justify-content-start align-items-center ">
                    <div className="">
                      {currentTeam != null ? (
                        <img
                          src={`${
                            baseURL +
                            "/team/getFile" +
                            currentTeam.logo.substring(1)
                          }`}
                          className="img_currentT"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="">
                      <button
                        className="btn text-primary"
                        onClick={() => {
                          likeDislike(playerId, isLiked);
                        }}
                      >
                        {isLiked == "yes" ? (
                          <i className="fa-solid fa-heart like_icon"></i>
                        ) : (
                          <i className="fa-regular fa-heart dislike_icon"></i>
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row px-md-1">
                  <div className="d-flex ">
                    <div className="pe-md-2 pe-2">{age} ans</div>
                    <div className="me-md-2 me-2 badge bg-secondary ">
                      {countryname}
                    </div>
                    {newrate <= 2 ? (
                      <div
                        className=" rate ps-lg-3 badge text-center d-flex justify-content-center  py-1 rate_player_red"
                      >
                        {newrate}
                      </div>
                    ) : newrate > 2 && newrate <= 4 ? (
                      <div
                        className=" rate ps-lg-3 badge d-flex justify-content-center py-1 rate_player_orange"
                      >
                        {newrate}
                      </div>
                    ) : newrate > 4 && newrate <= 6 ? (
                      <div
                        className=" rate ps-lg-3 badge d-flex justify-content-center py-1 rate_player_yellow"
                      >
                        {newrate}
                      </div>
                    ) : newrate > 6 && newrate <= 8 ? (
                      <div
                        className=" rate ps-lg-3 badge d-flex justify-content-center py-1 rate_player_green"
                      >
                        {newrate}
                      </div>
                    ) : (
                      <div
                        className=" rate ps-lg-3 badge d-flex justify-content-center py-1 rate_player_blue"
                      >
                        {newrate}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-8 ">
            <div className=" row d-flex pt-2 pt-xl-0 justify-content-start align-items-center">
              <div className="col-12 col-md-4 col-xl-4 pb-1">
                <button
                  type="button"
                  class={`btn ${
                    showStats ? "btn-primary" : "btn-outline-primary"
                  }`}
                  data-number="1"
                  onClick={toggleStats}
                >
                  Statistiques
                </button>
              </div>
              <div className="col-12 col-md-4 col-xl-4  pb-1">
                <button
                  type="button"
                  class={`btn ${
                    showObs ? "btn-primary" : "btn-outline-primary"
                  }`}
                  data-number="1"
                  onClick={toggleObs}
                >
                  Observations
                </button>
              </div>
              <div className="col-12 col-md-4 col-xl-4 pb-1">
                <button
                  type="button"
                  class={`btn ${
                    showVideos ? "btn-primary" : "btn-outline-primary"
                  }`}
                  data-number="1"
                  onClick={toggleVideos}
                >
                  Videos
                </button>
              </div>
            </div>

            {showStats && (
              <div>
                <PlayerStats
                  startedgames={startedgames}
                  assists={assists}
                  goals={goals}
                  attack={attack}
                  deffence={deffence}
                  Tactic={Tactic}
                  creativity={creativity}
                  tecnique={tecnique}
                  totalPlayed={totalPlayed}
                />
              </div>
            )}
            {showVideos && (
              <div className="mt-4">
                <button
                  type="button"
                  class={`btn  mb-2 ${
                    showVideosPlayer ? "btn-primary" : "btn-outline-primary"
                  }`}
                  data-number="1"
                  onClick={toggleVideosPlayers}
                >
                  FreeStyle
                </button>
                <button
                  type="button"
                  class={`ms-3 btn mb-2 ${
                    showVideosPerMatch ? "btn-primary" : "btn-outline-primary"
                  }`}
                  data-number="1"
                  onClick={toggleVideosPerMatch}
                >
                  Match
                </button>
                {showVideosPerMatch && (
                  <div>
                    <VideoPlayerByMatch />
                  </div>
                )}
                {showVideosPlayer && (
                  <div>
                    <VideoPlayer_of_simpleUser
                      videolist={playervideos}
                      getPlayerVideos={getPlayerVideos}
                    />
                  </div>
                )}
              </div>
            )}
            {showObs && (
              <div>
                <PlayerObservations playerId={playerId} displaycreate={true} />
              </div>
            )}
          </div>
        </div>
        <div className="row ps-1" style={{ overflow: "scroll" }}>
          <div className="col-lg-9 mt-4">
            <div class="row">
              <div className="d-flex justify-content-start align-items-center">
                <div class=" pb-2">
                  <button
                    type="button"
                    class={`btn ${
                      showMatches ? "btn-primary" : "btn-outline-primary"
                    }`}
                    data-number="1"
                    onClick={toggleMatches}
                  >
                    Matches
                  </button>
                </div>
                <div class=" pb-2 ms-3">
                  <button
                    type="button"
                    class={`btn ${
                      !showMatches ? "btn-primary" : "btn-outline-primary"
                    }`}
                    data-number="1"
                    onClick={toggleTransfers}
                  >
                    Transfers
                  </button>
                </div>
              </div>
            </div>
            {showMatches && (
              <div>
                <MatchesByplayerId playerId={playerId} />
              </div>
            )}
            {!showMatches && (
              <div>
                <TransferHistory playerId={playerId} />
              </div>
            )}
          </div>
          <div className="col-lg-3">
            <div className="accordion-div mt-4">
              <Accordion
                expanded={expanded === "matches"}
                onChange={handleChange("matches")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="fw-bold">Matchs</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>Matchs Totals : {totalPlayed != null && totalPlayed}</p>
                    <p>
                      Matchs Titulaire : {startedgames != null && startedgames}
                    </p>
                    <p>
                      Minutes par match :{" "}
                      {minutesPerGame != null && minutesPerGame}min
                    </p>
                    <p>
                      Équipe de la semaine :{" "}
                      {teamOfTheWeek != null && teamOfTheWeek}
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "passes"}
                onChange={handleChange("passes")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="fw-bold">Passes</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>Assists : {assists != null && assists}</p>
                    <p>Touches : {touches != null && touches}</p>
                    <p>
                      Grandes occasions crées :{" "}
                      {bigChancesCreated != null && bigChancesCreated}
                    </p>
                    <p>Passe-clés : {keyPasses != null && keyPasses}</p>
                    <p>
                    Précision par match : {accPerGame != null && accPerGame}
                    </p>
                    <p>Précision dans le propre moitié de terrain : {accOwnHalf != null && accOwnHalf}</p>
                    <p>
                    Précision dans la moitié de terrain adverse:{" "}
                      {accOppositionHalf != null && accOppositionHalf}
                    </p>
                    <p>
                    Précision Passes longues : {accLongBalls != null && accLongBalls}
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className="fw-bold">Attaque</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>Buts : {goals != null && goals}</p>
                    <p>
                      Fréquence des buts :{" "}
                      {scoringFrequency != null && scoringFrequency}min
                    </p>
                    <p>
                      Buts par match : {goalsPerGame != null && goalsPerGame}
                    </p>
                    <p>
                      Tirs par match : {shotsPerGame != null && shotsPerGame}
                    </p>
                    <p>
                      Tirs cadrés par match :{" "}
                      {shotsOnTargetPerGame != null && shotsOnTargetPerGame}
                    </p>
                    <p>
                      Occasions manquées :{" "}
                      {bigChancesMissed != null && bigChancesMissed}
                    </p>
                    <p>
                      Conversion de but :{" "}
                      {goalConversion != null && goalConversion}%
                    </p>
                    <p>
                      Buts sur coup franc :{" "}
                      {freeKickGoals != null && freeKickGoals}
                    </p>
                    <p>
                      Conversion de coup franc :{" "}
                      {freeKickConversion != null && freeKickConversion}%
                    </p>
                    <p>
                      Buts dans la surface :{" "}
                      {goalsInsideBox != null && goalsInsideBox}
                    </p>
                    <p>
                      Buts hors la surface :{" "}
                      {goalsOutsideBox != null && goalsOutsideBox}
                    </p>
                    <p>Buts de tête : {HeadedGoals != null && HeadedGoals}</p>
                    <p>
                      Buts pied gauche :{" "}
                      {leftFootGoals != null && leftFootGoals}
                    </p>
                    <p>
                      Buts pied droite :{" "}
                      {rightFootGoals != null && rightFootGoals}
                    </p>
                    <p>Pénalties gagnées: {penaltyWon != null && penaltyWon}</p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className="fw-bold">Défense</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>
                      Interceptions par match :{" "}
                      {interceptionsPerGame != null && interceptionsPerGame}
                    </p>
                    <p>
                      Tacles par match :{" "}
                      {tacklesPerGame != null && tacklesPerGame}
                    </p>
                    <p>
                      Possession gagnée :{" "}
                      {possessionWon != null && possessionWon}
                    </p>
                    <p>
                      Dribble par match :{" "}
                      {dribbledPastPerGame != null && dribbledPastPerGame}
                    </p>
                    <p>
                      Dégagements par match :{" "}
                      {clearancesPerGame != null && clearancesPerGame}
                    </p>
                    <p>
                      L'erreur a conduit au but :{" "}
                      {errorLedToGoal != null && errorLedToGoal}
                    </p>
                    <p>
                      Pénalties commises :{" "}
                      {penaltiesCommitted != null && penaltiesCommitted}
                    </p>
                    <p>
                      Dribbles réuissies :{" "}
                      {succDribbles != null && succDribbles}
                    </p>
                    <p>
                      Duels gangés : {totalDuelsWon != null && totalDuelsWon}{" "}
                    </p>
                    <p>
                      Duels au sol gagnés :{" "}
                      {groundDuelsWon != null && groundDuelsWon}
                    </p>
                    <p>
                      Duels aériens gagnés :{" "}
                      {aerialDuelsWon != null && aerialDuelsWon}
                    </p>
                    <p>
                      Possession perdue :{" "}
                      {possessionLost != null && possessionLost}
                    </p>
                    <p>Fautes : {fouls != null && fouls} </p>
                    <p>Fautes gangés : {wasFouled != null && wasFouled}</p>
                    <p>Hors-jeu : {offsides != null && offsides}</p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "cartes"}
                onChange={handleChange("cartes")}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="fw-bold">Cartes</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    <p>Cartes Jaunes : {yellowCard != null && yellowCard}</p>
                    <p>
                      Cartes Rouges (Directes) : {redCards != null && redCards}
                    </p>
                    <p>
                      Cartes Rouges (2 Jaunes) :{" "}
                      {yellowRedCard != null && yellowRedCard}
                    </p>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
        </div>
        {addPlayerModal}
        {deleteObservationModal}
        {updateObservationModal}
        {alertModal}
      </div>
    </div>
  );
};
export default PlayerDetails_of_simpleUser;
