import React from "react";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { useState, useEffect } from "react";
import CreeateUser from "../components/user/CreateUser";
import UpdateUser from "../components/user/UpdateUser";
import StatutUser from "../components/user/StatutUser";
import { logError } from "../components/Toastconfig";
import EmptyData from "../components/emptyData";
import LoaderLoading from "../components/LoaderLoading";

const Users = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [users, setusers] = useState([]);
  const [roles, setroles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  //  get ALL users funtion
  useEffect(function () {
    getall();
    getallroles();
  }, []);
  function getall() {
    axios
      .get(`${baseURL}/users/`)
      .then((res) => {
        setusers(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        logError();
        setIsLoading(false);
      });
  }
  function getallroles() {
    axios
      .get(`${baseURL}/roles/`)
      .then((res) => {
        setroles(res.data.data);
      })
      .catch((err) => {
        logError();
      });
  }
  return (
    <>
      <section className="pt-5 ">
        <div className="row mx-0">
          <div className="col-4">
            <h4 className="text-nowrap">Les Utilisateurs</h4>
          </div>
          <div className=" col-8 px-3">
            <CreeateUser getall={getall} Roles={roles} />
          </div>
        </div>
        {isLoading ? (
                            <LoaderLoading/>

        ) : users.length === 0 ? (
          <div className="row">
            <div className="col-lg-12">
              <EmptyData />
            </div>
          </div>
        ) : (
          <section>
            <div className="row mt-4 mx-0">
              <div className="table-responsive ">
                <Table className="table" responsive="sm" hover>
                  <thead className="style_thead">
                    <tr>
                      <th className="p-4 style_tab style_th">Utilisateurs</th>
                      <th className="p-4 style_tab style_th">Email</th>
                      <th className="p-4 style_tab style_th">Adresse</th>
                      <th className="p-4 style_tab style_th">Type</th>
                      <th className="p-4 style_tab style_th">Siret</th>
                      <th className="p-4 style_tab style_th">Role</th>
                      <th className="p-4 style_tab style_th">Actions</th>
                    </tr>
                  </thead>
                  <tbody className="my-2 style_tbody">
                    {users.map((user, index) => (
                      <tr
                        key={index}
                        className="border-b style_tbody text-nowrap"
                      >
                        <td
                          className="p-4 text-nowrap"
                          style={{
                            textDecoration: "none", 
                            color: "rgb(39 105 199)",
                          }}
                        > <img
                        src={`${baseURL + "/user/getFile" + user.avatar?.substring(1)}`}
                        width="35"
                        height="35"
                      />
                          <span className="ms-2">{user.name}</span>     
                        </td>
                        <td className="p-4 text-nowrap">{user.email}</td>
                        <td className="p-4 text-nowrap">{user.adress}</td>
                        <td className="p-4 text-nowrap">{user.type}</td>
                        <td className="p-4 text-nowrap">{user.siret_num}</td>
                        <td className="p-4 text-nowrap">{user.Role?.name}</td>
                        <td className="p-4 text-nowrap ">
                          <div className="row">
                          <div className="col-6  d-flex justify-content-start align-items-center px-2">
                              <UpdateUser
                                User={user}
                                getall={getall}
                                Roles={roles}
                              />
                            </div>
                            <div className="col-6  d-flex justify-content-start align-items-center px-2">
                              <StatutUser avatarUser={user.avatar} User={user} getall={getall} />
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </section>
        )}
      </section>
    </>
  );
};
export default Users;
