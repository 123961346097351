import React, { useState } from "react";
import axios from "axios";
import useAlert from "../../hooks/alertHook";

function DeleteObservations({ observationId, GetObservationsByPlayerId }) {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { alertModal, displayAlert, dismissAlert } = useAlert();

  function deleteObservation() {
    axios
      .post(
        `${baseURL}/players/deleteObservation/${observationId}`)
      .then((resp) => {
        if (resp.data.error) return;
        displayAlert("success", "", "Cette observation est supprimé avec succés!");
        setTimeout(() => {
          GetObservationsByPlayerId();
          dismissAlert();
        }, 2500);
      });
  }

  function handlclik (){
    let message = "Voulez-vous supprimer cette observation ?";
    displayAlert("danger", "", message, "Confirmer", () => {
      deleteObservation();
      dismissAlert();
    }, "cancel", dismissAlert)
  }
  return <div>
    <button
      className="btn"
      type="button"
      variant="primary"
      onClick={handlclik}
    >
      <i className="fas fa-times"></i>
    </button>

    {alertModal}
  </div>;
}

export default DeleteObservations;
