import "../App.css";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-phone-number-input/style.css";
import React, { useState, useEffect, createContext, useMemo } from "react";
// import RegisterType from "../components/RegisterType";
import Welcome from "../components/RegisterFormStep1";
import RegisterType from "../components/RegisterType";
export const UserRegistrationContext = createContext({
  siret: "",
  siren: "",
  rcv: "",
  tva_number: "",
  name: "",
  RoleId: "",
  adress: "",
  phone: "",
  password: "",
  confirm_password: "",
  email: "",
});
function App() {
  const [childComponent, setChildComponent] = useState();

  const [newUser, setNewUser] = useState({
    siret: "",
    siren: "",
    rcv: "",
    tva_number: "",
    name: "",
    RoleId: "",
    adress: "",
    phone: "",
    password: "",
    confirm_password: "",
    email: "",
  });

  useEffect(() => {
    setChildComponent(<RegisterType setChildComponent={setChildComponent} />);
  }, []);

  const value = useMemo(() => ({ newUser, setNewUser }), [newUser, setNewUser]);
  return (
    <>
      <div className="bg-main">
        <UserRegistrationContext.Provider value={value}>
          {childComponent}
        </UserRegistrationContext.Provider>
      </div>
    </>
  );
}

export default App;
