import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoaderLoading from "../../components/LoaderLoading";

const PlayersLiked = ({ likedPlayers }) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, [likedPlayers]);

  return (
    <div>
      {loading ? (
        <LoaderLoading />
      ) : likedPlayers?.length === 0 ? (
        ""
      ) : (
          <div
            className="card style_liked_cards  mt-2 me-1"
          >
            <div className="card-title ms-2 mt-2">
              <p className="style-title_liked">Mes joueurs:</p>
            </div>
            {likedPlayers?.slice(-3).map((likedplayer, key) => {
              return (
                <div className="row " key={key}>
                  <Link
                  className="textDeco"
                    to={`/player/details/${likedplayer.player.id}`}
                  >
                    <div className="cardFavorisPlayer px-3 py-2 ">
                      <div className="d-flex flex-nowrap align-items-center ">
                        <div>
                          <Link
                  className="textDeco"
                            to={`/player/details/${likedplayer.player.id}`}
                          >
                            <img
                              src={`${
                                baseURL +
                                "/players/getFile" +
                                likedplayer?.player?.avatar.substring(1)
                              }`}
                              className="team-logo me-2 align-self-center"
                            />{" "}
                          </Link>
                        </div>
                        <div>
                          <Link
                  className="textDeco"
                            to={`/player/details/${likedplayer.player.id}`}
                          >
                            <h5 className="mt-3 style-names_liked name_playerLiked  ">
                              {likedplayer.player.lastName} {likedplayer.player.firstName}
                            </h5>
                          </Link>
                        </div>
                      </div>
                    </div>
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default PlayersLiked;
