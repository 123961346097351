import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import useAlert from "../../hooks/alertHook";
import Select from "react-select";
import { logError } from "../../components/Toastconfig";
import EmptyData from "../../components/emptyData";
import DataTable from "react-data-table-component";
import LoaderLoading from "../../components/LoaderLoading";

export default function SearchPlayers() {
  const navigate = useNavigate();

  const [playersIsLiked, setPlayersIsLiked] = useState();
  const { alertModal, displayAlert, dismissAlert } = useAlert();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [data, setData] = useState();
  const [players, setPlayers] = useState([]);
  const [positions, setPositions] = React.useState("ALL");
  const [filtredplayers, setFiltredplayers] = React.useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTermGeneration, setsearchTermGeneration] = useState("");
  const [searchTermAssists, setsearchTermAssists] = useState("");
  const [totalPlayed, settotalPlayed] = useState();
  const [startedgames, setstartedgames] = useState();
  const [yellowCard, setyellowCard] = useState();
  const [redCards, setredCards] = useState();
  const [preferedfoot, setPreferedfoot] = useState("all");
  const [lastName, setlastName] = useState();
  const [countryname, setcountryname] = useState();
  const [countries, setCountris] = useState([]);
  const [optionsContry, setOptionsContry] = useState([]);

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "rgba(223, 223, 223, 0.59);",
        color: "#5d7079",
      },
    },
  };
  const dataPos = {
    GoalKeeper: "GK",
    Back_Right: "AD",
    Back_left: "AG",
    Libero_Left: "LG",
    Libero_Right: "LD",
    defensive_midfielder: "MDF",
    Right_Offensive_midfielder: "MOD",
    Left_Offensive_midfielder: "MOG",
    Right_Winger: "AID",
    Left_Winger: "AIG",
    Center_forward: "AV",
  };
  const footOptions = [
    { value: "all", label: "Tous" },
    { value: "left", label: "Gauche" },
    { value: "both", label: "Les deux" },
    { value: "right", label: "Droite" },
  ];

  const PositionsOptions = [
    { value: "ALL", label: "Tous" },
    { value: "GoalKeeper", label: "GK (Gardient)" },
    { value: "Back_Right", label: "AD (Arriére Droit)" },
    { value: "Back_left", label: "AG (Arriére Gauche)" },
    { value: "defensive_midfielder", label: "MDF (Milieu défensif)" },
    {
      value: "Right_Offensive_midfielder",
      label: "MOD (Milieu offensif droit)",
    },
    {
      value: "Left_Offensive_midfielder",
      label: "MOG (Milieu offensif gauche)",
    },
    { value: "Right_Winger", label: "AID (Ailier droit)" },
    { value: "Left_Winger", label: "AIG (Ailier gauche)" },
    { value: "center_forward", label: "AV (Avant centre)" },
    { value: "Libero_Right", label: "LD (Libéro droit)" },
    { value: "Libero_Left", label: "LG (Libéro gauche)" },
  ];

  function getallCountries() {
    axios
      .get(`${baseURL}/countries/read`)
      .then((res) => {
        setCountris(res.data);
        let options = [{ value: "", label: "Tous" }];
        res.data.map(function (country) {
          options.push({ value: country.id, label: country.name });
        });
        setOptionsContry(options);
      })
      .catch((err) => {
        logError();
      });
  }
  async function getAllPlayers() {
    setIsLoading(true);
    axios
      .get(`${baseURL}/players/getAllPlayersSimpleUser`)
      .then((response) => {
        if (response.data.error) return;
        setPlayers(response.data.data);
        setFiltredplayers(response.data.data);
        setPositions(JSON.parse(response.data.data.positions));
      })
      .catch((err) => {
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function getPlayersIsLiked() {
    axios
      .get(`${baseURL}/players/playerIsLiked`)
      .then((response) => {
        if (response.data.error) return;
        setPlayersIsLiked(response.data.data);
      })
      .catch((err) => {});
  }
  function filter() {
    if (
      lastName ||
      preferedfoot != "all" ||
      positions != "ALL" ||
      searchTermGeneration ||
      countryname ||
      searchTermAssists ||
      totalPlayed ||
      yellowCard ||
      redCards ||
      startedgames
    ) {
      axios
        .post(`${baseURL}/players/advancedSearchPlayer`, {
          lastName: lastName,
          preferedfoot: preferedfoot,
          positions: positions,
          birthday: searchTermGeneration,
          countryname: countryname,
          assists: searchTermAssists,
          totalPlayed: totalPlayed,
          yellowCard: yellowCard,
          redCards: redCards,
          startedgames: startedgames,
        })
        .then((response) => {
          if (response.data.error) return;
          setFiltredplayers(response.data.data);
          setPositions(JSON.parse(response.data.data.positions));
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      if (
        !lastName &&
        (preferedfoot == "all" || positions == "ALL") &&
        !searchTermGeneration &&
        !countryname &&
        !searchTermAssists &&
        !totalPlayed &&
        !yellowCard &&
        !redCards &&
        !startedgames
      ) {
        setFiltredplayers(players);
      }
    }
  }
  function init() {
    setFiltredplayers(players);
  }

  useEffect(() => {
    getAllPlayers();
    getPlayersIsLiked();
    getallCountries();
  }, []);

  useEffect(() => {
    if (filtredplayers == null) return;
    if (isLoading) {
      setData(<LoaderLoading />);
    } else if (filtredplayers.length > 0) {
      setData(
        <DataTable
          columns={columns}
          data={filtredplayers}
          customStyles={customStyles}
        />
      );
    } else {
      setData(<EmptyData />);
    }
  }, [isLoading, filtredplayers]);
  const columns = [
    {
      name: "Nom et Prénom",
      cell: (row) => (
        <div className="text-nowrap ">
          <Link className="textDeco" to={`/player/details/${row.id}`}>
            <img
              src={`${baseURL + "/players/getFile" + row.avatar}`}
              className="rounded-circle"
              width="35"
              height="35"
            />
            <span className="ps-2 playerName">
              {`${row.lastName} ${row.firstName}`.slice(0, 8)}
              {`${row.lastName} ${row.firstName}`.length > 8 && ".."}{" "}
            </span>
          </Link>
        </div>
      ),
      width: "21%",
    },

    {
      name: "Pays",
      cell: (row) => (
        <div className="text-nowrap ">
          {`${row.countryname} `.slice(0, 7)}
          {`${row.countryname} `.length > 7 && "..."}
        </div>
      ),
      width: "11%",
    },
    {
      name: "Equipe",
      cell: (row) => (
        <div className="text-nowrap ">
          {row.currentTeam?.id ? (
            <Link
              className="style_text_deco"
              to={`/oneTeam/${row.currentTeam.id}`}
            >
              {row.currentTeam?.logo ? (
                <img
                  className="pe-1"
                  src={`${baseURL}/team/getFile${row.currentTeam?.logo?.substring(
                    1
                  )}`}
                  width="35"
                  height="35"
                />
              ) : (
                <img src="./assets/imgs/emptyTeam.png" width="35" height="35" />
              )}
              <span
                style={{
                  color: row.currentTeam?.name ? "rgb(39 105 199)" : "black",
                }}
              >
                {row.currentTeam?.name
                  ? row.currentTeam.name.length > 15
                    ? row.currentTeam.name.slice(0, 15) + ".."
                    : row.currentTeam.name
                  : "No available"}
              </span>
            </Link>
          ) : (
            <div>
              {row.currentTeam?.logo ? (
                <img
                  className="pe-1"
                  src={`${baseURL}/team/getFile${row.currentTeam?.logo?.substring(
                    1
                  )}`}
                  width="35"
                  height="35"
                />
              ) : (
                <img src="./assets/imgs/emptyTeam.png" width="35" height="35" />
              )}
              <span
                style={{
                  color: row.currentTeam?.name ? "rgb(39 105 199)" : "black",
                }}
              >
                {row.currentTeam?.name
                  ? row.currentTeam.name.length > 15
                    ? row.currentTeam.name.slice(0, 15) + ".."
                    : row.currentTeam.name
                  : "No available"}
              </span>
            </div>
          )}
        </div>
      ),
      width: "30%",
    },
    {
      name: "Génerations",
      cell: (row) => (
        <div className="text-nowrap ">
          {`${row.birthday?.slice(0, 4)} `.slice(0, 7)}
          {`${row.birthday?.slice(0, 4)} `.length > 7 && "..."}
        </div>
      ),
      width: "12%",
    },
    {
      name: "Positions",
      cell: (row) => (
        <>
          {row.positions && (
            <div className="text-nowrap">
              {JSON.parse(row.positions)
                .slice(0, 2)
                .map((item, key) => (
                  <span className="badge bg-secondary me-1" key={key}>
                    {dataPos[item]}
                  </span>
                ))}
              {JSON.parse(row.positions).length > 2 && ".."}
            </div>
          )}
        </>
      ),
      width: "15%",
    },
    {
      name: "Note",
      width: "14%",
      cell: (row) => (
        <>
          {row.rate >= 0 && row.rate <= 2 ? (
            <div className="rate  badge px-2 text-center  ratePlayerSearch_red ">
              {row.rate}
            </div>
          ) : row.rate > 2 && row.rate <= 4 ? (
            <div className=" rate  badge px-2 text-center ratePlayerSearch_orange">
              {row.rate}
            </div>
          ) : row.rate > 4 && row.rate <= 6 ? (
            <div className=" rate  badge px-2 text-center  ratePlayerSearch_yellow">
              {row.rate}
            </div>
          ) : row.rate > 6 && row.rate <= 8 ? (
            <div className=" rate  badge px-2 text-center ratePlayerSearch_green ">
              {row.rate}
            </div>
          ) : row.rate > 8 && row.rate <= 10 ? (
            <div className=" rate  badge px-2 text-center  ratePlayerSearch_blue">
              {row.rate}
            </div>
          ) : (
            <div>-</div>
          )}
        </>
      ),
    },
  ];
  return (
    <>
      <div className="container-fluid">
        <div className="row mx-2 mt-4">
          <div className="col-lg-3 mb-2">
            <input
              type="text"
              placeholder="Nom de joueur "
              className="form-control"
              onChange={(e) => {
                setlastName(e.target.value);
              }}
            />
          </div>
          <div className="col-lg-3 mb-2">
            <Select
              options={optionsContry}
              isSearchable
              placeholder="Pays"
              onChange={(e) => setcountryname(e.value)}
            ></Select>
          </div>
          <div className="col-lg-3 mb-2">
            <input
              type="text"
              placeholder="Géneration "
              className="form-control"
              onChange={(e) => {
                setsearchTermGeneration(e.target.value);
              }}
            />
          </div>
          <div className="col-lg-3 mb-2">
            <Select
              options={PositionsOptions}
              isSearchable
              placeholder="Positions"
              onChange={(e) => {
                setPositions(e.value);
              }}
            />
          </div>
        </div>
        <div className="row mx-2">
          <div className="col-lg-3 mb-2">
            <Select
              options={footOptions}
              classNamePrefix="select your prefered foot"
              isSearchable
              placeholder="pied préféré du joueur"
              onChange={(e) => {
                setPreferedfoot(e.value);
              }}
            />
          </div>

          <div className="col-lg-3 mb-2">
            <input
              type="text"
              placeholder="Minimum d'assists "
              className="form-control"
              onChange={(e) => {
                setsearchTermAssists(e.target.value);
              }}
            />
          </div>
          <div className="col-lg-3 mb-2">
            <input
              type="text"
              placeholder="Minimum de match titulaire "
              className="form-control"
              onChange={(e) => {
                setstartedgames(e.target.value);
              }}
            />
          </div>
          <div className="col-lg-3 mb-2">
            <input
              type="text"
              placeholder="Minimum des matchs jouées "
              className="form-control"
              onChange={(e) => {
                settotalPlayed(e.target.value);
              }}
            />
          </div>
        </div>
        <div className="row  mx-2">
          <div className="col-lg-3 mb-2">
            <input
              type="text"
              placeholder="Maximum de cartons jaunes  "
              className="form-control"
              onChange={(e) => {
                setyellowCard(e.target.value);
              }}
            />
          </div>
          <div className="col-lg-3 mb-2">
            <input
              type="text"
              placeholder="Maximum de cartons rouges  "
              className="form-control"
              onChange={(e) => {
                setredCards(e.target.value);
              }}
            />
          </div>
          <div className="col-lg-3 mb-2">
            <div class="d-flex flex-row">
              <div class="">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={filter}
                >
                  <i className="fa-solid fa-magnifying-glass"></i> Rechercher
                </button>
              </div>
              <div class="px-2">
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={() => {
                    navigate("/players");
                  }}
                >
                  Retour
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="row mx-0">
            <div className="col-lg-12">
              <div className="card-body  pt-3">{data}</div>
            </div>
          </div>
        </div>
      </div>
      {alertModal}
    </>
  );
}
