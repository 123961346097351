import "./App.css";
import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";

import { UserContext } from "./context/UserContext";

import "react-toastify/dist/ReactToastify.css";
import RoutesList from "./components/router";

axios.defaults.withCredentials = true;
const baseURL = process.env.REACT_APP_BASE_URL;

function App() {
  const [user, setUser] = useState();
  const value = useMemo(() => ({ user, setUser }), [user, setUser]);
  const [isLoggedIn, setIsLoggedIn] = useState("waiting");
 
  async function checkAuth() {
    await axios.get(`${baseURL}/checkAuth`).then((response) => {
      if (response.data.error) return;
      if (response.data.data !== null) {
        setIsLoggedIn("yes");
        setUser(response.data.data);
      } else {
        setIsLoggedIn("no");
      }
    });
  }

  useEffect(() => {
    checkAuth();
  }, []);
  return (
    <UserContext.Provider value={value}>
    <RoutesList user={user} isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
    </UserContext.Provider>
  );
}

export default App;
