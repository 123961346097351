import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useCustomContext } from "../context/TogglerContextAPI";
import axios from "axios";
import { logError } from "./Toastconfig";
import { useHasPermission } from "../hooks/checkPermissions";
function SideBar() {
  const location = useLocation();
  const { handleSideBar, isSideBarOpen } = useCustomContext();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { checkPermissions } = useHasPermission();
  function logout() {
    axios
      .post(`${baseURL}/logout`)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        logError();
      });
  }
  useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        handleSideBar(false);
      } else {
        handleSideBar(true);
      }
    }
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <div className="link" activeclassname="active">
        <div className="icon">
          <i className="fa-solid fa-user-gear"></i>
        </div>
        <div
          style={{ display: isSideBarOpen ? "block" : "none" }}
          className="link_text"
        >
          Admin
        </div>
      </div>
    </a>
  ));

  return (
    <div
      className="sidebar d-flex align-items-start flex-column"
      style={{
        width: isSideBarOpen ? "200px" : "63px",
        height: isSideBarOpen ? "100%" : "100%",
      }}
    >
      <div className="top_section p-0">
        <div className="bars mt-4 px-3">
          <button
            onClick={() => handleSideBar(!isSideBarOpen)}
            className="border-0 bg-white"
          >
            {isSideBarOpen ? (
              <img src="/assets/imgs/icon-menu.png"></img>
            ) : (
              <img src="/assets/imgs/icon_menu_reversed.png"></img>
            )}
          </button>
        </div>
      </div>

      <div className="">
        <hr className="mx-2 mt-3 underline"></hr>
        <ul className="list-group">
          <li className="list-group-item  p-0 border-0">
            <NavLink to="/" className="link" activeclassname="active">
              <div className="icon">
                <i className="fa-solid fa-table-columns"></i>
              </div>
              <div
                style={{ display: isSideBarOpen ? "block" : "none" }}
                className="link_text text-nowrap"
              >
                Tableau de bords
              </div>
            </NavLink>
          </li>
          {checkPermissions("competitions") ? (
            <li className="list-group-item p-0 border-0">
              <NavLink
                to="/competitions"
                className={`link ${
                  location.pathname.includes("oneCompetition") &&
                  !location.pathname.includes("admin")
                    ? "active"
                    : ""
                }`}
              >
                <div className="icon">
                  <i className="fa-solid fa-trophy"></i>
                </div>
                <div
                  style={{ display: isSideBarOpen ? "block" : "none" }}
                  className="link_text"
                >
                  Compétitions
                </div>
              </NavLink>
            </li>
          ) : (
            ""
          )}
          {checkPermissions("teams") ? (
            <li className="list-group-item p-0 border-0">
              <NavLink
                to="/teams"
                className={`link ${
                  location.pathname.includes("oneTeam") &&
                  !location.pathname.includes("admin")
                    ? "active"
                    : ""
                }`}
              >
                <div className="icon">
                  <i className="fa-solid fa-shield-halved"></i>
                </div>
                <div
                  style={{ display: isSideBarOpen ? "block" : "none" }}
                  className="link_text"
                >
                  Equipes
                </div>
              </NavLink>
            </li>
          ) : (
            ""
          )}

          {checkPermissions("players") ? (
            <li className="list-group-item p-0 border-0">
              <NavLink
                to="/players"
                className={`link ${
                  location.pathname.includes("player") &&
                  !location.pathname.includes("admin")
                    ? "active"
                    : ""
                }`}
              >
                <div className="icon">
                  <i className="fa-solid fa-people-group"></i>
                </div>
                <div
                  style={{ display: isSideBarOpen ? "block" : "none" }}
                  className="link_text"
                >
                  Joueurs
                </div>
              </NavLink>
            </li>
          ) : (
            ""
          )}

          {checkPermissions("news") ? (
            <li className="list-group-item p-0 border-0">
              <NavLink
                to="/news"
                className={`link ${
                  location.pathname.includes("news") &&
                  !location.pathname.includes("admin")
                    ? "active"
                    : ""
                }`}
              >
                <div className="icon">
                  <i className="fa-regular fa-newspaper"></i>
                </div>
                <div
                  style={{ display: isSideBarOpen ? "block" : "none" }}
                  className="link_text"
                >
                  Actualités
                </div>
              </NavLink>
            </li>
          ) : (
            ""
          )}
          {/* { checkPermissions("partenaires") ? (
            <li className="list-group-item p-0 border-0">
              <NavLink
                to="/partenaires"
                className="link"
                activeclassname="active"
              >
                <div className="icon">
                  <i className="fa-regular fa-handshake"></i>
                </div>
                <div
                  style={{ display: isSideBarOpen ? "block" : "none" }}
                  className="link_text"
                >
                  Partenaires
                </div>
              </NavLink>
            </li>
          ) : (
            ""
          )} */}
          {checkPermissions("games") ? (
            <li className="list-group-item p-0 border-0">
              <NavLink
                to="/games"
                className={`link ${
                  location.pathname === "/games" ||
                  location.pathname.includes("game")
                    ? "active"
                    : ""
                }`}
              >
                <div className="icon">
                  <i className="fa-regular fa-futbol"></i>
                </div>
                <div
                  style={{ display: isSideBarOpen ? "block" : "none" }}
                  className="link_text"
                >
                  Matches
                </div>
              </NavLink>
            </li>
          ) : (
            ""
          )}

          <hr />
          {checkPermissions("admin_players") ||
          checkPermissions("admin_news") === true ||
          checkPermissions("admin_competitions") ||
          checkPermissions("admin_teams") ? (
            <li className="list-group-item p-0 border-0">
              <h6 className="d-flex ps-5">
                <div
                  style={{ display: isSideBarOpen ? "block" : "none" }}
                  className="link_text"
                >
                  Administration
                </div>
              </h6>
            </li>
          ) : (
            ""
          )}

          {checkPermissions("admin_competitions") ? (
            <li className="list-group-item p-0 border-0">
              <NavLink
                to="admin/competitions"
                className={`link ${
                  location.pathname.includes("oneCompetition") &&
                  location.pathname.includes("admin")
                    ? "active"
                    : ""
                }`}
              >
                <div className="icon">
                  <i className="fa-solid fa-trophy"></i>
                </div>
                <div
                  style={{ display: isSideBarOpen ? "block" : "none" }}
                  className="link_text"
                >
                  Compétitions
                </div>
              </NavLink>
            </li>
          ) : (
            ""
          )}
          {checkPermissions("admin_teams") ? (
            <li className="list-group-item p-0 border-0">
              <NavLink
                to="admin/teams"
                className={`link ${
                  location.pathname.includes("oneTeam") &&
                  location.pathname.includes("admin")
                    ? "active"
                    : ""
                }`}
              >
                <div className="icon">
                  <i className="fa-solid fa-shield-halved"></i>
                </div>
                <div
                  style={{ display: isSideBarOpen ? "block" : "none" }}
                  className="link_text"
                >
                  Equipes
                </div>
              </NavLink>
            </li>
          ) : (
            ""
          )}
          {checkPermissions("admin_players") ? (
            <li className="list-group-item p-0 border-0">
              <NavLink
                to="admin/players"
                className={`link ${
                  location.pathname.includes("player") &&
                  location.pathname.includes("admin")
                    ? "active"
                    : ""
                }`}
              >
                <div className="icon">
                  <i className="fa-solid fa-people-group"></i>
                </div>
                <div
                  style={{ display: isSideBarOpen ? "block" : "none" }}
                  className="link_text"
                >
                  Joueurs
                </div>
              </NavLink>
            </li>
          ) : (
            ""
          )}
          {checkPermissions("admin_news") ? (
            <li className="list-group-item p-0 border-0">
              <NavLink
                to="admin/news"
                className={`link ${
                  location.pathname.includes("news") &&
                  location.pathname.includes("admin")
                    ? "active"
                    : ""
                }`}
              >
                <div className="icon">
                  <i className="fa-regular fa-newspaper"></i>
                </div>
                <div
                  style={{ display: isSideBarOpen ? "block" : "none" }}
                  className="link_text"
                >
                  Actualités
                </div>
              </NavLink>
            </li>
          ) : (
            ""
          )}
          {/* {checkPermissions("partner")  ? (
            <li className="list-group-item p-0 border-0">
              <NavLink
                to="admin/partenaires"
                className="link"
                activeclassname="active"
              >
                <div className="icon">
                  <i className="fa-regular fa-handshake"></i>
                </div>
                <div
                  style={{ display: isSideBarOpen ? "block" : "none" }}
                  className="link_text"
                >
                  Partenaires
                </div>
              </NavLink>
            </li>
          ) : (
            ""
          )} */}

          {checkPermissions("admin_roles") ? (
            <li className="list-group-item p-0 border-0">
              <NavLink
                to="/admin/roles"
                className="link"
                activeclassname="active"
              >
                <div className="icon">
                  {/* <i className="fa-sharp fa-regular fa-album-collection-circle-user"></i> */}
                  <i className="fa-regular fa-futbol"></i>
                </div>
                <div
                  style={{ display: isSideBarOpen ? "block" : "none" }}
                  className="link_text"
                >
                  Rôles
                </div>
              </NavLink>
            </li>
          ) : (
            ""
          )}
          {checkPermissions("admin_users") ? (
            <li className="list-group-item p-0 border-0">
              <NavLink
                to="admin/users"
                className="link"
                activeclassname="active"
              >
                <div className="icon">
                  <i className="fa-regular fa-handshake"></i>
                </div>
                <div
                  style={{ display: isSideBarOpen ? "block" : "none" }}
                  className="link_text"
                >
                  Utilisateurs
                </div>
              </NavLink>
            </li>
          ) : (
            ""
          )}{checkPermissions("admin_users") ? (
            <li className="list-group-item p-0 border-0">
              <NavLink
                to="admin/allBoosted"
                className="link"
                activeclassname="active"
              >
                <div className="icon">
                <i class="fa-solid fa-bolt"></i>                </div>
                <div
                  style={{ display: isSideBarOpen ? "block" : "none" }}
                  className="link_text"
                >
                  Tous Boostés
                </div>
              </NavLink>
            </li>
          ) : (
            ""
          )}
        </ul>
      </div>
      <div className="p-2 mt-auto">
        <div
          type="button"
          className="link"
          onClick={logout}
          activeclassname="active"
        >
          <div className="icon">
            <i className="fa-sharp fa-solid fa-arrow-right-from-bracket"></i>
          </div>
          <div>
            <h6
              className="link_text"
              style={{
                display: isSideBarOpen ? "block" : "none",
                textDecoration: "none",
              }}
            >
              Déconnexion
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideBar;
