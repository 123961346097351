import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import useAlert from "../../hooks/alertHook";
import { useParams } from "react-router-dom";
import { UserContext } from "../../context/UserContext";

// initialize errors message
const initFormErrors = {
  name: [""],
  email: [""],
  adress: [""],
  CodePostal: [""],
  file: [""],
  type: [""],
  RoleId: [""],
  siret_num: [""],
  oldPassword: [""],
  newPassword: [""],
};
export default function UserDetails({}) {
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { userId } = useParams();
  const { user, setUser } = useContext(UserContext);
  // user props
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [adress, setadress] = useState("");
  const [CodePostal, setCodePostal] = useState("");
  const [type, settype] = useState("");
  const [RoleId, setRoleId] = useState("");
  const [siret_num, setsiret_num] = useState("");
  const [avatar, setAvatar] = useState("");
  const [errorMessage, setErrorMessage] = useState(initFormErrors);
  const { alertModal, displayAlert, dismissAlert } = useAlert();

  useEffect(() => {
    if (userId === undefined) return;
    getuser();
  }, [userId]);

  function getuser() {
    axios.get(`${baseURL}/users/${userId}`).then((resp) => {
      if (resp.data.error) return;
      const userData = resp.data.data;
      setname(userData.name);
      setemail(userData.email);
      setadress(userData.adress);
      setCodePostal(userData.CodePostal);
      settype(userData.type);
      setRoleId(userData.RoleId);
      setsiret_num(userData.siret_num);
      setAvatar(userData.avatar);
      GetRoleById(userData.RoleId);
    });
  }
  function GetRoleById(roleId) {
    if (roleId === undefined) return Promise.resolve([]); // Return an empty array if roleId is undefined
    return axios
      .get(`${baseURL}/roles/getRoleById/${roleId}`)
      .then((resp) => {
        if (resp.data.error) return []; // Return an empty array if there is an error
        return resp.data.data.permissions.Permissions || []; // Return permissions or an empty array if undefined
      })
      .catch(() => []);
  }
  useEffect(() => {
    GetRoleById();
  }, []);
  function senData() {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("adress", adress);
    formData.append("CodePostal", CodePostal);
    formData.append("type", type);
    formData.append("RoleId", RoleId.toString());
    formData.append("siret_num", siret_num);
    formData.append("avatar", avatar);
    formData.append("oldPassword", oldPassword);
    formData.append("newPassword", newPassword);
    formData.append("confirmNewPassword", confirmNewPassword);

    axios
      .post(`${baseURL}/users/update/${userId}`, formData)
      .then((resp) => {
        setErrorMessage(initFormErrors);
        if (resp.data.error) return;

        displayAlert("success", "", resp.data.message);
        setTimeout(() => {
          dismissAlert();
        }, 2500);

        // Get the updated user data from the UserContext
        const selectedRole = roles.find((role) => role.value === RoleId);
        const selectedRoleId = selectedRole ? selectedRole.label : null;

        GetRoleById(RoleId).then((permissions) => {
          const updatedUserData = {
            ...user,
            name: resp.data.data.name,
            email: resp.data.data.email,
            adress: resp.data.data.adress,
            CodePostal: resp.data.data.CodePostal,
            type: resp.data.data.type,
            roleId: resp.data.data.RoleId,
            role: selectedRoleId,
            permissions: permissions,
            siret_num: resp.data.data.siret_num,
            avatar: resp.data.data.avatar,
          };
          // Update the specific fields in the user object
          setUser(updatedUserData);
          navigate("/");
          getuser();
        });
      })
      .catch((error) => {
        const errors = error.response.data;
        setErrorMessage(errors);
      });
  }
  const [roles, setroles] = useState([]);
  useEffect(() => {
    getallroles();
  }, []);
  function getallroles() {
    axios
      .get(`${baseURL}/roles/`)
      .then((res) => {
        const rolesData = res.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));
        setroles(rolesData);
      })
      .catch((err) => {});
  }
  const uploadedImage = React.useRef(null);
  const [imageUploaded, setImageUploaded] = React.useState(false);

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
        setImageUploaded(true);
      };
      reader.readAsDataURL(file);
    }
  };
  return (
    <div className="row justify-content-center">
      <div className="justify-content-center d-flex col-11">
        <div class="card p-1 mt-4 pb-3 ">
          <h1 className="fw-bold mt-5 text-center">
            Informations personnelles
          </h1>
          <div className="row d-flex justify-content-center ">
            <div className="container">
              <div className="row">
                <div className="row ms-2">
                  <div className="col-md-6 mb-3">
                    <div className="form-outline">
                      <h6 className="form-label" for="Name">
                        Nom :
                      </h6>
                      <input
                        type="text"
                        id="name"
                        value={name}
                        className="form-control form-control"
                        onChange={(e) => setname(e.target.value)}
                        required
                      />
                      <div className="input-error">
                        <span className="text-danger">{errorMessage.name}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3 ">
                    <div className="form-outline">
                      <h6 className="form-label" for="Email">
                        Email :
                      </h6>
                      <input
                        type="email"
                        id="email"
                        value={email}
                        className="form-control form-control"
                        onChange={(e) => setemail(e.target.value)}
                        required
                      />
                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessage.email}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row ms-2">
                  <div className="col-md-6 mb-3">
                    <div className="form-outline">
                      <h6 className="form-label" for="Adresse">
                        Adresse :
                      </h6>
                      <input
                        type="text"
                        id="adresse"
                        value={adress}
                        className="form-control form-control"
                        onChange={(e) => setadress(e.target.value)}
                        required
                      />
                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessage.adress}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-outline">
                      <h6 className="form-label" for="code_postale">
                        Code Postal :
                      </h6>
                      <input
                        type="text"
                        id="code_postale"
                        value={CodePostal}
                        className="form-control form-control"
                        onChange={(e) => setCodePostal(e.target.value)}
                        required
                      />
                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessage.CodePostal}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row ms-2">
                  <div className="col-md-6 mb-3">
                    <h6 className="mb-2 pb-1">Choisir un role : </h6>
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessage.RoleName}
                      </span>
                    </div>
                    <Select
                      options={roles}
                      isSearchable
                      placeholder="roles"
                      value={roles.filter((option) => option.value === RoleId)}
                      onChange={(e) => setRoleId(e.value)}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <h6 className="mb-2 pb-1">Type : </h6>
                    <div className="input-error">
                      <span className="text-danger">{errorMessage.type}</span>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        value="personnel"
                        checked={type === "personnel"}
                        onChange={(e) => settype(e.target.value)}
                      />
                      <label className="form-check-label" for="personnel">
                        Personnel
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        value="professionel"
                        checked={type === "professionel"}
                        onChange={(e) => settype(e.target.value)}
                      />
                      <label className="form-check-label" for="professionel">
                        Professionel
                      </label>
                    </div>
                  </div>
                </div>

                <div className="row ms-2">
                  <div className="col-md-6 mb-3">
                    <h6 className="mb-2 pb-1">Avatar : </h6>

                    <input
                      required
                      id="avatar"
                      accept="image/*"
                      name="avatar"
                      type="file"
                      className="form-control"
                      onChange={(e) => {
                        handleImageUpload(e);
                        setAvatar(e.target.files[0]);
                      }}
                    />
                    <div className="input-error">
                      <span className="text-danger">{errorMessage.file}</span>
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <div className="form-outline">
                      <h6 className="mb-2 pb-1">Ancien Mot De passe :</h6>
                      <input
                        type="password"
                        id="email"
                        className="form-control form-control"
                        onChange={(e) => setOldPassword(e.target.value)}
                        required
                      />
                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessage.oldPassword}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row ms-2">
                  <div className="col-md-6 mb-3">
                    <div className="form-outline">
                      <h6 className="mb-2 pb-1">Nouveau Mot De passe :</h6>
                      <input
                        type="password"
                        id="email"
                        className="form-control form-control"
                        onChange={(e) => setNewPassword(e.target.value)}
                        required
                      />
                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessage.newPassword}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <div className="form-outline">
                      <h6 className="mb-2 pb-1">
                        Confirmer Nouveau Mot De passe :
                      </h6>

                      <input
                        type="password"
                        id="email"
                        className="form-control form-control"
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row ms-2">
                  <div className="col-md-6 ">
                    {!imageUploaded && (
                      <img
                        src={`${
                          baseURL + "/user/getFile" + user.avatar?.substring(1)
                        }`}
                        alt="media"
                        className="ms-1 userImgDetails"
                      />
                    )}
                    <img ref={uploadedImage} className="userImgDetails" />
                  </div>
                  {type === "professionel" ? (
                    <div className="col-md-6 ">
                      <div className="form-outline">
                        <h6 className="form-label" for="Adresse">
                          Numéro Siret :
                        </h6>
                        <input
                          type="text"
                          id="adresse"
                          value={siret_num != null ? siret_num : ""}
                          className="form-control form-control"
                          onChange={(e) => setsiret_num(e.target.value)}
                          required
                        />
                        <div className="input-error">
                          <span className="text-danger">
                            {errorMessage.siret_num}
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-12 d-flex justify-content-center align-items-center mb-2">
                  <Link to="/" className="btn btn-secondary">
                    Annuler
                  </Link>
                  <button
                    type="button"
                    onClick={senData}
                    className="btn btn-primary ms-4"
                  >
                    Enregistrer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {alertModal}
      </div>
    </div>
  );
}
