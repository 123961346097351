import React from "react";
import axios from "axios";
import useAlert from "../../hooks/alertHook";

export default function Statusnews({ news, getallnews }) {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { alertModal, displayAlert, dismissAlert } = useAlert();
  const handleShow = async () => {
    displayAlert(
      "info",
      "",
      "Voulez-vous changer le statut de cette actualité ?",
      "Changer",
      () => {
        axios
          .post(`${baseURL}/news/updatestatus/${news.id}`, {
            isPublished: !news.ispublished,
          })
          .then((resp) => {
            if (resp.data.error) return;
            if (resp.data.message)
              displayAlert("success", "", resp.data.message);
            setTimeout(() => {
              dismissAlert();
            }, 2500);
            getallnews();
          })
          .catch((error) => {
            console.error(error);
          });
        dismissAlert();
      },
      "cancel",
      dismissAlert
    );
  };
  return (
    <>
      <div>
        <button type="button" className="btn btn-update" onClick={handleShow}>
          {news.ispublished ? (
            <i
              className=" fa-solid fa-eye green"
              data-toggle="tooltip"
              data-placement="top"
              title="disable news"
            ></i>
          ) : (
            <i
              className="text-danger fa-solid fa-eye-slash red"
              data-toggle="tooltip"
              data-placement="top"
              title="make news online"
            ></i>
          )}
        </button>
      </div>
      {alertModal}
    </>
  );
}
