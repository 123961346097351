import React, { useContext, useState,useEffect } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Link } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import { UserRegistrationContext } from "../pages/Register";
import RegisterFormStep2 from "./RegisterFormStep2";
import RegisterType from "./RegisterType";
import { Welcome } from "./Welcome";
import axios from "axios";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const baseURL = process.env.REACT_APP_BASE_URL;

const initFormErrors = {
  name: [""],
  adress: [""],
  phone: [""],
  password: [""],
  confirm_password: [""],
  email: [""],
  RoleId: [""],
};
const RegisterFormStep1 = ({ setChildComponent }) => {
  const msgEmail = "Le champ email est requis";
  const msgAdress = "Le champ adresse est requis";
  const msgName = "Le champ nom est requis";
  const msgPhone = "Le champ téléphone est requis";
  const msgRoleId = "Le champ rôle est requis";
  const msgPassword= "Le champ mot de passe est requis";
  const msgConfirmPassword= "Le champ confirmer mot de passe  est requis";
  const msg1 = "Le champ email est invalide ";
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const [errorMessage, setErrorMessage] = useState(initFormErrors);
  const { newUser, setNewUser } = useContext(UserRegistrationContext);

  function validate() {
    let error = false;
    setErrorMessage(initFormErrors);
    if (newUser.name == "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        name: msgName,
      }));
    }
    if (newUser.adress == "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        adress: msgAdress,
      }));
    }

   
      if (newUser.phone == "") {
        error = true;
        setErrorMessage((prevState) => ({
          ...prevState,
          phone: msgPhone,
        }));
      }
      if (newUser.RoleId == "") {
        error = true;
        setErrorMessage((prevState) => ({
          ...prevState,
          RoleId: msgRoleId,
        }));
      } 
      if (newUser.password == "") {
        error = true;
        setErrorMessage((prevState) => ({
          ...prevState,
          password: msgPassword,
        }));
      }
      if (newUser.confirm_password == "") {
        error = true;
        setErrorMessage((prevState) => ({
          ...prevState,
          confirm_password: msgConfirmPassword,
        }));
      }
      if (newUser.email == "") {
        error = true;
        setErrorMessage((prevState) => ({
          ...prevState,
          email: msgEmail,
        }));
    } else {
      if (regex.test(newUser.email) === false) {
        error = true;
        setErrorMessage((prevState) => ({
          ...prevState,
          email: msg1,
        }));
      }
      if (newUser.confirm_password !== newUser.password) {
        error = true;
        setErrorMessage((prevState) => ({
          ...prevState,
          confirm_password: "Les mots de passe ne correspondent pas",
        }));
      }
    }

    if (error === false) {
      setChildComponent(
        <RegisterFormStep2 setChildComponent={setChildComponent} />
      );
    }
  }
  const [roles, setroles] = useState([]);
  useEffect(() => {
    getpublicRoles();
  }, []);
  function getpublicRoles() {
    axios
      .get(`${baseURL}/roles/publicRole`)
      .then((res) => {
        res.data.data.map((item, index) => {
            return setroles((prevState) => [
              ...prevState,
              { value: item.id, label: item.name },
            ]);
        });
      })
      .catch((err) => {});
  }
  
  return (
    <section className="container-fluid bg-main">
      <div className="container py-5 h-100 ">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col">
            <div className="card card_style border-0 h-100">
              <div className="row g-0">
                <div className="col-md-6 col-lg-6 d-none d-md-block bg-log">
                  <div className="pt-5 px-3">
                    <img
                      className="style_img"
                      src="../../assets/imgs/logo.png"
                      alt="logo"
                      width="150"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 d-flex align-items-center">
                  <div className="card-body text-black ">
                    <div className="">
                      <h3 className="title black mt-4 mb-1 pb-1 text-center">
                        Nous sommes ravis que vous nous rejoigniez !
                      </h3>
                      <h5 className="black mt-4 mb-5 pb-1">
                        Remplissons toutes les informations et configurons votre
                        compte, les joueurs que vous recherchez vous attendent !
                      </h5>
                    </div>
                    <div className="form-horizontal style_form"></div>
                    <div className="row mb-3">
                      <div className="col-lg-6">
                        <h6 className="fw-bold color_black float-start">
                          Nom de société
                        </h6>
                        <input
                          value={newUser.name}
                          onChange={(e) => {
                            setNewUser((prevState) => ({
                              ...prevState,
                              name: e.target.value,
                            }));
                          }}
                          required
                          type="text"
                          className="form-control"
                          placeholder="Nom de société"
                        />
                        <div className="input-error">
                          <span className="text-danger">
                            {errorMessage.name}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <h6 className="fw-bold color_black float-start">
                          Adresse
                        </h6>
                        <input
                          required
                          value={newUser.adress}
                          onChange={(e) => {
                            setNewUser((prevState) => ({
                              ...prevState,
                              adress: e.target.value,
                            }));
                          }}
                          type="text"
                          name="lastName"
                          className="form-control"
                          placeholder="Adresse"
                        />
                        <div className="input-error">
                          <span className="text-danger">
                            {errorMessage.adress}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-6">
                        <h6 className="fw-bold color_black float-start">
                          Email
                        </h6>
                        <input
                          type="email"
                          placeholder="Votre adresse mail"
                          value={newUser.email}
                          onChange={(e) => {
                            setNewUser((prevState) => ({
                              ...prevState,
                              email: e.target.value,
                            }));
                          }}
                          className="form-control"
                          required
                        />
                        <div className="input-error">
                          <span className="text-danger">
                            {errorMessage.email}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <h6 className="fw-bold color_black text-start">
                          Téléphone
                        </h6>
                        <PhoneInput
                          value={newUser.phone}
                          placeholder="Téléphone"
                          type="text"
                          onChange={(value, country, event) => {
                            setNewUser((prevState) => ({
                              ...prevState,
                              phone: event.target.value,
                            }));
                          }}
                        />
                        <div className="input-error">
                          <span className="text-danger">
                            {errorMessage.phone}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                    <div className="col-lg-6">
                        <h6 className="mb-2 fw-bold color_black text-start">
                          Role
                        </h6>
                        <Select
    options={roles}
    isSearchable
    placeholder="roles"
    value={roles.find((role) => role.value === newUser.RoleId)}
    onChange={(e) => {
      setNewUser((prevState) => ({
        ...prevState,
        RoleId: e.value,
      }));
    }}
  />
                    <div className="input-error">
                      <span className="text-danger">{errorMessage.RoleId}</span>
                    </div>
                      </div>
                      <div className="col-lg-6">
                        <h6 className="fw-bold color_black float-start">
                          Mot de passe
                        </h6>
                        <input
                          type="password"
                          placeholder="Mot de passe"
                          value={newUser.password}
                          onChange={(e) => {
                            setNewUser((prevState) => ({
                              ...prevState,
                              password: e.target.value,
                            }));
                          }}
                          className="form-control"
                          required
                        />
                        <div className="input-error">
                          <span className="text-danger">
                            {errorMessage.password}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                    <div className="col-lg-6">
                        <h6 className="fw-bold color_black float-start">
                          Confirmer Mot de passe
                        </h6>
                        <input
                          type="password"
                          placeholder=" Confirmer Mot de passe"
                          value={newUser.confirm_password}
                          onChange={(e) => {
                            setNewUser((prevState) => ({
                              ...prevState,
                              confirm_password: e.target.value,
                            }));
                          }}
                          className="form-control"
                          required
                        />
                        <div className="input-error">
                          <span className="text-danger">
                            {errorMessage.confirm_password}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="button-roww col-lg-6 col-sm-12 mb-3 mb-sm-0">
                        <button
                          type="button"
                          className="btn btn-outline-secondary style_butt mx-2"
                          onClick={() => {
                            setChildComponent(
                              <RegisterType
                                setChildComponent={setChildComponent}
                              />
                            );
                          }}
                        >
                          Retour
                        </button>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <button
                          onClick={validate}
                          type="button"
                          className="btn btn-primary style_butt mx-2"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                    <div className="row d-flex d-none d-sm-flex justify-content-center mt-3">
                      <div className="col-3 style_flag px-0 ">
                        <img className="" src="./flag.png" alt="" />
                      </div>
                      <div className="col-6 style_padding_bar  mt-5 ">
                        <ProgressBar className="style_barr  " now={33} />
                      </div>
                      <div className="col-3 style_flagsecond">
                        <img src="./flag2.png" alt="" />
                      </div>
                    </div>
                    <div className="text-center pt-md-4 mx-md-4  mt-5">
                      <div className="col-lg-12 text-center">
                        <span className="signin-link">
                          Vous avez déjà un compte? Cliquez ici pour{" "}
                          <Link to="/login" className="btn-click">
                          S'authentifier
                          </Link>
                        </span>
                      </div>
                    </div>
                    <div className=" text-center px-md-4 mx-md-4  mt-2">
                      <a href="#">
                        <i className="fa-brands fa-facebook-f p-2 "></i>
                      </a>
                      <a href="#">
                        <i className="fa-brands fa-instagram p-2"></i>
                      </a>
                      <a href="#">
                        <i className="fa-brands fa-youtube p-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegisterFormStep1;
