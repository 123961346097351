import React, { useContext,useState } from "react";
import { UserRegistrationContext } from "../pages/Register";
import RegisterFormStep2 from "./RegisterFormStep2";
import ProgressBar from "react-bootstrap/ProgressBar";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import useAlert from "../hooks/alertHook";

const initFormErrors = {
  email: [""],
  password: [""],
  name: [""],
};

const ConfirmRegister = ({ setChildComponent }) => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(initFormErrors);

  const { newUser, setNewUser } = useContext(UserRegistrationContext);
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { alertModal, displayAlert, dismissAlert } = useAlert();
  function sendData() {
    const data = {
      name: newUser.name,
      adress: newUser.adress,
      phone: newUser.phone,
      email: newUser.email,
      password: newUser.password,
      confirm_password: newUser.confirm_password,
      siret_num: newUser.siret,
      siren_num: newUser.siren,
      rcv: newUser.rcv,
      RoleId: newUser.RoleId,
      tva_num: newUser.tva_number,
    };
    axios
      .post(`${baseURL}/singupSociete`, data)
      .then((resp) => {
        setErrorMessage(initFormErrors);
        if (resp.data.error) return;
        displayAlert("success","",resp.data);
        setTimeout(() => {
          dismissAlert();
          navigate("/login");
       },2500);
      })
      .catch((error) => {
        const errors = error.response.data;
        setErrorMessage(errors);
      });
  }
  return (
    <section className="container-fluid bg-main">
      <div className="container py-5 h-100 ">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col">
            <div className="card card_style border-0 h-100">
              <div className="row g-0">
                <div className="col-md-6 col-lg-6 d-none d-md-block bg-log">
                  <div className="pt-5 px-3">
                    <img src="./assets/imgs/logo.png"></img>
                    <h2 className="my-4 title white">
                      Suivez votre joueur pas à pas, partie par partie !
                    </h2>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 d-flex align-items-center">
                  <div className="card-body text-black ">
                    <div className="text-center ">
                      <h1 className="fw-bold">
                        Nous sommes ravis que vous nous rejoigniez !
                      </h1>
                    </div>
                    <div className="text-center ">
                      <h5>
                        Remplissons toutes les informations et configurons votre
                        compte, les joueurs que vous recherchez vous attendent !
                      </h5>
                    </div>
                    <div className="input-error pt-2">
                      <span className="text-danger">{errorMessage.email}</span>
                    </div>
                    <div className="input-error pt-2">
                      <span className="text-danger">{errorMessage.password}</span>
                    </div>
                    <div className="input-error pt-2">
                      <span className="text-danger">{errorMessage.name}</span>
                    </div>
                    <div className="form-horizontal style_form ">
                      <div className="row mb-3 d-flex justify-content-center align-items-center">
                        <div className="col-lg-6 col-12 text-center">
                       
                          <h6 className="text-start">
                            Nom de société : {newUser.name}
                          </h6>
                          <h6 className="text-start">
                            Adresse : {newUser.adress}
                          </h6>
                          <h6 className="text-start">
                            Email : {newUser.email}
                          </h6>
                          <h6 className="text-start">
                            Phone : {newUser.phone}
                          </h6>
                          <h6 className="text-start">
                            Siret : {newUser.siret}
                          </h6>
                          <h6 className="text-start">
                            Siren : {newUser.siren}
                          </h6>
                          <h6 className="text-start">rcv : {newUser.rcv}</h6>
                          <h6 className="text-start">
                            tva_number : {newUser.tva_number}
                          </h6>
                        </div>
                      </div>

                      <div className="row ">
                        <div className="button-roww col-lg-6 col-sm-12 mb-3 mb-sm-0">
                          <button
                            type="button"
                            className="btn btn-outline-secondary style_butt mx-2"
                            onClick={() => {
                              setChildComponent(
                                <RegisterFormStep2
                                  setChildComponent={setChildComponent}
                                />
                              );
                            }}
                          >
                            Retour
                          </button>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <button
                            type="button"
                            className="btn btn-primary style_butt mx-2"
                            onClick={sendData}
                          >
                            Enregistrer
                          </button>
                        </div>
                      </div>
                      <div className="row d-flex d-none d-sm-flex justify-content-center mt-3">
                        <div className="col-3 px-0 style_flagone_registerstep2  ">
                          <img className="" src="./flag.png" alt="" />
                        </div>
                        <div className="col-6 style_padding_bar  mt-5 ">
                          <ProgressBar className="style_barr  " now={100} />
                        </div>
                        <div className="col-3 style_flagsecond_registerstep2">
                          <img src="./flag2.png" alt="" />
                        </div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-lg-12 text-center">
                          <span className="signin-link">
                            Vous avez déjà un compte? Cliquez ici pour{" "}
                            <Link to="/login" className="btn-click">
                            S'authentifier
                            </Link>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {alertModal}
    </section>
  );
};

export default ConfirmRegister;
