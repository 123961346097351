import React from 'react'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const Toastsettings = {
  position: "top-right",
  autoClose: 2500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
}
export const ToastContainerSettings = {
   position: "top-right",
   limit: 1,
   autoClose: 2500,
   hideProgressBar: false,
   newestOnTop: false,
   closeOnClick: true,
   rtl: false,
   pauseOnFocusLoss: true,
   draggable: true,
   pauseOnHover: true,
   theme: "light",
}

export const logError = () => {
  // Fermer tous les toasts précédents
  toast.dismiss();
  toast.error(
  <div>
    <p className='toast-message'>Une erreur est survenue !<br/> <span className='toast-span'>Veuillez réessayer !</span></p>
  </div>, Toastsettings);
};