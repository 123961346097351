import React from "react";
import axios from "axios";
import useAlert from "../../hooks/alertHook";

import { useState, useEffect } from "react";
const UpdateMatchStats = ({ setUpdateMatchStatsModalFromChild, id }) => {
  const initFormErrors = {
    goals: [""],
    ball_possession: [""],
    total_shots: [""],
    shotsOnTarget: [""],
    shotsOffTarget: [""],
    blocked_Shots: [""],
    corner_kicks: [""],
    offsides: [""],
    fouls: [""],
    yellow_cards: [""],
    big_chances: [""],
    counter_attacks: [""],
    counter_attack_shots: [""],
    shots_inside_box: [""],
    shots_outside_box: [""],
    goalkeeperSaves: [""],
    passes: [""],
    acc_passes: [""],
    long_balls: [""],
    crosses: [""],
    dribbles: [""],
    possession_lost: [""],
    aerials_won: [""],
    duels_won: [""],
    tackles: [""],
    interceptions: [""],
    clearances: [""],
  };
  const [teamHomeName, setteamHomeName] = useState("");
  const [teamHomeLogo, setteamHomeLogo] = useState("");
  const [teamAwayName, setteamAwayName] = useState("");
  const [teamAwayLogo, setteamAwayLogo] = useState("");
  const [journeyMatch, setjourneyMatch] = useState();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [goalsHome, setgoalsHome] = useState();
  const [goalsAway, setgoalsAway] = useState();
  const [ball_possessionHome, setball_possessionHome] = useState();
  const [ball_possessionAway, setball_possessionAway] = useState();
  const [total_shotsHome, settotal_shotsHome] = useState();
  const [total_shotsAway, settotal_shotsAway] = useState();
  const [shotsOnTargetHome, setshotsOnTargetHome] = useState();
  const [shotsOnTargetAway, setshotsOnTargetAway] = useState();
  const [shotsOffTargetHome, setshotsOffTargetHome] = useState();
  const [shotsOffTargetAway, setshotsOffTargetAway] = useState();
  const [blocked_ShotsHome, setblocked_ShotsHome] = useState();
  const [blocked_ShotsAway, setblocked_ShotsAway] = useState();
  const [corner_kicksHome, setcorner_kicksHome] = useState();
  const [corner_kicksAway, setcorner_kicksAway] = useState();
  const [offsidesHome, setoffsidesHome] = useState();
  const [offsidesAway, setoffsidesAway] = useState();
  const [foulsHome, setfoulsHome] = useState();
  const [foulsAway, setfoulsAway] = useState();
  const [yellow_cardsHome, setyellow_cardsHome] = useState();
  const [yellow_cardsAway, setyellow_cardsAway] = useState();
  const [big_chancesHome, setbig_chancesHome] = useState();
  const [big_chancesAway, setbig_chancesAway] = useState();
  const [counter_attacksHome, setcounter_attacksHome] = useState();
  const [counter_attacksAway, setcounter_attacksAway] = useState();
  const [counter_attack_shotsHome, setcounter_attack_shotsHome] = useState();
  const [counter_attack_shotsAway, setcounter_attack_shotsAway] = useState();
  const [shots_inside_boxHome, setshots_inside_boxHome] = useState();
  const [shots_inside_boxAway, setshots_inside_boxAway] = useState();
  const [shots_outside_boxHome, setshots_outside_boxHome] = useState();
  const [shots_outside_boxAway, setshots_outside_boxAway] = useState();
  const [goalkeeperSavesHome, setgoalkeeperSavesHome] = useState();
  const [goalkeeperSavesAway, setgoalkeeperSavesAway] = useState();
  const [passesHome, setpassesHome] = useState();
  const [passesAway, setpassesAway] = useState();
  const [acc_passesHome, setacc_passesHome] = useState();
  const [acc_passesAway, setacc_passesAway] = useState();
  const [long_ballsHome, setlong_ballsHome] = useState();
  const [long_ballsAway, setlong_ballsAway] = useState();
  const [crossesHome, setcrossesHome] = useState();
  const [crossesAway, setcrossesAway] = useState();
  const [dribblesHome, setdribblesHome] = useState();
  const [dribblesAway, setdribblesAway] = useState();
  const [possession_lostHome, setpossession_lostHome] = useState();
  const [possession_lostAway, setpossession_lostAway] = useState();
  const [duels_wonHome, setduels_wonHome] = useState();
  const [duels_wonAway, setduels_wonAway] = useState();
  const [aerials_wonHome, setaerials_wonHome] = useState();
  const [aerials_wonAway, setaerials_wonAway] = useState();
  const [tacklesHome, settacklesHome] = useState();
  const [tacklesAway, settacklesAway] = useState();
  const [interceptionsHome, setinterceptionsHome] = useState();
  const [interceptionsAway, setinterceptionsAway] = useState();
  const [clearancesHome, setclearancesHome] = useState();
  const [clearancesAway, setclearancesAway] = useState();
  const [homeTeamData, setHomeTeamData] = useState();
  const [awayTeamData, setAwayTeamData] = useState();
  const { alertModal, displayAlert, dismissAlert } = useAlert();
  const [errorMsg, setErrorMsg] = useState(initFormErrors);

  function GetMatchById() {
    axios.get(`${baseURL}/calendarMatches/${id}`).then((resp) => {
      if (resp.data.error) return;
      const MatchData = resp.data.data;
      setteamHomeName(MatchData.teamHome.name);
      setteamHomeLogo(MatchData.teamHome.logo);
      setteamAwayName(MatchData.teamAway.name);
      setteamAwayLogo(MatchData.teamAway.logo);
      setjourneyMatch(MatchData.journeyMatch);
    });
  }
  function GetMatchStatsById() {
    axios.get(`${baseURL}/calendarMatches/matchstats/${id}`).then((resp) => {
      if (resp.data.error) return;
      const MatchStatsData = resp.data.data;
      MatchStatsData?.map((item) => {
        if (item.status === "home") {
          setHomeTeamData(item);
          setgoalsHome(item.goals);
          setInputValue(item.ball_possession);
          settotal_shotsHome(item.total_shots);
          setshotsOnTargetHome(item.shotsOnTarget);
          setshotsOffTargetHome(item.shotsOffTarget);
          setblocked_ShotsHome(item.blocked_Shots);
          setcorner_kicksHome(item.corner_kicks);
          setoffsidesHome(item.offsides);
          setfoulsHome(item.fouls);
          setyellow_cardsHome(item.yellow_cards);
          setbig_chancesHome(item.big_chances);
          setcounter_attacksHome(item.counter_attacks);
          setcounter_attack_shotsHome(item.counter_attack_shots);
          setshots_inside_boxHome(item.shots_inside_box);
          setshots_outside_boxHome(item.shots_outside_box);
          setgoalkeeperSavesHome(item.goalkeeperSaves);
          setpassesHome(item.passes);
          setacc_passesHome(item.acc_passes);
          setlong_ballsHome(item.long_balls);
          setcrossesHome(item.crosses);
          setdribblesHome(item.dribbles);
          setpossession_lostHome(item.possession_lost);
          setduels_wonHome(item.duels_won);
          setaerials_wonHome(item.aerials_won);
          settacklesHome(item.tackles);
          setinterceptionsHome(item.interceptions);
          setclearancesHome(item.clearances);
        } else {
          setAwayTeamData(item);
          setgoalsAway(item.goals);
          setResult(item.ball_possession);
          settotal_shotsAway(item.total_shots);
          setshotsOnTargetAway(item.shotsOnTarget);
          setshotsOffTargetAway(item.shotsOffTarget);
          setblocked_ShotsAway(item.blocked_Shots);
          setcorner_kicksAway(item.corner_kicks);
          setoffsidesAway(item.offsides);
          setfoulsAway(item.fouls);
          setyellow_cardsAway(item.yellow_cards);
          setbig_chancesAway(item.big_chances);
          setcounter_attacksAway(item.counter_attacks);
          setcounter_attack_shotsAway(item.counter_attack_shots);
          setshots_inside_boxAway(item.shots_inside_box);
          setshots_outside_boxAway(item.shots_outside_box);
          setgoalkeeperSavesAway(item.goalkeeperSaves);
          setpassesAway(item.passes);
          setacc_passesAway(item.acc_passes);
          setlong_ballsAway(item.long_balls);
          setcrossesAway(item.crosses);
          setdribblesAway(item.dribbles);
          setpossession_lostAway(item.possession_lost);
          setduels_wonAway(item.duels_won);
          setaerials_wonAway(item.aerials_won);
          settacklesAway(item.tackles);
          setinterceptionsAway(item.interceptions);
          setclearancesAway(item.clearances);
        }
      });
    });
  }
  useEffect(() => {
    GetMatchById();
    GetMatchStatsById();
  }, []);
  function handletoggle() {
    displayAlert(
      "info",
      "",
      "Voulez-vous modifier ce match ?",
      "Confirmer",
      (change) => {
        handleUpdate();
        dismissAlert();
      },
      "cancel",
      dismissAlert
    );
  }
  const handleUpdate = () => {
    const data = {
      home: {
        goals: goalsHome,
        ball_possession: inputValue,
        total_shots: total_shotsHome,
        shotsOnTarget: shotsOnTargetHome,
        shotsOffTarget: shotsOffTargetHome,
        blocked_Shots: blocked_ShotsHome,
        corner_kicks: corner_kicksHome,
        offsides: offsidesHome,
        fouls: foulsHome,
        yellow_cards: yellow_cardsHome,
        big_chances: big_chancesHome,
        counter_attacks: counter_attacksHome,
        counter_attack_shots: counter_attack_shotsHome,
        shots_inside_box: shots_inside_boxHome,
        shots_outside_box: shots_outside_boxHome,

        goalkeeperSaves: goalkeeperSavesHome,
        passes: passesHome,
        acc_passes: acc_passesHome,
        long_balls: long_ballsHome,
        crosses: crossesHome,
        dribbles: dribblesHome,
        possession_lost: possession_lostHome,
        duels_won: duels_wonHome,
        aerials_won: aerials_wonHome,
        tackles: tacklesHome,
        interceptions: interceptionsHome,
        clearances: clearancesHome,
        teamId: homeTeamData.teamId,
      },
      away: {
        goals: goalsAway,
        ball_possession: result,
        total_shots: total_shotsAway,
        shotsOnTarget: shotsOnTargetAway,
        shotsOffTarget: shotsOffTargetAway,
        blocked_Shots: blocked_ShotsAway,
        corner_kicks: corner_kicksAway,
        offsides: offsidesAway,
        fouls: foulsAway,
        yellow_cards: yellow_cardsAway,
        big_chances: big_chancesAway,
        counter_attacks: counter_attacksAway,
        counter_attack_shots: counter_attack_shotsAway,
        shots_inside_box: shots_inside_boxAway,
        shots_outside_box: shots_outside_boxAway,
        goalkeeperSaves: goalkeeperSavesAway,
        passes: passesAway,
        acc_passes: acc_passesAway,
        long_balls: long_ballsAway,
        crosses: crossesAway,
        dribbles: dribblesAway,
        possession_lost: possession_lostAway,
        duels_won: duels_wonAway,
        aerials_won: aerials_wonAway,
        tackles: tacklesAway,
        interceptions: interceptionsAway,
        clearances: clearancesAway,
        teamId: awayTeamData.teamId,
      },
    };
    axios
      .put(`${baseURL}/calendarMatches/updateMatchStats/${id}`, data)
      .then((resp) => {
        setErrorMsg(initFormErrors);
        if (resp.data.error) return;
        displayAlert(
          "success",
          "",
          "Statistiques de Match mise à jours avec succes "
        );
        setTimeout(() => {
          dismissAlert();
          setUpdateMatchStatsModalFromChild();
        }, 2500);
        GetMatchStatsById();
      })
      .catch((error) => {
        const errors = error.response.data;
        setErrorMsg(errors);
      });
  };
  const [inputValue, setInputValue] = useState("");
  const [result, setResult] = useState("");
  function handleInputChange(event) {
    const value = event.target.value;
    setInputValue(value);
    if (value.length > 0 && value.length <= 2) {
      const calculatedResult = 100 - value;
      setResult(calculatedResult); // calculate your result based on the value
    } else {
      setResult("");
    }
  }
  return (
    <>
      <div >
        <div
          className="modal modal1 fade show style_block"
          id="role_modal"
          tabIndex="-1"
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog ">
            <div className="modal-content ">
              <div >
                <div className="row pb-4 pt-4 style_header_modal m-0">
                  <div className="col-11">
                    <h3 className="text-center text-white ">
                      Modifier Statistiques Match
                    </h3>
                  </div>
                </div>
              </div>
              <div className="modal-body ">
                <div className="row">
                  <div className="col-5 text-end">
                    <span className="fs-lg-3 text-nowrap fw-bold text-center text-uppercase align-items-center name_teamStats">
                      {teamHomeName}
                    </span>
                    <img
                      className="imgTeamStats_Match"
                      src={`${
                        baseURL + "/team/getFile" + teamHomeLogo.substring(1)
                      }`}
                    />
                  </div>
                  <div className="col-2 d-flex justify-content-center align-items-center goals_date">
                    <div className="scoreStats">
                      {homeTeamData != null && homeTeamData?.goals}
                    </div>
                    -
                    <span className="text-start scoreStats">
                      {awayTeamData != null && awayTeamData?.goals}
                    </span>
                  </div>
                  <div className="col-5">
                    <img
                      className="imgTeamStats_Match"
                      src={`${
                        baseURL + "/team/getFile" + teamAwayLogo.substring(1)
                      }`}
                    />
                    <span className="fs-lg-3 text-nowrap name_teamStats fw-bold text-center text-uppercase align-items-center">
                      {teamAwayName}
                    </span>
                  </div>
                </div>
                <hr></hr>
                <div className="row inputsStats">
                  <div className="col-lg-6">
                    <div>
                      <label className="fw-bold">Buts : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        value={goalsHome}
                        onChange={(e) => setgoalsHome(e.target.value)}
                      />
                         <div className="input-error">
                          <span className="text-danger"> {errorMsg.goals}</span>
                        </div>
                    </div>
                    <div>
                      <label className="fw-bold">Possession : </label>
                      <input
                        type="number"
                        id="Possession"
                        className="form-control form-control"
                        required
                        value={inputValue}
                        onChange={handleInputChange}
                      />
                       <div className="input-error">
                          <span className="text-danger"> {errorMsg.ball_possession}</span>
                        </div>
                    </div>
                    <div>
                      <label className="fw-bold">Nombre total des tirs : </label>
                      <input
                        type="number"
                        id="total shots"
                        className="form-control form-control"
                        required
                        disabled
                        value={
                          Number(shotsOnTargetHome) + Number(shotsOffTargetHome)
                        }
                        onChange={(e) => settotal_shotsHome(e.target.value)}
                      />
                       <div className="input-error">
                          <span className="text-danger"> {errorMsg.total_shots}</span>
                        </div>
                    </div>
                    <div>
                      <label className="fw-bold">Tirs cadrés :</label>
                      <input
                        type="number"
                        id="Shots On Target"
                        className="form-control form-control"
                        required
                        value={shotsOnTargetHome}
                        onChange={(e) => setshotsOnTargetHome(e.target.value)}
                      />
                       <div className="input-error">
                          <span className="text-danger"> {errorMsg.shotsOnTarget}</span>
                        </div>
                    </div>
                    <div>
                      <label className="fw-bold">Tirs non cadrés :</label>
                      <input
                        type="number"
                        id="Shots Off Target"
                        className="form-control form-control"
                        required
                        value={shotsOffTargetHome}
                        onChange={(e) => setshotsOffTargetHome(e.target.value)}
                      />
                        <div className="input-error">
                          <span className="text-danger"> {errorMsg.shotsOffTarget}</span>
                        </div>
                    </div>
                    <div>
                      <label className="fw-bold">Tirs bloqués :</label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        value={blocked_ShotsHome}
                        onChange={(e) => setblocked_ShotsHome(e.target.value)}
                      />
                       <div className="input-error">
                          <span className="text-danger"> {errorMsg.blocked_Shots}</span>
                        </div>
                    </div>
                    <div>
                      <label className="fw-bold">Corners : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        value={corner_kicksHome}
                        onChange={(e) => setcorner_kicksHome(e.target.value)}
                      />
                       <div className="input-error">
                          <span className="text-danger"> {errorMsg.corner_kicks}</span>
                        </div>
                    </div>
                    <div>
                      <label className="fw-bold">Hors-jeu : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        value={offsidesHome}
                        onChange={(e) => setoffsidesHome(e.target.value)}
                      />
                       <div className="input-error">
                          <span className="text-danger"> {errorMsg.offsides}</span>
                        </div>
                    </div>
                    <div>
                      <label className="fw-bold">Fautes : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        value={foulsHome}
                        onChange={(e) => setfoulsHome(e.target.value)}
                      />
                      <div className="input-error">
                          <span className="text-danger"> {errorMsg.fouls}</span>
                        </div>
                    </div>
                    <div>
                      <label className="fw-bold">Cartons jaunes : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        value={yellow_cardsHome}
                        onChange={(e) => setyellow_cardsHome(e.target.value)}
                      />
                        <div className="input-error">
                          <span className="text-danger"> {errorMsg.yellow_cards}</span>
                        </div>
                    </div>
                    <div>
                      <label className="fw-bold">Occasions importantes :</label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        value={big_chancesHome}
                        onChange={(e) => setbig_chancesHome(e.target.value)}
                      />
                      <div className="input-error">
                          <span className="text-danger"> {errorMsg.big_chances}</span>
                        </div>
                    </div>
                    <div>
                      <label className="fw-bold">Contre-attaques : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        value={counter_attacksHome}
                        onChange={(e) => setcounter_attacksHome(e.target.value)}
                      />
                        <div className="input-error">
                          <span className="text-danger"> {errorMsg.counter_attacks}</span>
                        </div>
                    </div>
                    <div>
                      <label className="fw-bold">Tirs en contre-attaque : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        value={counter_attack_shotsHome}
                        onChange={(e) =>
                          setcounter_attack_shotsHome(e.target.value)
                        }
                      />
                           <div className="input-error">
                          <span className="text-danger"> {errorMsg.counter_attack_shots}</span>
                        </div>
                    </div>
                    <div>
                      <label className="fw-bold">Tirs dans les 18 mètres : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        value={shots_inside_boxHome}
                        onChange={(e) =>
                          setshots_inside_boxHome(e.target.value)
                        }
                      />
                        <div className="input-error">
                          <span className="text-danger"> {errorMsg.shots_inside_box}</span>
                        </div>
                    </div>
                    <div>
                      <label className="fw-bold">Tirs en dehors des 18 mètres : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        value={shots_outside_boxHome}
                        onChange={(e) =>
                          setshots_outside_boxHome(e.target.value)
                        }
                      />
                      <div className="input-error">
                          <span className="text-danger"> {errorMsg.shots_outside_box}</span>
                        </div>
                    </div>
                    <div>
                      <label className="fw-bold">Arrêts du gardien de but : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        value={goalkeeperSavesHome}
                        onChange={(e) => setgoalkeeperSavesHome(e.target.value)}
                      />
                        <div className="input-error">
                          <span className="text-danger"> {errorMsg.goalkeeperSaves}</span>
                        </div>
                    </div>
                    <div>
                      <label className="fw-bold">Passes : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        value={passesHome}
                        onChange={(e) => setpassesHome(e.target.value)}
                      />
                       <div className="input-error">
                          <span className="text-danger"> {errorMsg.passes}</span>
                        </div>
                    </div>
                    <div>
                      <label className="fw-bold">Précision des passes : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        value={acc_passesHome}
                        onChange={(e) => setacc_passesHome(e.target.value)}
                      />
                          <div className="input-error">
                          <span className="text-danger"> {errorMsg.acc_passes}</span>
                        </div>
                    </div>
                    <div>
                      <label className="fw-bold">Passes longues : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        value={long_ballsHome}
                        onChange={(e) => setlong_ballsHome(e.target.value)}
                      />
                      <div className="input-error">
                          <span className="text-danger"> {errorMsg.long_balls}</span>
                        </div>
                    </div>
                    <div>
                      <label className="fw-bold">Centres : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        value={crossesHome}
                        onChange={(e) => setcrossesHome(e.target.value)}
                      />
                         <div className="input-error">
                          <span className="text-danger"> {errorMsg.crosses}</span>
                        </div>
                    </div>
                    <div>
                      <label className="fw-bold">Dribbles : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        value={dribblesHome}
                        onChange={(e) => setdribblesHome(e.target.value)}
                      />
                        <div className="input-error">
                          <span className="text-danger"> {errorMsg.dribbles}</span>
                        </div>
                    </div>
                    <div>
                      <label className="fw-bold">Pertes de possession : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        value={possession_lostHome}
                        onChange={(e) => setpossession_lostHome(e.target.value)}
                      />
                         <div className="input-error">
                          <span className="text-danger"> {errorMsg.possession_lost}</span>
                        </div>
                    </div>
                    <div>
                      <label className="fw-bold">Duels remportés : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        value={duels_wonHome}
                        onChange={(e) => setduels_wonHome(e.target.value)}
                      />
                       <div className="input-error">
                          <span className="text-danger"> {errorMsg.duels_won}</span>
                        </div>
                    </div>
                    <div>
                      <label className="fw-bold">Duels aériens remportés : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        value={aerials_wonHome}
                        onChange={(e) => setaerials_wonHome(e.target.value)}
                      />
                       <div className="input-error">
                          <span className="text-danger"> {errorMsg.aerials_won}</span>
                        </div>
                    </div>
                    <div>
                      <label className="fw-bold">Tacles : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        value={tacklesHome}
                        onChange={(e) => settacklesHome(e.target.value)}
                      />
                            <div className="input-error">
                          <span className="text-danger"> {errorMsg.tackles}</span>
                        </div>
                    </div>
                    <div>
                      <label className="fw-bold">Interceptions : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        value={interceptionsHome}
                        onChange={(e) => setinterceptionsHome(e.target.value)}
                      />
                       <div className="input-error">       
                          <span className="text-danger"> {errorMsg.interceptions}</span>
                        </div>
                    </div>
                    <div>
                      <label className="fw-bold">Dégagements : </label>       
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        value={clearancesHome}
                        onChange={(e) => setclearancesHome(e.target.value)}
                      />
                        <div className="input-error">
                          <span className="text-danger"> {errorMsg.clearances}</span>
                        </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div>
                      <label className="fw-bold">Buts : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        onChange={(e) => setgoalsAway(e.target.value)}
                        value={goalsAway}
                      />
                    </div>
                    <div>
                      <label className="fw-bold">Possession : </label>
                      <input
                        type="number"
                        id="Possession"
                        className="form-control form-control"
                        required
                        onChange={(e) => setball_possessionAway(e.target.value)}
                        // value={ball_possessionAway}
                        value={result}
                      />
                    </div>
                    <div>
                      <label className="fw-bold">Nombre total des tirs : </label>
                      <input
                        type="number"
                        id="total shots"
                        className="form-control form-control"
                        required
                        disabled
                        onChange={(e) => settotal_shotsAway(e.target.value)}
                        value={
                          Number(shotsOnTargetAway) + Number(shotsOffTargetAway)
                        }
                      />
                    </div>
                    <div>
                      <label className="fw-bold">Tirs cadrés : </label>
                      <input
                        type="number"
                        id="Shots On Target"
                        className="form-control form-control"
                        required
                        onChange={(e) => setshotsOnTargetAway(e.target.value)}
                        value={shotsOnTargetAway}
                      />
                    </div>
                    <div>
                    <label className="fw-bold">Tirs non cadrés :</label>
                      <input
                        type="number"
                        id="Shots Off Target"
                        className="form-control form-control"
                        required
                        onChange={(e) => setshotsOffTargetAway(e.target.value)}
                        value={shotsOffTargetAway}
                      />
                    </div>
                    <div>
                    <label className="fw-bold">Tirs bloqués :</label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        onChange={(e) => setblocked_ShotsAway(e.target.value)}
                        value={blocked_ShotsAway}
                      />
                    </div>
                    <div>
                    <label className="fw-bold">Corners : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        onChange={(e) => setcorner_kicksAway(e.target.value)}
                        value={corner_kicksAway}
                      />
                    </div>
                    <div>
                    <label className="fw-bold">Hors-jeu : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        onChange={(e) => setoffsidesAway(e.target.value)}
                        value={offsidesAway}
                      />
                    </div>
                    <div>
                    <label className="fw-bold">Fautes : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        onChange={(e) => setfoulsAway(e.target.value)}
                        value={foulsAway}
                      />
                    </div>
                    <div>
                    <label className="fw-bold">Cartons jaunes : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        onChange={(e) => setyellow_cardsAway(e.target.value)}
                        value={yellow_cardsAway}
                      />
                    </div>
                    <div>
                    <label className="fw-bold">Occasions importantes :</label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        onChange={(e) => setbig_chancesAway(e.target.value)}
                        value={big_chancesAway}
                      />
                    </div>
                    <div>
                    <label className="fw-bold">Contre-attaques : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        onChange={(e) => setcounter_attacksAway(e.target.value)}
                        value={counter_attacksAway}
                      />
                    </div>
                    <div>
                    <label className="fw-bold">Tirs en contre-attaque : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        onChange={(e) =>
                          setcounter_attack_shotsAway(e.target.value)
                        }
                        value={counter_attack_shotsAway}
                      />
                    </div>
                    <div>
                    <label className="fw-bold">Tirs dans les 18 mètres : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        onChange={(e) =>
                          setshots_inside_boxAway(e.target.value)
                        }
                        value={shots_inside_boxAway}
                      />
                    </div>
                    <div>
                    <label className="fw-bold">Tirs en dehors des 18 mètres : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        onChange={(e) =>
                          setshots_outside_boxAway(e.target.value)
                        }
                        value={shots_outside_boxAway}
                      />
                    </div>
                    <div>
                    <label className="fw-bold">Arrêts du gardien de but : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        onChange={(e) => setgoalkeeperSavesAway(e.target.value)}
                        value={goalkeeperSavesAway}
                      />
                    </div>
                    <div>
                      <label className="fw-bold">Passes : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        onChange={(e) => setpassesAway(e.target.value)}
                        value={passesAway}
                      />
                    </div>
                    <div>
                    <label className="fw-bold">Précision des passes : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        onChange={(e) => setacc_passesAway(e.target.value)}
                        value={acc_passesAway}
                      />
                    </div>
                    <div>
                    <label className="fw-bold">Passes longues : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        onChange={(e) => setlong_ballsAway(e.target.value)}
                        value={long_ballsAway}
                      />
                    </div>
                    <div>
                    <label className="fw-bold">Centres : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        onChange={(e) => setcrossesAway(e.target.value)}
                        value={crossesAway}
                      />
                    </div>
                    <div>
                    <label className="fw-bold">Dribbles : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        onChange={(e) => setdribblesAway(e.target.value)}
                        value={dribblesAway}
                      />
                    </div>
                    <div>
                    <label className="fw-bold">Pertes de possession : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        onChange={(e) => setpossession_lostAway(e.target.value)}
                        value={possession_lostAway}
                      />
                    </div>
                    <div>
                    <label className="fw-bold">Duels remportés : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        onChange={(e) => setduels_wonAway(e.target.value)}
                        value={duels_wonAway}
                      />
                    </div>
                    <div>
                    <label className="fw-bold">Duels aériens remportés : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        onChange={(e) => setaerials_wonAway(e.target.value)}
                        value={aerials_wonAway}
                      />
                    </div>
                    <div>
                    <label className="fw-bold">Tacles : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        onChange={(e) => settacklesAway(e.target.value)}
                        value={tacklesAway}
                      />
                    </div>
                    <div>
                      <label className="fw-bold">Interceptions : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        onChange={(e) => setinterceptionsAway(e.target.value)}
                        value={interceptionsAway}
                      />
                    </div>
                    <div>
                    <label className="fw-bold">Dégagements : </label>
                      <input
                        type="number"
                        id="Goals"
                        className="form-control form-control"
                        required
                        onChange={(e) => setclearancesAway(e.target.value)}
                        value={clearancesAway}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => setUpdateMatchStatsModalFromChild("")}
                >
                  Annuler
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleUpdate}
                >
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
        {alertModal}
      </div>
    </>
  );
};
export default UpdateMatchStats;
