import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { logError } from "../components/Toastconfig";
import UpdatePlayer from "./players/UpdatePlayer";
import TeamLiked from "../components/Teams/TeamLiked";
import DisplayLikedCompetitions from "../components/competitions/displayLikedCompetitions";
import PlayersLiked from "./players/PlayerLiked";
import { useHasPermission } from "../hooks/checkPermissions";
import Chart from "react-apexcharts";
import parse from "html-react-parser";

import DisplayLastTransfers from "../components/transfers/displayLastTransfers";
const dataPos = {
  GoalKeeper: "GK",
  Back_Right: "AD",
  Back_left: "AG",
  Libero_Left: "LG",
  Libero_Right: "LD",
  defensive_midfielder: "MDF",
  Right_Offensive_midfielder: "MOD",
  Left_Offensive_midfielder: "MOG",
  Right_Winger: "AID",
  Left_Winger: "AIG",
  Center_forward: "AV",
};
const baseURL = process.env.REACT_APP_BASE_URL;
const Test = () => {
  const { checkPermissions } = useHasPermission();
  const baseURL = process.env.REACT_APP_BASE_URL;

  const [likedPlayers, setLikedPlayers] = useState([]);
  const [competitionLiked, setCompetitionLiked] = useState([]);
  const [teamIsLiked, setteamIsLiked] = useState([]);
  const [Playercount, setPlayerCount] = useState();
  const [Teamscount, setTeamsCount] = useState();
  const [Competitionscount, setCompetitionsCount] = useState();
  const [playersGenerationsyears, setplayersGenerationsyears] = useState();
  const [playersGenerationscount, setplayersGenerationscount] = useState();
  const [adsPlayer, setAdsPlayer] = useState();
  const [adsTeams, setAdsTeams] = useState();
  const [adsCompetitions, setAdsCompetitions] = useState();

  function getFavorisPlayerList() {
    if (checkPermissions("players")) {
      axios
        .get(`${baseURL}/players/getAllPlayersLiked`)
        .then((response) => {
          setLikedPlayers(response.data.data);
        })
        .catch((error) => logError());
    }
  }
  function countPlayers() {
    if (checkPermissions("players")) {
      axios
        .get(`${baseURL}/players/playersStats/countPlayers`)
        .then((response) => {
          setPlayerCount(response.data.data.map((item) => item.count));
        })
        .catch((error) => logError());
    }
  }
  function countPlayersGenerations() {
    if (checkPermissions("players")) {
      axios
        .get(`${baseURL}/players/playersStats/countPlayersGenerations`)
        .then((response) => {
          setplayersGenerationscount(
            response.data.data.map((item) => item.count)
          );
          setplayersGenerationsyears(
            response.data.data.map((item) => item.year.toFixed(0))
          );
        })
        .catch((error) => logError());
    }
  }

  const [transfers, setTransfers] = useState([]);

  useEffect(function () {
    getLastTrasnfers();
  }, []);
  function getLastTrasnfers() {
    axios
      .get(`${baseURL}/transfers/getLastTransfers`)
      .then((res) => {
        setTransfers(res.data.data);
      })
      .catch((err) => {
        logError();
      });
  }
  function countTeams() {
    if (checkPermissions("teams")) {
      axios
        .get(`${baseURL}/teams/countTeam`)
        .then((response) => {
          setTeamsCount(response.data.data.map((item) => item.count));
        })
        .catch((error) => logError());
    }
  }
  function countCompetitions() {
    if (checkPermissions("competitions")) {
      axios
        .get(`${baseURL}/competitions/countcompetitions`)
        .then((response) => {
          setCompetitionsCount(response.data.data.map((item) => item.count));
        })
        .catch((error) => logError());
    }
  }
  function getLiked() {
    if (checkPermissions("competitions")) {
      axios
        .get(`${baseURL}/competitions/getLiked`)
        .then((res) => {
          setCompetitionLiked(res.data.data);
        })
        .catch((err) => {
          logError();
        });
    }
  }

  function getteamIsLiked() {
    if (checkPermissions("teams")) {
      axios
        .get(`${baseURL}/teams/GetLikesbyUserId`)
        .then((res) => {
          setteamIsLiked(res.data.data);
        })
        .catch((err) => {});
    }
  }
  useEffect(() => {
    getteamIsLiked();
    getLiked();
    getFavorisPlayerList();
    countPlayers();
    countTeams();
    countCompetitions();
    countPlayersGenerations();
  }, []);
  let state2 = {
    series: [
      {
        name: "New Players",
        data: Playercount,
        color: "#3284C2",
      },
      {
        name: "New Teams",
        data: Teamscount,
        color: "#A6D2E1",
      },
      {
        name: "New Competitions",
        data: Competitionscount,
        color: "#CCEDE4",
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"],
      },
      xaxis: {
        categories: [
          "Jan",
          "Fev",
          "Mar",
          "Avr",
          "Mai",
          "Juin",
          "Juil",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
      },
      yaxis: {
        title: {
          // text: "$ (thousands)",
        },
      },
      fill: {
        opacity: 1,
      },
      tooltip: {
        y: {
          // formatter: function (val) {
          //   return "$ " + val + " thousands";
          // },
        },
      },
      title: {
        text: "Joueurs,Equipes,Compétitions créer chaque mois",
        align: "left",
      },
    },
  };

  const [players, setPlayers] = useState([]);

  async function getAllPlayers() {
    if (checkPermissions("players")) {
      axios
        .get(`${baseURL}/players/getAllPlayers`)
        .then((response) => {
          if (response.data.error) return;
          setPlayers(response.data.data);
        })
        .catch((err) => {});
    }
  }
  useEffect(() => {
    getAllPlayers();
  }, []);
  let state3 = {
    series: [
      {
        name: "Joueurs",
        data: playersGenerationscount,
      },
    ],
    options: {
      chart: {
        type: "bar",
        height: 300,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          horizontal: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: playersGenerationsyears,
      },
      title: {
        text: "Nombre des joueurs par génération",
        align: "center",
      },
    },
  };
  const [newslist, setNewslist] = useState([]);
  function getallnews() {
    if (checkPermissions("news")) {
      axios
        .get(`${baseURL}/news/allNews`)
        .then((res) => {
          setNewslist(res.data.data);
        })
        .catch((err) => {});
    }
  }

  useEffect(function () {
    getallnews();
  }, []);

  function getallPlayerBoosted() {
    if (checkPermissions("players")) {
      axios
        .get(`${baseURL}/players/getPlayersBoosted`)
        .then((res) => {
          const boostedPlayers = res.data;
          const playerDataArray = Object.values(boostedPlayers)
            .filter((playerData) => {
              return playerData.status !== "canceled" && playerData.status !== "expired";
            })
            .map((playerData) => {
              return playerData.player;
            });
          if (playerDataArray.length > 0) {
            const lastBoostedPlayer = playerDataArray[0];
            setAdsPlayer([lastBoostedPlayer]);
          } else {
            setAdsPlayer([]);
          }
        })
        .catch((err) => {
          logError();
        });
    }
  }
  useEffect(function () {
    getallPlayerBoosted();
  }, []);

  function getallCompetition() {
    if (checkPermissions("competitions")) {
      axios
        .get(`${baseURL}/competitions/getCompetitionsBoosted`)
        .then((res) => {
          const boostedCompetitions = res.data;
          const CompetitionDataArray = Object.values(boostedCompetitions)
            .filter((competitionData) => {
              return competitionData.status !== "canceled" && competitionData.status !== "expired";
            })
            .map((competitionData) => {
              return competitionData.competition;
            });
        if (CompetitionDataArray.length > 0) {
          const lastBoostedCompetition = CompetitionDataArray[0];
          setAdsCompetitions([lastBoostedCompetition]);
        } else {
          setAdsCompetitions([]);
        }
      })
        .catch((err) => {
          logError();
        });
    }
  }
  function getTeamsBoosted() {
    if (checkPermissions("teams")) {
      axios
        .get(`${baseURL}/teams/getTeamsBoosted`)
        .then((res) => {
          const boostedTeams = res.data;
          const teamDataArray = Object.values(boostedTeams)
            .filter((teamData) => {
              return teamData.status !== "canceled" && teamData.status !== "expired";
            })
            .map((teamData) => {
              return teamData.team;
            });
  
          if (teamDataArray.length > 0) {
            const lastBoostedTeam = teamDataArray[0];
            setAdsTeams([lastBoostedTeam]);
          } else {
            setAdsTeams([]);
          }
        })
        .catch((err) => {
          logError();
        });
    }
  }
  useEffect(function () {
    getallCompetition();
    getTeamsBoosted();
  }, []);
  const dataPos = {
    GoalKeeper: "Gardient",
    Back_Right: "Arriére Droit",
    Back_Left: "Arriére Gauche",
    Libero_Left: "Libero Gauche",
    Libero_Right: "Libero Droit",
    defensive_midfielder: "Milieu Deffensive",
    Right_Offensive_midfielder: "Milieu Offensif Droit",
    Left_Offensive_midfielder: "Milieu Offensif Gauche",
    Right_Winger: "Ailier Droit",
    Left_Winger: "Ailier Gauche",
    Center_forward: "Avant centre",
  };
  const dataFoot = {
    right: "droite",
    left: "Gauche",
    both: "Les deux",
  };
  const [displayTeams, setDisplayTeams] = useState(false);
  const [displayLikedTeams, setDisplayLikedTeams] = useState(false);
  const [displayBoostedTeams, setDisplayBoostedTeams] = useState(false);
  const [displayNews, setDisplayNews] = useState(false);
  const [displayPlayers, setDisplayPlayers] = useState(false);
  const [displayLikedPlayers, setDisplayLikedPlayers] = useState(false);
  const [displayBoostedPlayers, setDisplayBoostedPlayers] = useState(false);
  const [displayCompetitions, setDisplayCompetitions] = useState(false);
  const [displayLikedCompetitions, setDisplayLikedCompetitions] =
    useState(false);
  const [displayBoostedCompetitions, setDisplayBoostedCompetitions] =
    useState(false);
  const [displayTransfers, setDisplayTransfers] = useState(false);
  const [displayLiked, setDisplayLiked] = useState(false);

  // Update the display states based on permissions and data lengths
  useEffect(() => {
    const hasTeamsPermission = checkPermissions("teams");
    const hasNewsPermission = checkPermissions("news");
    const hasPlayersPermission = checkPermissions("players");
    const hasCompetitionsPermission = checkPermissions("competitions");

    setDisplayTeams(hasTeamsPermission);
    setDisplayLikedTeams(teamIsLiked?.length > 0);
    setDisplayBoostedTeams(adsTeams?.length > 0);
    setDisplayPlayers(hasPlayersPermission);
    setDisplayLikedPlayers(likedPlayers?.length > 0);
    setDisplayBoostedCompetitions(adsCompetitions?.length > 0);
    setDisplayCompetitions(hasCompetitionsPermission);
    setDisplayLikedCompetitions(competitionLiked?.length > 0);
    setDisplayBoostedPlayers(adsPlayer?.length > 0);
    setDisplayNews(hasNewsPermission);
    setDisplayTransfers(transfers?.length > 0);
    const adCondition =
      !displayNews &&
      !displayBoostedCompetitions &&
      !displayBoostedTeams &&
      !displayBoostedPlayers &&
      (displayLikedTeams || displayLikedCompetitions || displayLikedPlayers);
    setDisplayLiked(adCondition);
  }, [
    checkPermissions,
    teamIsLiked?.length,
    competitionLiked?.length,
    likedPlayers?.length,
    transfers?.length,
    adsPlayer?.length,
    adsTeams?.length,
    adsCompetitions?.length,
  ]);

  return (
    <>
      <div className="row mt-4 ms-2">
        <>
          <div
            className={
              displayPlayers && displayNews
                ? "col-lg-4"
                : displayNews &&
                  (displayLikedTeams ||
                    displayLikedPlayers ||
                    displayLikedCompetitions) &&
                  !displayBoostedCompetitions &&
                  !displayBoostedTeams &&
                  !displayBoostedPlayers
                ? "col-lg-10"
                : displayNews &&
                  (displayBoostedCompetitions ||
                    displayBoostedPlayers ||
                    displayBoostedTeams)
                ? "col-lg-4"
                : displayPlayers
                ? "col-lg-4"
                : displayNews
                ? "col-lg-12"
                : "hidden"
            }
          >
            {displayPlayers && (
              <div className="card CardShadow mt-1">
                <div className="mixed-chart">
                  <Chart
                    options={state3.options}
                    series={state3.series}
                    type="bar"
                    height={300}
                  />
                </div>
              </div>
            )}
            <>
            {newslist?.length === 0 ? (
  ""
) : (
  <div className="row card CardShadow py-3 mt-3">
    <div className="card-title">
      <h3 className="fw-bold text-center">LES DERNIERES ACTUALITES</h3>
    </div>
    <div className="card-body">
      {newslist.map((news, key) => {
          return (
            <div className="col-lg-12 mb-3">
              <div
                className="cardNews CardShadow"
                style={{
                  background:
                    "url(" + baseURL + "/new/getFile" + news.media + ") no-repeat center center",
                }}
              >
                <div className="cardNews-content">
                <h5 className="cardNews-title">
                {news.title.length > 22 ? news.title.substring(0, 22) + "..." : news.title}
                </h5>
                  <p className="cardNews-body test">
                    ( {news.createdat.substring(0, 10)})
                    {news.content.length > 85 ? parse(news.content.substring(0, 85) + "...") : parse(news.content)}
                  </p>                 
                  <Link
                    to={`/news/details/${news.id}`}
                    className="buttonNews noTextDecoration" 
                  >
                    Learn More
                  </Link>
                </div>
              </div>
            </div>
          );
      })}
    </div>
  </div>
)}
            </>
            {displayPlayers && displayTeams && (
              <DisplayLastTransfers transfers={transfers} />
            )}
          </div>
          <div
            className={
              displayPlayers &&
              (displayLikedTeams ||
                displayLikedPlayers ||
                displayLikedCompetitions)
                ? "col-lg-6"
                : displayNews &&
                  (displayLikedTeams ||
                    displayLikedPlayers ||
                    displayLikedCompetitions) &&
                  (displayBoostedCompetitions ||
                    displayBoostedTeams ||
                    displayBoostedPlayers)
                ? "col-lg-6"
                : (displayPlayers || displayNews) &&
                  (displayBoostedCompetitions ||
                    displayBoostedTeams ||
                    displayBoostedPlayers) &&
                  !displayLikedTeams &&
                  !displayLikedPlayers &&
                  !displayLikedCompetitions
                ? "col-lg-8"
                : (displayLikedTeams ||
                    displayLikedPlayers ||
                    displayLikedCompetitions) &&
                  (displayBoostedCompetitions ||
                    displayBoostedTeams ||
                    displayBoostedPlayers) &&
                  !displayNews
                ? "col-lg-10"
                : displayBoostedCompetitions ||
                  displayBoostedTeams ||
                  displayBoostedPlayers
                ? "col-lg-12"
                : displayPlayers
                ? "col-lg-8"
                : "hidden"
            }
          >
            {displayPlayers && displayTeams && displayCompetitions && (
              <div className="card CardShadow mb-3">
                <div className="mixed-chart">
                  <Chart
                    options={state2.options}
                    series={state2.series}
                    type="bar"
                    height="350"
                  />
                </div>
              </div>
            )}
            <div>
              {players?.filter((player) => player.rate > 8).length === 0 ? (
                ""
              ) : (
                <div className="row">
                  <div className="col-lg-12">
                    <div className="card CardShadow">
                      <div className="card-body">
                        <div className="active-member">
                          <div className="table-responsive">
                            <h2 className="text-center">
                              JOUEURS LES PLUS NOTÉS
                            </h2>
                            <table className="table table-xs mb-0">
                              <thead>
                                <tr>
                                  <th>Joueurs</th>
                                  <th>Pays</th>
                                  <th>Génération</th>
                                  <th>Equipe</th>
                                  <th>Note</th>
                                </tr>
                              </thead>
                              <tbody>
                                {players
                                  ?.filter((player) => player.rate > 8)
                                  .sort((a, b) => b.rate - a.rate)
                                  .slice(0, 5)
                                  .map((player, key) => (
                                    <tr key={key}>
                                      <td className="d-flex">
                                        <img
                                          src={`${baseURL}/players/getFile${player.avatar}`}
                                          className="rounded-circle"
                                          width="35"
                                          height="35"
                                        />
                                        <Link
                                            className="noTextDecoration"
                                            to={`/player/details/${player.id}`}
                                        >
                                         <span className="ps-2 playerName text-nowrap playerNote">
                                         {player.lastName} 
                                         {" "}
                                         {player.firstName.length > 5
                                          ? player.firstName.substring(0, 5) + "..."
                                          : player.firstName}
                                        </span>
                                        </Link>
                                      </td>
                                      <td>{player.countryname}</td>
                                      <td>{player.birthday?.slice(0, 4)}</td>
                                      <td>
                                        <div
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title={player.currentTeam?.name}
                                        >
                                          {player.currentTeam === null ? (
                                            <div className="d-flex align-items-center">
                                              <img
                                                src="./assets/imgs/emptyTeam.png"
                                                width="35"
                                                height="35"
                                              />
                                            </div>
                                          ) : (
                                            <Link
                                              className="style_text_deco"
                                              to={`/oneTeam/${player.currentTeam?.id}`}
                                            >
                                              <img
                                                className="pe-1"
                                                src={`${baseURL}/team/getFile${player.currentTeam?.logo.substring(
                                                  1
                                                )}`}
                                                width="35"
                                                height="35"
                                              />
                                            </Link>
                                          )}
                                        </div>
                                      </td>
                                      <td>
                                        <div>
                                          {player.rate >= 8 &&
                                          player.rate < 8.5 ? (
                                            <div class="d-flex align-items-center">
                                              <span className="fw-bolder rate_home">
                                                {player.rate}
                                              </span>
                                              <div class="progress flex-grow-1 ms-1">
                                                <div className="progress-bar progress_home_orange"></div>
                                              </div>
                                              </div>
                                          ) : player.rate >= 8.5 &&
                                            player.rate < 9 ? (
                                              <div class="d-flex align-items-center">

                                              <div className="fw-bolder rate_home">
                                                {player.rate}
                                              </div>
                                              <div class="progress flex-grow-1 ms-1">
                                                <div className="progress-bar progress_home_yellow"></div>
                                              </div>
                                              </div>
                                          ) : player.rate >= 9 &&
                                            player.rate < 9.5 ? (
                                              <div class="d-flex align-items-center">
                                              <div className="fw-bolder rate_home">
                                                {player.rate}
                                              </div>
                                              <div className="progress flex-grow-1 ms-1">
                                                <div className="progress-bar progress_home_green"></div>
                                              </div>
                                              </div>
                                          ) : player.rate >= 9.5 &&
                                            player.rate <= 10 ? (
                                              <div class="d-flex align-items-center">
                                              <div className="fw-bolder rate_home">
                                                {player.rate}
                                              </div>
                                              <div className="progress flex-grow-1 ms-1">
                                                <div className="progress-bar progress_home_blue"></div>
                                              </div>
                                              </div>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!displayBoostedPlayers &&
              !displayBoostedCompetitions &&
              !displayBoostedTeams ? (
                ""
              ) : (
                <>
                  <div className=" card mt-3 mb-5  CardShadow">
                    <div className="card-title text-center mt-3 fw-bold">
                      <h3>BRILLÉS CETTE SEMAINE</h3>
                    </div>
                    <div className="card-body">
                      <div className="row mb-4 py-3">
                        {displayBoostedPlayers && (
                          <div className="col mb-4">
                            {adsPlayer?.map((player, key) =>
                              key > 0 ? (
                                ""
                              ) : (
                                <>
                                  <article className="card">
                                  <img
                    className="thumb"
                    src={
                      baseURL +
                      "/players/getFile" +
                      player.avatar.substring(1)
                    }
                    alt={player.firstName}
                  />
                                    <div className="infos">
                                      <h2 className="title text-center text-nowrap">
                                        Joueur de la semaine :
                                      </h2>
                                      <h3 className="title text-center">
                                        {player.firstName} {player.lastName}
                                       
                                      </h3>
                                      <div>
                                        <Link
                                            className="noTextDecoration"
                                            to={`/oneTeam/${player.CurrentTeam?.id}`}
                                        >
                                          <h3 className="seats">
                                            Equipe :
                                            <img
                                              className="ms-2"
                                              src={`${
                                                baseURL +
                                                "/team/getFile" +
                                                player.CurrentTeam?.logo.substring(
                                                  1
                                                )
                                              }`}
                                              width="10%"
                                              height="10%"
                                            />
                                            <span style={{ color: "#0d6efd" }}>
                                              {player?.CurrentTeam?.name}
                                            </span>
                                          </h3>
                                        </Link>
                                      </div>

                                      <div>
                                   <h3 className="seats">
                                    Poste:
                                   {player.positions &&
                                    JSON.parse(player.positions).slice(0, 1).map((item, key) => {
                                     return (
                                            <span className="badge bg-secondary ms-2" key={key}>
                                                 {dataPos[item]}
                                             </span>
                                             );
                                           })}
                                    {player.positions && JSON.parse(player.positions).length > 1 && ( <>...</> 
                                       )}
                                    </h3>
                                    </div>
                                      <div>
                                        <h3 className="seats">
                                          Age:
                                          <span>
                                          {new Date().getFullYear() -
                                            player.birthday.slice(0, 4)}
                                          ans
                                        </span>
                                        </h3>
                                      </div>
                                      <div>
                                        <h3 className="seats">
                                          Pied:
                                          <span className="fw-bold">
                                            {dataFoot[player.preferedfoot]}
                                          </span>
                                        </h3>
                                      </div>
                                      <div className="row">
                                        <div className="col-3">
                                          <h3 className="seats">note: </h3>
                                        </div>
                                        <div className="col-3">
                                          {player.rate <= 2 ? (
                                            <span className=" rate ps-lg-3 badge text-center py-1 rate_player_red">
                                              {player.rate}
                                            </span>
                                          ) : player.rate > 2 &&
                                            player.rate <= 4 ? (
                                            <span className="ms-2 fw-bold rate ps-lg-3 badge d-flex justify-content-center py-1 rate_player_orange">
                                              {player.rate}
                                            </span>
                                          ) : player.rate > 4 &&
                                            player.rate <= 6 ? (
                                            <span className="ms-2 fw-bold rate ps-lg-3 badge d-flex justify-content-center py-1 rate_player_yellow">
                                              {player.rate}
                                            </span>
                                          ) : player.rate > 6 &&
                                            player.rate <= 8 ? (
                                            <span className="ms-2 fw-bold rate ps-lg-3 badge d-flex justify-content-center py-1 rate_player_green">
                                              {player.rate}
                                            </span>
                                          ) : (
                                            <span className="ms-2 fw-bold rate ps-lg-3 badge d-flex justify-content-center py-1 rate_player_blue">
                                              {player.rate}
                                            </span>
                                          )}
                                        </div>

                                        <h6></h6>
                                      </div>
                                      <div className="row">
                                        <div className="col-12">
                                          <Link
                                            className="noTextDecoration"
                                            to={`/player/details/${player.id}`}
                                          >
                                            <button
                                              type="button"
                                              className="btn btn-primary smallFontSize text-nowrap"
                                            >
                                              Voir détails
                                            </button>
                                          </Link>
                                        </div>
                                        <div className="col-12 pt-2">
                                          <Link
                                            className="noTextDecoration"
                                            to={`/players`}
                                          >
                                            <button
                                              type="button"
                                              className="btn btn-primary smallFontSize"
                                            >
                                              Joueurs
                                            </button>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </article>
                                </>
                              )
                            )}
                          </div>
                        )}
                        {displayBoostedCompetitions && (
                          <div className="col mb-4">
                            {adsCompetitions?.map((competition, key) =>
                              key > 0 ? (
                                ""
                              ) : (
                                <>
                                  <article className="card">
                                         <img className="thumb"
                    src={
                      baseURL +
                      "/competition/getFile" +
                      competition.logo.substring(1)
                    }
                  />
                                    <div className="infos infosCompetition">
                                      <h2 className="title text-center text-nowrap">
                                        Compétition de la semaine :
                                      </h2>
                                      <h3 className="title text-center">
                                        {competition.comp_name}
                                      </h3>
                                      <span> </span>
                                      <h3 className="seats">
                                        Date Debut :
                                        <span className="fw-bold ms-2">
                                          {competition.date_debut}
                                        </span>
                                      </h3>
                                      <h3 className="seats">
                                        Pays :
                                        <span className="fw-bold">
                                          {competition.Pays.name}
                                        </span>
                                      </h3>
                                      <div>
                                        <h3 className="seats">
                                          Slug :
                                          <span className="fw-bold ms-2">
                                            {competition.comp_slug}
                                          </span>
                                        </h3>
                                      </div>
                                      <div className="row">
                                        <div className="col-12">
                                          <Link
                                            className="noTextDecoration"
                                            to={`/oneCompetition/${competition.id}`}
                                          >
                                            <button
                                              type="button"
                                              className="btn btn-primary text-nowrap smallFontSize"
                                            >
                                              Voir détails
                                            </button>
                                          </Link>
                                        </div>
                                        <div className="col-12 pt-2">
                                          <Link
                                            className="noTextDecoration"
                                            to={`/competitions`}
                                          >
                                            <button
                                              type="button"
                                              className="btn btn-primary smallFontSize"
                                            >
                                              Compétitions
                                            </button>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </article>
                                </>
                              )
                            )}
                          </div>
                        )}
                        {displayBoostedTeams && (
                          <div className="col mb-4">
                            {adsTeams?.map((team, key) =>
                              key > 0 ? (
                                ""
                              ) : (
                                <>
                                  <article className="card">
                                  <img
                    className="thumb"
                    src={
                      baseURL +
                      "/team/getFile" +
                     team.logo.substring(1)
                    }
                  />
                                    <div className="infos infosTeam ">
                                      <h2 className="title text-center text-nowrap">
                                        Equipe de la semaine :
                                      </h2>
                                      <h3 className="title text-center">                                        
                                          {team?.name}
                                      </h3>
                                      <h3 className="seats">
                                        Nom De Stade :
                                        <span className="fw-bold ms-2">
                                          {team.stade_Name}
                                        </span>
                                      </h3>
                                      <div>
                                        <h3 className="seats">
                                          Pays :
                                          <span className="fw-bold">
                                            {team.Pays.name}
                                          </span>
                                        </h3>
                                      </div>
                                      <div>
                                        <h3 className="seats">
                                          Acronyme :
                                          <span className="fw-bold ms-2">
                                            {team.acronyme}
                                          </span>
                                        </h3>
                                      </div>
                                      <div className="row">
                                        <div className="col-12">
                                          <Link
                                            className="noTextDecoration"
                                            to={`/oneTeam/${team.id}`}
                                          >
                                            <button
                                              type="button"
                                              className="btn btn-primary text-nowrap smallFontSize"
                                            >
                                              Voir détails
                                            </button>
                                          </Link>
                                        </div>
                                        <div className="col-12 pt-2">
                                          <Link
                                            className="noTextDecoration"
                                            to={`/teams`}
                                          >
                                            <button
                                              type="button"
                                              className="btn btn-primary smallFontSize"
                                            >
                                              Équipes
                                            </button>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </article>
                                </>
                              )
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div
            className={
              !displayNews &&
              !displayBoostedCompetitions &&
              !displayBoostedTeams &&
              !displayBoostedPlayers &&
              !displayPlayers &&
              (displayLikedTeams ||
                displayLikedCompetitions ||
                displayLikedPlayers)
                ? "col-lg-12"
                : (displayPlayers || displayTeams || displayCompetitions) &&
                  (displayLikedTeams ||
                    displayLikedCompetitions ||
                    displayLikedPlayers)
                ? "col-lg-2"
                : "hidden"
            }
          >
            <div className={displayLiked ? "row" : ""}>
              <div className={displayLiked ? "col-12  " : ""}>
                <TeamLiked teamIsLiked={teamIsLiked} />
              </div>
              <div className={displayLiked ? "col-12 " : ""}>
                <DisplayLikedCompetitions competitionLiked={competitionLiked} />
              </div>
              <div className={displayLiked ? "col-12 " : ""}>
                <PlayersLiked likedPlayers={likedPlayers} />
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default Test;
