import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useAlert from "../../hooks/alertHook";
import DataTable from "react-data-table-component";
import EmptyData from "../../components/emptyData";
import TeamLiked from "../../components/Teams/TeamLiked";
import DisplayLikedCompetitions from "../../components/competitions/displayLikedCompetitions";
import AdsCompetition from "./AdsCompetition";
import PlayersLiked from "../players/PlayerLiked";
import NewsLists from "../../components/news/NewsLists";
import { logError } from "../../components/Toastconfig";
import { useHasPermission } from "../../hooks/checkPermissions";
import LoaderLoading from "../../components/LoaderLoading";

const Competitions_of_simpleUser = () => {
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "rgba(223, 223, 223, 0.59);",
        color: "#5d7079",
      },
    },
  };
  const { checkPermissions } = useHasPermission();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [data, setData] = useState();
  const [competitions, setCompetition] = useState([]);
  const [addCompetitionModal, setAddCompetitionModal] = React.useState();
  const [filtredCompetitions, setFiltredcompetition] = React.useState(null);
  const { alertModal, displayAlert, dismissAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(true);

  async function getAllCompetitions() {
    setIsLoading(true);
    axios
      .get(`${baseURL}/competitions/getAllOfSimpleUser`)
      .then((response) => {
        if (response.data.error) return;
        const filteredCompetitions = response.data.data.filter(
          (competition) => competition.isEnabled === "yes"
        );
        setCompetition(filteredCompetitions);
        setFiltredcompetition(filteredCompetitions);
      })
      .catch((err) => {
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false); // set isLoading to false after receiving a response or an error
      });
  }
  useEffect(() => {
    if (filtredCompetitions == null) return;
    if (isLoading) {
      setData(<LoaderLoading />);
    } else if (filtredCompetitions.length > 0) {
      setData(
        <DataTable
          columns={columns}
          data={filtredCompetitions}
          customStyles={customStyles}
        />
      );
    } else {
      setData(<EmptyData />);
    }
  }, [isLoading, filtredCompetitions]);

  const [countries, setCountris] = useState([]);
  function getallCountries() {
    axios
      .get(`${baseURL}/countries/read`)
      .then((res) => {
        setCountris(res.data);
      })
      .catch((err) => {
        logError();
      });
  }
  useEffect(function () {
    getallCountries();
  }, []);
  const [addcreateCompetitionsModal, setcreateCompetitionsModal] =
    React.useState();

  async function enableDisable(competitionId, isEnabled) {
    let message =
      isEnabled == "yes"
        ? "voulez vous désactiver cette compétition?"
        : "Voulez vous activer cette compétition?";
    let status = isEnabled == "yes" ? "danger" : "info";
    displayAlert(
      status,
      "",
      message,
      "Confirmer",
      () => {
        axios
          .get(`${baseURL}/competitions/toggleStatus/${competitionId}`)
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getAllCompetitions();
              dismissAlert();
            }
          });
      },
      "cancel",
      dismissAlert
    );
  }

  useEffect(() => {
    getAllCompetitions();
  }, []);
  async function likeDislike(competitionId, isLiked) {
    let message =
      isLiked == "yes"
        ? "voulez vous supprimer cette compétition de la liste des favories?"
        : "voulez vous ajouter cette compétition à la liste des favories?";
    let status = isLiked == "yes" ? "danger" : "info";
    displayAlert(
      status,
      "",
      message,
      "Confirmer",
      () => {
        axios
          .post(`${baseURL}/competitions/like`, {
            competitionId: competitionId,
          })
          .then((resp) => {
            let usersInfo = resp.data;
            getAllCompetitions();
            getLiked();
            dismissAlert();
          });
      },
      "cancel",
      dismissAlert
    );
  }
  const columns = [
    {
      name: "",
      width: "6%",
      style: {
        padding: 0,
      },
      cell: (row) => (
        <div className="row">
          <div className="d-flex justify-content-center text-center">
            <button
              className="btn text-primary"
              onClick={() => {
                likeDislike(row.id, row.isLiked);
              }}
            >
              {row.isLiked == "yes" ? (
                <i className="fa-solid fa-heart like_icon "></i>
              ) : (
                <i className="fa-regular fa-heart dislike_icon "></i>
              )}
            </button>
          </div>
        </div>
      ),
    },
    {
      name: "Nom de compétition",
      cell: (row) => (
        <div className="text-wrap">
          <Link className="style_text_deco" to={`/oneCompetition/${row.id}`}>
            <div className="names">
              <img
                src={`${baseURL + "/competition/getFile" + row.logo}`}
                className="rounded-circle"
                width="35"
                height="35"
              />
              {row.comp_name}
            </div>
          </Link>
        </div>
      ),
      width: "29%",
      minWidth: "150px",
    },
    {
      name: " Abréviation de compétition",
      cell: (row) => (
        <div className="text-wrap">
          <div>{row.comp_slug}</div>
        </div>
      ),
      width: "20%",
      minWidth: "150px",
    },

    {
      name: "Date de début des compétitions",
      cell: (row) => (
        <div className="text-nowrap ">
          <div>{row.date_debut}</div>
        </div>
      ),
      width: "24%",
      minWidth: "150px",
    },

    {
      name: "Pays",
      cell: (row) => (
        <div className="text-wrap">
          <div>
            {row.countryname.length > 15
              ? row.countryname.substring(0, 15) + " ..."
              : row.countryname}
          </div>
        </div>
      ),
      width: "20%",
    },
  ];

  const [searchTermName, setsearchTermName] = useState("");
  const [searchTermSlug, setsearchTermSlug] = useState("");
  const [searchTermCountry, setsearchTermCountry] = useState("");
  const [showLikedCompetitions, setShowLikedCompetitions] = useState(false);
  function filterCompetition() {
    const items = competitions;
    const results = items
      .filter(
        (item) =>
          item.comp_name &&
          item.comp_name.toLowerCase().includes(searchTermName.toLowerCase())
      )
      .filter((item) => {
        if (typeof item.countryname === "string") {
          return item.countryname
            .toLowerCase()
            .includes(searchTermCountry.toLowerCase());
        }
        return false;
      })
      .filter(
        (item) =>
          item.comp_slug &&
          item.comp_slug.toLowerCase().includes(searchTermSlug.toLowerCase())
      )
      .filter((val) => {
        if (!showLikedCompetitions) {
          return val;
        } else {
          return val.isLiked === "yes";
        }
      });
    setFiltredcompetition(results);
  }
  const [likedPlayers, setLikedPlayers] = useState([]);
  const [competitionLiked, setCompetitionLiked] = useState([]);
  const [teamIsLiked, setteamIsLiked] = useState([]);
  function getFavorisPlayerList() {
    if (checkPermissions("players")) {
      axios
        .get(`${baseURL}/players/getAllPlayersLiked`)
        .then((response) => {
          setLikedPlayers(response.data.data);
        })
        .catch((error) => logError());
    }
  }

  function getLiked() {
    if (checkPermissions("competitions")) {
      axios
        .get(`${baseURL}/competitions/getLiked`)
        .then((res) => {
          setCompetitionLiked(res.data.data);
        })
        .catch((err) => {
          logError();
        });
    }
  }

  function getteamIsLiked() {
    if (checkPermissions("teams")) {
      axios
        .get(`${baseURL}/teams/GetLikesbyUserId`)
        .then((res) => {
          setteamIsLiked(res.data.data);
        })
        .catch((err) => {});
    }
  }

  useEffect(() => {
    getteamIsLiked();
    getLiked();
    getFavorisPlayerList();
  }, []);
  useEffect(() => {
    filterCompetition();
  }, [
    searchTermName,
    searchTermSlug,
    searchTermCountry,
    showLikedCompetitions,
  ]);
  const [adsCompetitions, setAdsCompetitions] = useState([]);

  function getall() {
    axios
      .get(`${baseURL}/competitions/getCompetitionsBoosted`)
      .then((res) => {
        const boostedCompetitions = res.data;
        const CompetitionDataArray = Object.values(boostedCompetitions)
          .filter((competitionData) => {
            return competitionData.status !== "canceled" && competitionData.status !== "expired";
          })
          .map((competitionData) => {
            return competitionData.competition;
          });
        setAdsCompetitions(CompetitionDataArray);
      })
      .catch((err) => {
        logError();
      });
  }
  useEffect(function () {
    getall();
  }, []);

  const [newslist, setNewslist] = useState([]);
  function getallnews() {
    if (checkPermissions("news")) {
      setIsLoading(true); // set isLoading to true when the request is made

      axios
        .get(`${baseURL}/news/allNews`)
        .then((res) => {
          setNewslist(res.data.data);
        })
        .catch((err) => {
          logError();
        })
        .finally(() => {
          setIsLoading(false); // set isLoading to false when the request is complete (whether it succeeded or failed)
        });
    }
  }

  useEffect(function () {
    getallnews();
  }, []);
  return (
    <>
      <div>
        <section className="pt-3">
          <section className="">
            <div className="row m-0 p-0">
              {teamIsLiked?.length === 0 &&
              competitionLiked?.length === 0 &&
              likedPlayers?.length === 0 ? (
                <>
                  <div className="col-lg-12   ">
                    <div className="row">
                      {adsCompetitions?.length === 0 && newslist?.length > 0 ? (
                        <div className="col-lg-11 pt-2 pt-lg-0 pe-3">
                          <NewsLists
                            newslist={newslist}
                            isLoading={isLoading}
                            boostExist={false}
                          />
                        </div>
                      ) : adsCompetitions?.length > 0 &&
                        newslist?.length === 0 ? (
                        <>
                          <div className="col-lg-12">
                            <AdsCompetition adsCompetitions={adsCompetitions} />
                          </div>
                        </>
                      ) : adsCompetitions?.length === 0 &&
                        newslist?.length === 0 ? (
                        ""
                      ) : (
                        <>
                          <div className="col-lg-9">
                            <AdsCompetition adsCompetitions={adsCompetitions} />
                          </div>
                          <div className="col-lg-3  pt-2 pt-lg-0 pe-3">
                            <NewsLists
                              newslist={newslist}
                              isLoading={isLoading}
                              boostExist={true}
                            />
                          </div>
                        </>
                      )}
                      <div className="card-body  pt-3">
                        <div className="row px-lg-3 px-5">
                          <div className="col-lg-3 ">
                            <label className="text-muted text-nowrap fw-bolder">
                              <small>Nom des compétitions</small>
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setsearchTermName(e.target.value)
                              }
                              className="form-control"
                              placeholder="nom des compétitions"
                            />
                          </div>
                          <div className="col-lg-2">
                            <label className="text-muted fw-bolder">
                              <small>Abréviation</small>
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setsearchTermSlug(e.target.value)
                              }
                              className="form-control"
                              placeholder="abréviation des compétitions"
                            />
                          </div>
                          <div className="col-lg-2 ">
                            <label className="text-muted fw-bolder">
                              <small>Pays</small>
                            </label>
                            <select
                              className="form-control"
                              onChange={(e) =>
                                setsearchTermCountry(e.target.value)
                              }
                            >
                              <option value="">Pays</option>
                              {countries.map((country, key) => (
                                <option value={country.name} key={key}>
                                  {country.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-lg-4 d-lg-flex justify-content-evenly py-4">
                            <div className="toggle_btn form-check form-switch d-md-flex flex-md-row-reverse mt-2  px-md-0 ">
                              <label className="form-check-label text-muted">
                                {showLikedCompetitions
                                  ? "Les compétitions favorites"
                                  : "Les compétitions favorites"}
                              </label>
                              <input
                                className="form-check-input me-2"
                                type="checkbox"
                                id="toggleSwitch"
                                checked={showLikedCompetitions}
                                onChange={() =>
                                  setShowLikedCompetitions(
                                    !showLikedCompetitions
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="data_table"></div>
                          {data}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="col-lg-3">
                    <TeamLiked teamIsLiked={teamIsLiked} />
                    <div className="mt-3">
                      <DisplayLikedCompetitions
                        competitionLiked={competitionLiked}
                      />
                    </div>
                    <div className="mt-3 mb-3">
                      <PlayersLiked likedPlayers={likedPlayers} />
                    </div>
                  </div>

                  <div className="col-lg-9">
                    <div className="row">
                      {adsCompetitions?.length === 0 && newslist?.length > 0 ? (
                        <div className="col-lg-11  pt-2 pt-lg-0 pe-3">
                          <NewsLists
                            newslist={newslist}
                            isLoading={isLoading}
                            boostExist={false}
                          />
                        </div>
                      ) : adsCompetitions?.length > 0 &&
                        newslist?.length === 0 ? (
                        <>
                          <div className="col-lg-11">
                            <AdsCompetition adsCompetitions={adsCompetitions} />
                          </div>
                        </>
                      ) : adsCompetitions?.length === 0 &&
                        newslist?.length === 0 ? (
                        ""
                      ) : (
                        <>
                          <div className="col-lg-9">
                            <AdsCompetition adsCompetitions={adsCompetitions} />
                          </div>
                          <div className="col-lg-3  pt-2 pt-lg-0 pe-3">
                            <NewsLists
                              newslist={newslist}
                              isLoading={isLoading}
                              boostExist={true}
                            />
                          </div>
                        </>
                      )}

                      <div className="card-body  pt-3">
                        <div className="row px-lg-3 px-4 w-100 ">
                          <div className="col-lg-3 ">
                            <label className="text-muted text-nowrap fw-bolder">
                              <small>Nom des compétitions</small>
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setsearchTermName(e.target.value)
                              }
                              className="form-control"
                              placeholder="nom des compétitions"
                            />
                          </div>
                          <div className="col-lg-2">
                            <label className="text-muted fw-bolder">
                              <small>Abréviation</small>
                            </label>
                            <input
                              type="text"
                              onChange={(e) =>
                                setsearchTermSlug(e.target.value)
                              }
                              className="form-control"
                              placeholder="abréviation des compétitions"
                            />
                          </div>
                          <div className="col-lg-3 ">
                            <label className="text-muted fw-bolder">
                              <small>Pays</small>
                            </label>
                            <select
                              className="form-control"
                              onChange={(e) =>
                                setsearchTermCountry(e.target.value)
                              }
                            >
                              <option value="">Pays</option>
                              {countries.map((country, key) => (
                                <option value={country.name} key={key}>
                                  {country.name}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-lg-4 d-lg-flex justify-content-evenly py-4">
                            <div className="toggle_btn form-check form-switch d-md-flex flex-md-row-reverse mt-2  px-md-0 ">
                              <label className="form-check-label text-muted">
                                {showLikedCompetitions
                                  ? "Les compétitions favorites"
                                  : "Les compétitions favorites"}
                              </label>
                              <input
                                className="form-check-input me-2"
                                type="checkbox"
                                id="toggleSwitch"
                                checked={showLikedCompetitions}
                                onChange={() =>
                                  setShowLikedCompetitions(
                                    !showLikedCompetitions
                                  )
                                }
                              />
                            </div>
                          </div>
                          <div className="data_table"></div>
                          {data}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
            {addcreateCompetitionsModal}
            {addCompetitionModal}
            {alertModal}
          </section>
        </section>
      </div>
    </>
  );
};

export default Competitions_of_simpleUser;
