import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import EmptyData from "../../components/emptyData";
import Select from "react-select";
import BoostPlayer from "../../components/players/BoostPlayer";
import useAlert from "../../hooks/alertHook";
import LoaderLoading from "../../components/LoaderLoading";

const Players = () => {
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "rgba(223, 223, 223, 0.59);",
        color: "#5d7079",
      },
    },
  };

  const [playersIsLiked, setPlayersIsLiked] = useState();
  const { alertModal, displayAlert, dismissAlert } = useAlert();

  async function getPlayersIsLiked() {
    axios
      .get(`${baseURL}/players/playerIsLiked`)
      .then((response) => {
        if (response.data.error) return;
        setPlayersIsLiked(response.data.data);
      })
      .catch((err) => {});
  }

  useEffect(() => {
    getPlayersIsLiked();
  }, []);
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [data, setData] = useState();
  const [players, setPlayers] = useState([]);
  const [addCompetitionModal, setAddCompetitionModal] = React.useState();
  const [positions, setPositions] = React.useState();
  const [filtredplayers, setFiltredplayers] = React.useState(null);
  const [newrate, setNewrate] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const dataPos = {
    GoalKeeper: "GK",
    Back_Right: "AD",
    Back_left: "AG",
    Libero_Left: "LG",
    Libero_Right: "LD",
    defensive_midfielder: "MDF",
    Right_Offensive_midfielder: "MOD",
    Left_Offensive_midfielder: "MOG",
    Right_Winger: "AID",
    Left_Winger: "AIG",
    Center_forward: "AV",
  };
  async function getAllPlayers() {
    setIsLoading(true);
    axios
      .get(`${baseURL}/players/getAllPlayers`)
      .then((response) => {
        if (response.data.error) return;
        setPlayers(response.data.data);
        setFiltredplayers(response.data.data);
        setPositions(JSON.parse(response.data.data.positions));
      })
      .catch((err) => {
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false); // set isLoading to false after receiving a response or an error
      });
  }

  useEffect(() => {
    if (filtredplayers == null) return;
    if (isLoading) {
      setData(<LoaderLoading />);
    } else if (filtredplayers.length > 0) {
      setData(
        <DataTable
          columns={columns}
          data={filtredplayers}
          customStyles={customStyles}
        />
      );
    } else {
      setData(<EmptyData />);
    }
  }, [isLoading, filtredplayers]);

  async function enableDisable(playerId, isEnabled) {
    let message =
      isEnabled == "yes"
        ? "voulez vous désactiver ce joueur?"
        : "Voulez vous activer ce joueur?";
    let status = isEnabled == "yes" ? "danger" : "info";
    displayAlert(
      status,
      "",
      message,
      "Confirmer",
      () => {
        axios
          .get(`${baseURL}/players/toggleStatus/${playerId}`)
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getAllPlayers();
              dismissAlert();
            }
          });
      },
      "cancel",
      dismissAlert
    );
  }
  useEffect(() => {
    getAllPlayers();
  }, []);
  async function likeDislike(playerId, isLiked) {
    let message =
      isLiked == "yes"
        ? "voulez vous supprimer ce joueur de la liste des favories?"
        : "voulez vous ajouter ce joueur à la liste des favories";
    let status = isLiked == "yes" ? "danger" : "info";
    displayAlert(
      status,
      "",
      message,
      "Confirmer",
      () => {
        axios
          .post(`${baseURL}/players/like`, {
            playerId: playerId,
          })
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getAllPlayers();
              dismissAlert();
            }
          });
      },
      "cancel",
      dismissAlert
    );
  }
  const columns = [
    {
      name: "",
      width: "4%",
      style: {
        padding: 0,
      },
      cell: (row) => (
        <div className="row">
          <div className="d-flex justify-content-center text-center">
            <button
              className="btn text-primary"
              onClick={() => {
                likeDislike(row.id, row.isLiked);
              }}
            >
              {row.isLiked == "yes" ? (
                <i className="fa-solid fa-heart like_icon"></i>
              ) : (
                <i className="fa-regular fa-heart dislike_icon "></i>
              )}
            </button>
          </div>
        </div>
      ),
    },
    {
      name: "Nom et Prénom",
      cell: (row) => (
        <div className="text-nowrap ">
          <Link className="textDeco" to={`/admin/player/details/${row.id}`}>
            <img
              src={`${baseURL + "/players/getFile" + row.avatar}`}
              className="rounded-circle"
              width="35"
              height="35"
            />
            <span className=" playerName ps-2">
              {`${row.lastName} ${row.firstName}`.slice(0, 8)}
              {`${row.lastName} ${row.firstName}`.length > 8 && ".."}{" "}
            </span>
          </Link>
        </div>
      ),
      width: "19%",
    },

    {
      name: "Pays",
      cell: (row) => (
        <div className="text-nowrap ">
          {`${row.countryname} `.slice(0, 7)}
          {`${row.countryname} `.length > 7 && "..."}
        </div>
      ),
      width: "11%",
    },
    {
      name: "Equipe",
      cell: (row) => (
        <div className="text-nowrap ">
          {row.currentTeam === null ? (
            <div className="d-flex align-items-center">
              <img src="./assets/imgs/emptyTeam.png" width="35" height="35" />
              <span>No available </span>
            </div>
          ) : (
            <Link
              className="style_text_deco"
              to={`/admin/oneTeam/${row.currentTeam?.id}`}
            >
              <img
                className="pe-1"
                src={`${
                  baseURL + "/team/getFile" + row.currentTeam?.logo.substring(1)
                }`}
                width="35"
                height="35"
              />{" "}
              <span className="currentTeamName">
                {`${row.currentTeam?.name}`.slice(0, 11)}
                {`${row.currentTeam?.name}`.length > 11 && "..."}
              </span>
            </Link>
          )}
        </div>
      ),
      width: "20%",
    },
    {
      name: "Génerations",
      cell: (row) => (
        <div className="text-nowrap ">
          {`${row.birthday?.slice(0, 4)} `.slice(0, 7)}
          {`${row.birthday?.slice(0, 4)} `.length > 7 && "..."}
        </div>
      ),
      width: "9%",
    },
    {
      name: "Positions",
      cell: (row) => (
        <>
          {row.positions && (
            <div className="text-nowrap">
              {JSON.parse(row.positions)
                .slice(0, 2)
                .map((item, key) => (
                  <span className="badge bg-secondary me-1" key={key}>
                    {dataPos[item]}
                  </span>
                ))}
              {JSON.parse(row.positions).length > 2 && ".."}
            </div>
          )}
        </>
      ),
      width: "11%",
    },
    {
      name: "Note",
      width: "10%",
      cell: (row) => (
        <>
          {row.rate >= 0 && row.rate <= 2 ? (
            <div className="rate  badge px-2 text-center  rateListPlayer_red ">
              {row.rate}
            </div>
          ) : row.rate > 2 && row.rate <= 4 ? (
            <div className=" rate  badge px-2 text-center rateListPlayer_orange">
              {row.rate}
            </div>
          ) : row.rate > 4 && row.rate <= 6 ? (
            <div className=" rate  badge px-2 text-center  rateListPlayer_yellow">
              {row.rate}
            </div>
          ) : row.rate > 6 && row.rate <= 8 ? (
            <div className=" rate  badge px-2 text-center rateListPlayer_green ">
              {row.rate}
            </div>
          ) : row.rate > 8 && row.rate <= 10 ? (
            <div className=" rate  badge px-2 text-center  rateListPlayer_blue">
              {row.rate}
            </div>
          ) : (
            <div>-</div>
          )}
        </>
      ),
    },

    {
      name: "Actions",
      width: "15%",
      style: {
        padding: 0,
      },
      cell: (row) => (
        <div className="row">
          <div className="col-3 d-flex justify-content-start align-items-center px-2">
            <Link to={`/admin/players/update/${row.id}`}>
              <i
                className="fa-solid fa-pen-to-square btnUpdatePlayers"
                title="Modifier joueur"
              ></i>
            </Link>
          </div>
          <div className="col-3  d-flex justify-content-start align-items-center px-2">
            <button
              className="btn p-0 "
              onClick={() => {
                enableDisable(row.id, row.isEnabled);
              }}
            >
              {row.isEnabled == "yes" ? (
                <i
                  className=" fa-regular fa-eye"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Désactiver joueur"
                ></i>
              ) : (
                <i
                  className="text-danger fa-regular fa-eye-slash"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Activer joueur"
                ></i>
              )}
            </button>
          </div>
          <div className="col-3 d-flex justify-content-start align-items-center px-2">
            <Link to={`/admin/player/details/${row.id}`}>
              <button type="button" className="btn p-0 m-0">
                <i
                  className="fa-solid fa-circle-info"
                  title="Details joueur"
                ></i>
              </button>
            </Link>
          </div>
          <div className="col-3 d-flex justify-content-start align-items-center px-2">
            <BoostPlayer PlayerId={row.id} />
          </div>
        </div>
      ),
    },
  ];
  const UnderAge = [
    { value: "Tous", label: "Tous" },
    { value: 23, label: "U23" },
    { value: 22, label: "U22" },
    { value: 21, label: "U21" },
    { value: 20, label: "U20" },
    { value: 19, label: "U19" },
    { value: 18, label: "U18" },
    { value: 17, label: "U17" },
    { value: 16, label: "U16" },
    { value: 15, label: "U15" },
  ];
  const PositionsOptions = [
    { value: "Tous", label: "Tous" },
    { value: "GoalKeeper", label: "GK (Gardient)" },
    { value: "Back_Right", label: "AD (Arriére Droit)" },
    { value: "Back_left", label: "AG (Arriére Gauche)" },
    { value: "defensive_midfielder", label: "MDF (Milieu défensif)" },
    {
      value: "Right_Offensive_midfielder",
      label: "MOD (Milieu offensif droit)",
    },
    {
      value: "Left_Offensive_midfielder",
      label: "MOG (Milieu offensif gauche)",
    },
    { value: "Right_Winger", label: "AID (Ailier droit)" },
    { value: "Left_Winger", label: "AIG (Ailier gauche)" },
    { value: "center_forward", label: "AV (Avant centre)" },
    { value: "Libero_Right", label: "LD (Libéro droit)" },
    { value: "Libero_Left", label: "LG (Libéro gauche)" },
  ];

  const [searchTermGeneration, setsearchTermGeneration] = useState("");
  const [searchTermPosition, setsearchPosition] = useState("Tous");
  const [searchTermunder, setsearchTermunder] = useState("Tous");
  const [showLikedPlayers, setShowLikedPlayers] = useState(false);

  function filterCompetition() {
    const items = players;
    const results = items
      .filter(
        (item) =>
          item.birthday &&
          item.birthday
            .toLowerCase()
            .slice(0, 4)
            .includes(searchTermGeneration.toLowerCase())
      )
      .filter((val) => {
        if (!showLikedPlayers) {
          return val;
        } else {
          return val.isLiked === "yes";
        }
      })
      .filter((player) => {
        if (searchTermPosition === "Tous") {
          return player;
        } else {
          return (
            player.positions &&
            JSON.parse(player.positions.toLowerCase()).includes(
              searchTermPosition.toLowerCase()
            )
          );
        }
      })

      .filter((agePlayer) => {
        const currentYear = new Date().getFullYear();
        const age =
          currentYear - Number(agePlayer.birthday?.toLowerCase().slice(0, 4));
        if (searchTermunder === "Tous") {
          return agePlayer;
        } else if (searchTermunder >= age) {
          return agePlayer;
        }
      });
    setFiltredplayers(results);
  }
  useEffect(() => {
    filterCompetition();
  }, [
    searchTermGeneration,
    searchTermPosition,
    showLikedPlayers,
    searchTermunder,
  ]);

  return (
    <div>
      <section className="pt-3">
        <section className="">
          <div className="row px-lg-3 px-5 ">
            <div className="col-lg-3  ">
              <label className="text-muted fw-bolder">
                <small>Génerations</small>
              </label>
              <input
                type="text"
                onChange={(e) => setsearchTermGeneration(e.target.value)}
                className="form-control"
                placeholder="Génerations"
              />
            </div>
            <div className="col-lg-2  ">
              <label className="text-muted fw-bolder">
                <small>Positions</small>
              </label>
              <Select
                options={PositionsOptions}
                isSearchable
                defaultValue={{ value: "Tous", label: "Tous" }}
                onChange={(e) => setsearchPosition(e.value)}
                placeholder="Positions"
              />
            </div>
            <div className="col-lg-2  ">
              <label className="text-muted fw-bolder">
                <small>Catégories</small>
              </label>
              <Select
                options={UnderAge}
                isSearchable
                defaultValue={{ value: "Tous", label: "Tous" }}
                onChange={(e) => setsearchTermunder(e.value)}
                placeholder="UnderAge"
              />
            </div>
            <div className="col-lg-4  ">
              <div className="row">
                <div className="col-lg-6  ">
                  <div className=" toggle_btn form-check form-switch mb-3 d-md-flex flex-md-row-reverse mt-4  px-md-0">
                    <label className="  form-check-label  text-lg-end text-muted">
                      {showLikedPlayers
                        ? "Les joueurs favoris"
                        : "Les joueurs favoris"}
                    </label>
                    <input
                      className=" form-check-input me-1"
                      type="checkbox"
                      id="toggleSwitch"
                      checked={showLikedPlayers}
                      onChange={() => setShowLikedPlayers(!showLikedPlayers)}
                    />
                  </div>
                </div>
                <div className="col-lg-6  ">
                  <div className="mt-0 mt-lg-4 ">
                    <Link to="/players/search">
                      <button
                        type="button"
                        className=" btn btn-sm btn-primary px-3 "
                      >
                        <i className="fa-solid fa-magnifying-glass"></i>{" "}
                        Recherche avancé
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-1 text-muted fw-bolder">
              <div className="py-3">
                <Link to="/admin/players/create">
                  <button
                    type="button"
                    className=" button-addPlayer btn btn-primary btn-lg me-2"
                  >
                    +
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="row mx-0">
            <div className="col-lg-12">
              <div className="card-body  pt-3">{data}</div>
            </div>
          </div>
        </section>
      </section>
      {alertModal}
    </div>
  );
};

export default Players;
