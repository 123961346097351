import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import { logError } from "../../components/Toastconfig";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import PlayersStatsMatch from "./PlayerStatsMatch";
import Select from "react-select";
import useAlert from "../../hooks/alertHook";
const initFormErrors = {
  status: [""],
};
const PlayersOfMatches = ({ matchId }) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [playersOfMatchTH, setPlayersTH] = useState([]);
  const [playersOfMatchTA, setPlayersTA] = useState([]);
  const [matche, setMatch] = useState([]);
  const [selectedPlayerId, setSelectedPlayerId] = useState(null);
  const [errorMessageTeamHome, setErrorMessageTeamHome] =
    useState(initFormErrors);
  const [errorMessageTeamAway, setErrorMessageTeamAway] =
    useState(initFormErrors);
  const [isSideBarOpen] = useState(true);
  const [playersStatusTeamHome, setPlayersStatusTeamHome] = useState([]);
  const [playersPositionsTeamHome, setPlayersPositionsTeamHome] = useState([]);
  const [playersStatusTeamAway, setPlayersStatusTeamAway] = useState([]);
  const [playersPositionsTeamAway, setPlayersPositionsTeamAway] = useState([]);
  const [positionsOptions] = useState([
    { value: "GoalKeeper", label: "GK (Gardient)" },
    { value: "Back_Right", label: "AD (Arrière Droit)" },
    { value: "Libero_Right", label: "LD (Libéro droit)" },
    { value: "Libero_Left", label: "LG (Libéro gauche)" },
    { value: "Back_left", label: "AG (Arrière Gauche)" },
    { value: "defensive_midfielder", label: "MDF (Milieu défensif)" },
    {
      value: "Right_Offensive_midfielder",
      label: "MOD (Milieu offensif droit)",
    },
    {
      value: "Left_Offensive_midfielder",
      label: "MOG (Milieu offensif gauche)",
    },
    { value: "Right_Winger", label: "AID (Ailier droit)" },
    { value: "Left_Winger", label: "AIG (Ailier gauche)" },
    { value: "center_forward", label: "AV (Avant centre)" },
  ]);
  const [remainingPositionsHome, setRemainingPositionsHome] = useState([
    ...positionsOptions,
  ]);
  const [remainingPositionsAway, setRemainingPositionsAway] = useState([
    ...positionsOptions,
  ]);
  //update remaining positions whenever players'team home positions change
  useEffect(() => {
    const assignedPositions = playersPositionsTeamHome.filter(Boolean);
    const updatedOptions = positionsOptions.filter(
      (option) => !assignedPositions.includes(option.value)
    );
    setRemainingPositionsHome(updatedOptions);
  }, [playersPositionsTeamHome]);
  // Update remaining positions whenever players'team away positions change
  useEffect(() => {
    const assignedPositions = playersPositionsTeamAway.filter(Boolean);
    const updatedOptions = positionsOptions.filter(
      (option) => !assignedPositions.includes(option.value)
    );
    setRemainingPositionsAway(updatedOptions);
  }, [playersPositionsTeamAway]);
  const statusOptions = [
    { value: "starting", label: "partant" },
    { value: "substituted", label: "remplacant" },
    { value: "missing", label: "manquant" },
  ];
  const { alertModal, displayAlert, dismissAlert } = useAlert();
  function getplayersByMatch() {
    axios
      .get(`${baseURL}/calendarMatches/${matchId}`)
      .then((res) => {
        setPlayersTH(res.data.data.teamHome.players);
        setPlayersTA(res.data.data.teamAway.players);
        const playerStatusesTeamHome = res.data.data.teamHome.players.map(
          (player) =>
            player.playersMatches[0] ? player.playersMatches[0].status : ""
        );
        setPlayersStatusTeamHome(playerStatusesTeamHome);

        const playerPositionsTeamHome = res.data.data.teamHome.players.map(
          (player) =>
            player.playersMatches[0] ? player.playersMatches[0].position : ""
        );
        setPlayersPositionsTeamHome(playerPositionsTeamHome);

        const playerStatusesTeamAway = res.data.data.teamAway.players.map(
          (player) =>
            player.playersMatches[0] ? player.playersMatches[0].status : ""
        );
        setPlayersStatusTeamAway(playerStatusesTeamAway);

        const playersPositionsTeamAway = res.data.data.teamAway.players.map(
          (player) =>
            player.playersMatches[0] ? player.playersMatches[0].position : ""
        );
        setPlayersPositionsTeamAway(playersPositionsTeamAway);
        setMatch(res.data.data);
      })
      .catch((err) => {
        logError();
      });
  }
  useEffect(() => {
    getplayersByMatch();
  }, []);

  const handleSetLineupTeamHome = () => {
    const data = {
      lineup: playersOfMatchTH.map((player, index) => ({
        playerId: player.id,
        teamId: player.currentTeam,
        status: playersStatusTeamHome[index],
        position: playersPositionsTeamHome[index],
      })),
      matchId: matchId,
    };

    axios
      .post(`${baseURL}/calendarMatches/setLineups`, data)
      .then((resp) => {
        setErrorMessageTeamHome(initFormErrors);
        if (resp.data.error) return;
        displayAlert("success", "", resp.data.message);
        getplayersByMatch();
        setTimeout(() => {
          dismissAlert();
        }, 2500);
      })
      .catch((error) => {
        const playerErrors = error.response.data;
        setErrorMessageTeamHome(playerErrors);
      });
  };
  const handlePositionJoueurChangeTeamHome = (e, index) => {
    const selectedPosition = e.value;
    const updatedPositions = [...playersPositionsTeamHome];
    updatedPositions[index] = selectedPosition;
    setPlayersPositionsTeamHome(updatedPositions);

    // remove the selected position from the options
    const updatedOptions = positionsOptions.filter(
      (option) => !updatedPositions.includes(option.value)
    );

    setRemainingPositionsHome(updatedOptions);
  };
  const handleStatuJoueurChangeTeamHome = (e, index) => {
    const updatedStatus = [...playersStatusTeamHome];
    updatedStatus[index] = e.value;
    setPlayersStatusTeamHome(updatedStatus);

    if (e.value !== "starting") {
      const updatedPositions = [...playersPositionsTeamHome];
      const previousPosition = updatedPositions[index];
      updatedPositions[index] = "";
      setPlayersPositionsTeamHome(updatedPositions);
      // add the previous position back to the options
      if (previousPosition) {
        const previousOption = positionsOptions.find(
          (option) => option.value === previousPosition
        );
        if (previousOption) {
          setRemainingPositionsHome((prevOptions) => [
            ...prevOptions,
            previousOption,
          ]);
        }
      }
    } else {
      const selectedPosition = playersPositionsTeamHome[index];
      const updatedOptions = remainingPositionsHome.filter(
        (option) => option.value !== selectedPosition
      );
      setRemainingPositionsHome(updatedOptions);
    }
  };

  const handleSetLineupTeamAway = () => {
    const data = {
      lineup: playersOfMatchTA.map((player, index) => ({
        playerId: player.id,
        teamId: player.currentTeam,
        status: playersStatusTeamAway[index],
        position: playersPositionsTeamAway[index],
      })),
      matchId: matchId,
    };
    axios
      .post(`${baseURL}/calendarMatches/setLineups`, data)
      .then((resp) => {
        setErrorMessageTeamAway(initFormErrors);
        if (resp.data.error) return;
        displayAlert("success", "", resp.data.message);
        getplayersByMatch();
        setTimeout(() => {
          dismissAlert();
        }, 2500);
      })
      .catch((error) => {
        const playerErrors = error.response.data;
        setErrorMessageTeamAway(playerErrors);
      });
  };
  const handlePositionJoueurChangeTeamAway = (e, index) => {
    const selectedPosition = e.value;
    const updatedPositions = [...playersPositionsTeamAway];
    const previousPosition = updatedPositions[index];
    updatedPositions[index] = selectedPosition;
    setPlayersPositionsTeamAway(updatedPositions);

    // remove the selected position from the options
    const updatedOptions = remainingPositionsAway.filter(
      (option) => option.value !== selectedPosition
    );
    setRemainingPositionsAway(updatedOptions);
  };
  const handleStatuJoueurChangeTeamAway = (e, index) => {
    const updatedStatus = [...playersStatusTeamAway];
    updatedStatus[index] = e.value;
    setPlayersStatusTeamAway(updatedStatus);
    if (e.value !== "starting") {
      const updatedPositions = [...playersPositionsTeamAway];
      const previousPosition = updatedPositions[index];
      updatedPositions[index] = "";
      setPlayersPositionsTeamAway(updatedPositions);

      // add the previous position back to the options
      if (previousPosition) {
        const previousOption = positionsOptions.find(
          (option) => option.value === previousPosition
        );
        if (previousOption) {
          setRemainingPositionsAway((prevOptions) => [
            ...prevOptions,
            previousOption,
          ]);
        }
      }
    } else {
      const selectedPosition = playersPositionsTeamAway[index];
      const updatedOptions = remainingPositionsAway.filter(
        (option) => option.value !== selectedPosition
      );
      setRemainingPositionsAway(updatedOptions);
    }
  };
  return (
    <div>
      <div className="d-flex ">
        <div className="px-md-4  px-lg-4 col-6 my-2 ">
          {playersOfMatchTH?.map((playerOfMatchTH, index) => (
            <div className="row pb-3 ">
              <div className="col-lg-4 ">
                <React.Fragment key={isSideBarOpen}>
                  <Button
                    className=" fs-6 text-nowrap name_player"
                    onClick={() => {
                      setSelectedPlayerId(playerOfMatchTH.id);
                    }}
                  >
                    <div className="d-flex">
                      <img
                        width="33"
                        height="33"
                        src={`${baseURL}/players/getFile${playerOfMatchTH.avatar.slice(
                          1
                        )}`}
                        className="rounded-circle me-2"
                      />
                      <div className="row">
                        <span
                          className=" col-9 text-nowrap name_player"
                        >
                          {playerOfMatchTH.firstName}
                          {playerOfMatchTH.lastName.substring(0, 3) + ".."}
                        </span>
                      </div>
                    </div>
                  </Button>
                </React.Fragment>
              </div>
              <div className="col-lg-4">
                <Select
                  options={statusOptions}
                  classNamePrefix="Status "
                  isSearchable
                  placeholder="Status "
                  defaultValue={
                    playersStatusTeamHome[index]
                      ? statusOptions.find(
                          (option) =>
                            option.value === playersStatusTeamHome[index]
                        )
                      : statusOptions.find(
                          (option) => option.value === "substituted"
                        )
                  }
                  onChange={(e) => handleStatuJoueurChangeTeamHome(e, index)}
                />
              </div>
              <div className=" col-lg-4 pt-lg-0 pt-3">
                {playersStatusTeamHome[index] === "starting" && (
                  <Select
                    options={remainingPositionsHome}
                    defaultValue={remainingPositionsHome.find(
                      (option) =>
                        option.value === playersPositionsTeamHome[index]
                    )}
                    classNamePrefix="Position "
                    isSearchable
                    placeholder="Position "
                    onChange={(e) =>
                      handlePositionJoueurChangeTeamHome(e, index)
                    }
                  />
                )}
                {errorMessageTeamHome[index] && (
                  <div className="input-error pt-2">
                    <span className="text-danger">
                      {errorMessageTeamHome[index]}
                    </span>
                  </div>
                )}
              </div>
            </div>
          ))}
          <button
            type="button"
            className="btn btn-dark btn-sm"
            onClick={handleSetLineupTeamHome}
          >
            Définir les compositions{" "}
          </button>
          {errorMessageTeamHome && (
            <div className="input-error pt-2">
              <span className="text-danger">{errorMessageTeamHome.error}</span>
            </div>
          )}
        </div>
        <div className=" px-md-4 px-lg-4 col-6 my-2  ">
          {playersOfMatchTA?.map((playerOfMatchTA, index) => (
            <div className="row pb-3">
              <div className="col-lg-4">
                <React.Fragment key={isSideBarOpen}>
                  <Button
                    className=" fs-6 text-nowrap name_player"
                    onClick={() => {
                      setSelectedPlayerId(playerOfMatchTA.id);
                    }}
                  >
                    <div className="d-flex ">
                      <img
                        width="33"
                        height="33"
                        src={`${baseURL}/players/getFile${playerOfMatchTA.avatar.slice(
                          1
                        )}`}
                        className="rounded-circle me-2"
                      />
                      <div className="row ">
                        <span className=" col-9 text-nowrap name_player">
                          {playerOfMatchTA.firstName}
                          {playerOfMatchTA.lastName.substring(0, 3) + ".."}
                        </span>
                      </div>
                    </div>
                  </Button>
                </React.Fragment>
              </div>
              <div className="col-lg-4">
                <Select
                  options={statusOptions}
                  defaultValue={
                    playersStatusTeamAway[index]
                      ? statusOptions.find(
                          (option) =>
                            option.value === playersStatusTeamAway[index]
                        )
                      : statusOptions.find(
                          (option) => option.value === "substituted"
                        )
                  }
                  classNamePrefix="Status "
                  isSearchable
                  placeholder="Status "
                  onChange={(e) => handleStatuJoueurChangeTeamAway(e, index)}
                />
              </div>
              <div className="col-lg-4 pt-lg-0 pt-3">
                {playersStatusTeamAway[index] === "starting" && (
                  <Select
                    options={remainingPositionsAway}
                    defaultValue={remainingPositionsAway.find(
                      (option) =>
                        option.value === playersPositionsTeamAway[index]
                    )}
                    classNamePrefix="Position "
                    isSearchable
                    placeholder="Position "
                    onChange={(e) =>
                      handlePositionJoueurChangeTeamAway(e, index)
                    }
                  />
                )}
                {errorMessageTeamAway[index] && (
                  <div className="input-error pt-2">
                    <span className="text-danger">
                      {errorMessageTeamAway[index]}
                    </span>
                  </div>
                )}
              </div>
            </div>
          ))}
          <button
            type="button"
            className="btn btn-dark btn-sm"
            onClick={handleSetLineupTeamAway}
          >
            Définir les compositions{" "}
          </button>
          {errorMessageTeamAway && (
            <div className="input-error pt-2">
              <span className="text-danger">{errorMessageTeamAway.error}</span>
            </div>
          )}
        </div>
      </div>
      <Drawer
        isSideBarOpen={isSideBarOpen}
        open={selectedPlayerId !== null}
        onClose={() => setSelectedPlayerId(null)}
      >
        {selectedPlayerId && (
          <PlayersStatsMatch
            matchId={matchId}
            playerId={selectedPlayerId}
            playerTeamH={playersOfMatchTH}
            playerTeamA={playersOfMatchTA}
            teamHomeName={matche.teamHome?.acronyme}
            teamAwayName={matche.teamAway?.acronyme}
          />
        )}
      </Drawer>
      {alertModal}
    </div>
  );
};

export default PlayersOfMatches;
