import React from "react";
import { useState, useEffect } from "react";
import { Carousel, Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoaderLoading from "../../components/LoaderLoading";

const Ads = ({ adsteam }) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, [adsteam]);

  return (
    <Container className="px-2">
      {isLoading ? (
        <LoaderLoading />
      ) : (
        <Carousel>
          {adsteam?.map((team, key) => (
            <Carousel.Item key={key}>
              <Card className="style_ads">
                <Card.Body>
                  <Row>
                    <Col md={3} className="text-center mt-2">
                      <h5 className="text-white text-nowrap fw-bold ">
                      ÉQUIPE 
                      </h5>
                      <h5 className="text-white text-nowrap fw-bold ">
                      DE LA
                      </h5>
                      <h5 className="text-white text-nowrap fw-bold ">
                      SEMAINE !
                      </h5>
                    </Col>
                    <Col md={5} className="text-center">
                      <h5 className="text-white text-start">
                        {team.name}
                      </h5>
                      <div className="row mt-4">
                        <div className="text-start">
                          <h6 className="text-white fs-6">
                            Acronyme :
                            <span className="fw-bold ms-2">
                              {team.acronyme}
                            </span>
                          </h6>
                          <h6 className="text-white fs-6">
                            Nom De Stade :
                            <span className="fw-bold ms-2">
                              {team.stade_Name.length > 9
                                ? `${team.stade_Name.slice(0, 9)}...`
                                : team.stade_Name}{" "}
                            </span>
                          </h6>
                          <h6 className="text-white">
                            Pays :{" "}
                            <span className="fw-bold">{team.Pays.name} </span>
                          </h6>
                        </div>
                      </div>
                    </Col>
                    <Col md={4} className="text-center">
                      <div>
                        <img
                          src={`${
                            baseURL + "/team/getFile" + team.logo.substring(1)
                          }`}
                          className="imgADS"
                        />
                      </div>
                      <div className="pt-3">
                        <Link className="textDeco" to={`/oneTeam/${team.id}`}>
                          <button
                            type="button"
                            className=" style_button_decouvrir"
                          >
                            Découvrir{" "}
                            <i className="fa-solid fa-arrow-right"></i>
                          </button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </Container>
  );
};

export default Ads;
