import React from "react";
import Chart from "react-apexcharts";

const GoalsAssistsStats = ({ goals, assists, totalPlayed }) => {
  let nbrHelpes = (goals + assists) / totalPlayed;
  let state = {
    series: [nbrHelpes.toFixed(2)],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          hollow: {
            size: "70%",
          },
        },
      },
      labels: ["B/A par matche"],
    },
  };
  return (
    <div>
      <div>
        <Chart
          options={state.options}
          series={state.series}
          type="radialBar"
          height={200}
        />
        <h6 className="style_stats_card_text justify-content-center d-flex text-center ">
          {goals} buts et {assists} passes décisives en {totalPlayed} matchs
        </h6>
      </div>
    </div>
  );
};

export default GoalsAssistsStats;
