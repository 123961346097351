import React, { useState } from "react";
import ReactPlayer from "react-player";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams } from "react-router-dom";
import EmptyData from "../emptyData";

const videosPerPage = 2;

export default function VideoPlayer_of_simpleUser({ videolist }) {
  const { playerId } = useParams();

  const baseURL = process.env.REACT_APP_BASE_URL;
  const [currentVideo, setCurrentVideo] = useState(videolist[0]);
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastVideo = currentPage * videosPerPage;
  const indexOfFirstVideo = indexOfLastVideo - videosPerPage;
  const currentVideos = videolist.slice(indexOfFirstVideo, indexOfLastVideo);

  const handleVideoClick = (video) => {
    setCurrentVideo(video);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(videolist.length / videosPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <div className="container ml-0">
      {videolist.length === 0 ? (
        <div className="row">
          <div className="col-lg-12">
            <EmptyData />
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="row mb-5">
            <div className="col-lg-8">
              <div className="video-player">
                <ReactPlayer
                  url={`${baseURL + "/players/getFile" + currentVideo.path}`}
                  controls
                  playIcon={
                    <button className="btn">
                      <i className="fa-sharp fa-solid fa-circle-play button-play"></i>
                    </button>
                  }
                  width="96%"
                  height="auto"
                />
                <div className="overlay">
                  <h2>{currentVideo.title}</h2>
                  <p>{currentVideo.description}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 ">
              <div className=" p-0 pe-1">
                <div className="video-list ">
                  {currentVideos.map((video, index) => (
                    <div
                      className="style-videos mb-3"
                      key={index}
                      onClick={() => handleVideoClick(video)}
                    >
                      <video
                        style={{
                          cursor: "pointer",
                        }}
                        className="style_video_list"
                        src={`${baseURL + "/players/getFile" + video.path}`}
                        alt={video.title}
                      />
                      <div
                        className="overlay px-2"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <h6>{video.title}</h6>
                        <p className="text-truncate">{video.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="pagination paginationObservation">
                  {pageNumbers.map((pageNumber) => (
                    <span
                      key={pageNumber}
                      className={`page-number paginations ${
                        pageNumber === currentPage ? "active" : ""
                      }`}
                      onClick={() => paginate(pageNumber)}
                    >
                      {pageNumber}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
