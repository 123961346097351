import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
import { logError } from "../../components/Toastconfig";
import useAlert from "../../hooks/alertHook";
import EmptyData from "../../components/emptyData";
import AllJourneysOfSimpleUser from "./AlljourneysOfSimpleUser";
import LoaderLoading from "../../components/LoaderLoading";
const OneCompetitionOfSimpleUser = () => {
  const [competitionn, setCompetitionn] = useState();
  const { id } = useParams();
  const { alertModal, displayAlert, dismissAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(true);

  //  get ALL teams funtion
  async function likeDislike(id) {
    let message =
      competitionn.isLiked == "yes"
        ? "voulez vous supprimer cette compétition de la liste des favories?"
        : "voulez vous ajouter cette compétition à la liste des favories?";
    let status = competitionn.isLiked == "yes" ? "danger" : "info";
    displayAlert(
      status,
      "",
      message,
      "Confirmer",
      () => {
        axios
          .post(`${baseURL}/competitions/like`, {
            competitionId: id,
          })
          .then((resp) => {
            let usersInfo = resp.data;
            getOne();
            dismissAlert();
          });
      },
      "cancel",
      dismissAlert
    );
  }
  function getOne() {
    axios
      .get(`${baseURL}/competitions/${id}`)
      .then((res) => {
        setCompetitionn(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        logError();
        setIsLoading(false);
      });
  }
  useEffect(() => {
    getOne();
  }, [id]);

  const baseURL = process.env.REACT_APP_BASE_URL;
  const [showEquipes, setShowEquipes] = useState(true); // State to control the visibility of Equipes and Matches content
  const toggleEquipes = () => {
    setShowEquipes(true);
  };

  const toggleMatches = () => {
    setShowEquipes(false);
  };
  return (
    <>
      {isLoading ? (
        <LoaderLoading />
      ) : (
        <section className="p-4">
          <div className="container-fluid">
            <div className="row ">
              <div className="col-lg-6 ">
                <div className="row">
                  <div className="col-lg-4  col-sm-4 mb-2 ">
                    {competitionn && (
                      <img
                        className="p-0 m-0"
                        src={`${baseURL}/competition/getFile/${competitionn.logo}`}
                        width="100%"
                        height="100%"
                      />
                    )}
                  </div>
                  <div className="col-lg-8 col-sm-8 small-team ">
                    <div className="row">
                      <div className="col-lg-11">
                        <h2>
                          {competitionn && competitionn.comp_name}
                          <button
                            className="btn text-primary"
                            onClick={() => {
                              likeDislike(id);
                            }}
                          >
                            {competitionn && competitionn.isLiked == "yes" ? (
                              <i className="fa-solid fa-heart like_icon"></i>
                            ) : (
                              <i className="fa-regular fa-heart dislike_icon"></i>
                            )}
                          </button>
                        </h2>
                      </div>
                    </div>
                    <div>{competitionn && competitionn.comp_slug}</div>
                    <hr></hr>
                    <div className="row">
                      <div className="col-lg-10">
                        <div className="mb-4 small-team">
                          <label className="fw-bold ">
                            Date de debut des competitions:{" "}
                          </label>{" "}
                          {competitionn && competitionn.date_debut}
                        </div>{" "}
                        <div className="mb-4 small-team">
                          <label className="fw-bold">Pays : </label>{" "}
                          {competitionn && competitionn.countryname}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 ">
                <div className="row ">
                  <div className="d-flex flex-row-reverse">
                    <div className=" col-xxl-7 col-sm-12 col-lg-12 style_top_players_div">
                      <div className="style_top">Meilleurs joueurs</div>
                      <div className="style_best">
                        Joueurs les mieux notés lors des matchs récents
                      </div>
                      <div className="style_all_best">
                        {competitionn &&
                          competitionn.teams
                            ?.flatMap((team) => team.players) // Flattening the array of players for each team into a single array of players.
                            ?.filter((player) => player.rate > 7) // Filtering out players whose rate is not greater than 7.
                            ?.sort((a, b) => b.rate - a.rate) // Sorting the filtered array of players in descending order based on their 'rate' property.
                            ?.slice(0, 3) //  filter only the first 3 players
                            ?.map((player, key) => (
                              <div
                                key={key}
                                className="row style_div_player_link style_div_player_img"
                                cursor="pointer"
                                display="flex"
                              >
                                <div className="col-lg-10 py-2">
                                  <Link
                                    style={{ textDecoration: "none" }}
                                    to={`/player/details/${player.id}`}
                                  >
                                    <img
                                      src={`${baseURL}/players/getFile${player.avatar.substring(
                                        1
                                      )}`}
                                      className="rounded-circle"
                                      width="43"
                                      height="43"
                                    />
                                    <span className="ps-2 playerName">
                                      {player.lastName} {player.firstName}
                                    </span>
                                  </Link>
                                </div>
                                <div className="col-lg-2">
                                  <span className="names">
                                    {player.rate <= 2 ? (
                                      <div className="rate ps-lg-3 badge p-2 text-center rates_red ">
                                        {player.rate}
                                      </div>
                                    ) : player.rate > 2 && player.rate <= 4 ? (
                                      <div className="rate  badge p-2 text-center rates_orange">
                                        {player.rate}
                                      </div>
                                    ) : player.rate > 4 && player.rate <= 6 ? (
                                      <div className="rate  badge p-2 text-center rates_yellow">
                                        {player.rate}
                                      </div>
                                    ) : player.rate > 6 && player.rate <= 8 ? (
                                      <div className="rate  badge p-2 text-center rates_green">
                                        {player.rate}
                                      </div>
                                    ) : (
                                      <div className="rate  badge p-2 text-center rates_blue">
                                        {player.rate}
                                      </div>
                                    )}
                                  </span>
                                </div>
                              </div>
                            ))}
                        {competitionn &&
                          competitionn.teams
                          ?.flatMap((team) => team.players)
                          ?.filter((player) => player.rate > 7) // Filtering out players whose rate is not greater than 7.
                          ?.length === 0 && (
                            <tbody className="my-4 style_tbody">
                              <div className="d-flex justify-content-center align-items-center">
                                <div className="ms-2">
                                  <EmptyData />
                                </div>
                              </div>
                            </tbody>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col-lg-12 mt-4 mx-0">
                <div class="row">
                  <div className="d-flex justify-content-start align-items-center">
                    <div class=" pb-2">
                      <button
                        type="button"
                        class={`btn ${
                          showEquipes ? "btn-primary" : "btn-outline-primary"
                        }`}
                        data-number="1"
                        onClick={toggleEquipes}
                      >
                        Equipes
                      </button>
                    </div>
                    <div class=" pb-2 ms-3">
                      <button
                        type="button"
                        class={`btn ${
                          !showEquipes ? "btn-primary" : "btn-outline-primary"
                        }`}
                        data-number="1"
                        onClick={toggleMatches}
                      >
                        Matchs
                      </button>
                    </div>
                  </div>
                </div>
                {showEquipes && (
                  <div className="table-responsive pt-3">
                    <table className=" table bg-white  table-sm ">
                      <thead className="style_thead">
                        <tr>
                          <th className="style_tab style_th text-nowrap">
                            Liste des équipes
                          </th>
                          <th className="style_tab style_th text-nowrap">
                            Acronyme
                          </th>
                          <th className="style_tab style_th text-nowrap"></th>

                          <th className="style_tab style_th text-nowrap">
                            Partenaire
                          </th>
                          <th className="style_tab style_th text-nowrap">
                            Date de Partenariat
                          </th>
                          <th className="style_tab style_th text-nowrap">
                            Agent
                          </th>
                          <th className="style_tab style_th text-nowrap">
                            Stade nom
                          </th>
                        </tr>
                      </thead>
                      {competitionn.teams?.length === 0 ||
                      (competitionn.teams &&
                        !competitionn.teams.some(
                          (team) => team.isEnabled === "yes"
                        )) ? (
                        <tbody className="my-4 style_tbody">
                          <tr>
                            <td colSpan={7}>
                              <EmptyData />
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody className="my-4 style_tbody ">
                          {competitionn &&
                            competitionn.teams
                              ?.filter((team) => team.isEnabled === "yes")
                              .map((team, key) => (
                                <tr className="border-b style_tbody" key={key}>
                                  <td className="p-4 text-nowrap">
                                    <Link
                                      className="style_text_deco"
                                      to={`/oneTeam/${team.id}`}
                                    >
                                      <img
                                        width="33"
                                        height="33"
                                        key={key}
                                        src={`${
                                          baseURL +
                                          "/team/getFile" +
                                          team.logo.slice(1)
                                        }`}
                                      ></img>
                                      <span className="ms-3 text-nowrap currentTeamName">
                                        {team.name}
                                      </span>
                                    </Link>
                                  </td>
                                  <td className="p-4 text-nowrap">
                                    {team.acronyme}
                                  </td>
                                  <td className="p-4 text-nowrap ">
                                    {team.countryname}
                                  </td>
                                  <td className="p-4 text-nowrap">
                                    {team.isPartner == "yes" ? (
                                      <p>oui</p>
                                    ) : (
                                      <p>non</p>
                                    )}
                                  </td>
                                  <td className="p-4 text-nowrap">
                                    {team.partnerShip_Date}
                                  </td>
                                  <td className="px-2 py-4 text-nowrap">
                                    <div>
                                      {" "}
                                      <a href="tel:">
                                        <i className="bi bi-telephone"></i>{" "}
                                        {team.agent_Phone}
                                      </a>
                                    </div>
                                    <div>
                                      <a href="mailto:">
                                        <i className="bi bi-envelope"></i>{" "}
                                        {team.agent_Email}
                                      </a>
                                    </div>
                                  </td>
                                  <td className="px-2 py-4 text-nowrap">
                                    {team.stade_Name.length > 9
                                      ? `${team.stade_Name.slice(0, 9)}...`
                                      : team.stade_Name}
                                  </td>
                                </tr>
                              ))}
                        </tbody>
                      )}
                    </table>
                  </div>
                )}
                {!showEquipes && (
                  <div>
                    <AllJourneysOfSimpleUser
                      CompetitionId={competitionn.id}
                      competitionDetails={competitionn}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
      {alertModal}
    </>
  );
};

export default OneCompetitionOfSimpleUser;
