import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {toast}  from 'react-toastify';
import { logError } from "../Toastconfig";
import useAlert from "../../hooks/alertHook";

const initFormErrors = {
  file: [""],
};
const CreateTeam = ({ getall }) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const emailRegex = /^\S+@\S+\.\S+$/;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [teams, setTeams] = useState([]);
  const [logo, setLogo] = useState("");
  const [name, setName] = useState("");
  const [acronyme, setAcronyme] = useState("");
  const [country, setCountry] = useState("");
  const [agent_Phone, setAgent_Phone] = useState("");
  const [agent_Email, setAgent_Email] = useState("");
  const [stade_Name, setStade_Name] = useState("");
  const [isPartner, setisPartner] = useState("no");
  const [partnerShip_Date, setpartnerShip_Date] = useState(
    new Date().toISOString().substr(0, 10)
  );
  const [formerrors, setFormErrors] = React.useState(initFormErrors);
  const [partnerShip_DateError, setpartnerShip_DateError] = useState(null);
  const { alertModal, displayAlert, dismissAlert } = useAlert();

  const handleSubmit = () => {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("acronyme", acronyme);
      formData.append("country", country);
      formData.append("logo", logo);
      formData.append("agent_Phone", agent_Phone);
      formData.append("agent_Email", agent_Email);
      formData.append("stade_Name", stade_Name);
      formData.append("isPartner", isPartner);
      if (isPartner === "yes") {
        formData.append("partnerShip_Date", partnerShip_Date);
      } 
      axios
        .post(`${baseURL}/teams/create`, formData)
        .then((resp) => {
          setFormErrors(initFormErrors);
        if (resp.data.error) return;
          if (resp.data.message)
          displayAlert("success","",resp.data.message);
          setTimeout(() => {
            dismissAlert();
         },2500);
          getall();
          setShow(false);
        })
        .catch((error) => {
          setFormErrors(error.response.data);
        });
  };

  const [countries, setCountris] = useState([]);
  //  get ALL countries funtion
  useEffect(function () {
    axios
      .get(`${baseURL}/countries/read`)
      .then((res) => {
        setCountris(res.data);
      })
      .catch((err) => {
        logError();
      });
  }, []);
  return (
    <>
      <div>
        <Button
          className="btn btn-primary btn-lg button-add "
          type="button"
          variant="primary"
          onClick={handleShow}
        >
          +
        </Button>
      </div>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="style_modalHeader">
          <div>
            <Modal.Title className="style_modalTitle">Créer équipe</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="row mb-4">
              <div className="col-lg-6">
                <h6 className="fw-bold color_black"> Nom d'équipe</h6>
                <input
                  onChange={(e) => setName(e.target.value)}
                  id="name"
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Nom d'équipe"
                />
                 <div className="input-error">
                    <span className="text-danger">{formerrors.name}</span>
                  </div>
              </div>
              <div className="col-lg-6">
                <h6 className="fw-bold color_black">Acronyme</h6>
                <input
                  required
                  onChange={(e) => setAcronyme(e.target.value)}
                  type="text"
                  id="acronyme"
                  name="acronyme"
                  className="form-control"
                  placeholder="Acroname"
                />
                 <div className="input-error">
                    <span className="text-danger">{formerrors.acronyme}</span>
                  </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-6">
                <h6 className="fw-bold color_black">Pays</h6>
                <select
                  className="form-control"
                  id="country"
                  name="country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                >
                  <option value="">Pays</option>
                  {countries.map((country, key) => (
                    <option value={country.id} key={key}>
                      {/* {country.flag} */}
                      {country.name}
                    </option>
                  ))}
                </select>
                <div className="input-error">
                    <span className="text-danger">{formerrors.country}</span>
                  </div>
              </div>
              <div className="col-lg-6">
                <h6 className="fw-bold color_black">Stade Nom</h6>
                <input
                  required
                  onChange={(e) => setStade_Name(e.target.value)}
                  name="stade_Name"
                  className="form-control"
                  placeholder="Stade Nom"
                />
                 <div className="input-error">
                    <span className="text-danger">{formerrors.stade_Name}</span>
                  </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-6">
                <h6 className="fw-bold color_black">Agent téléphone</h6>
                <PhoneInput
                  placeholder="Agent téléphone"
                  type="text"
                  onChange={(value, country, event) =>
                    setAgent_Phone(event.target.value)
                  }
                />
                 <div className="input-error">
                    <span className="text-danger">{formerrors.agent_Phone}</span>
                  </div>
              </div>
              <div className="col-lg-6">
                <h6 className="fw-bold color_black">Agent Email</h6>
                <input
                  required
                  onChange={(e) => setAgent_Email(e.target.value)}
                  name="agent_Email"
                  className="form-control"
                  placeholder="Agent Email"
                />
                 <div className="input-error">
                    <span className="text-danger">{formerrors.agent_Email}</span>
                  </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-6">
                <h6 className="fw-bold color_black"> Partenaire</h6>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    onChange={(e) => setisPartner(e.target.value)}
                    name="inlineRadioOptions"
                    value="yes"
                    checked={isPartner === "yes"}
                  />
                  <label className="form-check-label" for="yes">
                    Oui
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    onChange={(e) => setisPartner(e.target.value)}
                    name="inlineRadioOptions"
                    value="no"
                    checked={isPartner === "no"}
                  />
                  <label className="form-check-label" for="no">
                    Non
                  </label>
                </div>
                <div className="input-error">
                    <span className="text-danger">{formerrors.isPartner}</span>
                  </div>
                {isPartner === "yes" && (
                  <div className="row mb-4">
                    <div className="col-lg-6">
                      <h6 className="fw-bold color_black">Date Parteneriat</h6>
                      <input
                        type="date"
                        className="form-control"
                        id="date"
                        placeholder="PartnerShip Date"
                        onChange={(e) => setpartnerShip_Date(e.target.value)}
                        value={partnerShip_Date}
                        required
                      />

<div className="input-error">
                    <span className="text-danger">{formerrors.partnerShip_Date}</span>
                  </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-lg-6">
                <h6 className="fw-bold color_black">Logo</h6>
                <input
                  required
                  onChange={(e) => setLogo(e.target.files[0])}
                  id="logo"
                  accept="image/*"
                  name="logo"
                  type="file"
                  className="form-control"
                  placeholder="Team Logo"
                />
  <div className="input-error">
                <span className="text-danger">
                  {formerrors.file}
                </span>
              </div>              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            Créer équipe
          </Button>
          <Button
            variant="secondary"
            className="style_modalTitle"
            onClick={handleClose}
          >
            Annuler
          </Button>
        </Modal.Footer>
      </Modal>
      {alertModal}
    </>
  );
};

export default CreateTeam;
