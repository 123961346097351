import React from "react";
import { useState,useEffect } from "react";
import axios from "axios";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import useAlert from "../../hooks/alertHook";
import { Link } from "react-router-dom";
import { logError } from "../../components/Toastconfig";
import EmptyData from "../emptyData";

const initFormErrors = {
  title: [""],
  content: [""],
  file:[""]
};

const NewsMatch = ({ matchId }) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [title, settitle] = useState("");
  const [media, setmedia] = useState("");
  const [content, setContent] = useState("");
  const [formErrors, setFormErrors] = useState(initFormErrors);
  const { alertModal, displayAlert, dismissAlert } = useAlert();

  const uploadedImage = React.useRef(null);

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  function sendData() {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    formData.append("media", media);
    formData.append("matchId", matchId);
    axios
      .post(`${baseURL}/calendarMatches/matchDetails/createNews`, formData)
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        displayAlert("success", "", resp.data.message);
        setTimeout(() => {
          dismissAlert();
          setActiveTab(0)
          getallnews();
       },2500);
      })
      .catch((error) => {
        setFormErrors(error.response.data);
      });
  }
  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }],
    ["image", "link"],
    [{ font: [] }],
    [{ align: [] }],

    ["clean"],
  ];
  const [activeTab, setActiveTab] = useState(0);
  useEffect(function () {
    getallnews();
  }, []);
  const [newslist, setNewslist] = useState([]);
  function getallnews() {
    axios
      .get(`${baseURL}/calendarMatches/matchDetails/allNews/${matchId}`)
      .then((res) => {
        setNewslist(res.data.data);
      })
      .catch((err) => {
        logError();
      });
  }
  return (
    <>
      <div className="d-flex justify-content-end">
                <button
                className={ `mt-2 btn ${
                  activeTab === 1 ? "btn-primary" : "btn-outline-primary"
                }`}
              onClick={() => setActiveTab(1)}
                >
                  <div className="d-flex flex-column ">
                    <span className="tabsMatch  fs-but">Ajouter</span>
                  </div>                
                </button>
              </div>
              {activeTab === 1 && (
                  <div className="m-4">
                  <div className="card">
                    <div className="card-body">
                      <div className="row mb-1">
                        <div className="col-lg-6">
                          <h6>Titre</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Titre"
                            onChange={(e) => settitle(e.target.value)}
                            required
                          />
                          <div className="input-error">
                            <span className="text-danger">{formErrors.title}</span>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <h6>Couverture </h6>
                          <input
                            required
                            type="file"
                            className="form-control"
                            placeholder="actualité media"
                            onChange={(e) => {
                              handleImageUpload(e);
                              setmedia(e.target.files[0]);
                            }}
                          />
                             <div className="input-error">
                            <span className="text-danger">{formErrors.file}</span>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-1">
                        <div className="col-lg-6">
                          <img
                            ref={uploadedImage}
                            style={{
                              weight: "30%",
                              width: "40%",
                            }}
                          />
                        </div>
                      </div>
                      <div className="mb-3">
                        <h6>Contenu </h6>
                        <ReactQuill
                          theme="snow"
                          modules={{
                            toolbar: toolbarOptions,
                          }}
                          value={content}
                          placeholder="Contenu..."
                          onChange={(value) => {
                            setContent(value);
                          }}
                        />
                            <div className="input-error">
                            <span className="text-danger">{formErrors.content}</span>
                          </div>
                      </div>
                      <div class="row">
              <div className="d-flex justify-content-start align-items-center">
                <div class=" pb-2">
                        <button
                          type="button"
                          onClick={sendData}
                          className="btn btn-primary ms-2"
                        >
                          Enregistrer
                        </button>
                    </div>
                    <div class=" pb-2">
                        <button
                          type="button"
                          className="btn btn-secondary ms-2"
                          onClick={() => setActiveTab(0)}
                >
                 Annuler
                </button>
                      </div>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
                )}
    {alertModal}
              <div className="row card-body">
              {newslist.length === 0 ? (
    <EmptyData />
  ) : (
        newslist.map((news, key) => {
          //function to calculate the time difference for news create
          const now = new Date();
          const createdAt = new Date(news.createdat);
          const diffInMilliseconds = now - createdAt;
          let relativeTime;

          if (diffInMilliseconds < 60000) {
            relativeTime = "À l'instant";
          } else if (diffInMilliseconds < 3600000) {
            const minutes = Math.floor(diffInMilliseconds / 60000);
            relativeTime = `${minutes} ${minutes === 1 ? "minute" : "minutes"} auparavant`;
          } else if (diffInMilliseconds < 86400000) {
            const hours = Math.floor(diffInMilliseconds / 3600000);
            relativeTime = `${hours} ${hours === 1 ? "heure" : "heures"} auparavant`;
          } else {
            const days = Math.floor(diffInMilliseconds / 86400000);
            relativeTime = `${days} ${days === 1 ? "jour" : "jours"} auparavant`;
          }
          return (
            
<div className="col-md-6 col-12 mb-3 d-grid align-items-center shadow-effect" key={key}>
              <div className="row d-flex align-items-center justify-content-center">
                <div className="col-4">
                  <Link to={`/admin/oneCompetition/matches/matchDetails/newDetails/${news.id}`} className="noTextDecoration">
                    <img className="w-75" src={`${baseURL}/competition/getFile${news.media}`} alt="News Image" />
                  </Link>
                </div>
                <div className="col-8">
                  <Link to={`/admin/oneCompetition/matches/matchDetails/newDetails/${news.id}`} className="noTextDecoration text-dark">
                    <h5 className="hoverUnderline">
                      {news.title.length > 40 ? `${news.title.slice(0, 40)}...` : news.title}
                    </h5>
                    <p>{relativeTime}</p>
                  </Link>
                </div>
              </div>
            </div>
          );
        })
        )}
      </div>
             
  </>
  );


};

export default NewsMatch;
