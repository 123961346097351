import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { logError } from "../Toastconfig";
import EmptyData from "../../components/emptyData";
import parse from "html-react-parser";
import LoaderLoading from "../LoaderLoading";
import { Carousel } from 'react-bootstrap';

const NewsLists = ({ newslist, isLoading, boostExist }) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  return (
    <>
      {isLoading ? (
        <LoaderLoading />
      ) : (boostExist ? (newslist?.length === 0 ? (
        <div className="row">
          <div className="col-lg-12">
            <EmptyData />
          </div>
        </div>
      ) : (
        <div className="row bg-white mx-md-0 mx-lg-0 mx-1">
          <h3 className="fs-6 mt-2">Dernières Actualités :</h3>
          {newslist.slice(0, 2).map((item, key) => (
            <Link
              className="p-0 mb-1"
              style={{ textDecoration: "none" }}
              to={`/news/details/${item.id}`}
            >
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-3 p-0">
                      <img src={`${baseURL + "/new/getFile" + item.media}`} className="w-100 h-100" alt="..." />
                    </div>
                    <div className="col-9 pe-0">
                      <p className=" m-0 ">
                        <a href="#">
                          <p className="txt_card_news">
                            {item.title.length > 15 ? `${item.title.slice(0, 15)} ...` : item.title}
                          </p>
                        </a>
                      </p>
                      <div className="txt_card_news"> <p>{item.createdat.slice(0, 10)}</p></div>
                    </div>
                  </div>
                </div>
              </div>
            </Link>))
          }
          <div className="col-lg-12 text-end justify-content-end ">
          <a href="#/news" type="button" className="btn btn-link">
            Voir plus <i className="fa-solid fa-arrow-right"></i>
          </a>
        </div>
        </div>
      )) : (newslist.length === 0 ? (<div className="row">
        <div className="col-lg-12">
          <EmptyData />
        </div>
      </div>
      ) : (
        <div className="row ">
          <h3 className="fs-5 fs-sm-6 mt-2">Dernières Actualités :</h3>
          {newslist?.slice(0, 2).map((item, key) => (
            <div className="col-xl-6 col-sm-5">
              <div className="card-body color_name_news cardFavorisPlayer px-3 py-2">
                <div className="row">
                  <img
                    src={`${baseURL + "/new/getFile" + item.media}`}
                    className="img_width"
                  />
                  <div className="text-wrap ">
                    <Link
                      className="mt-1 news-title name_playerLiked text-decoration-none"
                      to={`/news/details/${item.id}`}
                    >
                      <p className="fw-bold mt-2">
                        {item.title.length > 30 ? `${item.title.slice(0, 30)} ...` : item.title}
                      </p>
                    </Link>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-lg-12 text-end color_name_news"
                    style={{ fontSize: "12px" }}
                  >
                    <p>{item.createdat.slice(0, 10)}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="col-lg-12 text-end justify-content-end ">
              <a href="#/news" type="button" className="btn btn-link">
                Voir plus <i className="fa-solid fa-arrow-right"></i>
              </a>
            </div>
        </div>

      )))
      }
    </>
  );
};
export default NewsLists;
