import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import { logError } from "../Toastconfig";
import useAlert from "../../hooks/alertHook";

const initFormErrors = {
  competitionId: [""],
};

const AffectCompetition = ({ competition, getOneCompetition }) => {
  const [competitionId, setCompetitionId] = useState(competition);
  const [teamId, setTeamId] = useState();
  const [errorMessage, setErrorMessage] = useState(initFormErrors);
  const { alertModal, displayAlert, dismissAlert } = useAlert();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const baseURL = process.env.REACT_APP_BASE_URL;
  const msg = "Field is required";
  function validateform() {
    setErrorMessage(initFormErrors);
    let error = false;
    if (competitionId === "") {
      error = true;
      setErrorMessage({ name: msg });
    }
    if (!error) {
      handleSubmit();
    }
  }
  const handleSubmit = () => {
    const data = {
      competitionId: competitionId,
      teamId: teamId,
    };
    axios
      .post(`${baseURL}/teams/affectCompetition`, data)
      .then((resp) => {
        setErrorMessage(initFormErrors);
        if (resp.data.error) return;
        displayAlert("success", "", resp.data.message);
        setTimeout(() => {
          dismissAlert();
        }, 2500);
        setShow(false);
        getOneCompetition();
      })
      .catch((error) => {
        const errors = error.response.data;
        setErrorMessage(errors);
      });
  };

  useEffect(() => {
    //Print data each time the checkbox is "checked" or "unchecked"
  }, [competitionId]);
  const [teams, setTeams] = useState([]);
  //  get ALL competitions funtion
  useEffect(function () {
    axios
      .get(`${baseURL}/teams/read`)
      .then((res) => {
        setTeams(res.data.data);
      })
      .catch((err) => {
        logError();
      });
  }, []);
  const [teamSelected, setTeamSelected] = useState([]);
  function getOnee() {
    axios
      .get(`${baseURL}/competitions/${competitionId}`)
      .then((res) => {
        setTeamSelected(res.data.data);
      })
      .catch((err) => {
        logError()
      });
  }
  useEffect(() => {
    getOnee();
  }, []);

const [showCreateTeam, setShowCreateTeam] = useState(false);
const handleCreateTeamClose = () => {
    setShowCreateTeam(false);
  };

  // code for createTeam
  const emailRegex = /^\S+@\S+\.\S+$/;

  const [logo, setLogo] = useState("");
  const [name, setName] = useState("");
  const [acronyme, setAcronyme] = useState("");
  const [country, setCountry] = useState("");
  const [agent_Phone, setAgent_Phone] = useState("");
  const [agent_Email, setAgent_Email] = useState("");
  const [stade_Name, setStade_Name] = useState("");
  const [isPartner, setisPartner] = useState("no");
  const [partnerShip_Date, setpartnerShip_Date] = useState(
    new Date().toISOString().substr(0, 10)
  );

  const [errorMessageTeam, setErrorMessageTeam] = useState(initFormErrors);

  const handleSubmitt = () => {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("acronyme", acronyme);
      formData.append("country", country);
      formData.append("logo", logo);
      formData.append("agent_Phone", agent_Phone);
      formData.append("agent_Email", agent_Email);
      formData.append("stade_Name", stade_Name);
      formData.append("isPartner", isPartner);
      if (isPartner === "yes") {
        formData.append("partnerShip_Date", partnerShip_Date);
      }
      axios
        .post(`${baseURL}/teams/create`, formData)
        .then((resp) => {
          setErrorMessageTeam(initFormErrors);
        if (resp.data.error) return;
          if (resp.data.message) displayAlert("success", "", resp.data.message);
          setTimeout(() => {
            dismissAlert();
          }, 2500);
          axios.get(`${baseURL}/teams/read`).then((res) => {
            setTeams(res.data.data);
          });
          setShowCreateTeam(false);
        })
        .catch((error) => {
          const errors = error.response.data;
          setErrorMessageTeam(errors);

        });
  };

  const [countries, setCountris] = useState([]);
  //  get ALL countries funtion
  useEffect(function () {
    axios
      .get(`${baseURL}/countries/read`)
      .then((res) => {
        setCountris(res.data);
      })
      .catch((err) => {
        logError();
      });
  }, []);

  return (
    <>
        <button
          type="button"
          className="btn btn-dark btn-sm"
          onClick={handleShow}
        >
         Ajouter équipe
        </button>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="style_modalHeader">
          <div>
            <Modal.Title className="style_modalTitle">
              Ajouter Equipe
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="form-horizontal ">
            <div className="row mb-4">
              <div className="col-12">
                <h6 className="fw-bold color_black">Equipes: </h6>
                <Select
                  required
                  placeholder="Select equipes "
                  id="competitionId"
                  name="competitionId"
                  autoFocus
                  isClearable
                  isSearchable
                  isMulti
                  value={teamSelected.teams?.map((e) => ({
                    label: e.name,
                    value: e.key,
                  }))}
                  options={teams.map((team) => ({
                    label: team.name,
                    value: team.id,
                  })).concat({
                    label: "Ajouter équipe ",
                    value: "createTeam",
                  })}
                  onChange={(selectedOptions) => {
                    if (selectedOptions && selectedOptions[selectedOptions.length - 1].value === "createTeam") {
                      setShowCreateTeam(true);
                    } else {
                      setTeamSelected(selectedOptions);
                      setTeamId(selectedOptions.map((e) => e.value));
                    }
                  }}
                ></Select>
                 {showCreateTeam && (
                    <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={showCreateTeam}
                    onHide={handleCreateTeamClose}
  >                      <Modal.Header className="style_modalHeader ">
                        <div>
                          <Modal.Title className="style_modalTitle">
                            Créer équipe
                          </Modal.Title>
                        </div>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="">
                          <div className="row mb-4">
                            <div className="col-lg-6">
                              <h6 className="fw-bold color_black">
                                Nom d'équipe
                              </h6>
                              <input
                                onChange={(e) => setName(e.target.value)}
                                id="name"
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder=" Nom d'équipe"
                              />
                              <div className="input-error">
                    <span className="text-danger">{errorMessageTeam.name}</span>
                  </div>
                            </div>
                            <div className="col-lg-6">
                              <h6 className="fw-bold color_black">Acronyme</h6>
                              <input
                                required
                                onChange={(e) => setAcronyme(e.target.value)}
                                type="text"
                                id="acronyme"
                                name="acronyme"
                                className="form-control"
                                placeholder="Acroname"
                              />
                               <div className="input-error">
                    <span className="text-danger">{errorMessageTeam.acronyme}</span>
                  </div>
                            </div>
                          </div>
                          <div className="row mb-4">
                            <div className="col-lg-6">
                              <h6 className="fw-bold color_black">Pays</h6>
                              <select
                                className="form-control"
                                id="country"
                                name="country"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                              >
                                <option value="">Pays</option>
                                {countries.map((country, key) => (
                                  <option value={country.id} key={key}>
                                    {/* {country.flag} */}
                                    {country.name}
                                  </option>
                                ))}
                              </select>
                              <div className="input-error">
                    <span className="text-danger">{errorMessageTeam.country}</span>
                  </div>
                            </div>
                            <div className="col-lg-6">
                              <h6 className="fw-bold color_black">Stade Nom</h6>
                              <input
                                required
                                onChange={(e) => setStade_Name(e.target.value)}
                                name="stade_Name"
                                className="form-control"
                                placeholder="Stade Nom "
                              />
                             <div className="input-error">
                    <span className="text-danger">{errorMessageTeam.stade_Name}</span>
                  </div>
                            </div>
                          </div>
                          <div className="row mb-4">
                            <div className="col-lg-6">
                              <h6 className="fw-bold color_black">
                                Agent téléphone
                              </h6>
                              <PhoneInput
                                className="style_phone_comp"
                                placeholder=" Agent téléphone"
                                type="text"
                                onChange={(value, country, event) =>
                                  setAgent_Phone(event.target.value)
                                }
                              />
                           <div className="input-error">
                    <span className="text-danger">{errorMessageTeam.agent_Phone}</span>
                  </div>
                            </div>
                            <div className="col-lg-6">
                              <h6 className="fw-bold color_black">
                                Agent Email
                              </h6>
                              <input
                                required
                                onChange={(e) => setAgent_Email(e.target.value)}
                                name="agent_Email"
                                className="form-control"
                                placeholder="Agent Email "
                              />
                                     <div className="input-error">
                    <span className="text-danger">{errorMessageTeam.agent_Email}</span>
                  </div>
                            </div>
                          </div>
                          <div className="row mb-4">
                            <div className="col-lg-6">
                              <h6 className="fw-bold color_black">
                                Partenaire
                              </h6>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  onChange={(e) => setisPartner(e.target.value)}
                                  name="inlineRadioOptions"
                                  value="yes"
                                  checked={isPartner === "yes"}
                                />
                                <label className="form-check-label" for="yes">
                                  Oui
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  onChange={(e) => setisPartner(e.target.value)}
                                  name="inlineRadioOptions"
                                  value="no"
                                  checked={isPartner === "no"}
                                />
                                <label className="form-check-label" for="no">
                                  Non
                                </label>
                              </div>
                              <div className="input-error">
                    <span className="text-danger">{errorMessageTeam.isPartner}</span>
                  </div>
                              {isPartner === "yes" && (
                                <div className="row mb-4">
                                  <div className="col-lg-6">
                                    <h6 className="fw-bold color_black">
                                      Date Parteneriat
                                    </h6>
                                    <input
                                      type="date"
                                      className="form-control"
                                      id="date"
                                      placeholder="PartnerShip Date"
                                      onChange={(e) =>
                                        setpartnerShip_Date(e.target.value)
                                      }
                                      value={partnerShip_Date}
                                      required
                                    />

<div className="input-error">
                    <span className="text-danger">{errorMessageTeam.partnerShip_Date}</span>
                  </div>
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="col-lg-6">
                              <h6 className="fw-bold color_black">Logo</h6>
                              <input
                                required
                                onChange={(e) => setLogo(e.target.files[0])}
                                id="logo"
                                accept="image/*"
                                name="logo"
                                type="file"
                                className="form-control"
                                placeholder="Team Logo"
                              />
                           <div className="input-error">
                            <span className="text-danger">
                                {errorMessageTeam.file}
                            </span>
                              </div>
                               
                  
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="primary" onClick={handleSubmitt}>
                          Créer équipe
                        </Button>
                        <Button
                          variant="secondary"
                          className="style_modalTitle"
                          onClick={handleCreateTeamClose}
                        >
                          Annuler
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  )}

                {/* {CompetitionIdError && ( */}
                <div className="style_err">{errorMessage.teamId}</div>
                {/* )} */}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={validateform}>
            Confirmer
          </Button>
          <Button
            variant="secondary"
            className="style_modalTitle"
            onClick={handleClose}
          >
            Annuler
          </Button>
        </Modal.Footer>
      </Modal>
      {alertModal}
    </>
  );
};

export default AffectCompetition;
