import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

const MatchesStatistics = ({ totalPlayed, startedPlayGame }) => {
  const [series, setSeries] = useState([((startedPlayGame * 100) / totalPlayed).toFixed(0)]);

  useEffect(() => {
    const updatedSeries = [((startedPlayGame * 100) / totalPlayed).toFixed(0)];
    setSeries(updatedSeries);
  }, [totalPlayed, startedPlayGame]);
  const options = {
    chart: {
      height: 350,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: "70%",
        },
      },
    },
    labels: ["Matchs"],
  };

  return (
    <div>
      <div>
        <Chart options={options} series={series} type="radialBar" height={200} />
        <h6 className="text-center style_stats_card_text">
          {startedPlayGame} matchs titulaire/{totalPlayed}
        </h6>
      </div>
    </div>
  );
};

export default MatchesStatistics;