import React from "react";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { useState, useEffect } from "react";
import EditTeam from "../components/Teams/EditTeam";
import StatusTeam from "../components/Teams/StatusTeam";
import CreateTeam from "../components/Teams/CreateTeam";
import { Link } from "react-router-dom";
import BoostTeam from "../components/Teams/BoostTeam";
import { logError } from "../components/Toastconfig";
import EmptyData from "../components/emptyData";
import useAlert from "../hooks/alertHook";
import Select from "react-select";
import LoaderLoading from "../components/LoaderLoading";

export default function Teams() {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [teams, setTeams] = useState([]);
  const [searchTermName, setsearchTermName] = useState("");
  const [searchTermisPartner, setsearchTermisPartner] = useState({
    value: "Two",
  });
  const [searchTermCountry, setsearchTermCountry] = useState("");
  const [showLikedTeams, setShowLikedTeams] = useState(false);
  const { alertModal, displayAlert, dismissAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(true);

  //  get ALL teams funtion
  useEffect(function () {
    getall();
    getallCountries();
  }, []);
  function getall() {
    axios
      .get(`${baseURL}/teams/read`)
      .then((res) => {
        setTeams(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        logError();
        setIsLoading(false);
      });
  }
  const selectTeams = [...new Set(teams.map((team) => team.isPartner))];
  const [countries, setCountris] = useState([]);
  function getallCountries() {
    axios
      .get(`${baseURL}/countries/read`)
      .then((res) => {
        setCountris(res.data);
      })
      .catch((err) => {
        logError();
      });
  }

  async function likeDislike(id, isLiked) {
    let message =
      isLiked == "yes"
        ? "voulez vous supprimer cette équipe de la liste des favories?"
        : "voulez vous ajouter cette équipe à la liste des favories";
    let status = isLiked == "yes" ? "danger" : "info";
    displayAlert(
      status,
      "",
      message,
      "Confirmer",
      () => {
        axios
          .post(`${baseURL}/teams/like`, {
            teamId: id,
          })
          .then((resp) => {
            getall();
            dismissAlert();
            let usersInfo = resp.data;
          });
      },
      "cancel",
      dismissAlert
    );
  }
  const filteredTeams = teams
    .filter((val) => {
      if (searchTermName === "") {
        return val;
      } else if (
        val.name.toLowerCase().includes(searchTermName.toLowerCase())
      ) {
        return val;
      }
    })
    .filter((val) => {
      if (searchTermisPartner.value === "Two") {
        return val;
      } else if (
        val.isPartner
          .toLowerCase()
          .includes(searchTermisPartner.value?.toLowerCase())
      ) {
        return val;
      }
    })
    .filter((val) => {
      if (searchTermCountry === "") {
        return val;
      } else if (
        val.countryname.toLowerCase().includes(searchTermCountry.toLowerCase())
      ) {
        return val;
      }
    })
    .filter((val) => {
      if (!showLikedTeams) {
        return val;
      } else {
        return val.isLiked === "yes";
      }
    });
  const partner = [
    { value: "Two", label: "Les Deux" },
    { value: "yes", label: "OUI" },
    { value: "no", label: "NON" },
  ];
  return (
    <>
      <section className="pt-3">
        <section className="">
          <div className="row mx-0">
            <div className="col-lg-2 ">
              <label className="text-muted fw-bolder">
                <small>Nom de l'équipe</small>
              </label>
              <input
                type="text"
                placeholder="Nom de l'équipe"
                className="form-control"
                onChange={(e) => {
                  setsearchTermName(e.target.value);
                }}
              />
            </div>
            <div className="col-lg-3 ">
              <label className="text-muted fw-bolder">
                <small>Pays</small>
              </label>
              <select
                className="form-control"
                onChange={(e) => setsearchTermCountry(e.target.value)}
              >
                <option value="">Pays</option>
                {countries.map((country, key) => (
                  <option value={country.name} key={key}>
                    {country.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-lg-2 ">
              <label className="text-muted fw-bolder">
                <small>Partenaire: o/n</small>
              </label>
              <Select
                options={partner}
                isSearchable
                defaultValue={{ value: "Two", label: "Les deux" }}
                onChange={(value) => setsearchTermisPartner(value)}
              />
            </div>
            <div className="col-lg-3 d-lg-flex justify-content-evenly py-4">
              <div className="toggle_btn form-check form-switch  d-md-flex flex-md-row-reverse   px-md-0 ">
                <label className="form-check-label text-muted">
                  {showLikedTeams
                    ? "Les équipes favorites"
                    : "Les équipes favorites"}
                </label>
                <input
                  className="form-check-input me-2"
                  type="checkbox"
                  id="toggleSwitch"
                  checked={showLikedTeams}
                  onChange={() => setShowLikedTeams(!showLikedTeams)}
                />
              </div>
            </div>
            <div className="col-lg-1 py-lg-3 py-1">
              <CreateTeam getall={getall} />
            </div>
          </div>
          <div className="row mt-4 mx-0 p-scroll">
            <div className="table-responsive ">
              {isLoading ? (
                <LoaderLoading />
              ) : filteredTeams.length === 0 ? (
                <div className="row">
                  <div className="col-lg-12">
                    <EmptyData />
                  </div>
                </div>
              ) : (
                <Table className="table">
                  <thead className="style_thead">
                    <tr>
                      {" "}
                      <th className="style_tab style_th"></th>
                      <th className="style_tab style_th">Nom de l'équipe</th>
                      <th className="style_tab style_th">Acronyme</th>
                      <th className="style_tab style_th">Pays</th>
                      <th className="style_tab style_th">Partenaire</th>
                      <th className="style_tab style_th text-nowrap">
                        Agent Téléphone
                      </th>
                      <th className="style_tab style_th text-nowrap">
                        Agent Email
                      </th>
                      <th className="style_tab style_th text-nowrap">
                        Nom du Stade
                      </th>
                      <th className="style_tab style_th">Actions</th>
                    </tr>
                  </thead>
                  {filteredTeams.map((team, key) => (
                    <tbody className="my-4 style_tbody " key={key}>
                      <tr className="border-b style_tbody text-nowrap">
                        <td className="py-4">
                          <div className="row">
                            <div className="col-3  d-flex justify-content-start align-items-center px-2">
                              <button
                                className="btn text-primary"
                                onClick={() => {
                                  likeDislike(team.id, team.isLiked);
                                }}
                              >
                                {team.isLiked == "yes" ? (
                                  <i className="fa-solid fa-heart like_icon "></i>
                                ) : (
                                  <i className="fa-regular fa-heart dislike_icon "></i>
                                )}
                              </button>
                            </div>
                          </div>
                        </td>
                        <td className="p-4 ">
                          <Link
                            style={{ textDecoration: "none" }}
                            to={`/admin/oneTeam/${team.id}`}
                          >
                            <img
                              src={`${baseURL + "/team/getFile" + team.logo}`}
                              width="35"
                              height="35"
                            />{" "}
                            <span className="ms-3">
                              {team.name.length > 20
                                ? `${team.name.slice(0, 20)}...`
                                : team.name}
                            </span>
                          </Link>
                        </td>
                        <td className="p-4 text-nowrap">{team.acronyme}</td>
                        <td className="p-4 text-nowrap">{team.countryname}</td>
                        <td className="p-4 text-nowrap">
                          {team.isPartner == "yes" ? <p>oui</p> : <p>non</p>}
                        </td>

                        <td className="px-2 py-4 text-nowrap">
                          {team.agent_Phone}
                        </td>
                        <td className="px-2 py-4 text-nowrap">
                          {team.agent_Email}
                        </td>
                        <td className="px-2 py-4 text-nowrap">
                          {team.stade_Name.length > 20
                            ? `${team.stade_Name.slice(0, 20)}...`
                            : team.stade_Name}
                        </td>
                        <td className="py-4 p-0 text-nowrap">
                          <div className="row w-100">
                            <div className="col-3  d-flex justify-content-start align-items-center px-2">
                              <EditTeam team={team} getall={getall} />
                            </div>
                            <div className="col-3  d-flex justify-content-start align-items-center px-2">
                              <StatusTeam team={team} getall={getall} />
                            </div>
                            <div className="col-3  d-flex justify-content-start align-items-center px-2">
                              <Link to={`/admin/oneTeam/${team.id}`}>
                                <button
                                  type="button"
                                  className="btn btn-sm p-0"
                                >
                                  <i
                                    className="fa-solid fa-circle-info"
                                    title="Details Team"
                                  ></i>
                                </button>
                              </Link>
                            </div>
                            <div className="col-3 d-flex justify-content-start align-items-center px-2">
                              <BoostTeam TeamId={team.id} />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  ))}
                </Table>
              )}
            </div>
          </div>
        </section>
      </section>
      {alertModal}
    </>
  );
}
