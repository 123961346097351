import React from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import { useState, useEffect } from "react";
const StatsOfMatchsSimpleUser = ( ) => { 
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [goalsHome, setgoalsHome] = useState();
  const [goalsAway, setgoalsAway] = useState();
  const [shotsOnTargetHome, setshotsOnTargetHome] = useState();
  const [shotsOnTargetAway, setshotsOnTargetAway] = useState();
  const [shotsOffTargetHome, setshotsOffTargetHome] = useState();
  const [shotsOffTargetAway, setshotsOffTargetAway] = useState();
  const [blocked_ShotsHome, setblocked_ShotsHome] = useState();
  const [blocked_ShotsAway, setblocked_ShotsAway] = useState();
  const [corner_kicksHome, setcorner_kicksHome] = useState();
  const [corner_kicksAway, setcorner_kicksAway] = useState();
  const [offsidesHome, setoffsidesHome] = useState();
  const [offsidesAway, setoffsidesAway] = useState();
  const [foulsHome, setfoulsHome] = useState();
  const [foulsAway, setfoulsAway] = useState();
  const [yellow_cardsHome, setyellow_cardsHome] = useState();
  const [yellow_cardsAway, setyellow_cardsAway] = useState();
  const [big_chancesHome, setbig_chancesHome] = useState();
  const [big_chancesAway, setbig_chancesAway] = useState();
  const [counter_attacksHome, setcounter_attacksHome] = useState();
  const [counter_attacksAway, setcounter_attacksAway] = useState();
  const [counter_attack_shotsHome, setcounter_attack_shotsHome] = useState();
  const [counter_attack_shotsAway, setcounter_attack_shotsAway] = useState();
  const [shots_inside_boxHome, setshots_inside_boxHome] = useState();
  const [shots_inside_boxAway, setshots_inside_boxAway] = useState();
  const [shots_outside_boxHome, setshots_outside_boxHome] = useState();
  const [shots_outside_boxAway, setshots_outside_boxAway] = useState();
  const [goalkeeperSavesHome, setgoalkeeperSavesHome] = useState();
  const [goalkeeperSavesAway, setgoalkeeperSavesAway] = useState();
  const [passesHome, setpassesHome] = useState();
  const [passesAway, setpassesAway] = useState();
  const [acc_passesHome, setacc_passesHome] = useState();
  const [acc_passesAway, setacc_passesAway] = useState();
  const [long_ballsHome, setlong_ballsHome] = useState();
  const [long_ballsAway, setlong_ballsAway] = useState();
  const [crossesHome, setcrossesHome] = useState();
  const [crossesAway, setcrossesAway] = useState();
  const [dribblesHome, setdribblesHome] = useState();
  const [dribblesAway, setdribblesAway] = useState();
  const [possession_lostHome, setpossession_lostHome] = useState();
  const [possession_lostAway, setpossession_lostAway] = useState();
  const [duels_wonHome, setduels_wonHome] = useState();
  const [duels_wonAway, setduels_wonAway] = useState();
  const [aerials_wonHome, setaerials_wonHome] = useState();
  const [aerials_wonAway, setaerials_wonAway] = useState();
  const [tacklesHome, settacklesHome] = useState();
  const [tacklesAway, settacklesAway] = useState();
  const [interceptionsHome, setinterceptionsHome] = useState();
  const [interceptionsAway, setinterceptionsAway] = useState();
  const [clearancesHome, setclearancesHome] = useState();
  const [clearancesAway, setclearancesAway] = useState();
  const [inputValue, setInputValue] = useState("");
  const [result, setResult] = useState("");
  const { matchId } = useParams();
  function GetMatchStatsById() {
    axios.get(`${baseURL}/calendarMatches/matchstats/${matchId}`).then((resp) => {
      if (resp.data.error) return;
      const MatchStatsData = resp.data.data;
      MatchStatsData?.map((item) => {
        if (item.status === "home") {
          setgoalsHome(item.goals);
          setInputValue(item.ball_possession);
          setshotsOnTargetHome(item.shotsOnTarget);
          setshotsOffTargetHome(item.shotsOffTarget);
          setblocked_ShotsHome(item.blocked_Shots);
          setcorner_kicksHome(item.corner_kicks);
          setoffsidesHome(item.offsides);
          setfoulsHome(item.fouls);
          setyellow_cardsHome(item.yellow_cards);
          setbig_chancesHome(item.big_chances);
          setcounter_attacksHome(item.counter_attacks);
          setcounter_attack_shotsHome(item.counter_attack_shots);
          setshots_inside_boxHome(item.shots_inside_box);
          setshots_outside_boxHome(item.shots_outside_box);
          setgoalkeeperSavesHome(item.goalkeeperSaves);
          setpassesHome(item.passes);
          setacc_passesHome(item.acc_passes);
          setlong_ballsHome(item.long_balls);
          setcrossesHome(item.crosses);
          setdribblesHome(item.dribbles);
          setpossession_lostHome(item.possession_lost);
          setduels_wonHome(item.duels_won);
          setaerials_wonHome(item.aerials_won);
          settacklesHome(item.tackles);
          setinterceptionsHome(item.interceptions);
          setclearancesHome(item.clearances);
        } else {
          setgoalsAway(item.goals);
          setResult(item.ball_possession);
          setshotsOnTargetAway(item.shotsOnTarget);
          setshotsOffTargetAway(item.shotsOffTarget);
          setblocked_ShotsAway(item.blocked_Shots);
          setcorner_kicksAway(item.corner_kicks);
          setoffsidesAway(item.offsides);
          setfoulsAway(item.fouls);
          setyellow_cardsAway(item.yellow_cards);
          setbig_chancesAway(item.big_chances);
          setcounter_attacksAway(item.counter_attacks);
          setcounter_attack_shotsAway(item.counter_attack_shots);
          setshots_inside_boxAway(item.shots_inside_box);
          setshots_outside_boxAway(item.shots_outside_box);
          setgoalkeeperSavesAway(item.goalkeeperSaves);
          setpassesAway(item.passes);
          setacc_passesAway(item.acc_passes);
          setlong_ballsAway(item.long_balls);
          setcrossesAway(item.crosses);
          setdribblesAway(item.dribbles);
          setpossession_lostAway(item.possession_lost);
          setduels_wonAway(item.duels_won);
          setaerials_wonAway(item.aerials_won);
          settacklesAway(item.tackles);
          setinterceptionsAway(item.interceptions);
          setclearancesAway(item.clearances);
        }
      });
    });
  }
  useEffect(() => {
    GetMatchStatsById();
  }, []);

  return (
    <>
<div className="mb-3 mt-2">
    <div className="row">
      <div className="col-sm-5 col-2">{goalsHome}</div>
      <div className="col-sm-2 col-8 text-nowrap text-center">Buts</div>
      <div className="col-sm-5 col-2 text-end">{goalsAway}</div>
    </div>
    <div className="row">
    <div class="col">
    <div class="progress progressStats d-flex justify-content-end">
      <div
        className="progress-bar"
        style={{
          width: `${(goalsHome / (goalsHome + goalsAway)) * 100}%`,
          backgroundColor: '#216ff5',
        }}
      ></div>
      </div>
      </div>
  <div class="col">
  <div class="progress progressStats">
      <div
        className="progress-bar"
        style={{
          width: `${(goalsAway / (goalsHome + goalsAway)) * 100}%`,
          backgroundColor: '#001e28',
        }}
      >
      </div>
      </div>
      </div>
  </div>
    </div>
    <div className="mb-3">
    <div className="row">
      <div className="col-sm-5 col-2">{inputValue}</div>
      <div className="col-sm-2 col-8 text-nowrap text-center">Possession</div>
      <div className="col-sm-5 col-2 text-end">{result}</div>
    </div>
    <div className="row">
    <div class="col ">
    <div class="progress progressStats d-flex justify-content-end">
      <div
        className="progress-bar"
        style={{
          width: `${(inputValue / (inputValue + result)) * 100}%`,
          backgroundColor: '#216ff5',
        }}
      ></div>
      </div>
      </div>
  <div class="col ">
  <div class=" progress progressStats">
      <div
        className="progress-bar"
        style={{
          width: `${(result / (inputValue + result)) * 100}%`,
          backgroundColor: '#001e28',
        }}
      >
      </div>
      </div>
      </div>
  </div>
    </div>
<div className="mb-3">
    <div className="row">
      <div className="col-sm-5 col-2">{ Number(shotsOnTargetHome) + Number(shotsOffTargetHome)}</div>
      <div className="col-sm-2 col-8 text-nowrap text-center">Tirs totaux </div>
      <div className="col-sm-5 col-2 text-end">{Number(shotsOnTargetAway) + Number(shotsOffTargetAway)}</div>
    </div>
    <div className="row">
    <div class="col ">
    <div class=" progress progressStats d-flex justify-content-end">
      <div
        className="progress-bar"
        style={{
          width: `${((Number(shotsOnTargetHome) + Number(shotsOffTargetHome)) / ((Number(shotsOnTargetHome) + Number(shotsOffTargetHome) + Number(shotsOnTargetAway) + Number(shotsOffTargetAway)))) * 100}%`,
          backgroundColor: '#216ff5',
        }}
      ></div>
      </div>
      </div>
  <div class="col ">
  <div class="progress progressStats">
      <div
        className="progress-bar"
        style={{
          width: `${((Number(shotsOnTargetAway) + Number(shotsOffTargetAway) ) / ((Number(shotsOnTargetHome) + Number(shotsOffTargetHome) + Number(shotsOnTargetAway) + Number(shotsOffTargetAway)))) * 100}%`,
          backgroundColor: '#001e28',
        }}
      >
      </div>
      </div>
      </div>
  </div>
    </div>
    <div className="mb-3">
    <div className="row">
      <div className="col-sm-5 col-2">{shotsOnTargetHome}</div>
      <div className="col-sm-2 col-8 text-nowrap text-center">Tirs cadrés</div>
      <div className="col-sm-5 col-2 text-end">{shotsOnTargetAway}</div>
    </div>
    <div className="row">
    <div class="col ">
    <div class=" progress progressStats d-flex justify-content-end">
      <div
        className="progress-bar"
        style={{
          width: `${(shotsOnTargetHome / (shotsOnTargetHome + shotsOnTargetAway)) * 100}%`,
          backgroundColor: '#216ff5',
        }}
      ></div>
      </div>

  
  </div>
  <div class="col ">
  <div class=" progress progressStats">
      <div
        className="progress-bar"
        style={{
          width: `${(shotsOnTargetAway / (shotsOnTargetHome + shotsOnTargetAway)) * 100}%`,
          backgroundColor: '#001e28',
        }}
      >
      </div>
      </div>
    </div>
    </div>
    </div>
    <div className="mb-3">
    <div className="row">
      <div className="col-sm-5 col-2">{shotsOffTargetHome}</div>
      <div className="col-sm-2 col-8 text-nowrap text-center">Tirs non cadrés </div>
      <div className="col-sm-5 col-2 text-end">{shotsOffTargetAway}</div>
    </div>
    <div className="row">
    <div class="col ">
    <div class=" progress progressStats d-flex justify-content-end">
      <div
        className="progress-bar"
        style={{
          width: `${(shotsOffTargetHome / (shotsOffTargetHome + shotsOffTargetAway)) * 100}%`,
          backgroundColor: '#216ff5',
        }}
      ></div>
      </div>
      </div>
  <div class="col ">
  <div class=" progress progressStats">
      <div
        className="progress-bar"
        style={{
          width: `${(shotsOffTargetAway / (shotsOffTargetHome + shotsOffTargetAway)) * 100}%`,
          backgroundColor: '#001e28',
        }}
      >
      </div>
      </div>
      </div>
  </div>
    </div>
    <div className="mb-3">
    <div className="row">
      <div className="col-sm-5 col-2">{blocked_ShotsHome}</div>
      <div className="col-sm-2 col-8 text-nowrap text-center">Tirs bloqués </div>
      <div className="col-sm-5 col-2 text-end">{blocked_ShotsAway}</div>
    </div>
    <div className="row">
    <div class="col ">
    <div class=" progress progressStats d-flex justify-content-end">
      <div
        className="progress-bar"
        style={{
          width: `${(blocked_ShotsHome / (blocked_ShotsHome + blocked_ShotsAway)) * 100}%`,
          backgroundColor: '#216ff5',
        }}
      ></div>
      </div>
      </div>
  <div class="col ">
  <div class=" progress progressStats">
      <div
        className="progress-bar"
        style={{
          width: `${(blocked_ShotsAway / (blocked_ShotsHome + blocked_ShotsAway)) * 100}%`,
          backgroundColor: '#001e28',
        }}
      >
      </div>
      </div>
      </div>
  </div>
  </div>
  <div className="mb-3">
    <div className="row">
      <div className="col-sm-5 col-2">{corner_kicksHome}</div>
      <div className="col-sm-2 col-8 text-nowrap text-center">Corners:</div>
      <div className="col-sm-5 col-2 text-end">{corner_kicksAway}</div>
    </div>
    <div className="row">
    <div class="col ">
    <div class=" progress progressStats d-flex justify-content-end">
      <div
        className="progress-bar"
        style={{
          width: `${(corner_kicksHome / (corner_kicksHome + corner_kicksAway)) * 100}%`,
          backgroundColor: '#216ff5',
        }}
      ></div>
      </div>
 
  </div>
  <div class="col ">
  <div class=" progress progressStats">
      <div
        className="progress-bar"
        style={{
          width: `${(corner_kicksAway / (corner_kicksHome + corner_kicksAway)) * 100}%`,
          backgroundColor: '#001e28',
        }}
      >
      </div>
      </div>
      </div>
  </div>
  </div>
  <div className="mb-3">
    <div className="row">
      <div className="col-sm-5 col-2">{offsidesHome}</div>
      <div className="col-sm-2 col-8 text-nowrap text-center">Hors-jeu</div>
      <div className="col-sm-5 col-2 text-end">{offsidesAway}</div>
    </div>
    <div className="row">
    <div class="col ">
    <div class=" progress progressStats d-flex justify-content-end">
      <div
        className="progress-bar"
        style={{
          width: `${(offsidesHome / (offsidesHome + offsidesAway)) * 100}%`,
          backgroundColor: '#216ff5',
        }}
      ></div>
      </div>
      </div>
      <div class="col ">
  <div class=" progress progressStats">
      <div
        className="progress-bar"
        style={{
          width: `${(offsidesAway / (offsidesHome + offsidesAway)) * 100}%`,
          backgroundColor: '#001e28',
        }}
      >
      </div>
      </div>
      </div>
  </div>
  </div>
  <div className="mb-3">
    <div className="row">
      <div className="col-sm-5 col-2">{foulsHome}</div>
      <div className="col-sm-2 col-8 text-nowrap text-center">Fautes </div>
      <div className="col-sm-5 col-2 text-end">{foulsAway}</div>
    </div>
    <div className="row">
    <div class="col ">
    <div class=" progress progressStats d-flex justify-content-end">
      <div
        className="progress-bar"
        style={{
          width: `${(foulsHome / (foulsHome + foulsAway)) * 100}%`,
          backgroundColor: '#216ff5',
        }}
      ></div>
      </div>
      </div>
      <div class="col ">
  <div class=" progress progressStats">
      <div
        className="progress-bar"
        style={{
          width: `${(foulsAway / (foulsHome + foulsAway)) * 100}%`,
          backgroundColor: '#001e28',
        }}
      >
      </div>
      </div>
  </div>
  </div>
  </div>
  <div className="mb-3">
    <div className="row">
      <div className="col-sm-5 col-2">{yellow_cardsHome}</div>
      <div className="col-sm-2 col-8 text-nowrap text-center">Cartons jaunes</div>
      <div className="col-sm-5 col-2 text-end">{yellow_cardsAway}</div>
    </div>
    <div className="row">
    <div class="col ">
    <div class="progress progressStats d-flex justify-content-end">
      <div
        className="progress-bar"
        style={{
          width: `${(yellow_cardsHome / (yellow_cardsHome + yellow_cardsAway)) * 100}%`,
          backgroundColor: '#216ff5',
        }}
      ></div>
      </div>
      </div>
      <div class="col ">
  <div class=" progress progressStats">
      <div
        className="progress-bar"
        style={{
          width: `${(yellow_cardsAway / (yellow_cardsHome + yellow_cardsAway)) * 100}%`,
          backgroundColor: '#001e28',
        }}
      >
      </div>
      </div>
  </div>
  </div>
  </div>
  <div className="mb-3">
    <div className="row">
      <div className="col-sm-5 col-2">{big_chancesHome}</div>
      <div className="col-sm-2 col-8 text-nowrap text-center">Tentatives de but</div>
      <div className="col-sm-5 col-2 text-end">{big_chancesAway}</div>
    </div>
    <div className="row">
    <div class="col ">
    <div class=" progress progressStats d-flex justify-content-end">
      <div
        className="progress-bar"
        style={{
          width: `${(big_chancesHome / (big_chancesHome + big_chancesAway)) * 100}%`,
          backgroundColor: '#216ff5',
        }}
      ></div>
      </div>
      </div>
      <div class="col">
      <div class=" progress progressStats">
      <div
        className="progress-bar"
        style={{
          width: `${(big_chancesAway / (big_chancesHome + big_chancesAway)) * 100}%`,
          backgroundColor: '#001e28',
        }}
      >
      </div>
      </div>
      </div>
  </div>
  </div>
  <div className="mb-3">
    <div className="row">
      <div className="col-sm-5 col-2">{counter_attacksHome}</div>
      <div className="col-sm-2 col-8 text-nowrap text-center">Contre attaques </div>
      <div className="col-sm-5 col-2 text-end">{counter_attacksAway}</div>
    </div>
    <div className="row">
    <div class="col">
    <div class=" progress progressStats d-flex justify-content-end">
      <div
        className="progress-bar"
        style={{
          width: `${(counter_attacksHome / (counter_attacksHome + counter_attacksAway)) * 100}%`,
          backgroundColor: '#216ff5',
        }}
      ></div>
      </div>
      </div>
      <div class="col">
  <div class="progress progressStats">
      <div
        className="progress-bar"
        style={{
          width: `${(counter_attacksAway / (counter_attacksHome + counter_attacksAway)) * 100}%`,
          backgroundColor: '#001e28',
        }}
      >
      </div>
      </div>
      </div>
  </div>
  </div>
  <div className="mb-3">
    <div className="row">
      <div className="col-sm-5 col-2">{counter_attack_shotsHome}</div>
      <div className="col-sm-2 col-8 text-nowrap text-center">Contre attaque <br /> tirs </div>
      <div className="col-sm-5 col-2 text-end">{counter_attack_shotsAway}</div>
    </div>
    <div className="row">
    <div class="col">
    <div class=" progress progressStats d-flex justify-content-end">
      <div
        className="progress-bar"
        style={{
          width: `${(counter_attack_shotsHome / (counter_attack_shotsHome + counter_attack_shotsAway)) * 100}%`,
          backgroundColor: '#216ff5',
        }}
      ></div>
      </div>
      </div>
  <div class="col">
  <div class=" progress progressStats">
      <div
        className="progress-bar"
        style={{
          width: `${(counter_attack_shotsAway / (counter_attack_shotsHome + counter_attack_shotsAway)) * 100}%`,
          backgroundColor: '#001e28',
        }}
      >
      </div>
      </div>
  </div>
  </div>
  </div>
  <div className="mb-3">
    <div className="row">
      <div className="col-sm-5 col-2">{shots_inside_boxHome}</div>
      <div className="col-sm-2 col-8 text-nowrap text-center">Tirs dans le 18m </div>
      <div className="col-sm-5 col-2 text-end">{shots_inside_boxAway}</div>
    </div>
    <div className="row">
    <div class="col">
    <div class=" progress progressStats d-flex justify-content-end">
      <div
        className="progress-bar"
        style={{
          width: `${(shots_inside_boxHome / (shots_inside_boxHome + shots_inside_boxAway)) * 100}%`,
          backgroundColor: '#216ff5',
        }}
      ></div>
      </div>
      </div>
  <div class="col">
  <div class="progress progressStats">
      <div
        className="progress-bar"
        style={{
          width: `${(shots_inside_boxAway / (shots_inside_boxHome + shots_inside_boxAway)) * 100}%`,
          backgroundColor: '#001e28',
        }}
      >
      </div>
      </div>
      </div>
  </div>
  </div>
  <div className="mb-3">
    <div className="row">
      <div className="col-sm-5 col-2">{shots_outside_boxHome}</div>
      <div className="col-sm-2 col-8 text-nowrap text-center">Tirs en dehors <br /> du 18m</div>
      <div className="col-sm-5 col-2 text-end">{shots_outside_boxAway}</div>
    </div>
    <div className="row">
    <div class="col ">
    <div class=" progress progressStats d-flex justify-content-end">
      <div
        className="progress-bar"
        style={{
          width: `${(shots_outside_boxHome / (shots_outside_boxHome + shots_outside_boxAway)) * 100}%`,
          backgroundColor: '#216ff5',
        }}
      ></div>
      </div>
      </div>
  <div class="col ">
  <div class=" progress progressStats">
      <div
        className="progress-bar"
        style={{
          width: `${(shots_outside_boxAway / (shots_outside_boxHome + shots_outside_boxAway)) * 100}%`,
          backgroundColor: '#001e28',
        }}
      >
      </div>
      </div>
      </div>
  </div>
  </div>
  <div className="mb-3">
    <div className="row">
      <div className="col-sm-5 col-2">{goalkeeperSavesHome}</div>
      <div className="col-sm-2 col-8 text-nowrap text-center">Arrêts du gardien</div>
      <div className="col-sm-5 col-2 text-end">{goalkeeperSavesAway}</div>
    </div>
    <div className="row">
    <div class="col">
    <div class="progress progressStats d-flex justify-content-end">
      <div
        className="progress-bar"
        style={{
          width: `${(goalkeeperSavesHome / (goalkeeperSavesHome + goalkeeperSavesAway)) * 100}%`,
          backgroundColor: '#216ff5',
        }}
      ></div>
      </div>
      </div>
      <div class="col">
  <div class=" progress progressStats">
      <div
        className="progress-bar"
        style={{
          width: `${(goalkeeperSavesAway / (goalkeeperSavesHome + goalkeeperSavesAway)) * 100}%`,
          backgroundColor: '#001e28',
        }}
      >
      </div>
      </div>
      </div>
  </div>
  </div>
  <div className="mb-3">
    <div className="row">
      <div className="col-sm-5 col-2">{passesHome}</div>
      <div className="col-sm-2 col-8 text-nowrap text-center">Passes </div>
      <div className="col-sm-5 col-2 text-end">{passesAway}</div>
    </div>
    <div className="row">
    <div class="col">
    <div class=" progress progressStats d-flex justify-content-end">
      <div
        className="progress-bar"
        style={{
          width: `${(passesHome / (passesHome + passesAway)) * 100}%`,
          backgroundColor: '#216ff5',
        }}
      ></div>
      </div>
      </div>
      <div class="col">
  <div class=" progress progressStats">
      <div
        className="progress-bar"
        style={{
          width: `${(passesAway / (passesHome + passesAway)) * 100}%`,
          backgroundColor: '#001e28',
        }}
      >
      </div>
      </div>
  </div>
  </div>
  </div>
  <div className="mb-3">
    <div className="row">
      <div className="col-sm-5 col-2">{acc_passesHome}</div>
      <div className="col-sm-2 col-8 text-nowrap text-center">Précision de <br /> passes </div>
      <div className="col-sm-5 col-2 text-end">{acc_passesAway}</div>
    </div>
    <div className="row">
    <div class="col">
    <div class=" progress progressStats d-flex justify-content-end">
      <div
        className="progress-bar"
        style={{
          width: `${(acc_passesHome / (acc_passesHome + acc_passesAway)) * 100}%`,
          backgroundColor: '#216ff5',
        }}
      ></div>
      </div>
      </div>
  <div class="col">
  <div class="progress progressStats">
      <div
        className="progress-bar"
        style={{
          width: `${(acc_passesAway / (acc_passesHome + acc_passesAway)) * 100}%`,
          backgroundColor: '#001e28',
        }}
      >
      </div>
      </div>
      </div>
  </div>
  </div>
  <div className="mb-3">
    <div className="row">
      <div className="col-sm-5 col-2">{long_ballsHome}</div>
      <div className="col-sm-2 col-8 text-nowrap text-center">Passes longues </div>
      <div className="col-sm-5 col-2 text-end">{long_ballsAway}</div>
    </div>
    <div className="row">
    <div class="col">
    <div class=" progress progressStats d-flex justify-content-end">
      <div
        className="progress-bar"
        style={{
          width: `${(long_ballsHome / (long_ballsHome + long_ballsAway)) * 100}%`,
          backgroundColor: '#216ff5',
        }}
      ></div>
      </div>
      </div>
  <div class="col">
  <div class=" progress progressStats">
      <div
        className="progress-bar"
        style={{
          width: `${(long_ballsAway / (long_ballsHome + long_ballsAway)) * 100}%`,
          backgroundColor: '#001e28',
        }}
      >
      </div>
      </div>
      </div>
  </div>
  </div>
  <div className="mb-3">
    <div className="row">
      <div className="col-sm-5 col-2">{crossesHome}</div>
      <div className="col-sm-2 col-8 text-nowrap text-center">Centres </div>
      <div className="col-sm-5 col-2 text-end">{crossesAway}</div>
    </div>
    <div className="row">
    <div class="col">
    <div class="progress progressStats d-flex justify-content-end">
      <div
        className="progress-bar"
        style={{
          width: `${(crossesHome / (crossesHome + crossesAway)) * 100}%`,
          backgroundColor: '#216ff5',
        }}
      ></div>
      </div>
      </div>
  <div class="col">
  <div class="progress progressStats">
      <div
        className="progress-bar"
        style={{
          width: `${(crossesAway / (crossesHome + crossesAway)) * 100}%`,
          backgroundColor: '#001e28',
        }}
      >
      </div>
      </div>
      </div>
  </div>
  </div>
  <div className="mb-3">
    <div className="row">
      <div className="col-sm-5 col-2">{dribblesHome}</div>
      <div className="col-sm-2 col-8 text-nowrap text-center">Dribbles </div>
      <div className="col-sm-5 col-2 text-end">{dribblesAway}</div>
    </div>
    <div className="row">
    <div class="col">
    <div class="progress progressStats d-flex justify-content-end">
      <div
        className="progress-bar"
        style={{
          width: `${(dribblesHome / (dribblesHome + dribblesAway)) * 100}%`,
          backgroundColor: '#216ff5',
        }}
      ></div>
      </div>
      </div>
  <div class="col">
  <div class=" progress progressStats">
      <div
        className="progress-bar"
        style={{
          width: `${(dribblesAway / (dribblesHome + dribblesAway)) * 100}%`,
          backgroundColor: '#001e28',
        }}
      >
      </div>
      </div>
  </div>
  </div>
  </div>
  <div className="mb-3">
    <div className="row">
      <div className="col-sm-5 col-2">{possession_lostHome}</div>
      <div className="col-sm-2 col-8 text-nowrap text-center">Possession perdue</div>
      <div className="col-sm-5 col-2 text-end">{possession_lostAway}</div>
    </div>
    <div className="row">
    <div class="col">
    <div class=" progress progressStats d-flex justify-content-end">
      <div
        className="progress-bar"
        style={{
          width: `${(possession_lostHome / (possession_lostHome + possession_lostAway)) * 100}%`,
          backgroundColor: '#216ff5',
        }}
      ></div>
      </div>
      </div>
  <div class="col">
  <div class="progress progressStats">
      <div
        className="progress-bar"
        style={{
          width: `${(possession_lostAway / (possession_lostHome + possession_lostAway)) * 100}%`,
          backgroundColor: '#001e28',
        }}
      >
      </div>
      </div>
      </div>
  </div>
  </div>
  <div className="mb-3">
    <div className="row">
      <div className="col-sm-5 col-2">{duels_wonHome}</div>
      <div className="col-sm-2 col-8 text-nowrap text-center">Duels remportés</div>
      <div className="col-sm-5 col-2 text-end">{duels_wonAway}</div>
    </div>
    <div className="row">
    <div class="col">
    <div class="progress progressStats d-flex justify-content-end">
      <div
        className="progress-bar"
        style={{
          width: `${(duels_wonHome / (duels_wonHome + duels_wonAway)) * 100}%`,
          backgroundColor: '#216ff5',
        }}
      ></div>
      </div>
      </div>
      <div class="col">
  <div class=" progress progressStats">
      <div
        className="progress-bar"
        style={{
          width: `${(duels_wonAway / (duels_wonHome + duels_wonAway)) * 100}%`,
          backgroundColor: '#001e28',
        }}
      >
      </div>
      </div>
      </div>
  </div>
  </div>
  <div className="mb-3">
    <div className="row">
      <div className="col-sm-5 col-2">{aerials_wonHome}</div>
      <div className="col-sm-2 col-8 text-nowrap text-center">Duels aériens <br /> remportés </div>
      <div className="col-sm-5 col-2 text-end">{aerials_wonAway}</div>
    </div>
    <div className="row">
    <div class="col">
    <div class="progress progressStats d-flex justify-content-end">
      <div
        className="progress-bar"
        style={{
          width: `${(aerials_wonHome / (aerials_wonHome + aerials_wonAway)) * 100}%`,
          backgroundColor: '#216ff5',
        }}
      ></div>
      </div>
      </div>
  <div class="col">
  <div class="progress progressStats">
      <div
        className="progress-bar"
        style={{
          width: `${(aerials_wonAway / (aerials_wonHome + aerials_wonAway)) * 100}%`,
          backgroundColor: '#001e28',
        }}
      >
      </div>
      </div>
      </div>
  </div>
  </div>
  <div className="mb-3">
    <div className="row">
      <div className="col-sm-5 col-2">{tacklesHome}</div>
      <div className="col-sm-2 col-8 text-nowrap text-center">Tacles</div>
      <div className="col-sm-5 col-2 text-end">{tacklesAway}</div>
    </div>
    <div className="row">
    <div class="col">
    <div class="progress progressStats d-flex justify-content-end">
      <div
        className="progress-bar"
        style={{
          width: `${(tacklesHome / (tacklesHome + tacklesAway)) * 100}%`,
          backgroundColor: '#216ff5',
        }}
      ></div>
      </div>
      </div>
  <div class="col">
  <div class="progress progressStats">
      <div
        className="progress-bar"
        style={{
          width: `${(tacklesAway / (tacklesHome + tacklesAway)) * 100}%`,
          backgroundColor: '#001e28',
        }}
      >
      </div>
      </div>
      </div>
  </div>
  </div>
  <div className="mb-3">
    <div className="row">
      <div className="col-sm-5 col-2">{interceptionsHome}</div>
      <div className="col-sm-2 col-8 text-nowrap text-center">Interceptions </div>
      <div className="col-sm-5 col-2 text-end">{interceptionsAway}</div>
    </div>
    <div className="row">
    <div class="col">
    <div class=" progress progressStats d-flex justify-content-end">
      <div
        className="progress-bar"
        style={{
          width: `${(interceptionsHome / (interceptionsHome + interceptionsAway)) * 100}%`,
          backgroundColor: '#216ff5',
        }}
      ></div>
      </div>
      </div>
  <div class="col">
  <div class=" progress progressStats">
      <div
        className="progress-bar"
        style={{
          width: `${(interceptionsAway / (interceptionsHome + interceptionsAway)) * 100}%`,
          backgroundColor: '#001e28',
        }}
      >
      </div>
      </div>
      </div>
  </div>
  </div>
  <div className="mb-3">
    <div className="row">
      <div className="col-sm-5 col-2">{clearancesHome}</div>
      <div className="col-sm-2 col-8 text-nowrap text-center">Dégagements</div>
      <div className="col-sm-5 col-2 text-end">{clearancesAway}</div>
    </div>
    <div className="row">
    <div class="col">
    <div class=" progress progressStats d-flex justify-content-end">
      <div
        className="progress-bar"
        style={{
          width: `${(clearancesHome / (clearancesHome + clearancesAway)) * 100}%`,
          backgroundColor: '#216ff5',
        }}
      ></div>
      </div>
      </div>
  <div class="col">
  <div class="progress progressStats">
      <div
        className="progress-bar"
        style={{
          width: `${(clearancesAway / (clearancesHome + clearancesAway)) * 100}%`,
          backgroundColor: '#001e28',
        }}
      >
      </div>
      </div>
      </div>
  </div>
  </div>
    </>
  );
};
export default StatsOfMatchsSimpleUser;
