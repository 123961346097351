import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import EmptyData from "../../components/emptyData";
import Select from "react-select";
import TeamLiked from "../../components/Teams/TeamLiked";
import DisplayLikedCompetitions from "../../components/competitions/displayLikedCompetitions";
import Ads from "./Ads";
import PlayersLiked from "../players/PlayerLiked";
import NewsLists from "../../components/news/NewsLists";
import useAlert from "../../hooks/alertHook";
import { logError } from "../../components/Toastconfig";
import Loader from "../../components/loader";
import { useHasPermission } from "../../hooks/checkPermissions";
import LoaderLoading from "../../components/LoaderLoading";

const Players_Of_SimpleUser = () => {
  const { checkPermissions } = useHasPermission();
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "rgba(223, 223, 223, 0.59);",
        color: "#5d7079",
      },
    },
  };
  const { alertModal, displayAlert, dismissAlert } = useAlert();
  const [searchTermGeneration, setsearchTermGeneration] = useState("");
  const [searchTermunder, setsearchTermunder] = useState("Tous");
  const [searchTermPosition, setsearchPosition] = useState("Tous");
  const [showLikedPlayers, setShowLikedPlayers] = useState(false);
  const [data, setData] = useState();
  const [players, setPlayers] = useState([]);
  const [filtredplayers, setFiltredplayers] = React.useState(null);

  const baseURL = process.env.REACT_APP_BASE_URL;
  const dataPos = {
    GoalKeeper: "GK",
    Back_Right: "AD",
    Back_left: "AG",
    Libero_Left: "LG",
    Libero_Right: "LD",
    defensive_midfielder: "MDF",
    Right_Offensive_midfielder: "MOD",
    Left_Offensive_midfielder: "MOG",
    Right_Winger: "AID",
    Left_Winger: "AIG",
    Center_forward: "AV",
  };
  const [isLiked, setIsLiked] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  function getFavorisPlayerList() {
    axios
      .get(`${baseURL}/players/getAllPlayersLiked`)
      .then((response) => {
        setLikedPlayers(response.data.data);
      })
      .catch((error) => logError());
  }
  useEffect(() => {
    getFavorisPlayerList();
  }, []);
  async function getAllPlayers() {
    setIsLoading(true);
    axios
      .get(`${baseURL}/players/getAllPlayersSimpleUser`)
      .then((response) => {
        if (response.data.error) return;
        const filteredPlayers = response.data.data;
        setPlayers(filteredPlayers);
        setFiltredplayers(filteredPlayers);
      })
      .catch((err) => {
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false); // set isLoading to false after receiving a response or an error
      });
  }

  useEffect(() => {
    if (filtredplayers == null) return;
    if (isLoading) {
      setData(<LoaderLoading />);
    } else if (filtredplayers.length > 0) {
      setData(
        <DataTable
          columns={columns}
          data={filtredplayers}
          customStyles={customStyles}
        />
      );
    } else {
      setData(<EmptyData />);
    }
  }, [isLoading, filtredplayers]);

  useEffect(() => {
    setData(<Loader />);

    getAllPlayers();
  }, []);
  async function likeDislike(id, isLiked) {
    let message =
      isLiked == "yes"
        ? "voulez vous supprimer ce joueur de la liste des favories?"
        : "voulez vous ajouter ce joueur à la liste des favories";
    let status = isLiked == "yes" ? "danger" : "info";
    displayAlert(
      status,
      "",
      message,
      "Confirmer",
      () => {
        axios
          .post(`${baseURL}/players/like`, {
            playerId: id,
          })
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getAllPlayers();
              getFavorisPlayerList();
              dismissAlert();
            }
          });
      },
      "cancel",
      dismissAlert
    );
  }

  const columns = [
    {
      name: "",
      width: "6%",
      style: {
        padding: 0,
      },
      cell: (row) => (
        <div className="row">
          <div className="text-center text-center">
            <button
              className="btn text-primary"
              onClick={() => {
                likeDislike(row.id, row.isLiked);
              }}
            >
              {row.isLiked == "yes" ? (
                <i className="fa-solid fa-heart like_icon"></i>
              ) : (
                <i className="fa-regular fa-heart dislike_icon "></i>
              )}
            </button>
          </div>
        </div>
      ),
    },
    {
      name: "Nom et Prénom",
      cell: (row) => (
        <div className="text-nowrap ">
          <Link
            style={{ textDecoration: "none" }}
            to={`/player/details/${row.id}`}
          >
            <img
              src={`${baseURL + "/players/getFile" + row.avatar}`}
              className="rounded-circle"
              width="35"
              height="35"
            />
            <span className="ps-2 playerName">
              {`${row.lastName} ${row.firstName}`.slice(0, 8)}
              {`${row.lastName} ${row.firstName}`.length > 8 && ".."}{" "}
            </span>
          </Link>
        </div>
      ),
      width: "21%",
    },

    {
      name: "Pays",
      cell: (row) => (
        <div className="text-nowrap ">
          {`${row.countryname} `.slice(0, 7)}
          {`${row.countryname} `.length > 7 && "..."}
        </div>
      ),
      width: "11%",
    },
    {
      name: "Equipe",
      cell: (row) => (
        <div className="text-nowrap ">
          {row.currentTeam === null ? (
            <div className="d-flex align-items-center">
              <img src="./assets/imgs/emptyTeam.png" width="35" height="35" />
              <span>No available </span>
            </div>
          ) : (
            <Link
              className="style_text_deco"
              to={`/oneTeam/${row.currentTeam?.id}`}
            >
              <img
                className="pe-1"
                src={`${
                  baseURL + "/team/getFile" + row.currentTeam?.logo.substring(1)
                }`}
                width="35"
                height="35"
              />{" "}
              <span className="currentTeamName">
                {`${row.currentTeam?.name}`.slice(0, 20)}
                {`${row.currentTeam?.name}`.length > 20 && "..."}
              </span>
            </Link>
          )}
        </div>
      ),
      width: "28%",
    },
    {
      name: "Génerations",
      cell: (row) => (
        <div className="text-nowrap ">
          {`${row.birthday?.slice(0, 4)} `.slice(0, 7)}
          {`${row.birthday?.slice(0, 4)} `.length > 7 && "..."}
        </div>
      ),
      width: "9%",
    },
    {
      name: "Positions",
      cell: (row) => (
        <>
          {row.positions && (
            <div className="text-nowrap">
              {JSON.parse(row.positions)
                .slice(0, 2)
                .map((item, key) => (
                  <span className="badge bg-secondary me-1" key={key}>
                    {dataPos[item]}
                  </span>
                ))}
              {JSON.parse(row.positions).length > 2 && ".."}
            </div>
          )}
        </>
      ),
      width: "15%",
    },
    {
      name: "Note",
      width: "14%",
      cell: (row) => (
        <>
          {row.rate > 0 && row.rate <= 2 ? (
            <div className="rate  badge px-2 text-center rateListPlayer_red   ">
              {row.rate}
            </div>
          ) : row.rate > 2 && row.rate <= 4 ? (
            <div className=" rate  badge px-2 text-center rateListPlayer_orange ">
              {row.rate}
            </div>
          ) : row.rate > 4 && row.rate <= 6 ? (
            <div className=" rate  badge px-2 text-center rateListPlayer_yellow  ">
              {row.rate}
            </div>
          ) : row.rate > 6 && row.rate <= 8 ? (
            <div className=" rate  badge px-2 text-center rateListPlayer_green  ">
              {row.rate}
            </div>
          ) : row.rate > 8 && row.rate <= 10 ? (
            <div className=" rate  badge px-2 text-center rateListPlayer_blue  ">
              {row.rate}
            </div>
          ) : (
            <div>-</div>
          )}
        </>
      ),
    },
  ];
  const UnderAge = [
    { value: "Tous", label: "Tous" },
    { value: 23, label: "U23" },
    { value: 22, label: "U22" },
    { value: 21, label: "U21" },
    { value: 20, label: "U20" },
    { value: 19, label: "U19" },
    { value: 18, label: "U18" },
    { value: 17, label: "U17" },
    { value: 16, label: "U16" },
    { value: 15, label: "U15" },
  ];
  const PositionsOptions = [
    { value: "Tous", label: "Tous" },
    { value: "GoalKeeper", label: "GK (Gardient)" },
    { value: "Back_Right", label: "AD (Arriére Droit)" },
    { value: "Back_left", label: "AG (Arriére Gauche)" },
    { value: "defensive_midfielder", label: "MDF (Milieu défensif)" },
    {
      value: "Right_Offensive_midfielder",
      label: "MOD (Milieu offensif droit)",
    },
    {
      value: "Left_Offensive_midfielder",
      label: "MOG (Milieu offensif gauche)",
    },
    { value: "Right_Winger", label: "AID (Ailier droit)" },
    { value: "Left_Winger", label: "AIG (Ailier gauche)" },
    { value: "center_forward", label: "AV (Avant centre)" },
    { value: "Libero_Right", label: "LD (Libéro droit)" },
    { value: "Libero_Left", label: "LG (Libéro gauche)" },
  ];


  function filterCompetition() {
    const items = players;
    const results = items
      .filter(
        (item) =>
          item.birthday &&
          item.birthday
            .toLowerCase()
            .slice(0, 4)
            .includes(searchTermGeneration.toLowerCase())
      )
      .filter((val) => {
        if (!showLikedPlayers) {
          return val;
        } else {
          return val.isLiked === "yes";
        }
      })
      .filter((player) => {
        if (searchTermPosition === "Tous") {
          return player;
        } else {
          return (
            player.positions &&
            JSON.parse(player.positions.toLowerCase()).includes(
              searchTermPosition.toLowerCase()
            )
          );
        }
      })
      .filter((agePlayer) => {
        const currentYear = new Date().getFullYear();
        const age =
          currentYear - Number(agePlayer.birthday?.toLowerCase().slice(0, 4));
        if (searchTermunder === "Tous") {
          return agePlayer;
        } else if (searchTermunder >= age) {
          return agePlayer;
        }
      });
    setFiltredplayers(results);
  }
  const [likedPlayers, setLikedPlayers] = useState([]);
  const [competitionLiked, setCompetitionLiked] = useState([]);
  const [teamIsLiked, setteamIsLiked] = useState([]);
  const [newslist, setNewslist] = useState([]);

  function getFavorisPlayerList() {
    if (checkPermissions("players")) {
      axios
        .get(`${baseURL}/players/getAllPlayersLiked`)
        .then((response) => {
          setLikedPlayers(response.data.data);
        })
        .catch((error) => logError());
    }
  }

  function getLiked() {
    if (checkPermissions("competitions")) {
      axios
        .get(`${baseURL}/competitions/getLiked`)
        .then((res) => {
          setCompetitionLiked(res.data.data);
        })
        .catch((err) => {
          logError();
        });
    }
  }

  function getteamIsLiked() {
    if (checkPermissions("teams")) {
      axios
        .get(`${baseURL}/teams/GetLikesbyUserId`)
        .then((res) => {
          setteamIsLiked(res.data.data);
        })
        .catch((err) => {});
    }
  }

  useEffect(() => {
    getteamIsLiked();
    getLiked();
    getFavorisPlayerList();
  }, []);
  useEffect(() => {
    filterCompetition();
  }, [
    searchTermGeneration,
    searchTermPosition,
    showLikedPlayers,
    searchTermunder,
  ]);
  const [adsplayers, setAdsPlayers] = useState([]);

  function getall() {
    axios
      .get(`${baseURL}/players/getPlayersBoosted`)
      .then((res) => {
        const boostedPlayers = res.data;
        const playerDataArray = Object.values(boostedPlayers)
          .filter((playerData) => {
            return playerData.status !== "canceled"&& playerData.status !== "expired";
          })
          .map((playerData) => {
            return playerData.player;
          });
        setAdsPlayers(playerDataArray);
      })
      .catch((err) => {
        logError();
      });
  }
  useEffect(function () {
    getall();
  }, []);

  function getallnews() {
    if (checkPermissions("news")) {
      setIsLoading(true); // set isLoading to true when the request is made

      axios
        .get(`${baseURL}/news/allNews`)
        .then((res) => {
          setNewslist(res.data.data);
        })
        .catch((err) => {
          logError();
        })
        .finally(() => {
          setIsLoading(false); // set isLoading to false when the request is complete (whether it succeeded or failed)
        });
    }
  }

  useEffect(function () {
    getallnews();
  }, []);
  return (
    <>
      <div>
        <section className="pt-3">
          <section className="">
            <div className="row p-0 m-0">
              {teamIsLiked?.length === 0 &&
              competitionLiked?.length === 0 &&
              likedPlayers?.length === 0 ? (
                <div className="col-lg-12  ">
                  <div className="row">
                    {adsplayers?.length === 0 && newslist?.length > 0 ? (
                      <div className="col-lg-11 ms-3 pt-2 pt-lg-0 pe-3">
                        <NewsLists
                          newslist={newslist}
                          isLoading={isLoading}
                          boostExist={false}
                        />
                      </div>
                    ) : adsplayers?.length > 0 && newslist?.length === 0 ? (
                      <>
                        <div className="col-lg-11">
                          <Ads adsplayers={adsplayers} />
                        </div>
                      </>
                    ) : adsplayers?.length === 0 && newslist?.length === 0 ? (
                      ""
                    ) : (
                      <>
                        <div className="col-lg-9">
                          <Ads adsplayers={adsplayers} />
                        </div>
                        <div className="col-lg-3 pt-2 pt-lg-0 pe-3">
                          <NewsLists
                            newslist={newslist}
                            isLoading={isLoading}
                            boostExist={true}
                          />
                        </div>
                      </>
                    )}

                    <div className="card-body  pt-3">
                      <div className="row px-lg-3 px-5 ">
                        <div className="col-lg-3 ">
                          <label className="text-muted fw-bolder">
                            <small>Génerations</small>
                          </label>
                          <input
                            type="text"
                            onChange={(e) =>
                              setsearchTermGeneration(e.target.value)
                            }
                            className="form-control"
                            placeholder="Génerations"
                          />
                        </div>
                        <div className="col-lg-2  ">
                          <label className="text-muted fw-bolder">
                            <small>Positions</small>
                          </label>
                          <Select
                            options={PositionsOptions}
                            isSearchable
                            defaultValue={{ value: "Tous", label: "Tous" }}
                            onChange={(e) => setsearchPosition(e.value)}
                            placeholder="Positions"
                          />
                        </div>
                        <div className="col-lg-2  ">
                          <label className="text-muted fw-bolder">
                            <small>Catégories</small>
                          </label>
                          <Select
                            options={UnderAge}
                            isSearchable
                            defaultValue={{ value: "Tous", label: "Tous" }}
                            onChange={(e) => setsearchTermunder(e.value)}
                            placeholder="UnderAge"
                          />
                        </div>
                        <div className="col-lg-5  ">
                          <div className="row">
                            <div className="col-lg-6  ">
                              <div className=" toggle_btn form-check form-switch mb-3 d-md-flex flex-md-row-reverse mt-4  px-md-0">
                                <label className="  form-check-label  text-lg-end text-muted">
                                  {showLikedPlayers
                                    ? "Les joueurs favoris"
                                    : "Les joueurs favoris"}
                                </label>
                                <input
                                  className=" form-check-input me-1"
                                  type="checkbox"
                                  id="toggleSwitch"
                                  checked={showLikedPlayers}
                                  onChange={() =>
                                    setShowLikedPlayers(!showLikedPlayers)
                                  }
                                />
                              </div>
                            </div>
                            <div className="col-lg-6  ">
                              <div className="mt-4 mb-2 ">
                                <Link to="/players/search">
                                  <button
                                    type="button"
                                    className=" btn btn-sm btn-primary  px-3 "
                                  >
                                    <i className="fa-solid fa-magnifying-glass"></i>{" "}
                                    Recherche avancé
                                  </button>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=" data_table ">{data} </div>
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="col-lg-3 ">
                    <TeamLiked teamIsLiked={teamIsLiked} />
                    <div className="mt-3">
                      <DisplayLikedCompetitions
                        competitionLiked={competitionLiked}
                      />
                    </div>
                    <div className="mt-3 mb-3">
                      <PlayersLiked likedPlayers={likedPlayers} />
                    </div>
                  </div>
                  <div className="col-lg-9 ">
                    <div className="row">
                      {adsplayers?.length === 0 && newslist?.length > 0 ? (
                        <div className="col-lg-11 ms-3 pt-2 pt-lg-0 pe-3 mt-lg-0 mt-2">
                          <NewsLists
                            isLoading={isLoading}
                            newslist={newslist}
                            boostExist={false}
                          />
                        </div>
                      ) : adsplayers?.length > 0 && newslist?.length === 0 ? (
                        <>
                          <div className="col-lg-11">
                            <Ads adsplayers={adsplayers} />
                          </div>
                        </>
                      ) : adsplayers?.length === 0 && newslist?.length === 0 ? (
                        ""
                      ) : (
                        <>
                          <div className="col-lg-9">
                            <Ads adsplayers={adsplayers} />
                          </div>
                          <div className="col-lg-3 pt-2 pt-lg-0 pe-3">
                            <NewsLists
                              newslist={newslist}
                              isLoading={isLoading}
                              boostExist={true}
                            />
                          </div>
                        </>
                      )}
                    </div>

                    <div className="card-body  pt-3">
                      <div className="row px-lg-3 px-5 me-3 ">
                        <div className="col-lg-3  ">
                          <label className="text-muted fw-bolder">
                            <small>Génerations</small>
                          </label>
                          <input
                            type="text"
                            onChange={(e) =>
                              setsearchTermGeneration(e.target.value)
                            }
                            className="form-control"
                            placeholder="Génerations"
                          />
                        </div>
                        <div className="col-lg-3  ">
                          <label className="text-muted fw-bolder">
                            <small>Positions</small>
                          </label>
                          <Select
                            options={PositionsOptions}
                            isSearchable
                            defaultValue={{ value: "Tous", label: "Tous" }}
                            onChange={(e) => setsearchPosition(e.value)}
                            placeholder="Positions"
                          />
                        </div>
                        <div className="col-lg-3  ">
                          <label className="text-muted fw-bolder">
                            <small>Catégories</small>
                          </label>
                          <Select
                            options={UnderAge}
                            isSearchable
                            defaultValue={{ value: "Tous", label: "Tous" }}
                            onChange={(e) => setsearchTermunder(e.value)}
                            placeholder="UnderAge"
                          />
                        </div>
                        <div className="col-lg-3  ">
                          <div className=" toggle_btn form-check form-switch mb-3 d-md-flex flex-md-row-reverse mt-4  px-md-0">
                            <label className="  form-check-label  text-lg-end text-muted">
                              {showLikedPlayers
                                ? "Les joueurs favoris"
                                : "Les joueurs favoris"}
                            </label>
                            <input
                              className=" form-check-input me-1"
                              type="checkbox"
                              id="toggleSwitch"
                              checked={showLikedPlayers}
                              onChange={() =>
                                setShowLikedPlayers(!showLikedPlayers)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row px-lg-3 px-5 me-3 d-flex justify-content-end ">
                        <div className="col-lg-3 d-inline-flex">
                          <div className="mb-2">
                            <Link to="/players/search">
                              <button
                                type="button"
                                className=" btn btn-sm btn-primary px-3 "
                              >
                                <i className="fa-solid fa-magnifying-glass"></i>{" "}
                                Recherche avancé
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className=" data_table ">{data} </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </section>
        </section>
      </div>
      {alertModal}
    </>
  );
};

export default Players_Of_SimpleUser;
