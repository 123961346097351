import React from "react";
import { useState, useEffect } from "react";
import { Carousel, Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoaderLoading from "../../components/LoaderLoading";

const Ads = ({ adsplayers }) => {
  const baseURL = process.env.REACT_APP_BASE_URL;

  const dataPos = {
    GoalKeeper: "GK",
    Back_Right: "AD",
    Back_left: "AG",
    Libero_Left: "LG",
    Libero_Right: "LD",
    defensive_midfielder: "MDF",
    Right_Offensive_midfielder: "MOD",
    Left_Offensive_midfielder: "MOG",
    Right_Winger: "AID",
    Left_Winger: "AIG",
    Center_forward: "AV",
  };
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, [adsplayers]);

  return (
    <Container className="px-2">
      {isLoading ? (
        <LoaderLoading />
      ) : (
        <Carousel>
          {adsplayers?.map((player, key) => (
            <Carousel.Item key={key}>
              <Card className="style_ads">
                <Card.Body>
                  <Row>
                    <Col md={3} className="text-center mt-2">
                      <h5 className="text-white  text-nowrap fw-bold ">
                      BRILLANT
                      </h5>
                      <h5 className="text-white text-nowrap fw-bold">
                       CETTE 
                      </h5>
                      <h5 className="text-white text-nowrap fw-bold">
                       SEMAINE !
                      </h5>
                    </Col>
                    <Col md={5} className="text-center">
                      <h5 className="text-white text-start">
                        {player.firstName} {player.lastName}
                        {player.currentTeam === null ? (
                          <img
                            className="ms-2"
                            src="./assets/imgs/emptyTeam.png"
                            width="35"
                            height="35"
                          />
                        ) : (
                          <img
                            className="ms-2"
                            src={`${
                              baseURL +
                              "/team/getFile" +
                              player.CurrentTeam?.logo.substring(1)
                            }`}
                            width="37"
                            height="37"
                          />
                        )}
                      </h5>
                      <div className="row mt-2">
                        <div className="text-start">
                          <h6 className="text-white fs-6">
                            Equipe :
                            {player.currentTeam === null ? (
                              <span className="ms-2">No available Team</span>
                            ) : (
                              <span className="fw-bold ms-2">
                                {player.CurrentTeam?.name}
                              </span>
                            )}
                          </h6>
                          <h6 className="text-white fs-6">
                            Positions :
                            <span className="fw-bold">
                              {player.positions &&
                                JSON.parse(player.positions).map(
                                  (item, key) => {
                                    return (
                                      <span
                                        className="badge bg-secondary  ms-2"
                                        key={key}
                                      >
                                        {dataPos[item]}
                                      </span>
                                    );
                                  }
                                )}
                            </span>
                          </h6>
                          <h6 className="text-white fs-6 ">
                          Date de naissance :
                            <span className="fw-bold ms-2">
                              {player.birthday}
                            </span>
                          </h6>

                          <h6 className="text-white fs-6 d-flex ">
                            Note :
                            {player.rate <= 2 ? (
                              <span className=" rate ps-lg-3 badge text-center d-flex justify-content-center  py-1 rate_player_red  ms-1">
                                {player.rate}
                              </span>
                            ) : player.rate > 2 && player.rate <= 4 ? (
                              <span className="ms-2 fw-bold rate ps-lg-3 badge d-flex justify-content-center py-1  rate_player_orange ms-1">
                                {player.rate}
                              </span>
                            ) : player.rate > 4 && player.rate <= 6 ? (
                              <span className="ms-2 fw-bold rate ps-lg-3 badge d-flex justify-content-center py-1 rate_player_yellow ms-1">
                                {player.rate}
                              </span>
                            ) : player.rate > 6 && player.rate <= 8 ? (
                              <span className="ms-2 fw-bold rate ps-lg-3 badge d-flex justify-content-center py-1 rate_player_green ms-1">
                                {player.rate}
                              </span>
                            ) : (
                              <span className="ms-2 fw-bold rate ps-lg-3 badge d-flex justify-content-center py-1 rate_player_blue ms-1">
                                {player.rate}
                              </span>
                            )}
                          </h6>
                        </div>
                      </div>
                    </Col>
                    <Col md={4} className="text-center">
                      <div>
                        <img
                          src={`${
                            baseURL +
                            "/players/getFile" +
                            player.avatar.substring(1)
                          }`}
                          className="imgADS"
                        />
                      </div>
                      <div className="pt-3">
                        <Link to={`/player/details/${player.id}`}>
                          <button
                            type="button"
                            className=" style_button_decouvrir"
                          >
                            Découvrir
                            <i className="fa-solid fa-arrow-right"></i>
                          </button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </Container>
  );
};
export default Ads;
