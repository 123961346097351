import Select from "react-select";
import React, { useEffect, useState } from "react";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "./player.css";
import { useNavigate } from "react-router-dom";
import useAlert from "../../hooks/alertHook";
import { Link } from "react-router-dom";

const initFormErrors = {
  file: [""],
  firstName: [""],
  lastName: [""],
  email: [""],
  phone: [""],
  birthday: [""],
  adresse: [""],
  countryId: [""],
  national_team: [""],
  isPartner: [""],
  positions: [""],
  date_partner_debut: [""],
  date_partner_fin: [""],
  preferedfoot: [""],
};

const CreatePlayer = () => {
  const positions = {
    gk: false,
    def1: false,
    def2: false,
    def3: false,
    def4: false,
    md1: false,
    mod: false,
    mog: false,
    aig: false,
    aid: false,
    av: false,
  };
  const [formerrors, setFormErrors] = React.useState(initFormErrors);
  const uploadedImage = React.useRef(null);
  const imageUploader = React.useRef(null);
  const [firstName, setFirstname] = useState("");
  const [lastName, setLaststname] = useState("");
  const [birthday, setBirthday] = useState(
    ""
    // new Date().toISOString().substr(0, 10)
  );
  const [adresse, setAdresse] = useState("");
  const [country, setCountry] = React.useState("");
  const [countryId, setCountryId] = React.useState("");
  const [nat_team, setNat_team] = useState("");
  const [isPartner, setIsPartner] = useState("no");
  const [avatar, setAvatar] = useState("");
  const [position, setPosition] = useState([]);
  const [preferedfoot, setpreferedfoot] = useState("");
  const [date_partner_debut, setDate_partner_debut] = useState( new Date().toISOString().substr(0, 10));
  const [date_partner_fin, setDate_partner_fin] = useState( new Date().toISOString().substr(0, 10));
  const navigate = useNavigate();
  const [selected, setSelected] = useState("no");
  const [counter, setCounter] = useState(0);
  const [counterstep2, setCounterStep2] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const [isCompletestep2, setIsCompleteStep2] = useState(false);
  const [isCheckedGK, setIscheckedGK] = useState(false);
  const [isCheckeddef1, setIscheckeddef1] = useState(false);
  const [isCheckeddef2, setIscheckeddef2] = useState(false);
  const [isCheckedard, setIscheckedard] = useState(false);
  const [isCheckedarg, setIscheckedarg] = useState(false);
  const [isCheckedmd, setIscheckedmd] = useState(false);
  const [isCheckedmod, setIscheckedmod] = useState(false);
  const [isCheckedmog, setIscheckedmog] = useState(false);
  const [isCheckedaid, setIscheckedaid] = useState(false);
  const [isCheckedaig, setIscheckedaig] = useState(false);
  const [isCheckedav, setIscheckedav] = useState(false);
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { alertModal, displayAlert, dismissAlert } = useAlert();


  const changeHandler = (e) => {
    setSelected(e.target.value);
  };
  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };
  const PositionsOptions = [
    { value: "Goal keeper", label: "Gardient" },
    { value: "Full-back", label: "Arriére latéral" },
    { value: "Libero", label: "Libéro" },
    { value: "Defensive midfielder", label: "milieu défensif" },
    { value: "Attacking midfielder", label: "milieu offensif" },
    { value: "Seconde attacker", label: "2éme attaquant" },
    { value: "Center forward", label: "Avant centre" },
  ];
  const footOptions = [
    { value: "left", label: "gauche" },
    { value: "both", label: "les deux" },
    { value: "right", label: "droite" },
  ];

  const [countries, setCountries] = React.useState([]);
  function getCountries() {
    axios.get(`${baseURL}/countries/allCountries`).then((resp) => {
      if (resp.data.error) return;
      resp.data.data.map((item, index) => {
        return setCountries((prevState) => [
          ...prevState,
          { value: item.id, label: item.name },
        ]);
      });
    });
  }

  const CheckHandler = (e) => {
    const value = e.target.value;
    setPosition((prev) =>
      position.includes(value)
        ? prev.filter((cur) => cur !== value)
        : [...prev, e.target.value]
    );
  };

  function sendData() {
    const formData = new FormData();
    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("birthday", birthday);
    formData.append("isPartner", isPartner);
    formData.append("positions", JSON.stringify(position));
    formData.append("preferedfoot", preferedfoot);
    formData.append("adresse", adresse);
    formData.append("avatar", avatar);
    formData.append("national_team", nat_team);
    formData.append("countryId", countryId);
    if (isPartner === "yes") {
      formData.append("date_partner_debut", date_partner_debut);
      formData.append("date_partner_fin", date_partner_fin);
    }

    axios
      .post(`${baseURL}/players/create`, formData)
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        displayAlert("success","",resp.data.message);
        setTimeout(() => {
          dismissAlert();
          navigate("/admin/players");
       },2500);
      })
      .catch((error) => {
        setFormErrors(error.response.data);
      });
  }

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    checkPersonalFilled();
  }, [adresse, birthday]);

  useEffect(() => {
    checkPersonalFilledstep2();
  }, [country, nat_team,preferedfoot]);

  function checkPersonalFilled() {
    let totalFilled = 0;

    if (adresse !== "") {
      totalFilled += 1;
    }
    if (birthday.length > 0) {
      totalFilled += 1;
    }

    if (totalFilled < 2) {
      setIsComplete(false);
    }
    if (totalFilled === 2) {
      setIsComplete(true);
    }
    setCounter(totalFilled);
  }
  function checkPersonalFilledstep2() {
    let totalFilledStep2 = 0;
    if (preferedfoot !== "") {
      totalFilledStep2 += 1;
    }
    if (country !== "") {
      totalFilledStep2 += 1;
    }
    if (nat_team !== "") {
      totalFilledStep2 += 1;
    }

    if (totalFilledStep2 < 3) {
      setIsCompleteStep2(false);
    }
    if (totalFilledStep2 === 3) {
      setIsCompleteStep2(true);
    }
    setCounterStep2(totalFilledStep2);
  }

  return (
    <>
      <section className="p-lg-4 p-0">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-lg-2 col-sm-12 mb-3">
              <div>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => {
                    handleImageUpload(e);
                    setAvatar(e.target.files[0]);
                  }}
                  ref={imageUploader}
                  style={{
                    display: "none",
                  }}
                />
                <button className="btncreatePlayer">
                  <div
                    className="nophoto border"
                    onClick={() => imageUploader.current.click()}
                  >
                    <img className="uploadImgPlayer"
                      ref={uploadedImage}
                    />
                  </div>
                </button>
              </div>
              <div className="input-error">
                <span className="text-danger">
                  {formerrors.file}
                </span>
              </div>
            </div>
            <div className="col-lg-7 col-sm-12 mb-3">
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <input
                    type="text"
                    className="form-control mb-2"
                    id=""
                    placeholder="Nom du joueur"
                    required
                    onChange={(e) => {
                      setLaststname(e.target.value);
                    }}
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.lastName} </span>
                  </div>
                  <input
                    type="text"
                    className="form-control mb-2"
                    id=""
                    placeholder="Prénom du joueur"
                    required
                    onChange={(e) => {
                      setFirstname(e.target.value);
                    }}
                  />
                  <div className="input-error">
                    <span className="text-danger">{formerrors.firstName} </span>
                  </div>
                  <label className="fw-bold">Partner : </label>
                  <div className="form-check form-check-inline mt-4 ">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio1"
                      value="no"
                      checked={selected === "no"}
                      onChange={(e) => {
                        changeHandler(e);
                        setIsPartner(e.target.value);
                      }}
                    />
                    <label htmlFor="no">Non</label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="inlineRadio2"
                      value="yes"
                      checked={selected === "yes"}
                      onChange={(e) => {
                        changeHandler(e);
                        setIsPartner(e.target.value);
                      }}
                    />
                    <label htmlFor="yes">Oui</label>
                  </div>
                  <div className="input-error">
                    <span className="text-danger">{formerrors.isPartner}</span>
                  </div>
                  <div aria-hiddenPlayer={selected !== "yes" ? true : false}>
                    <div className="row mt-4">
                      <div className="col-lg-6">
                        <label className="fw-bold mb-2">
                          Date de début parteneriat:
                        </label>
                        <input
                          type="date"
                          className="form-control mb-2"
                          placeholder="Date début"
                          required
                          onChange={(e) => {
                            setDate_partner_debut(e.target.value);
                          }}
                        value={date_partner_debut}

                        />
                          <div className="input-error">
                    <span className="text-danger">{formerrors.date_partner_debut} </span>
                  </div>
                      </div>
                      <div className="col-lg-6">
                        {" "}
                        <label className="fw-bold mb-2">
                          Date de fin parteneriat :
                        </label>
                        <input
                          type="date"
                          className="form-control mb-2"
                          placeholder="Date fin"
                          required
                          onChange={(e) => {
                            setDate_partner_fin(e.target.value);
                          }}
                         value={date_partner_fin}
                        />
                        <div className="input-error">
                    <span className="text-danger">{formerrors.date_partner_fin} </span>
                  </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row d-lg-flex d-sm-none compo_display">
                <div className="col-lg-12 bg_stadium mb-3 mt-3">
                  <div className="row pt-2">
                    <div className="col-lg-1"></div>
                    <div className="col-lg-2 mt-3 ms-5">
                      <i
                        className={`${
                          isCheckedarg ? "color_blue" : "text-white"
                        }  style_t-shirt_icon fa-solid fa-shirt mt-5`}
                      ></i>
                    </div>
                    <div className="col-lg-4 mt-4">
                      <i
                        className={`${
                          isCheckedmog ? "color_blue" : "text-white"
                        }  style_t-shirt_icon fa-solid fa-shirt mt-5 float-end`}
                      ></i>
                    </div>
                    <div className="col-lg-3">
                      <i
                        className={`${
                          isCheckedaig ? "color_blue" : "text-white"
                        }  style_t-shirt_icon fa-solid fa-shirt mt-5 float-end`}
                      ></i>
                    </div>
                  </div>
                  <div className="row" style={{ paddingTop: "5vh" }}>
                    <div className="col-lg-1">
                      <i
                        className={`${
                          isCheckedGK ? "color_blue" : "text-white"
                        }  style_t-shirt_icon fa-solid fa-shirt mt-4`}
                      ></i>
                    </div>
                    <div className="col-lg-2">
                      <i
                        className={`${
                          isCheckeddef1 ? "color_blue" : "text-white"
                        }  style_t-shirt_icon fa-solid fa-shirt  mb-2 ms-5 mt-2`}
                      ></i>
                      <i
                        className={`${
                          isCheckeddef2 ? "color_blue" : "text-white"
                        }  style_t-shirt_icon fa-solid fa-shirt   ms-5 `}
                      ></i>
                    </div>
                    <div className="col-lg-4">
                      <i
                        className={`${
                          isCheckedmd ? "color_blue" : "text-white"
                        }  style_t-shirt_icon fa-solid fa-shirt mt-5  ms-5 `}
                      ></i>
                    </div>
                    <div className="col-lg-4">
                      <i
                        className={`${
                          isCheckedav ? "color_blue" : "text-white"
                        }  style_t-shirt_icon fa-solid fa-shirt mt-5 float-end `}
                      ></i>
                    </div>
                  </div>
                  <div
                    className="row"
                    style={{ paddingBottom: "3vh", paddingTop: "10vh" }}
                  >
                    <div className="col-lg-1"></div>
                    <div className="col-lg-2 mt-2 ms-5">
                      <i
                        className={`${
                          isCheckedard ? "color_blue" : "text-white"
                        }  style_t-shirt_icon fa-solid fa-shirt `}
                      ></i>
                    </div>
                    <div className="col-lg-4">
                      <i
                        className={`${
                          isCheckedmod ? "color_blue" : "text-white"
                        }  style_t-shirt_icon fa-solid fa-shirt float-end`}
                      ></i>
                    </div>
                    <div className="col-lg-3">
                      <i
                        className={`${
                          isCheckedaid ? "color_blue" : "text-white"
                        }  style_t-shirt_icon fa-solid fa-shirt mt-5 float-end`}
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-12  mb-3">
              <div className="row">
                <div className="col-lg-12 d-flex">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={sendData}
                  >
                    Enregistrer
                  </button>
                  <Link
                    to="/admin/players"
                    className="btn btn-outline-secondary mx-3"
                  >
                    Annuler
                </Link>
                </div>
              </div>
              <div className="row">
                <div className="mt-lg-5 mt-sm-0 pt-sm-0 padding_checkbox">
                  <label className="mt-4 mb-3 fw-bold fs-5">
                    Les positions :
                  </label>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="GoalKeeper"
                      onChange={(e) => {
                        setIscheckedGK(!isCheckedGK);
                        CheckHandler(e);
                      }}
                    />
                    <label className="form-check-label">Gardient</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Back_left"
                      onChange={(e) => {
                        setIscheckedarg(!isCheckedarg);
                        CheckHandler(e);
                      }}
                    />
                    <label className="form-check-label">Arriére Gauche</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Back_Right"
                      id="flexCheckChecked"
                      onChange={(e) => {
                        setIscheckedard(!isCheckedard);
                        CheckHandler(e);
                      }}
                    />
                    <label className="form-check-label">Arriére Droite</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Libero_Left"
                      onChange={(e) => {
                        setIscheckeddef1(!isCheckeddef1);
                        CheckHandler(e);
                      }}
                    />
                    <label className="form-check-label">Libéro Gauche</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Libero_Right"
                      id="flexCheckChecked"
                      onChange={(e) => {
                        setIscheckeddef2(!isCheckeddef2);
                        CheckHandler(e);
                      }}
                    />
                    <label className="form-check-label">Libéro Droite</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="defensive_midfielder"
                      id="flexCheckChecked"
                      onChange={(e) => {
                        setIscheckedmd(!isCheckedmd);
                        CheckHandler(e);
                      }}
                    />
                    <label className="form-check-label">Milieu déffensif</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Right_Offensive_midfielder"
                      id="flexCheckChecked"
                      onChange={(e) => {
                        setIscheckedmod(!isCheckedmod);
                        CheckHandler(e);
                      }}
                    />
                    <label className="form-check-label">
                      Milieu Offensif droite
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Left_Offensive_midfielder"
                      id="flexCheckChecked"
                      onChange={(e) => {
                        setIscheckedmog(!isCheckedmog);
                        CheckHandler(e);
                      }}
                    />
                    <label className="form-check-label">
                      Milieu Offensif gauche
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Right_Winger"
                      id="flexCheckChecked"
                      onChange={(e) => {
                        setIscheckedaid(!isCheckedaid);
                        CheckHandler(e);
                      }}
                    />
                    <label className="form-check-label">Ailier droite</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Left_Winger"
                      id="flexCheckChecked"
                      onChange={(e) => {
                        setIscheckedaig(!isCheckedaig);
                        CheckHandler(e);
                      }}
                    />
                    <label className="form-check-label">Ailier gauche</label>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value="Center_forward"
                      id="flexCheckChecked"
                      onChange={(e) => {
                        setIscheckedav(!isCheckedav);
                        CheckHandler(e);
                      }}
                    />
                    <label className="form-check-label">Avant centre</label>
                  </div>
                </div>
                <div className="input-error">
                  <span className="text-danger">{formerrors.positions} </span>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    Informations personnel{" "}
                    <span
                      className={
                        isComplete === false
                          ? "color-red ms-2"
                          : "color-green ms-2"
                      }
                    >
                      {counter}/2
                    </span>
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div className="row">
                      <div className="col-lg-6"></div>
                      <div className="col-lg-6"></div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <label className="fw-bold mb-2">Adresse :</label>
                        <input
                          type="text"
                          className="form-control mb-2"
                          placeholder="Adresse du joueur"
                          required
                          onChange={(e) => {
                            setAdresse(e.target.value);
                          }}
                        />
                        <div className="input-error">
                          <span className="text-danger">
                            {formerrors.adresse}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <label className="fw-bold mb-2">
                          Date de naissance du joueur :
                        </label>
                        <input
                          type="date"
                          className="form-control mb-2"
                          placeholder="Date de naissance du joueur"
                          required
                          onChange={(e) => {
                            setBirthday(e.target.value);
                          }}
                        />
                        <div className="input-error">
                          <span className="text-danger">
                            {formerrors.birthday}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingTwo">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    Information professionnelle
                    <span
                      className={
                        isCompletestep2 === false
                          ? "color-red ms-2"
                          : "color-green ms-2"
                      }
                    >
                      {"  "}
                      {counterstep2}/3
                    </span>
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <div className="row">
                      <div className="col-lg-6">
                        <label className="fw-bold mb-2">Pays :</label>

                        <Select
                          options={countries}
                          isSearchable
                          placeholder="pays du joueur"
                          onChange={(e) => {
                            setCountry(e.value);
                            setCountryId(e.value);
                          }}
                        />
                        <div className="input-error">
                          <span className="text-danger">
                            {formerrors.countryId}
                          </span>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <label className="fw-bold mb-2">
                          Equipe national :
                        </label>
                        <input
                          type="text"
                          className="form-control mb-2"
                          placeholder="Equipe national du joueur"
                          required
                          onChange={(e) => {
                            setNat_team(e.target.value);
                          }}
                        />
                        <div className="input-error">
                          <span className="text-danger">
                            {formerrors.national_team}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <label className="fw-bold mb-2">Pied préféré :</label>
                        <Select
                          options={footOptions}
                          classNamePrefix="select your prefered foot"
                          isSearchable
                          placeholder="Pied préféré du joueur"
                          onChange={(e) => {
                            setpreferedfoot(e.value);
                          }}
                        />
                        <div className="input-error">
                          <span className="text-danger">
                            {formerrors.preferedfoot}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {alertModal}
    </>
  );
};

export default CreatePlayer;
