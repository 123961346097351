import React from "react";
import axios from "axios";
import { logError } from "../Toastconfig";
import useAlert from "../../hooks/alertHook";

export default function DeleteRole({ Role, getallroles }) {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { alertModal, displayAlert, dismissAlert } = useAlert();
  function handletoggle() {
    displayAlert("danger", "", "Voulez-vous supprimer ce rôle ?", "Supprimer", () => {
      axios
      .post(`${baseURL}/roles/delete/${Role.id}`)
      .then((resp) => {
        if (resp.data.error) return;
        displayAlert("sucess","","Role supprimé avec succès");
        setTimeout(() => {
          dismissAlert();
       },2500);
       getallroles();
      })
      .catch((error) => {
        dismissAlert();
        logError();
        displayAlert("danger", "", error.response.data.message);
      });
    }, "cancel", dismissAlert);
  }
  function senData() {
    
  }
  return (
    <div>
      <button className=" btn btn-update">
        <i
          className="fa-solid fa-trash-can red"
          title="Supprimer ce role"
          onClick={handletoggle}
        ></i>
      </button>
      {alertModal}
    </div>
  );
}
