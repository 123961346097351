import React, { useState, useEffect } from "react";
import Table from "react-bootstrap/Table";
import axios from "axios";
import EditRole from "../components/role/EditRole";
import DeleteRole from "../components/role/DeleteRole";
import { logError } from "../components/Toastconfig";
import { Link } from "react-router-dom";
import EmptyData from "../components/emptyData";
import LoaderLoading from "../components/LoaderLoading";

export default function Roles() {
  const [isLoading, setIsLoading] = useState(true);
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [roles, setroles] = useState([]);
  useEffect(() => {
    getallroles();
  }, []);

  function getallroles() {
    axios
      .get(`${baseURL}/roles/`)
      .then((res) => {
        setroles(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        logError();
      });
  }
  return (
   
      <>
        <div className="pt-5 ">
          <div className="row mx-3 ">
            <div className="col-4 ">
              <h4 className="text-nowrap">Les Rôles</h4>
            </div>
            <div className=" col-8 px-4">
              <Link to="/admin/roles/create">
                <button
                  className="btn btn-primary float-end btn-lg button-add"
                  type="button"
                  variant="primary"
                >
                  +
                </button>
              </Link>
            </div>
          </div>
        </div>
         {isLoading ? (
          <LoaderLoading/>
      ) : roles.length === 0 ? (
        <div className="row">
          <div className="col-lg-12">
            <EmptyData />
          </div>
        </div>
      ) : (
        <div className="row mt-4 mx-0 p-scroll">
          <div className="table-responsive ">
            <Table className="table">
              <thead className="style_thead">
                <tr>
                  <th className=" p-2 style_tab style_th">Rôles</th>
                  <th
                    className="p-2 style_tab style_th text-center"
                    style={{ width: "20%" }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <>
                {roles.map((role, index) => (
                  <tbody className="my-4 style_tbody " key={index}>
                    <tr className="border-b style_tbody text-nowrap">
                      <td className="p-2 " style={{ textDecoration: "none",color: "rgb(39 105 199)" }}>{role.name}</td>
                      <td className="p-2 d-flex justify-content-center">
                          <div >
                            {<EditRole Role={role} getallroles={getallroles} />}
                          </div>
                          <div>
                            <DeleteRole Role={role} getallroles={getallroles} />
                          </div>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </>
            </Table>
          </div>
        </div>
      )}
      </>
  );
}
