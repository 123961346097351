import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import { logError } from "../../components/Toastconfig";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import PlayersStatsMatchSimpleUser from "./PlayerStatsMatchSimpleUser";

const PlayersOfMatchesSimpleUser = ({ matchId }) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [playersOfMatchTH, setPlayersTH] = useState([]);
  const [playersOfMatchTA, setPlayersTA] = useState([]);
  const [matche, setMatch] = useState([]);

  const [selectedPlayerId, setSelectedPlayerId] = useState(null);

  function getplayersByMatch() {
    axios
      .get(`${baseURL}/calendarMatches/${matchId}`)
      .then((res) => {
        setPlayersTH(res.data.data.teamHome.players);
        setPlayersTA(res.data.data.teamAway.players);
        setMatch(res.data.data);
      })
      .catch((err) => {
        logError();
      });
  }
  useEffect(() => {
    getplayersByMatch();
  }, []);

  const [isSideBarOpen, setIsSideBarOpen] = useState(true);
  const handleSideBar = (status) => {
    setIsSideBarOpen(status);
  };
  return (
    <div>
      <div className="d-flex ">
        <div className="px-md-4  px-lg-4 col-6 my-2 ">
          <div className="badge p-2 partPlayers">Joueurs Partants :</div>
          {playersOfMatchTH
            ?.filter((playerOfMatchTH) =>
              playerOfMatchTH?.playersMatches?.some(
                (pm) => pm.status === "starting"
              )
            )
            .map((playerOfMatchTH) => (
              <div className="row pb-3 ">
                <div className="col-lg-10 ">
                  <React.Fragment key={isSideBarOpen}>
                    <Button
                      className=" fs-6 text-nowrap name_player"
                      onClick={() => {
                        setSelectedPlayerId(playerOfMatchTH.id);
                      }}
                    >
                      <div className="d-flex">
                        <img
                          width="33"
                          height="33"
                          src={`${baseURL}/players/getFile${playerOfMatchTH.avatar.slice(
                            1
                          )}`}
                          className="rounded-circle me-2"
                        />
                        <div className="row">
                          <span className=" col-9 text-nowrap name_player">
                            {playerOfMatchTH.firstName}
                            {playerOfMatchTH.lastName.substring(0, 3) + ".."}
                          </span>
                        </div>
                      </div>
                    </Button>
                  </React.Fragment>
                </div>
              </div>
            ))}
          {playersOfMatchTH.filter((playerOfMatchTH) =>
            playerOfMatchTH.playersMatches?.some(
              (pm) => pm.status === "starting"
            )
          ).length === 0 && (
            <div className="team_name fw-bolder fs-md-6">
              Aucun joueur disponible.
            </div>
          )}
          <div className="badge p-2  rempPlayer">Joueurs Remplacants :</div>
          {playersOfMatchTH
            ?.filter((playerOfMatchTH) =>
              playerOfMatchTH?.playersMatches?.some(
                (pm) => pm.status === "substituted"
              )
            )
            .map((playerOfMatchTH) => (
              <div className="row pb-3 ">
                <div className="col-lg-10">
                  <React.Fragment key={isSideBarOpen}>
                    <Button
                      className=" fs-6 text-nowrap name_player"
                      onClick={() => {
                        setSelectedPlayerId(playerOfMatchTH.id);
                      }}
                    >
                      <div className="d-flex">
                        <img
                          width="33"
                          height="33"
                          src={`${baseURL}/players/getFile${playerOfMatchTH.avatar.slice(
                            1
                          )}`}
                          className="rounded-circle me-2"
                        />
                        <div className="row">
                          <span className=" col-9 text-nowrap name_player">
                            {playerOfMatchTH.firstName}
                            {playerOfMatchTH.lastName.substring(0, 3) + ".."}
                          </span>
                        </div>
                      </div>
                    </Button>
                  </React.Fragment>
                </div>
              </div>
            ))}
          {playersOfMatchTH.filter((playerOfMatchTH) =>
            playerOfMatchTH.playersMatches?.some(
              (pm) => pm.status === "substituted"
            )
          ).length === 0 && (
            <div className="team_name fw-bolder fs-md-6">
              Aucun joueur disponible.
            </div>
          )}
          <div className="badge p-2  ManqpPlayer">Joueurs Manquants :</div>
          {playersOfMatchTH
            ?.filter((playerOfMatchTH) =>
              playerOfMatchTH?.playersMatches?.some(
                (pm) => pm.status === "missing"
              )
            )
            .map((playerOfMatchTH) => (
              <div className="row pb-3 ">
                <div className="col-lg-10 ">
                  <React.Fragment key={isSideBarOpen}>
                    <Button
                      className=" fs-6 text-nowrap name_player"
                      onClick={() => {
                        setSelectedPlayerId(playerOfMatchTH.id);
                      }}
                    >
                      <div className="d-flex">
                        <img
                          width="33"
                          height="33"
                          src={`${baseURL}/players/getFile${playerOfMatchTH.avatar.slice(
                            1
                          )}`}
                          className="rounded-circle me-2"
                        />
                        <div className="row">
                          <span className=" col-9 text-nowrap name_player">
                            {playerOfMatchTH.firstName}
                            {playerOfMatchTH.lastName.substring(0, 3) + ".."}
                          </span>
                        </div>
                      </div>
                    </Button>
                  </React.Fragment>
                </div>
              </div>
            ))}
          {playersOfMatchTH.filter((playerOfMatchTH) =>
            playerOfMatchTH.playersMatches?.some(
              (pm) => pm.status === "missing"
            )
          ).length === 0 && (
            <div className="team_name fw-bolder fs-md-6">
              Aucun joueur disponible.
            </div>
          )}
        </div>
        <div className="px-md-4  px-lg-4 col-6 my-2 section1">
          <div className="badge p-2 partPlayers">Joueurs Partants :</div>
          {playersOfMatchTA
            ?.filter((playerOfMatchTA) =>
              playerOfMatchTA?.playersMatches?.some(
                (pm) => pm.status === "starting"
              )
            )
            .map((playerOfMatchTA) => (
              <div className="row pb-3 ">
                <div className="col-lg-10 ">
                  <React.Fragment key={isSideBarOpen}>
                    <Button
                      className=" fs-6 text-nowrap name_player"
                      onClick={() => {
                        setSelectedPlayerId(playerOfMatchTA.id);
                      }}
                    >
                      <div className="d-flex">
                        <img
                          width="33"
                          height="33"
                          src={`${baseURL}/players/getFile${playerOfMatchTA.avatar.slice(
                            1
                          )}`}
                          className="rounded-circle me-2"
                        />
                        <div className="row">
                          <span className=" col-9 text-nowrap name_player">
                            {playerOfMatchTA.firstName}
                            {playerOfMatchTA.lastName.substring(0, 3) + ".."}
                          </span>
                        </div>
                      </div>
                    </Button>
                  </React.Fragment>
                </div>
              </div>
            ))}
          {playersOfMatchTA.filter((playerOfMatchTA) =>
            playerOfMatchTA.playersMatches?.some(
              (pm) => pm.status === "starting"
            )
          ).length === 0 && (
            <div className="team_name fw-bolder fs-md-6">
              Aucun joueur disponible.
            </div>
          )}
          <div className="badge p-2 rempPlayer ">Joueurs Remplacants :</div>
          {playersOfMatchTA
            ?.filter((playerOfMatchTA) =>
              playerOfMatchTA?.playersMatches?.some(
                (pm) => pm.status === "substituted"
              )
            )
            .map((playerOfMatchTA) => (
              <div className="row pb-3 ">
                <div className="col-lg-10 ">
                  <React.Fragment key={isSideBarOpen}>
                    <Button
                      className=" fs-6 text-nowrap name_player"
                      onClick={() => {
                        setSelectedPlayerId(playerOfMatchTA.id);
                      }}
                    >
                      <div className="d-flex">
                        <img
                          width="33"
                          height="33"
                          src={`${baseURL}/players/getFile${playerOfMatchTA.avatar.slice(
                            1
                          )}`}
                          className="rounded-circle me-2"
                        />
                        <div className="row">
                          <span className=" col-9 text-nowrap name_player">
                            {playerOfMatchTA.firstName}
                            {playerOfMatchTA.lastName.substring(0, 3) + ".."}
                          </span>
                        </div>
                      </div>
                    </Button>
                  </React.Fragment>
                </div>
              </div>
            ))}
          {playersOfMatchTA.filter((playerOfMatchTA) =>
            playerOfMatchTA.playersMatches?.some(
              (pm) => pm.status === "substituted"
            )
          ).length === 0 && (
            <div className="team_name fw-bolder fs-md-6">
              Aucun joueur disponible.
            </div>
          )}
          <div className="badge p-2 ManqpPlayer">Joueurs Manquants :</div>
          {playersOfMatchTA
            ?.filter((playerOfMatchTA) =>
              playerOfMatchTA?.playersMatches?.some(
                (pm) => pm.status === "missing"
              )
            )
            .map((playerOfMatchTA) => (
              <div className="row pb-3 ">
                <div className="col-lg-10 ">
                  <React.Fragment key={isSideBarOpen}>
                    <Button
                      className=" fs-6 text-nowrap name_player"
                      onClick={() => {
                        setSelectedPlayerId(playerOfMatchTA.id);
                      }}
                    >
                      <div className="d-flex">
                        <img
                          width="33"
                          height="33"
                          src={`${baseURL}/players/getFile${playerOfMatchTA.avatar.slice(
                            1
                          )}`}
                          className="rounded-circle me-2"
                        />
                        <div className="row">
                          <span className=" col-9 text-nowrap name_player">
                            {playerOfMatchTA.firstName}
                            {playerOfMatchTA.lastName.substring(0, 3) + ".."}
                          </span>
                        </div>
                      </div>
                    </Button>
                  </React.Fragment>
                </div>
              </div>
            ))}
          {playersOfMatchTA.filter((playerOfMatchTA) =>
            playerOfMatchTA.playersMatches?.some(
              (pm) => pm.status === "missing"
            )
          ).length === 0 && (
            <div className="team_name fw-bolder fs-md-6">
              Aucun joueur disponible.
            </div>
          )}
        </div>
      </div>
      <Drawer
        isSideBarOpen={isSideBarOpen}
        open={selectedPlayerId !== null}
        onClose={() => setSelectedPlayerId(null)}
      >
        {selectedPlayerId && (
          <PlayersStatsMatchSimpleUser
            matchId={matchId}
            playerId={selectedPlayerId}
            playerTeamH={playersOfMatchTH}
            playerTeamA={playersOfMatchTA}
            teamHomeName={matche.teamHome?.acronyme}
            teamAwayName={matche.teamAway?.acronyme}
          />
        )}
      </Drawer>
    </div>
  );
};

export default PlayersOfMatchesSimpleUser;
