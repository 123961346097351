import React, { Fragment, useEffect, useState } from "react";
import axios from "axios";
import "react-phone-input-2/lib/style.css";
import Select from "react-select";
import useAlert from "../../hooks/alertHook";
import { logError } from "../Toastconfig";

const initFormErrors = {
  team_to: [""],
  team_from: [""],
  type: [""],
  date_debut_transfer: [""],
  date_fin_transfer: [""],
};
const EditTransfer = ({ transfer }) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const today = new Date().toISOString().substr(0, 10);
  const [date_debut_transferUpd, setDate_debut_transferUpd] = useState(
    transfer.date_debut_transfer
  );
  const [date_fin_transferUpd, setDate_fin_transferUpd] = useState(
    transfer.date_fin_transfer
  );

  const [team_fromUpd, setTeam_fromUpd] = useState({
    value: transfer.team_fromId,
    label: transfer.team_from,
    logo: transfer.team_fromLogo
      ? baseURL + "/team/getFile" + transfer.team_fromLogo.slice(1)
      : null,
  });
  const [team_toUpd, setTeam_toUpd] = useState({
    value: transfer.team_toId,
    label: transfer.team_to,
    logo: transfer.team_toLogo
      ? baseURL + "/team/getFile" + transfer.team_toLogo.slice(1)
      : null,
  });

  const { alertModal, displayAlert, dismissAlert } = useAlert();
  const [errorMessage, setErrorMessage] = useState(initFormErrors);

  const onchange = (selectedOption, name) => {
    if (name === "team_from") setSelectedTeamFrom(selectedOption);
    else setSelectedTeamTo(selectedOption);
  };
  function handletoggle() {
    displayAlert(
      "info",
      "",
      "Voulez-vous modifier ce transfer ?",
      "Confirmer",
      (change) => {
        handleUpdate();
      },
      "cancel",
      dismissAlert
    );
  }
  const handleUpdate = () => {
    const data = {
      type: typeUpd.value,
      date_debut_transfer: date_debut_transferUpd,
      date_fin_transfer: date_fin_transferUpd,
    };
    if (
      selectedTeamFrom &&
      selectedTeamFrom.value &&
      selectedTeamFrom.value !== transfer.team_fromId
    ) {
      data.team_from = selectedTeamFrom.value.toString();
    } else {
      data.team_from = transfer.team_fromId.toString();
    }
    if (
      selectedTeamTo &&
      selectedTeamTo.value &&
      selectedTeamTo.value !== transfer.team_toId
    ) {
      data.team_to = selectedTeamTo.value.toString();
    } else {
      data.team_to = transfer.team_toId.toString();
    }
    axios
      .put(`${baseURL}/transfers/${transfer.id}`, data)
      .then((resp) => {
        setErrorMessage(initFormErrors);

        if (resp.data.error) return;
        displayAlert("success", "", "Transfer updated successfully");
        setTimeout(() => {
          dismissAlert();
        }, 2500);
      })
      .catch((error) => {
        dismissAlert();
        logError();
        const errors = error.response.data;
        setErrorMessage(errors);
      });
  };
  const [teams, setTeams] = useState([]);

  useEffect(function getALL() {
    axios
      .get(`${baseURL}/teams/read`)
      .then((res) => {
        setTeams(res.data.data);
      })
      .catch((err) => {});
  }, []);

  const [selectedTeamFrom, setSelectedTeamFrom] = useState(team_fromUpd);
  const [selectedTeamTo, setSelectedTeamTo] = useState(team_toUpd);

  const options = teams.map((team) => ({
    value: team.id,
    label: team.name,
    logo: baseURL + "/team/getFile" + team.logo,
  }));
  const [typeUpd, setTypeUpd] = useState({
    value: transfer.type,
    label: transfer.type,
  });

  const optionsTypes = [
    { value: "transfer", label: "Transfert" },
    { value: "loan", label: "Loan" },
    { value: "freeTransfer", label: "Free Transfer" },
  ];

  const handleChange = (selectedOption) => {
    setTypeUpd(selectedOption);
  };
  return (
    <>
      <tr className="border-b style_tbody">
        <td>
          <Select
            id="Team_from"
            name="Team_from"
            options={options}
            getOptionLabel={(e) => (
              <div style={{ display: "flex" }}>
                <img className="pe-1 teamImgSelected" src={e.logo}></img>
                <span style={{ marginTop: 3 }}>{e.label}</span>
              </div>
            )}
            value={selectedTeamFrom}
            placeholder="Choose Team From"
            onChange={(e) => onchange(e, "team_from")}
          ></Select>
          <div className="input-error">
            <span className="text-danger">{errorMessage.team_from}</span>
          </div>{" "}
        </td>
        <td>
          <Select
            id="Team_from"
            name="Team_from"
            options={optionsTypes}
            value={typeUpd}
            placeholder="Choose Type of Transfer"
            onChange={handleChange}
          ></Select>
          <div className="input-error">
            <span className="text-danger">{errorMessage.type}</span>
          </div>
        </td>
        <td>
          <Select
            id="Team_from"
            name="Team_from"
            options={options}
            getOptionLabel={(e) => (
              <div className="style_flex">
                <img className="pe-1 teamImgSelected" src={e.logo}></img>
                <span style={{ marginTop: 3 }}>{e.label}</span>
              </div>
            )}
            value={selectedTeamTo}
            placeholder="Choose Team From"
            onChange={(e) => onchange(e, "team_to")}
          ></Select>
          <div className="input-error">
            <span className="text-danger">{errorMessage.team_to}</span>
          </div>{" "}
        </td>
        <td>
          <input
            type="date"
            className="form-control"
            id="date"
            min={today}
            value={date_debut_transferUpd}
            onChange={(e) => setDate_debut_transferUpd(e.target.value)}
          />
          <div className="input-error">
            <span className="text-danger">
              {errorMessage.date_debut_transfer}
            </span>
          </div>
        </td>
        <td>
          <input
            type="date"
            min={today}
            value={date_fin_transferUpd}
            onChange={(e) => setDate_fin_transferUpd(e.target.value)}
            className="form-control"
            id="date"
          />
          <div className="input-error">
            <span className="text-danger">
              {errorMessage.date_fin_transfer}
            </span>
          </div>
        </td>
        <td>
          <button type="button" className="btn" onClick={handletoggle}>
            <i className="fa-solid fa-pen-to-square"></i>
          </button>
        </td>
      </tr>
      {alertModal}
    </>
  );
};

export default EditTransfer;
