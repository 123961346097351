import React from "react";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TeamLiked from "../../components/Teams/TeamLiked";
import DisplayLikedCompetitions from "../../components/competitions/displayLikedCompetitions";
import AdsTeam from "./AdsTeam";
import PlayersLiked from "../players/PlayerLiked";
import NewsLists from "../../components/news/NewsLists";
import { logError } from "../../components/Toastconfig";
import EmptyData from "../../components/emptyData";
import useAlert from "../../hooks/alertHook";
import Select from "react-select";
import { useHasPermission } from "../../hooks/checkPermissions";
import LoaderLoading from "../../components/LoaderLoading";

export default function TeamOfSimpleUser() {
  const { checkPermissions } = useHasPermission();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [teams, setTeams] = useState([]);
  const [boostExist, setBoostExist] = useState(false);
  const [searchTermName, setsearchTermName] = useState("");
  const [searchTermisPartner, setsearchTermisPartner] = useState({
    value: "Two",
  });
  const [searchTermCountry, setsearchTermCountry] = useState("");
  const [showLikedTeams, setShowLikedTeams] = useState(false);
  const { alertModal, displayAlert, dismissAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(true);

  //  get ALL teams funtion
  useEffect(function () {
    getallTeams();
    getallCountries();
  }, []);
  function getallTeams() {
    axios
      .get(`${baseURL}/teams/getTeamsSimpleUser`)
      .then((res) => {
        setTeams(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        logError();
        setIsLoading(false);
      });
  }
  function getteamIsLiked() {
    axios
      .get(`${baseURL}/teams/GetLikesbyUserId`)
      .then((res) => {
        setteamIsLiked(res.data.data);
      })
      .catch((err) => {});
  }
  useEffect(() => {
    getteamIsLiked();
  }, []);

  const selectTeams = [...new Set(teams.map((team) => team.isPartner))];
  const [countries, setCountris] = useState([]);
  function getallCountries() {
    axios
      .get(`${baseURL}/countries/read`)
      .then((res) => {
        setCountris(res.data);
      })
      .catch((err) => {
        logError();
      });
  }

  async function likeDislike(id, isLiked) {
    let message =
      isLiked == "yes"
        ? "voulez vous supprimer ce joueur de la liste des favories?"
        : "voulez vous ajouter ce joueur à la liste des favories";
    let status = isLiked == "yes" ? "danger" : "info";
    displayAlert(
      status,
      "",
      message,
      "Confirmer",
      () => {
        axios
          .post(`${baseURL}/teams/like`, {
            teamId: id,
          })
          .then((resp) => {
            getallTeams();
            getteamIsLiked();
            dismissAlert();
          });
      },
      "cancel",
      dismissAlert
    );
  }
  const filteredTeams = teams
    .filter((val) => {
      if (searchTermName === "") {
        return val;
      } else if (
        val.name.toLowerCase().includes(searchTermName.toLowerCase())
      ) {
        return val;
      }
    })
    .filter((val) => {
      if (searchTermisPartner.value === "Two") {
        return val;
      } else if (
        val.isPartner
          .toLowerCase()
          .includes(searchTermisPartner.value?.toLowerCase())
      ) {
        return val;
      }
    })
    .filter((val) => {
      if (searchTermCountry === "") {
        return val;
      } else if (
        val.countryname.toLowerCase().includes(searchTermCountry.toLowerCase())
      ) {
        return val;
      }
    })
    .filter((val) => {
      if (!showLikedTeams) {
        return val;
      } else {
        return val.isLiked === "yes";
      }
    })
    .filter((team) => team.isEnabled === "yes");
  const [adsteam, setadsTeams] = useState([]);
  const [likedPlayers, setLikedPlayers] = useState([]);
  const [competitionLiked, setCompetitionLiked] = useState([]);
  const [teamIsLiked, setteamIsLiked] = useState([]);
  const [newslist, setNewslist] = useState([]);
  function getFavorisPlayerList() {
    if (checkPermissions("players")) {
      axios
        .get(`${baseURL}/players/getAllPlayersLiked`)
        .then((response) => {
          setLikedPlayers(response.data.data);
        })
        .catch((error) => logError());
    }
  }

  function getLiked() {
    if (checkPermissions("competitions")) {
      axios
        .get(`${baseURL}/competitions/getLiked`)
        .then((res) => {
          setCompetitionLiked(res.data.data);
        })
        .catch((err) => {
          logError();
        });
    }
  }

  function getteamIsLiked() {
    if (checkPermissions("teams")) {
      axios
        .get(`${baseURL}/teams/GetLikesbyUserId`)
        .then((res) => {
          setteamIsLiked(res.data.data);
        })
        .catch((err) => {});
    }
  }

  useEffect(() => {
    getteamIsLiked();
    getLiked();
    getFavorisPlayerList();
  }, []);
  useEffect(function () {
    getall();
  }, []);

  function getall() {
    axios
      .get(`${baseURL}/teams/getTeamsBoosted`)
      .then((res) => {
        const boostedTeams = res.data;
        const teamDataArray = Object.values(boostedTeams)
          .filter((teamData) => {
            return teamData.status !== "canceled"&& teamData.status !== "expired";
          })
          .map((teamData) => {
            return teamData.team;
          });
        setadsTeams(teamDataArray);
      })
      .catch((err) => {
        logError();
      });
  }
  function getallnews() {
    if (checkPermissions("news")) {
      setIsLoading(true); // set isLoading to true when the request is made

      axios
        .get(`${baseURL}/news/allNews`)
        .then((res) => {
          setNewslist(res.data.data);
        })
        .catch((err) => {
          logError();
        })
        .finally(() => {
          setIsLoading(false); // set isLoading to false when the request is complete (whether it succeeded or failed)
        });
    }
  }

  useEffect(function () {
    getallnews();
  }, []);
  const partner = [
    { value: "Two", label: "Les Deux" },
    { value: "yes", label: "OUI" },
    { value: "no", label: "NON" },
  ];
  return (
    <>
      <div>
        <section className="pt-3">
          <section className="">
            <div className="row m-0 p-0">
              {teamIsLiked?.length === 0 &&
              competitionLiked?.length === 0 &&
              likedPlayers?.length === 0 ? (
                <div className="col-lg-12   ">
                  <div className="row">
                    {adsteam?.length === 0 && newslist?.length > 0 ? (
                      <div
                        className="col-lg-11 pt-2 pt-lg-0 pe-3"
                        style={{ marginLeft: "1rem" }}
                      >
                        <NewsLists newslist={newslist} boostExist={false} />
                      </div>
                    ) : adsteam?.length > 0 && newslist?.length === 0 ? (
                      <>
                        <div className="col-lg-11">
                          <AdsTeam adsteam={adsteam} />
                        </div>
                      </>
                    ) : adsteam?.length === 0 && newslist?.length === 0 ? (
                      ""
                    ) : (
                      <>
                        <div className="col-lg-9">
                          <AdsTeam adsteam={adsteam} />
                        </div>
                        <div className="col-lg-3 pt-2 pt-lg-0 pe-3">
                          <NewsLists newslist={newslist} boostExist={false} />
                        </div>
                      </>
                    )}
                  </div>
                  <div className="card-body  pt-3">
                    <div className="row px-lg-3 px-5">
                      <div className="col-lg-2 ">
                        <label className="text-muted fw-bolder">
                          <small>Nom de l'équipe</small>
                        </label>
                        <input
                          type="text"
                          placeholder="Nom de l'équipe"
                          className="form-control"
                          onChange={(e) => {
                            setsearchTermName(e.target.value);
                          }}
                        />
                      </div>
                      <div className="col-lg-3 ">
                        <label className="text-muted fw-bolder">
                          <small>Pays</small>
                        </label>
                        <select
                          className="form-control"
                          onChange={(e) => setsearchTermCountry(e.target.value)}
                        >
                          <option value="">Pays</option>
                          {countries.map((country, key) => (
                            <option value={country.name} key={key}>
                              {country.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-lg-2 ">
                        <label className="text-muted fw-bolder text-nowrap">
                          <small>Partenaire: o/n</small>
                        </label>
                        <Select
                          options={partner}
                          isSearchable
                          defaultValue={{ value: "Two", label: "Les deux" }}
                          onChange={(value) => setsearchTermisPartner(value)}
                        />
                      </div>
                      <div className="col-lg-4 d-lg-flex justify-content-evenly py-4">
                        <div className="toggle_btn form-check form-switch  d-md-flex flex-md-row-reverse   px-md-0 ">
                          <label className="form-check-label text-muted">
                            {showLikedTeams
                              ? "Les équipes favorites"
                              : "Les équipes favorites"}
                          </label>
                          <input
                            className="form-check-input me-2"
                            type="checkbox"
                            id="toggleSwitch"
                            checked={showLikedTeams}
                            onChange={() => setShowLikedTeams(!showLikedTeams)}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="table-responsive style_table-equipes">
                      {isLoading ? (
                        <LoaderLoading />
                      ) : filteredTeams.length === 0 ? (
                        <div className="row">
                          <div className="col-lg-12">
                            <EmptyData />
                          </div>
                        </div>
                      ) : (
                        <Table className="table ">
                          <thead className="style_thead">
                            <tr>
                              <th className="style_tab style_th"></th>
                              <th className="style_tab style_th">
                                Nom de l'équipe
                              </th>
                              <th className="style_tab style_th">Acronyme</th>
                              <th className="style_tab style_th">Pays</th>
                              <th className="style_tab style_th">Partenaire</th>
                              <th className="style_tab style_th text-nowrap">
                                Agent
                              </th>
                              <th className="style_tab style_th text-nowrap">
                                Nom du Stade
                              </th>
                            </tr>
                          </thead>
                          {filteredTeams.map((team, key) => (
                            <tbody className="my-4 style_tbody " key={key}>
                              <tr className="border-b style_tbody text-nowrap">
                                <td className="py-4">
                                  <div className="row">
                                    <div className="col-3  d-flex justify-content-start align-items-center px-2">
                                      <button
                                        className="btn text-primary"
                                        onClick={() => {
                                          likeDislike(team.id, team.isLiked);
                                        }}
                                      >
                                        {team.isLiked == "yes" ? (
                                          <i className="fa-solid fa-heart like_icon"></i>
                                        ) : (
                                          <i className="fa-regular fa-heart dislike_icon"></i>
                                        )}
                                      </button>
                                    </div>
                                  </div>
                                </td>
                                <td className="p-4 ">
                                  <Link
                                    style={{ textDecoration: "none" }}
                                    to={`/oneTeam/${team.id}`}
                                  >
                                    <img
                                      src={`${baseURL}/team/getFile${team.logo}`}
                                      width="35"
                                      height="35"
                                    />
                                    <span className="ms-3">
                                      {team.name.length > 20
                                        ? `${team.name.slice(0, 20)}...`
                                        : team.name}
                                    </span>
                                  </Link>
                                </td>
                                <td className="p-4 text-nowrap">
                                  {team.acronyme}
                                </td>
                                <td className="p-4 text-nowrap text-truncate">
                                  {team.countryname}
                                </td>
                                <td className="p-4 text-nowrap">
                                  {team.isPartner == "yes" ? (
                                    <p>oui</p>
                                  ) : (
                                    <p>non</p>
                                  )}
                                </td>

                                <td className="px-2 py-4 text-nowrap">
                                  <div>
                                    {" "}
                                    <a href="tel:">
                                      <i className="bi bi-telephone"></i>{" "}
                                      {team.agent_Phone}
                                    </a>
                                  </div>
                                  <div>
                                    <a href="mailto:">
                                      <i className="bi bi-envelope"></i>{" "}
                                      {team.agent_Email}
                                    </a>
                                  </div>
                                </td>
                                <td className="px-2 py-4 text-nowrap">
                                  {team.stade_Name.length > 20
                                    ? `${team.stade_Name.slice(0, 20)}...`
                                    : team.stade_Name}
                                </td>
                              </tr>
                            </tbody>
                          ))}
                        </Table>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="col-lg-3">
                    <TeamLiked teamIsLiked={teamIsLiked} />
                    <div className="mt-3">
                      <DisplayLikedCompetitions
                        competitionLiked={competitionLiked}
                      />
                    </div>
                    <div className="mt-3 mb-3">
                      <PlayersLiked likedPlayers={likedPlayers} />
                    </div>
                  </div>
                  <div className="col-lg-9 ">
                    <div className="row">
                      {adsteam?.length === 0 && newslist?.length > 0 ? (
                        <div
                          className="col-lg-11 pt-2 pt-lg-0 pe-3"
                          style={{ marginLeft: "1rem" }}
                        >
                          <NewsLists newslist={newslist} boostExist={false} />
                        </div>
                      ) : adsteam?.length > 0 && newslist?.length === 0 ? (
                        <>
                          <div className="col-lg-11">
                            <AdsTeam adsteam={adsteam} />
                          </div>
                        </>
                      ) : adsteam?.length === 0 && newslist?.length === 0 ? (
                        ""
                      ) : (
                        <>
                          <div className="col-lg-9">
                            <AdsTeam adsteam={adsteam} />
                          </div>
                          <div className="col-lg-3 pt-2 pt-lg-0 pe-3 mt-lg-0 mt-2">
                            <NewsLists newslist={newslist} boostExist={true} />
                          </div>
                        </>
                      )}
                    </div>
                    <div className="card-body  pt-3">
                      <div className="row px-lg-3 px-5">
                        <div className="col-lg-3 ">
                          <label className="text-muted fw-bolder">
                            <small>Nom de l'équipe</small>
                          </label>
                          <input
                            type="text"
                            placeholder="Nom de l'équipe"
                            className="form-control"
                            onChange={(e) => {
                              setsearchTermName(e.target.value);
                            }}
                          />
                        </div>
                        <div className="col-lg-3 ">
                          <label className="text-muted fw-bolder">
                            <small>Pays</small>
                          </label>
                          <select
                            className="form-control"
                            onChange={(e) =>
                              setsearchTermCountry(e.target.value)
                            }
                          >
                            <option value="">Pays</option>
                            {countries.map((country, key) => (
                              <option value={country.name} key={key}>
                                {country.name}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-lg-2 ">
                          <label className="text-muted fw-bolder text-nowrap">
                            <small>Partenaire: o/n</small>
                          </label>
                          <Select
                            options={partner}
                            isSearchable
                            defaultValue={{ value: "Two", label: "Les deux" }}
                            onChange={(value) => setsearchTermisPartner(value)}
                          />
                        </div>
                        <div className="col-lg-4 d-lg-flex justify-content-evenly py-4">
                          <div className="toggle_btn form-check form-switch  d-md-flex flex-md-row-reverse   px-md-0 ">
                            <label className="form-check-label text-muted">
                              {showLikedTeams
                                ? "Les équipes favorites"
                                : "Les équipes favorites"}
                            </label>
                            <input
                              className="form-check-input me-2"
                              type="checkbox"
                              id="toggleSwitch"
                              checked={showLikedTeams}
                              onChange={() =>
                                setShowLikedTeams(!showLikedTeams)
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive style_table-equipes m-3">
                        {isLoading ? (
                          <LoaderLoading />
                        ) : filteredTeams.length === 0 ? (
                          <div className="row">
                            <div className="col-lg-12">
                              <EmptyData />
                            </div>
                          </div>
                        ) : (
                          <Table className="table ">
                            <thead className="style_thead">
                              <tr>
                                <th className="style_tab style_th"></th>
                                <th className="style_tab style_th">
                                  Nom de l'équipe
                                </th>
                                <th className="style_tab style_th">Acronyme</th>
                                <th className="style_tab style_th">Pays</th>
                                <th className="style_tab style_th">
                                  Partenaire
                                </th>
                                <th className="style_tab style_th text-nowrap">
                                  Agent
                                </th>
                                <th className="style_tab style_th text-nowrap">
                                  Nom du Stade
                                </th>
                              </tr>
                            </thead>
                            {filteredTeams.map((team, key) => (
                              <tbody className="my-4 style_tbody " key={key}>
                                <tr className="border-b style_tbody text-nowrap">
                                  <td className="py-4">
                                    <div className="row">
                                      <div className="col-3  d-flex justify-content-start align-items-center px-2">
                                        <button
                                          className="btn text-primary"
                                          onClick={() => {
                                            likeDislike(team.id, team.isLiked);
                                          }}
                                        >
                                          {team.isLiked == "yes" ? (
                                            <i className="fa-solid fa-heart like_icon"></i>
                                          ) : (
                                            <i className="fa-regular fa-heart dislike_icon "></i>
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                  </td>
                                  <td className="p-4 ">
                                    <Link
                                      style={{ textDecoration: "none" }}
                                      to={`/oneTeam/${team.id}`}
                                    >
                                      <img
                                        src={`${baseURL}/team/getFile${team.logo}`}
                                        width="35"
                                        height="35"
                                      />
                                      <span className="ms-3">
                                        {team.name.length > 20
                                          ? `${team.name.slice(0, 20)}...`
                                          : team.name}
                                      </span>
                                    </Link>
                                  </td>
                                  <td className="p-4 text-nowrap">
                                    {team.acronyme}
                                  </td>
                                  <td className="p-4 text-nowrap text-truncate">
                                    {team.countryname}
                                  </td>
                                  <td className="p-4 text-nowrap">
                                    {team.isPartner == "yes" ? (
                                      <p>oui</p>
                                    ) : (
                                      <p>non</p>
                                    )}
                                  </td>

                                  <td className="px-2 py-4 text-nowrap">
                                    <div>
                                      {" "}
                                      <a href="tel:">
                                        <i className="bi bi-telephone"></i>{" "}
                                        {team.agent_Phone}
                                      </a>
                                    </div>
                                    <div>
                                      <a href="mailto:">
                                        <i className="bi bi-envelope"></i>{" "}
                                        {team.agent_Email}
                                      </a>
                                    </div>
                                  </td>
                                  <td className="px-2 py-4 text-nowrap">
                                    {team.stade_Name.length > 20
                                      ? `${team.stade_Name.slice(0, 20)}...`
                                      : team.stade_Name}
                                  </td>
                                </tr>
                              </tbody>
                            ))}
                          </Table>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </section>
        </section>
      </div>
      {alertModal}
    </>
  );
}
