import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import "./style.css";

const Alert = ({
  status,
  title,
  content = "",
  confirmBtnAction = () => {},
  confirmBtnTitle = "",
  cancelBtnAction = () => {},
  cancelBtnTitle = "",
  dismissBtnAction = () => {},
}) => {
  const [alertHeading, setAlertHeading] = useState("");
  const [alertTitleClasses, setAlertTitleClasses] = useState("");
  const [confirmBtnClasses, setConfirmBtnClasses] = useState("");

  useEffect(() => {
    switch (status) {
      case "success":
        setAlertHeading(
          parse(`<img src="./assets/alertImgs/goal.png" width=100 />`)
        );
        setAlertTitleClasses("title-success");
        setConfirmBtnClasses("success-btn");
        break;
      case "danger":
        setAlertHeading(
          parse(`<img src="./assets/alertImgs/red-card.png" width=100 />`)
        );
        setAlertTitleClasses("title-danger");
        setConfirmBtnClasses("danger-btn");
        break;
      case "info":
        setAlertHeading(
          parse(`<img src="./assets/alertImgs/yellow-card.png" width=100 />`)
        );
        setAlertTitleClasses("title-info");
        setConfirmBtnClasses("info-btn");
        break;

      default:
        break;
    }
  }, []);

  return (
    <section className="alert_modal">
      <div className="alert_container">
        <div className={"alert_heading "}>{alertHeading}</div>
        <div className="alert_details">
          <h2 className={alertTitleClasses}>{title}</h2>
          <p>{content}</p>
        </div>
        <div className="alert_footer">
          {confirmBtnTitle === "" && cancelBtnTitle === "" ? (
            <span
              className="cancel-btn"
              title="OK"
              onClick={() => {
                dismissBtnAction();
              }}
            >
              OK
            </span>
          ) : (
            ""
          )}
          <>
            {confirmBtnTitle !== "" && (
              <span
                className={confirmBtnClasses}
                title={confirmBtnTitle}
                onClick={() => {
                  confirmBtnAction();
                }}
              >
                {confirmBtnTitle}
              </span>
            )}
            {cancelBtnTitle !== "" && (
              <span
                className="cancel-btn"
                title={cancelBtnTitle}
                onClick={cancelBtnAction}
              >
                {cancelBtnTitle}
              </span>
            )}
          </>
        </div>
      </div>
    </section>
  );
};

export default Alert;
