import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useAlert from "../../hooks/alertHook";
import Modal from "react-bootstrap/Modal";

const UpdatePlayer = ({ playersData, GetPlayerStatsByPlayerId }) => {
  const { playerId } = useParams();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const initFormErrors = {
    totalPlayed: [""],
    startedgames: [""],
    minutesPerGame: [""],
    teamOfTheWeek: [""],
    goals: [""],
    scoringFrequency: [""],
    goalsPerGame: [""],
    shotsPerGame: [""],
    shotsOnTargetPerGame: [""],
    bigChancesMissed: [""],
    goalConversion: [""],
    freeKickGoals: [""],
    freeKickConversion: [""],
    goalsInsideBox: [""],
    goalsOutsideBox: [""],
    HeadedGoals: [""],
    leftFootGoals: [""],
    rightFootGoals: [""],
    penaltyWon: [""],
    assists: [""],
    touches: [""],
    bigChancesCreated: [""],
    keyPasses: [""],
    accPerGame: [""],
    accOwnHalf: [""],
    accOppositionHalf: [""],
    accLongBalls: [""],
    accChippedPasses: [""],
    accCrosses: [""],
    interceptionsPerGame: [""],
    tacklesPerGame: [""],
    possessionWon: [""],
    dribbledPastPerGame: [""],
    clearancesPerGame: [""],
    errorLedToShot: [""],
    errorLedToGoal: [""],
    penaltiesCommitted: [""],
    succDribbles: [""],
    totalDuelsWon: [""],
    groundDuelsWon: [""],
    aerialDuelsWon: [""],
    possessionLost: [""],
    fouls: [""],
    wasFouled: [""],
    offsides: [""],
    yellowCard: [""],
    yellowRedCard: [""],
    redCards: [""],
    deffence: [""],
    attack: [""],
    Tactic: [""],
    tecnique: [""],
    creativity: [""],
  };
  const [totalPlayed, settotalPlayed] = useState();
  const [startedgames, setstartedgames] = useState();
  const [minutesPerGame, setminutesPerGame] = useState();
  const [teamOfTheWeek, setteamOfTheWeek] = useState();
  const [goals, setgoals] = useState();
  const [scoringFrequency, setscoringFrequency] = useState();
  const [goalsPerGame, setgoalsPerGame] = useState();
  const [shotsPerGame, setshotsPerGame] = useState();
  const [shotsOnTargetPerGame, setshotsOnTargetPerGame] = useState();
  const [bigChancesMissed, setbigChancesMissed] = useState();
  const [goalConversion, setgoalConversion] = useState();
  const [freeKickGoals, setfreeKickGoals] = useState();
  const [freeKickConversion, setfreeKickConversion] = useState();
  const [goalsInsideBox, setgoalsInsideBox] = useState();
  const [goalsOutsideBox, setgoalsOutsideBox] = useState();
  const [HeadedGoals, setHeadedGoals] = useState();
  const [leftFootGoals, setleftFootGoals] = useState();
  const [rightFootGoals, setrightFootGoals] = useState();
  const [penaltyWon, setpenaltyWon] = useState();
  const [assists, setassists] = useState();
  const [touches, settouches] = useState();
  const [bigChancesCreated, setbigChancesCreated] = useState();
  const [keyPasses, setkeyPasses] = useState();
  const [accPerGame, setaccPerGame] = useState();
  const [accOwnHalf, setaccOwnHalf] = useState();
  const [accOppositionHalf, setaccOppositionHalf] = useState();
  const [accLongBalls, setaccLongBalls] = useState();
  const [accChippedPasses, setaccChippedPasses] = useState();
  const [accCrosses, setaccCrosses] = useState();
  const [interceptionsPerGame, setinterceptionsPerGame] = useState();
  const [tacklesPerGame, settacklesPerGame] = useState();
  const [possessionWon, setpossessionWon] = useState();
  const [dribbledPastPerGame, setdribbledPastPerGame] = useState();
  const [clearancesPerGame, setclearancesPerGame] = useState();
  const [errorLedToShot, seterrorLedToShot] = useState();
  const [errorLedToGoal, seterrorLedToGoal] = useState();
  const [penaltiesCommitted, setpenaltiesCommitted] = useState();
  const [succDribbles, setsuccDribbles] = useState();
  const [totalDuelsWon, settotalDuelsWon] = useState();
  const [groundDuelsWon, setgroundDuelsWon] = useState();
  const [aerialDuelsWon, setaerialDuelsWon] = useState();
  const [possessionLost, setpossessionLost] = useState();
  const [fouls, setfouls] = useState();
  const [wasFouled, setwasFouled] = useState();
  const [offsides, setoffsides] = useState();
  const [yellowCard, setyellowCard] = useState();
  const [yellowRedCard, setyellowRedCard] = useState();
  const [redCards, setredCards] = useState();
  const [attack, setattack] = useState();
  const [deffence, setdeffence] = useState();
  const [Tactic, settactic] = useState();
  const [creativity, setcreativity] = useState();
  const [tecnique, settecnique] = useState();
  const [observation, setobservation] = useState();
  const [rate, setrate] = useState();
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
    settotalPlayed(playersData.totalPlayed);
    setstartedgames(playersData.startedgames);
    setminutesPerGame(playersData.minutesPerGame);
    setteamOfTheWeek(playersData.teamOfTheWeek);
    setgoals(playersData.goals);
    setscoringFrequency(playersData.scoringFrequency);
    setgoalsPerGame(playersData.goalsPerGame);
    setshotsPerGame(playersData.shotsPerGame);
    setshotsOnTargetPerGame(playersData.shotsOnTargetPerGame);
    setbigChancesMissed(playersData.bigChancesMissed);
    setgoalConversion(playersData.goalConversion);
    setfreeKickGoals(playersData.freeKickGoals);
    setfreeKickConversion(playersData.freeKickConversion);
    setgoalsInsideBox(playersData.goalsInsideBox);
    setgoalsOutsideBox(playersData.goalsOutsideBox);
    setHeadedGoals(playersData.HeadedGoals);
    setleftFootGoals(playersData.leftFootGoals);
    setrightFootGoals(playersData.rightFootGoals);
    setpenaltyWon(playersData.penaltyWon);
    setassists(playersData.assists);
    settouches(playersData.touches);
    setbigChancesCreated(playersData.bigChancesCreated);
    setkeyPasses(playersData.keyPasses);
    setaccPerGame(playersData.accPerGame);
    setaccOwnHalf(playersData.accOwnHalf);
    setaccOppositionHalf(playersData.accOppositionHalf);
    setaccLongBalls(playersData.accLongBalls);
    setaccChippedPasses(playersData.accChippedPasses);
    setaccCrosses(playersData.accCrosses);
    setinterceptionsPerGame(playersData.interceptionsPerGame);
    settacklesPerGame(playersData.tacklesPerGame);
    setpossessionWon(playersData.possessionWon);
    setdribbledPastPerGame(playersData.dribbledPastPerGame);
    setclearancesPerGame(playersData.clearancesPerGame);
    seterrorLedToShot(playersData.errorLedToShot);
    seterrorLedToGoal(playersData.errorLedToGoal);
    setpenaltiesCommitted(playersData.penaltiesCommitted);
    setsuccDribbles(playersData.succDribbles);
    settotalDuelsWon(playersData.totalDuelsWon);
    setgroundDuelsWon(playersData.groundDuelsWon);
    setaerialDuelsWon(playersData.aerialDuelsWon);
    setpossessionLost(playersData.possessionLost);
    setfouls(playersData.fouls);
    setwasFouled(playersData.wasFouled);
    setoffsides(playersData.offsides);
    setyellowCard(playersData.yellowCard);
    setyellowRedCard(playersData.yellowRedCard);
    setredCards(playersData.redCards);
    setattack(playersData.attack);
    setdeffence(playersData.deffence);
    setcreativity(playersData.creativity);
    settactic(playersData.Tactic);
    settecnique(playersData.tecnique);
    setrate(playersData.rate);
  };
  const handleClose = () => {
    setShow(false);
    settotalPlayed("");
    setstartedgames("");
    setminutesPerGame("");
    setteamOfTheWeek("");
    setgoals("");
    setscoringFrequency("");
    setgoalsPerGame("");
    setshotsPerGame("");
    setshotsOnTargetPerGame("");
    setbigChancesMissed("");
    setgoalConversion("");
    setfreeKickGoals("");
    setfreeKickConversion("");
    setgoalsInsideBox("");
    setgoalsOutsideBox("");
    setHeadedGoals("");
    setleftFootGoals("");
    setrightFootGoals("");
    setpenaltyWon("");
    setassists("");
    settouches("");
    setbigChancesCreated("");
    setkeyPasses("");
    setaccPerGame("");
    setaccOwnHalf("");
    setaccOppositionHalf("");
    setaccLongBalls("");
    setaccChippedPasses("");
    setaccCrosses("");
    setinterceptionsPerGame("");
    settacklesPerGame("");
    setpossessionWon("");
    setdribbledPastPerGame("");
    setclearancesPerGame("");
    seterrorLedToShot("");
    seterrorLedToGoal("");
    setpenaltiesCommitted("");
    setsuccDribbles("");
    settotalDuelsWon("");
    setgroundDuelsWon("");
    setaerialDuelsWon("");
    setpossessionLost("");
    setfouls("");
    setwasFouled("");
    setoffsides("");
    setyellowCard("");
    setyellowRedCard("");
    setredCards("");
    setrate("");
    setattack("");
    setdeffence("");
    setcreativity("");
    settactic("");
    settecnique("");
  };

  const { alertModal, displayAlert, dismissAlert } = useAlert();
  const [errorMessage, setErrorMessage] = useState(initFormErrors);

  function sendData() {
    axios
      .put(`${baseURL}/players/UpdatePlayerStats/${playerId}`, {
        totalPlayed: totalPlayed,
        startedgames: startedgames,
        minutesPerGame: minutesPerGame,
        teamOfTheWeek: teamOfTheWeek,
        goals: goals,
        scoringFrequency: scoringFrequency,
        goalsPerGame: goalsPerGame,
        shotsPerGame: shotsPerGame,
        shotsOnTargetPerGame: shotsOnTargetPerGame,
        bigChancesMissed: bigChancesMissed,
        goalConversion: goalConversion,
        freeKickGoals: freeKickGoals,
        freeKickConversion: freeKickConversion,
        goalsInsideBox: goalsInsideBox,
        goalsOutsideBox: goalsOutsideBox,
        HeadedGoals: HeadedGoals,
        leftFootGoals: leftFootGoals,
        rightFootGoals: rightFootGoals,
        penaltyWon: penaltyWon,
        assists: assists,
        touches: touches,
        bigChancesCreated: bigChancesCreated,
        keyPasses: keyPasses,
        accPerGame: accPerGame,
        accOwnHalf: accOwnHalf,
        accOppositionHalf: accOppositionHalf,
        accLongBalls: accLongBalls,
        accChippedPasses: accChippedPasses,
        accCrosses: accCrosses,
        interceptionsPerGame: interceptionsPerGame,
        tacklesPerGame: tacklesPerGame,
        possessionWon: possessionWon,
        dribbledPastPerGame: dribbledPastPerGame,
        clearancesPerGame: clearancesPerGame,
        errorLedToShot: errorLedToShot,
        errorLedToGoal: errorLedToGoal,
        penaltiesCommitted: penaltiesCommitted,
        succDribbles: succDribbles,
        totalDuelsWon: totalDuelsWon,
        groundDuelsWon: groundDuelsWon,
        aerialDuelsWon: aerialDuelsWon,
        possessionLost: possessionLost,
        fouls: fouls,
        wasFouled: wasFouled,
        offsides: offsides,
        yellowCard: yellowCard,
        yellowRedCard: yellowRedCard,
        redCards: redCards,
        observation: observation,
        attack: attack,
        deffence: deffence,
        Tactic: Tactic,
        tecnique: tecnique,
        creativity:creativity,
      })
      .then((resp) => {
        if (resp.data.error) return;
        setErrorMessage(initFormErrors);
        displayAlert("success", "", resp.data.message,);
        setTimeout(() => {
          dismissAlert();
        }, 2500);
        GetPlayerStatsByPlayerId();
        handleClose();
      })
      .catch((error) => {
        const errors = error.response.data;
        setErrorMessage(errors);
      });
  }

  return (
    <>
      <div className="">
        <button
          className="btn"
          title="Modifier Statistiques"
          type="button"
          variant="primary"
          onClick={handleShow}
        >
          <i
            className="fa-solid fa-chart-simple chartPlayer"
          ></i>
        </button>
        <Modal
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={handleClose}
        >
          <Modal.Header className="style_modalHeader">
            <div>
              <Modal.Title className="style_modalTitle">
                Modifier statistiques
              </Modal.Title>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <div className="row mb-4">
                <h6 className="my-2">Matchs : </h6>
                <div className="col-lg-3">
                  <label className="fw-bold">Matchs Totals : </label>
                  <input
                    value={totalPlayed != null && totalPlayed}
                    type="number"
                    pattern="[0-9]*"
                    className="form-control"
                    placeholder="Matchs joués"
                    onChange={(e) => settotalPlayed(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.totalPlayed}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Matchs Titulaire : </label>
                  <input
                    value={startedgames != null && startedgames}
                    type="number"
                    className="form-control"
                    placeholder="Matchs Titulaire"
                    onChange={(e) => setstartedgames(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.startedgames}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Minutes par match : </label>
                  <input
                    value={minutesPerGame != null && minutesPerGame}
                    type="number"
                    className="form-control"
                    placeholder="Minutes par match"
                    onChange={(e) => setminutesPerGame(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.minutesPerGame}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Équipe de la semaine :</label>
                  <input
                    value={teamOfTheWeek != null && teamOfTheWeek}
                    type="number"
                    className="form-control"
                    placeholder="Équipe de la semaine"
                    onChange={(e) => setteamOfTheWeek(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.teamOfTheWeek}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <h6 className="my-2">Attaque :</h6>
                <div className="col-lg-3">
                  <label className="fw-bold">Buts :</label>
                  <input
                    value={goals != null && goals}
                    type="number"
                    className="form-control"
                    placeholder="buts"
                    onChange={(e) => setgoals(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">{errorMessage.goals}</span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Fréquence des buts : (min) </label>
                  <input
                    value={scoringFrequency != null && scoringFrequency}
                    type="number"
                    className="form-control"
                    placeholder="Fréquence des buts"
                    onChange={(e) => setscoringFrequency(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.scoringFrequency}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Buts par match : </label>
                  <input
                    value={goalsPerGame != null && goalsPerGame}
                    type="number"
                    className="form-control"
                    placeholder="Buts par match "
                    onChange={(e) => setgoalsPerGame(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.goalsPerGame}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Tirs par match : </label>
                  <input
                    value={shotsPerGame != null && shotsPerGame}
                    type="number"
                    className="form-control"
                    placeholder="Tirs par match"
                    onChange={(e) => setshotsPerGame(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.shotsPerGame}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-3">
                  <label className="fw-bold">Tirs cadrés par match : </label>
                  <input
                    value={shotsOnTargetPerGame != null && shotsOnTargetPerGame}
                    type="number"
                    className="form-control"
                    placeholder="Tirs cadrés par match"
                    onChange={(e) => setshotsOnTargetPerGame(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.shotsOnTargetPerGame}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">
                    Grandes occasions manquées :{" "}
                  </label>
                  <input
                    value={bigChancesMissed != null && bigChancesMissed}
                    type="number"
                    className="form-control"
                    placeholder="Grandes occasions manquées "
                    onChange={(e) => setbigChancesMissed(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.bigChancesMissed}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Conversion de but : (%) </label>
                  <input
                    value={goalConversion != null && goalConversion}
                    type="number"
                    className="form-control"
                    placeholder="Conversion de but"
                    onChange={(e) => setgoalConversion(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.goalConversion}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Buts sur coup franc : </label>
                  <input
                    value={freeKickGoals != null && freeKickGoals}
                    type="number"
                    className="form-control"
                    placeholder="Buts sur coup franc"
                    onChange={(e) => setfreeKickGoals(e.target.value)}
                    required
                  />{" "}
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.freeKickGoals}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-3">
                  <label className="fw-bold">
                    Conversion de coup franc : (%)
                  </label>
                  <input
                    value={freeKickConversion != null && freeKickConversion}
                    type="number"
                    className="form-control"
                    placeholder="Conversion de coup franc"
                    onChange={(e) => setfreeKickConversion(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.freeKickConversion}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Buts dans la surface : </label>
                  <input
                    value={goalsInsideBox != null && goalsInsideBox}
                    type="number"
                    className="form-control"
                    placeholder="Buts dans la surface"
                    onChange={(e) => setgoalsInsideBox(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.goalsInsideBox}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Buts hors la surface : </label>
                  <input
                    value={goalsOutsideBox != null && goalsOutsideBox}
                    type="number"
                    className="form-control"
                    placeholder="Buts hors la surface"
                    onChange={(e) => setgoalsOutsideBox(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.goalsOutsideBox}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Buts de tête : </label>
                  <input
                    value={HeadedGoals != null && HeadedGoals}
                    type="number"
                    className="form-control"
                    placeholder="Buts de tête"
                    onChange={(e) => setHeadedGoals(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.HeadedGoals}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-3">
                  <label className="fw-bold">Buts pied gauche : </label>
                  <input
                    value={leftFootGoals != null && leftFootGoals}
                    type="number"
                    className="form-control"
                    placeholder="Buts pied gauche"
                    onChange={(e) => setleftFootGoals(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.leftFootGoals}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Buts pied droite : </label>
                  <input
                    value={rightFootGoals != null && rightFootGoals}
                    type="number"
                    className="form-control"
                    placeholder="Buts pied droite"
                    onChange={(e) => setrightFootGoals(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.rightFootGoals}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Pénalties gagnées: </label>
                  <input
                    value={penaltyWon != null && penaltyWon}
                    type="number"
                    className="form-control"
                    placeholder="Pénalties gagnées"
                    onChange={(e) => setpenaltyWon(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.penaltyWon}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <h6 className="my-2">Passes : </h6>
                <div className="col-lg-3">
                  <label className="fw-bold">Assists :</label>
                  <input
                    value={assists != null && assists}
                    type="number"
                    className="form-control"
                    placeholder="Assists"
                    onChange={(e) => setassists(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">{errorMessage.assists}</span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Touches : </label>
                  <input
                    value={touches != null && touches}
                    type="number"
                    className="form-control"
                    placeholder="Touches"
                    onChange={(e) => settouches(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">{errorMessage.touches}</span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Grandes occasions crées : </label>
                  <input
                    value={bigChancesCreated != null && bigChancesCreated}
                    type="number"
                    className="form-control"
                    placeholder="Buts dans la surface"
                    onChange={(e) => setbigChancesCreated(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.bigChancesCreated}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Passe-clés : </label>
                  <input
                    value={keyPasses != null && keyPasses}
                    type="number"
                    className="form-control"
                    placeholder="Buts hors la surface"
                    onChange={(e) => setkeyPasses(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.keyPasses}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-3">
                  <label className="fw-bold">Précision par match</label>
                  <input
                    value={accPerGame != null && accPerGame}
                    type="number"
                    className="form-control"
                    placeholder="Accurate per game"
                    onChange={(e) => setaccPerGame(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.accPerGame}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Précision dans le propre moitié de terrain :</label>
                  <input
                    value={accOwnHalf != null && accOwnHalf}
                    type="number"
                    className="form-control"
                    placeholder="Précision dans le propre moitié de terrain "
                    onChange={(e) => setaccOwnHalf(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.accOwnHalf}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Précision dans la moitié de terrain adverse :</label>
                  <input
                    value={accOppositionHalf != null && accOppositionHalf}
                    type="number"
                    className="form-control"
                    placeholder="Précision dans la moitié de terrain adverse "
                    onChange={(e) => setaccOppositionHalf(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.accOppositionHalf}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Précision Passes longues : </label>
                  <input
                    value={accLongBalls != null && accLongBalls}
                    type="number"
                    className="form-control"
                    placeholder="Précision Passes longues "
                    onChange={(e) => setaccLongBalls(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.accLongBalls}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
              <div className="col-lg-3">
                  <label className="fw-bold">Précision Passes lobées : </label>
                  <input
                    value={accChippedPasses != null && accChippedPasses}
                    type="number"
                    className="form-control"
                    placeholder="Précision Passes lobées"
                    onChange={(e) => setaccChippedPasses(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.accChippedPasses}
                    </span>
                  </div>
                </div>  
                <div className="col-lg-3">
                  <label className="fw-bold">Précision Centres : </label>
                  <input
                    value={accCrosses != null && accCrosses}
                    type="number"
                    className="form-control"
                    placeholder="Précision Centres"
                    onChange={(e) => setaccCrosses(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.accCrosses}
                    </span>
                  </div>
                </div>
                </div>
            
              <div className="row mb-4">
                <h6 className="my-2">Défense : </h6>
                <div className="col-lg-3">
                  <label className="fw-bold">Interceptions par match :</label>
                  <input
                    value={interceptionsPerGame != null && interceptionsPerGame}
                    type="number"
                    className="form-control"
                    placeholder="Interceptions par match"
                    onChange={(e) => setinterceptionsPerGame(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.interceptionsPerGame}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Tacles par match :</label>
                  <input
                    value={tacklesPerGame != null && tacklesPerGame}
                    type="number"
                    className="form-control"
                    placeholder="Tacles par match"
                    onChange={(e) => settacklesPerGame(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.tacklesPerGame}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Fautes : </label>
                  <input
                    value={fouls != null && fouls}
                    type="number"
                    className="form-control"
                    placeholder="Fautes"
                    onChange={(e) => setfouls(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">{errorMessage.fouls}</span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Fautes gangés :</label>
                  <input
                    value={wasFouled != null && wasFouled}
                    type="number"
                    className="form-control"
                    placeholder="Fautes gangés"
                    onChange={(e) => setwasFouled(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.wasFouled}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-3">
                  <label className="fw-bold">Possession gagnée :</label>
                  <input
                    value={possessionWon != null && possessionWon}
                    type="number"
                    className="form-control"
                    placeholder="Possession gagnée"
                    onChange={(e) => setpossessionWon(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.possessionWon}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Dribble par match :</label>
                  <input
                    value={dribbledPastPerGame != null && dribbledPastPerGame}
                    type="number"
                    className="form-control"
                    placeholder="Dribble par match"
                    onChange={(e) => setdribbledPastPerGame(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.dribbledPastPerGame}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Dégagements par match : </label>
                  <input
                    value={clearancesPerGame != null && clearancesPerGame}
                    type="number"
                    className="form-control"
                    placeholder="Dégagements par match"
                    onChange={(e) => setclearancesPerGame(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.clearancesPerGame}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">
                    L'erreur a conduit au tir :{" "}
                  </label>
                  <input
                    value={errorLedToShot != null && errorLedToShot}
                    type="number"
                    className="form-control"
                    placeholder="L'erreur a conduit au tir"
                    onChange={(e) => seterrorLedToShot(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.errorLedToShot}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-3">
                  <label className="fw-bold">L'erreur a conduit au but :</label>
                  <input
                    value={errorLedToGoal != null && errorLedToGoal}
                    type="number"
                    className="form-control"
                    placeholder="L'erreur a conduit au but"
                    onChange={(e) => seterrorLedToGoal(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.errorLedToGoal}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Pénalties commises:</label>
                  <input
                    value={penaltiesCommitted != null && penaltiesCommitted}
                    type="number"
                    className="form-control"
                    placeholder="Pénalties commises"
                    onChange={(e) => setpenaltiesCommitted(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.penaltiesCommitted}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Dribbles réuissies : </label>
                  <input
                    value={succDribbles != null && succDribbles}
                    type="number"
                    className="form-control"
                    placeholder="Dribbles réuissies"
                    onChange={(e) => setsuccDribbles(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.succDribbles}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Duels gangés : </label>
                  <input
                    value={totalDuelsWon != null && totalDuelsWon}
                    type="number"
                    className="form-control"
                    placeholder="Duels gangés"
                    onChange={(e) => settotalDuelsWon(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.totalDuelsWon}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-3">
                  <label className="fw-bold">Duels au sol gagnés :</label>
                  <input
                    value={groundDuelsWon != null && groundDuelsWon}
                    type="number"
                    className="form-control"
                    placeholder="Duels au sol gagnés"
                    onChange={(e) => setgroundDuelsWon(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.groundDuelsWon}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Duels aériens gagnés :</label>
                  <input
                    value={aerialDuelsWon != null && aerialDuelsWon}
                    type="number"
                    className="form-control"
                    placeholder="Duels aériens gagnés"
                    onChange={(e) => setaerialDuelsWon(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.aerialDuelsWon}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Possession perdue : </label>
                  <input
                    value={possessionLost != null && possessionLost}
                    type="number"
                    className="form-control"
                    placeholder="Possession perdue"
                    onChange={(e) => setpossessionLost(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.possessionLost}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Hors-jeu :</label>
                  <input
                    value={offsides != null && offsides}
                    type="number"
                    className="form-control"
                    placeholder="Hors-jeu"
                    onChange={(e) => setoffsides(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">{errorMessage.offsides}</span>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <h6 className="my-2">Cartes : </h6>
                <div className="col-lg-3">
                  <label className="fw-bold">Cartes Jaunes :</label>
                  <input
                    value={yellowCard != null && yellowCard}
                    type="number"
                    className="form-control"
                    placeholder="Cartes Jaunes"
                    onChange={(e) => setyellowCard(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.yellowCard}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Cartes Rouges (Directes) :</label>
                  <input
                    value={redCards != null && redCards}
                    type="number"
                    className="form-control"
                    placeholder="Cartes Rouges (Directes)"
                    onChange={(e) => setredCards(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">{errorMessage.redCards}</span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">
                    Cartes Rouges (Deux Jaunes) :{" "}
                  </label>
                  <input
                    value={yellowRedCard != null && yellowRedCard}
                    type="number"
                    className="form-control"
                    placeholder="Cartes Rouges (Deux Jaunes)"
                    onChange={(e) => setyellowRedCard(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.yellowRedCard}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <h6 className="my-2">Puissance </h6>
                <div className="col-lg-3">
                  <label className="fw-bold">Attaque :</label>
                  <input
                    value={attack != null && attack}
                    type="number"
                    className="form-control"
                    placeholder="Attaque"
                    onChange={(e) => setattack(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.attack}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Défense :</label>
                  <input
                    value={deffence != null && deffence}
                    type="number"
                    className="form-control"
                    placeholder="Défense"
                    onChange={(e) => setdeffence(e.target.value)}
                    required
                  />
                <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.deffence}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Tactique : </label>
                  <input
                    value={Tactic != null && Tactic}
                    type="number"
                    className="form-control"
                    placeholder="Tactique"
                    onChange={(e) => settactic(e.target.value)}
                    required
                  />
                   <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.Tactic}
                    </span>
                  </div>
                </div>
                <div className="col-lg-3">
                  <label className="fw-bold">Technique : </label>
                  <input
                    value={tecnique != null && tecnique}
                    type="number"
                    className="form-control"
                    placeholder="Technique"
                    onChange={(e) => settecnique(e.target.value)}
                    required
                  />
                   <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.tecnique}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
              <div className="col-lg-3">
                  <label className="fw-bold">Créativité : </label>
                  <input
                    value={creativity != null && creativity}
                    type="number"
                    className="form-control"
                    placeholder="Créativité"
                    onChange={(e) => setcreativity(e.target.value)}
                    required
                  />
                   <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.creativity}
                    </span>
                  </div>
                </div>
            </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={handleClose}
            >
              fermer
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={sendData}
            >
              Enregistrer
            </button>
          </Modal.Footer>
        </Modal>
      </div>
      {alertModal}
    </>
  );
};

export default UpdatePlayer;
