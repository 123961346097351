import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CreateCompetitions from "../components/competitions/CreateCompetitions";
import UpdateCompetitions from "../components/competitions/UpdateCompetitions";
import DataTable from "react-data-table-component";
import EmptyData from "../components/emptyData";
import BoostCompetition from "../components/competitions/BoostCompetition";
import { logError } from "../components/Toastconfig";
import useAlert from "../hooks/alertHook";
import LoaderLoading from "../components/LoaderLoading";

const Competitions = () => {
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "rgba(223, 223, 223, 0.59);",
        color: "#5d7079",
      },
    },
  };
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [data, setData] = useState();
  const [competitions, setCompetition] = useState([]);
  const [addCompetitionModal, setAddCompetitionModal] = React.useState();
  const [newcountry, setNewCountry] = React.useState();
  const [filtredCompetitions, setFiltredcompetition] = React.useState(null);
  const { alertModal, displayAlert, dismissAlert } = useAlert();
  const [isLoading, setIsLoading] = useState(true);
  async function getAllCompetitions() {
    setIsLoading(true);
    axios
      .get(`${baseURL}/competitions/all`)
      .then((response) => {
        if (response.data.error) return;
        setCompetition(response.data.data);
        setFiltredcompetition(response.data.data);
      })
      .catch((err) => {
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false); // set isLoading to false after receiving a response or an error
      });
  }
  useEffect(() => {
    if (filtredCompetitions == null) return;
    if (isLoading) {
      setData(<LoaderLoading />);
    } else if (filtredCompetitions.length > 0) {
      setData(
        <DataTable
          columns={columns}
          data={filtredCompetitions}
          customStyles={customStyles}
        />
      );
    } else {
      setData(<EmptyData />);
    }
  }, [isLoading, filtredCompetitions]);

  const [countries, setCountris] = useState([]);
  function getallCountries() {
    axios
      .get(`${baseURL}/countries/read`)
      .then((res) => {
        setCountris(res.data);
      })
      .catch((err) => {
        logError();
      });
  }
  useEffect(function () {
    getallCountries();
  }, []);
  const [addcreateCompetitionsModal, setcreateCompetitionsModal] =
    React.useState();

  async function enableDisable(competitionId, isEnabled) {
    let message =
      isEnabled == "yes"
        ? "voulez vous désactiver cette compétition?"
        : "Voulez vous activer cette compétition?";
    let status = isEnabled == "yes" ? "danger" : "info";
    displayAlert(
      status,
      "",
      message,
      "Confirmer",
      () => {
        axios
          .get(`${baseURL}/competitions/toggleStatus/${competitionId}`)
          .then((resp) => {
            let usersInfo = resp.data;
            if (!usersInfo.error) {
              getAllCompetitions();
              dismissAlert();
            }
          });
      },
      "cancel",
      dismissAlert
    );
  }

  useEffect(() => {
    getAllCompetitions();
  }, []);
  async function likeDislike(competitionId, isLiked) {
    let message =
      isLiked == "yes"
        ? "voulez vous supprimer cette compétition de la liste des favories?"
        : "voulez vous ajouter cette compétition à la liste des favories?";
    let status = isLiked == "yes" ? "danger" : "info";
    displayAlert(
      status,
      "",
      message,
      "Confirmer",
      () => {
        axios
          .post(`${baseURL}/competitions/like`, {
            competitionId: competitionId,
          })
          .then((resp) => {
            let usersInfo = resp.data;
            getAllCompetitions();
            dismissAlert();
          });
      },
      "cancel",
      dismissAlert
    );
  }
  const columns = [
    {
      name: "",
      width: "4%",
      style: {
        padding: 0,
      },
      cell: (row) => (
        <div className="row">
          <div className="d-flex justify-content-center text-center">
            <button
              className="btn text-primary"
              onClick={() => {
                likeDislike(row.id, row.isLiked);
              }}
            >
              {row.isLiked == "yes" ? (
                <i className="fa-solid fa-heart like_icon"></i>
              ) : (
                <i className="fa-regular fa-heart dislike_icon"></i>
              )}
            </button>
          </div>
        </div>
      ),
    },
    {
      name: "Nom de compétition",
      cell: (row) => (
        <div className="text-wrap">
          <Link
            style={{ textDecoration: "none", color: "black" }}
            to={`/admin/oneCompetition/${row.id}`}
          >
            <div className="names">
              <img
                src={`${baseURL + "/competition/getFile" + row.logo}`}
                className="rounded-circle"
                width="35"
                height="35"
              />
              {row.comp_name}
            </div>
          </Link>
        </div>
      ),
      width: "27%",
      minWidth: "150px",
    },

    {
      name: "Date de début des compétitions",
      cell: (row) => (
        <div className="text-wrap">
          <div>{row.date_debut}</div>
        </div>
      ),
      width: "19%",
      minWidth: "150px",
    },

    {
      name: "Pays",
      cell: (row) => (
        <div className="text-wrap">
          <div>{row.countryname}</div>
        </div>
      ),
      width: "17%",
    },
    {
      name: "Créé par",
      cell: (row) => (
        <div className="text-wrap">
          <div>{row.created_by}</div>
        </div>
      ),
      width: "18%",
    },
    {
      name: "Actions",
      width: "15%",

      cell: (row) => (
        <div className="row">
          <div className="col-3 d-flex justify-content-start align-items-center px-2">
            <button
              type="button"
              className="btn p-0"
              data-toggle="tooltip"
              data-placement="top"
              title="Modifier compétition"
              onClick={() =>
                setAddCompetitionModal(
                  <UpdateCompetitions
                    setcreateCompetitionsModalFromChild={setAddCompetitionModal}
                    getAllCompetitions={getAllCompetitions}
                    competitionId={row.id}
                  />
                )
              }
            >
              <i className="fa-solid fa-pen-to-square"></i>
            </button>
          </div>
          <div className="col-3   d-flex justify-content-start align-items-center px-2">
            <button
              type="button"
              className="btn btn-sm p-0"
              onClick={() => {
                enableDisable(row.id, row.isEnabled);
              }}
            >
              {row.isEnabled == "yes" ? (
                <i
                  className=" fa-regular fa-eye"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Désactiver compétition"
                ></i>
              ) : (
                <i
                  className="text-danger fa-regular fa-eye-slash"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="Activer compétition"
                ></i>
              )}
            </button>
          </div>
          <div className="col-3 d-flex justify-content-start align-items-center px-2">
            <Link to={`/admin/oneCompetition/${row.id}`}>
              <button type="button" className="btn p-0 m-0">
                <i
                  className="fa-solid fa-circle-info"
                  title="Details compétition"
                ></i>
              </button>
            </Link>
          </div>
          <div className="col-3 d-flex justify-content-start align-items-center px-2">
            <BoostCompetition CompetitionId={row.id} />
          </div>
        </div>
      ),
    },
  ];
  const [searchTermName, setsearchTermName] = useState("");
  const [searchTermSlug, setsearchTermSlug] = useState("");
  const [searchTermCountry, setsearchTermCountry] = useState("");
  const [showLikedCompetitions, setShowLikedCompetitions] = useState(false);
  function filterCompetition() {
    const items = competitions;
    const results = items
      .filter(
        (item) =>
          item.comp_name &&
          item.comp_name.toLowerCase().includes(searchTermName.toLowerCase())
      )
      .filter((item) => {
        if (typeof item.countryname === "string") {
          return item.countryname
            .toLowerCase()
            .includes(searchTermCountry.toLowerCase());
        }
        return false;
      })
      .filter(
        (item) =>
          item.comp_slug &&
          item.comp_slug.toLowerCase().includes(searchTermSlug.toLowerCase())
      )
      .filter((val) => {
        if (!showLikedCompetitions) {
          return val;
        } else {
          return val.isLiked === "yes";
        }
      });
    setFiltredcompetition(results);
  }
  useEffect(() => {
    filterCompetition();
  }, [
    searchTermName,
    searchTermSlug,
    searchTermCountry,
    showLikedCompetitions,
  ]);
  return (
    <div>
      <section className="pt-3">
        <div className="row px-lg-3 px-5 m-0">
          <div className="col-lg-2 ">
            <label className="text-muted fw-bolder text-nowrap">
              <small>Nom des compétitions</small>
            </label>
            <input
              type="text"
              onChange={(e) => setsearchTermName(e.target.value)}
              className="form-control"
              placeholder="nom des compétitions"
            />
          </div>
          <div className="col-lg-2 ">
            <label className="text-muted fw-bolder">
              <small>Abréviation </small>
            </label>
            <input
              type="text"
              onChange={(e) => setsearchTermSlug(e.target.value)}
              className="form-control"
              placeholder="Abréviation des compétitions"
            />
          </div>
          <div className="col-lg-2 ">
            <label className="text-muted fw-bolder">
              <small>Pays</small>
            </label>
            <select
              className="form-control"
              onChange={(e) => setsearchTermCountry(e.target.value)}
            >
              <option value="">Pays</option>
              {countries.map((country, key) => (
                <option value={country.name} key={key}>
                  {country.name}
                </option>
              ))}
            </select>
          </div>
          <div className="col-lg-3 d-lg-flex justify-content-evenly py-4">
            <div className="toggle_btn form-check form-switch d-md-flex flex-md-row-reverse mt-2  px-md-0 ">
              <label className="form-check-label text-muted">
                {showLikedCompetitions
                  ? "Les compétitions favorites"
                  : "Les compétitions favorites"}
              </label>
              <input
                className="form-check-input me-2"
                type="checkbox"
                id="toggleSwitch"
                checked={showLikedCompetitions}
                onChange={() =>
                  setShowLikedCompetitions(!showLikedCompetitions)
                }
              />
            </div>
          </div>
          <div className="col-lg-2 py-4">
            <button
              className="btn btn-primary float-end btn-lg button-add  "
              type="button"
              variant="primary"
              onClick={() =>
                setcreateCompetitionsModal(
                  <CreateCompetitions
                    setcreateCompetitionsModalFromChild={
                      setcreateCompetitionsModal
                    }
                    getAllCompetitions={getAllCompetitions}
                  />
                )
              }
            >
              +
            </button>
          </div>
        </div>
        <div className="row w-100 m-0">
          <div className="col-lg-12">
            <div className="card-body  pt-3">{data}</div>
          </div>
        </div>
        {addcreateCompetitionsModal}
        {addCompetitionModal}
        {alertModal}
      </section>
    </div>
  );
};

export default Competitions;
