import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LoaderLoading from "../LoaderLoading";

const TeamLiked = ({ teamIsLiked }) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, [teamIsLiked]);
  return (
    <div>
      {loading ? (
        <LoaderLoading />
      ) : teamIsLiked?.length === 0 ? (
        ""
      ) : (
        <div className="card style_liked_cards me-1 mt-lg-0 mt-2">
          <div className="card-title ms-2 mt-2">
            <p className="style-title_liked">Mes Équipes:</p>
          </div>
          {teamIsLiked?.slice(0 , 3).map((item, key) => {
            return (
              <div className="row" key={key}>
                <Link
                  style={{ textDecoration: "none" }}
                  to={`/oneTeam/${item.team.id}`}
                >
                  <div className=" cardFavorisPlayer px-3 py-2">
                    <div className="d-flex flex-nowrap align-items-center">
                      <div>
                        <Link
                          style={{ textDecoration: "none" }}
                          to={`/oneTeam/${item.team.id}`}
                        >
                          <img
                            src={`${
                              baseURL +
                              "/team/getFile" +
                              item.team.logo.substring(1)
                            }`}
                            className="team-logo me-2 align-self-center"
                          />
                        </Link>
                      </div>
                      <div>
                        <Link
                          style={{ textDecoration: "none" }}
                          to={`/oneTeam/${item.team.id}`}
                        >
                          <h5 className="mt-2 style-names_liked name_playerLiked  ">
                            {item.team.name}
                          </h5>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TeamLiked;
