import React, { useState } from "react";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import Box from "@mui/material/Box";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import { useEffect } from "react";
import useAlert from "../../hooks/alertHook";
import { Link } from "react-router-dom";

const PlayersStatsMatchSimpleUser = ({
  matchId,
  playerId,
  playerTeamH,
  playerTeamA,
  teamHomeName,
  teamAwayName,
}) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [PlayersStatsMatch, setPlayersStatsMatch] = useState([]);
  const [minutesPlayed, setminutesPlayed] = useState();
  const [goals, setgoals] = useState();
  const [assists, setassists] = useState();
  const [touches, settouches] = useState();
  const [accPasses, setaccPasses] = useState();
  const [keyPasses, setkeyPasses] = useState();
  const [crossesAcc, setcrossesAcc] = useState();
  const [longBallsAcc, setlongBallsAcc] = useState();
  const [bigChancesCreated, setbigChancesCreated] = useState();
  const [shotsOnTarget, setshotsOnTarget] = useState();
  const [shotsOffTarget, setshotsOffTarget] = useState();
  const [shotsBlocked, setshotsBlocked] = useState();
  const [dribbleAttemptsSucc, setdribbleAttemptsSucc] = useState();
  const [groundDuelsWon, setgroundDuelsWon] = useState();
  const [aerialDuelsWon, setaerialDuelsWon] = useState();
  const [possessionLost, setpossessionLost] = useState();
  const [fouls, setfouls] = useState();
  const [wasFouled, setwasFouled] = useState();
  const [offsides, setoffsides] = useState();
  const [clearances, setclearances] = useState();
  const [blockedShots, setblockedShots] = useState();
  const [interceptions, setinterceptions] = useState();
  const [tackles, settackles] = useState();
  const [dribbledPast, setdribbledPast] = useState();
  const [playerrId, setPlayerId] = useState();
  const [isSideBarOpen, setIsSideBarOpen] = useState(true);
  const [selectedPlayerId, setSelectedPlayerId] = useState(null);
  const [show, setShow] = useState(false);
  const [addStatsModal, setAddStatsModal] = React.useState();
  const { alertModal, displayAlert, dismissAlert } = useAlert();

  function GetPlayerStatsOfMatchBymatchId() {
    if (matchId === undefined) return;
    axios
      .get(`${baseURL}/calendarMatches/playerStatsMatch/${matchId}/${playerId}`)

      .then((resp) => {
        if (resp.data.error) return;
        const PlayerStatsData = resp.data.data;
        setPlayersStatsMatch(resp.data.data);
        setminutesPlayed(PlayerStatsData.minutesPlayed);
        setgoals(PlayerStatsData.goals);
        setassists(PlayerStatsData.assists);
        settouches(PlayerStatsData.touches);
        setaccPasses(PlayerStatsData.accPasses);
        setkeyPasses(PlayerStatsData.keyPasses);
        setcrossesAcc(PlayerStatsData.crossesAcc);
        setlongBallsAcc(PlayerStatsData.longBallsAcc);
        setbigChancesCreated(PlayerStatsData.bigChancesCreated);
        setshotsOnTarget(PlayerStatsData.shotsOnTarget);
        setshotsOffTarget(PlayerStatsData.shotsOffTarget);
        setshotsBlocked(PlayerStatsData.shotsBlocked);
        setdribbleAttemptsSucc(PlayerStatsData.dribbleAttemptsSucc);
        setgroundDuelsWon(PlayerStatsData.groundDuelsWon);
        setaerialDuelsWon(PlayerStatsData.aerialDuelsWon);
        setpossessionLost(PlayerStatsData.possessionLost);
        setfouls(PlayerStatsData.fouls);
        setwasFouled(PlayerStatsData.wasFouled);
        setoffsides(PlayerStatsData.offsides);
        setclearances(PlayerStatsData.clearances);
        setblockedShots(PlayerStatsData.blockedShots);
        setinterceptions(PlayerStatsData.interceptions);
        settackles(PlayerStatsData.tackles);
        setdribbledPast(PlayerStatsData.dribbledPast);
        setPlayerId(PlayerStatsData.playerId);
      });
  }
  useEffect(() => {
    GetPlayerStatsOfMatchBymatchId();
  }, []);
  const selectedPlayerTH = playerTeamH.find((player) => player.id === playerId);
  const selectedPlayerTA = playerTeamA.find((player) => player.id === playerId);

  return (
    <>
      <Box
        className="drawer_stat"
        sx={{
          width:
            isSideBarOpen === "top" || isSideBarOpen === "bottom"
              ? "auto"
              : 250,
        }}
        role="presentation"
      >
        <List>
          {selectedPlayerTH && (
            <div className="row">
              <div className="d-flex  pb-3 ms-2">
                <Link to={`/player/details/${selectedPlayerTH.id}`}>
                  <img
                    width="60"
                    height="60"
                    src={`${baseURL}/players/getFile${selectedPlayerTH.avatar.slice(
                      1
                    )}`}
                    className="rounded me-3"
                  />
                </Link>
                <div className="row">
                  <Link to={`/player/details/${selectedPlayerTH.id}`}>
                    <span className=" col-lg-12 fw-bolder fs-6 text-nowrap name_player">
                      {selectedPlayerTH.firstName} {selectedPlayerTH.lastName}
                    </span>
                  </Link>

                  <div className="col-lg-12 d-flex ">
                    {selectedPlayerTH.rate <= 2 ? (
                      <span className=" rate ps-lg-3 badge text-center d-flex justify-content-center  py-1 rate_player_red">
                        {selectedPlayerTH.rate}
                      </span>
                    ) : selectedPlayerTH.rate > 2 &&
                      selectedPlayerTH.rate <= 4 ? (
                      <span className="ms-2 fw-bold rate ps-lg-3 badge d-flex justify-content-center py-1 rate_player_orange">
                        {selectedPlayerTH.rate}
                      </span>
                    ) : selectedPlayerTH.rate > 4 &&
                      selectedPlayerTH.rate <= 6 ? (
                      <span className="ms-2 fw-bold rate ps-lg-3 badge d-flex justify-content-center py-1 rate_player_yellow">
                        {selectedPlayerTH.rate}
                      </span>
                    ) : selectedPlayerTH.rate > 6 &&
                      selectedPlayerTH.rate <= 8 ? (
                      <span className="ms-2 fw-bold rate ps-lg-3 badge d-flex justify-content-center py-1 rate_player_green">
                        {selectedPlayerTH.rate}
                      </span>
                    ) : (
                      <span className="ms-2 fw-bold rate ps-lg-3 badge d-flex justify-content-center py-1 rate_player_blue">
                        {selectedPlayerTH.rate}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {selectedPlayerTA && (
            <div className="row">
              <div className="d-flex  pb-3 ms-2">
                <Link to={`/player/details/${selectedPlayerTA.id}`}>
                  <img
                    width="60"
                    height="60"
                    src={`${baseURL}/players/getFile${selectedPlayerTA.avatar.slice(
                      1
                    )}`}
                    className="rounded me-3"
                  />
                </Link>
                <div className="row">
                  <Link to={`/player/details/${selectedPlayerTA.id}`}>
                    <span className=" col-lg-12 fw-bolder fs-6 text-nowrap name_player">
                      {selectedPlayerTA.firstName} {selectedPlayerTA.lastName}
                    </span>
                  </Link>
                  <div className="col-lg-12">
                    {selectedPlayerTA.rate <= 2 ? (
                      <span className=" rate ps-lg-3 badge text-center d-flex justify-content-center  py-1 rate_player_red">
                        {selectedPlayerTA.rate}
                      </span>
                    ) : selectedPlayerTA.rate > 2 &&
                      selectedPlayerTA.rate <= 4 ? (
                      <span className="ms-2 fw-bold rate ps-lg-3 badge d-flex justify-content-center py-1 rate_player_orange">
                        {selectedPlayerTA.rate}
                      </span>
                    ) : selectedPlayerTA.rate > 4 &&
                      selectedPlayerTA.rate <= 6 ? (
                      <span className="ms-2 fw-bold rate ps-lg-3 badge d-flex justify-content-center py-1 rate_player_yellow">
                        {selectedPlayerTA.rate}
                      </span>
                    ) : selectedPlayerTA.rate > 6 &&
                      selectedPlayerTA.rate <= 8 ? (
                      <span className="ms-2 fw-bold rate ps-lg-3 badge d-flex justify-content-center py-1 rate_player_green">
                        {selectedPlayerTA.rate}
                      </span>
                    ) : (
                      <span className="ms-2 fw-bold rate ps-lg-3 badge d-flex justify-content-center py-1 rate_player_blue">
                        {selectedPlayerTA.rate}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}

          <Divider className=" bar_stat " />

          <>
            <ListItem disablePadding>
              <ul>
                <div className="d-flex justify-content-between ">
                  <li>Minutes jouées</li>
                  <div className="fw-bold">
                    {PlayersStatsMatch.minutesPlayed}
                  </div>
                </div>
                <div className="d-flex justify-content-between ">
                  <li> Buts </li>
                  <div className="fw-bold">{PlayersStatsMatch.goals}</div>
                </div>
                <div className="d-flex justify-content-between ">
                  <li>Passes décisives </li>
                  <div className="fw-bold">{PlayersStatsMatch.assists}</div>
                </div>
                <Divider className=" bar_stat py-3" />
                <div className="d-flex justify-content-between ">
                  <li>Touches </li>
                  <div className="fw-bold">{PlayersStatsMatch.touches}</div>
                </div>
                <div className="d-flex justify-content-between ">
                  <li>Précision des passes </li>
                  <div className="fw-bold">{PlayersStatsMatch.accPasses}</div>
                </div>
                <div className="d-flex justify-content-between ">
                  <li>Passes clés </li>
                  <div className="fw-bold">{PlayersStatsMatch.keyPasses}</div>
                </div>
                <div className="d-flex justify-content-between ">
                  <li>Centres </li>
                  <div className="fw-bold">{PlayersStatsMatch.crossesAcc}</div>
                </div>
                <div className="d-flex justify-content-between ">
                  <li>Balles longues</li>
                  <div className="fw-bold">
                    {PlayersStatsMatch.longBallsAcc}
                  </div>
                </div>
                <Divider className=" bar_stat py-3 " />
                <div className="d-flex justify-content-between ">
                  <li>Grosses occasions crées</li>
                  <div className="fw-bold">
                    {PlayersStatsMatch.bigChancesCreated}
                  </div>
                </div>
                <div className="d-flex justify-content-between ">
                  <li>Tirs cadrés </li>
                  <div className="fw-bold">
                    {PlayersStatsMatch.shotsOnTarget}
                  </div>
                </div>
                <div className="d-flex justify-content-between ">
                  <li>Tirs non cadrés</li>
                  <div className="fw-bold">
                    {PlayersStatsMatch.shotsOffTarget}
                  </div>
                </div>
                <div className="d-flex justify-content-between ">
                  <li>Tirs bloqués</li>
                  <div className="fw-bold">
                    {PlayersStatsMatch.shotsBlocked}
                  </div>
                </div>
                <div className="d-flex justify-content-between ">
                  <li>Tentatives de dribbles </li>
                  <div className="fw-bold">
                    {PlayersStatsMatch.dribbleAttemptsSucc}
                  </div>
                </div>
                <Divider className=" bar_stat py-3 " />
                <div className="d-flex justify-content-between ">
                  <li>Duels au sol (Remportés)</li>
                  <div className="fw-bold">
                    {PlayersStatsMatch.groundDuelsWon}
                  </div>
                </div>
                <div className="d-flex justify-content-between ">
                  <li>Duels aériens (Remportés)</li>
                  <div className="fw-bold">
                    {PlayersStatsMatch.aerialDuelsWon}
                  </div>
                </div>
                <div className="d-flex justify-content-between ">
                  <li> Perte de possession</li>
                  <div className="fw-bold">
                    {PlayersStatsMatch.possessionLost}
                  </div>
                </div>
                <div className="d-flex justify-content-between ">
                  <li>Fautes </li>
                  <div className="fw-bold">{PlayersStatsMatch.fouls}</div>
                </div>
                <div className="d-flex justify-content-between ">
                  <li>A subi une faute </li>
                  <div className="fw-bold">
                    <div className="fw-bold">{PlayersStatsMatch.wasFouled}</div>
                  </div>
                </div>
                <Divider className=" bar_stat py-3 " />
                <div className="d-flex justify-content-between ">
                  <li>Hors-jeu </li>
                  <div className="fw-bold">{PlayersStatsMatch.offsides}</div>
                </div>
                <div className="d-flex justify-content-between ">
                  <li>Dégagements </li>
                  <div className="fw-bold">{PlayersStatsMatch.clearances}</div>
                </div>
                <div className="d-flex justify-content-between ">
                  <li>Interceptions</li>
                  <div className="fw-bold">
                    {PlayersStatsMatch.interceptions}
                  </div>
                </div>
                <div className="d-flex justify-content-between ">
                  <li>Tacles </li>
                  <div className="fw-bold">{PlayersStatsMatch.tackles}</div>
                </div>
                <div className="d-flex justify-content-between ">
                  <li>Dribblé </li>
                  <div className="fw-bold">
                    {PlayersStatsMatch.dribbledPast}
                  </div>
                </div>
              </ul>
            </ListItem>
            <Divider className=" bar_stat" />
          </>
        </List>
      </Box>
      {addStatsModal}
      {alertModal}
    </>
  );
};

export default PlayersStatsMatchSimpleUser;
