import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { logError } from "../components/Toastconfig";
import { useParams } from "react-router-dom";
import useAlert from "../hooks/alertHook";
const initFormErrors = {
  password: "",
  confirmPassword: ""};
  const ResetPassword = () => {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const { resetToken } = useParams();
    const { alertModal, displayAlert, dismissAlert } = useAlert();
    const navigate = useNavigate();
    const [password, setPassword] = useState("");
    const [confirmPassword, setCofirmPassword] = useState("");
    const [formerrors, setFormErrors] = React.useState(initFormErrors);
    const [errorMsg, setErrorMsg] = useState("");
    
  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .post(`${baseURL}/resetpassword`, {
        password: password,
        confirmPassword: confirmPassword,
        resetToken: resetToken,
      })
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) {
          setErrorMsg(resp.data.message);
        } else {
          setErrorMsg("");
          displayAlert("success", "", resp.data.message);
          setTimeout(() => {
            dismissAlert();
          navigate("/login");
          }, 2500);
        }
      })
      .catch((error) => {
        logError();
        setFormErrors(error.response.data);
        setErrorMsg(error.response.data.message);
      });
  };
 
  return (
    <section className="container-fluid bg-main">
      <div className="container py-5 h-100 ">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col">
            <div className="card card_style border-0 h-100">
              <div className="row g-0">
                <div className="col-md-6 col-lg-6 d-none d-md-block bg-log">
                  <div className="pt-5 px-3">
                    <img src="./assets/imgs/logo.png"></img>
                    <h2 className="my-4 title white">
                      Suivez votre joueur pas à pas, partie par partie !
                    </h2>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 d-flex align-items-center">
                  <div className="card-body text-black ">
                    <div className="">
                      <h2 className="title black mt-4 mb-1 pb-1 text-center">
                        Content de te revoir !
                      </h2>
                      <h4 className="black mt-4 mb-5 pb-1">
                        Terminez ce que vous avez commencé, rédigez votre nouveau mot de passe 
                      </h4>
                    </div>
                    <div className="input-error p-2">
                    <span className="text-danger">{errorMsg}</span>
                  </div>
                    <div
                      className=" d-flex flex-column align-items-center row"
                      onSubmit={handleSubmit}
                    >
                      <div className=" col-6 form-outline text-black mb-4">
                        <label className="form-label ">Nouveau mot de passe </label>
                        <input
                          type="password"
                          id="form2Example11"
                          className="form-control login-input"
                          placeholder="Mot de passe"
                          onChange={(e) => setPassword(e.target.value)}
                          required
                        />
                          <div className="input-error">
                      <span className="text-danger">
                        {formerrors.password}
                      </span>
                    </div>
                      </div>
                      <div className="col-6  form-outline text-black mb-4">
                        <label className="form-label ">Confirmer mot de passe </label>
                        <input
                          type="password"
                          id="form2Example11"
                          className="form-control login-input"
                          placeholder="Confirmer mot de passe"
                          onChange={(e) => setCofirmPassword(e.target.value)}
                          required
                        />
                       <div className="input-error">
                      <span className="text-danger">
                        {formerrors.confirmPassword}
                      </span>
                    </div>
                      </div>
                      <div className="row text-center">
                        <div className="col-lg-6 col-sm-12 mb-3 mb-sm-0">
                          <Link to="/login">
                            <button
                              type="button"
                              className="btn btn-outline-secondary next_btn me-2 btn-sm btn-ipad"
                            >
                              Retour
                            </button>
                          </Link>
                        </div>
                        <div className="col-lg-6 col-sm-12 mb-3 mb-sm-0 log_style">
                          <button
                            className="btn white btn-login btn-ipad"
                            onClick={handleSubmit}
                          >
                            confirmer
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {alertModal}
    </section>
  );
};
export default ResetPassword;
