import React from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import useAlert from "../../hooks/alertHook";

const initFormErrors = {
  title: [""],
  description: [""],
  video: [""],
};
export default function UploadPlayerVideo({ playerId, getPlayerVideos }) {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { alertModal, displayAlert, dismissAlert } = useAlert();
  const [video, setvideo] = useState("");
  const [title, setTitle] = useState("");
  const [description, setdescription] = useState("");
  const [formErrors, setFormErrors] = useState(initFormErrors);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setvideo("");
    setTitle("");
    setdescription("");
    setFormErrors(initFormErrors);
    setShow(false);
  };
  const handleSubmit = () => {
    sendData();
  };
  function sendData() {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("path", video);
    formData.append("playerId", playerId);
    axios
      .post(`${baseURL}/players/playerDetails/uploadvideo`, formData)
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        displayAlert("success", "", resp.data.message);
        setTimeout(() => {
          dismissAlert();
        }, 2500);
        getPlayerVideos();
        handleClose();
      })
      .catch((error) => {
        setFormErrors(error.response.data);
      });
  }
  return (
    <>
      <div className="">
        <button
          className="style_butt btn btn-primary btn-sm"
          style={{ width: "20vh" }}
          type="button"
          variant="primary"
          onClick={handleShow}
        >
          Ajouter une Video
        </button>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={show}
          onHide={handleClose}
        >
          <Modal.Header className="style_modalHeader">
            <div>
              <Modal.Title className="style_modalTitle">
                Télécharger Video
              </Modal.Title>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div className="">
              <div className="row mb-4">
                <div className="col-lg-6 col-sm-12">
                  <h6>Titre</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Titre"
                    onChange={(e) => setTitle(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">{formErrors.title}</span>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-12">
                  <h6>Video</h6>
                  <input
                    required
                    type="file"
                    className="form-control"
                    placeholder="video"
                    onChange={(e) => {
                      setvideo(e.target.files[0]);
                    }}
                  />
                  <div className="input-error">
                    <span className="text-danger">{formErrors.file}</span>
                  </div>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-6 col-sm-12">
                  <h6>Description</h6>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="description"
                    onChange={(e) => setdescription(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {formErrors.description}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={handleClose}
            >
              Annuler
            </button>
            <button
              type="button"
              onClick={handleSubmit}
              className="btn btn-primary"
            >
              Enregistrer
            </button>
          </Modal.Footer>
        </Modal>
      </div>
      {alertModal}
    </>
  );
}
