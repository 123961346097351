import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useCustomContext } from "../context/TogglerContextAPI";
import { UserContext } from "../context/UserContext";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { logError } from "./Toastconfig";
import { Link } from "react-router-dom";

export default function HeaderBar() {
  const [userName, setUserName] = useState("");
  const [role, setRole] = useState("");
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { user, setUser } = useContext(UserContext);
  function logout() {
    axios
      .post(`${baseURL}/logout`)
      .then(() => {
        window.location.reload();
      })
      .catch((err) => {
        logError();
      });
  }

  //context use to know if the sidebar is open
  const { isSideBarOpen } = useCustomContext();
  return (
    <header className="p-0 m-0 fixed-top bg-white header align-items-center">
      <nav className="navbar align-items-center">
        <a className="navbar-brand p-2 mt-2 mt-lg-0" href="#">
          {isSideBarOpen ? (
            <img
              src="/assets/imgs/logo_nav.png"
              alt="M&m Elite Logo"
              className="display-mobile"
            />
          ) : (
            <img
              src="/assets/imgs/favicon.png"
              height="50px;"
              alt="M&m Elite Logo"
            />
          )}
        </a>
        <form className=" flex-row mx-auto search-form d-none d-md-block">
          <div className="input-group">
            <input
              type="search"
              id="search_input"
              className="form-control"
              placeholder="Rechercher matchs,joueurs,statstiques..."
            />
          </div>
        </form>
        <div className="d-flex align-items-center pe-md-5">
          <Navbar >
            <Container fluid>
              <Navbar.Toggle aria-controls="navbar-dark-example" />
              <Navbar.Collapse id="navbar-dark-example">
                <Nav>
                <img
                        src={`${baseURL + "/user/getFile" + user.avatar?.substring(1)}`}
                        width="35"
                        height="35"
                      />
                  <span className="d-none d-md-block ps-2 username">
                    {user && user.name} <br></br>
                    <span className="userrole">{user && user.role}</span>
                  </span>
                  <NavDropdown
                    id="nav-dropdown-dark-example"
                    className="userDropdown"
                    drop="down-centered"
                    // style={{ left: "-100px" }}
                    title=""
                  >
                    <NavDropdown.Item href="#">
                    <Link to={`/admin/users/${user?.id}`}>
                      <span className="link p-0">
                        <i className="bi bi-pen"></i>Modifier Profile
                      </span>
                  </Link>
                    </NavDropdown.Item>
                    <NavDropdown.Item href="#" onClick={logout}>
                      <span className="link p-0">
                        <i className="bi bi-box-arrow-right"></i>
                        <span>Déconnexion</span>
                      </span>
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </div>
      </nav>
    </header>
  );
}
