import React, { useState, useContext, createContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import axios from "axios";
import { logError } from "../components/Toastconfig";
import useAlert from "../hooks/alertHook";
const baseURL = process.env.REACT_APP_BASE_URL;
const initFormErrors = {
  email: "",};
const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [formerrors, setFormErrors] = React.useState(initFormErrors);
  const [errorMsg, setErrorMsg] = useState("");
  const { alertModal, displayAlert, dismissAlert } = useAlert();
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
    .post(`${baseURL}/forgetpassword`, {
      email: email,
      })
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) {
          setErrorMsg(resp.data.message);
        } else {
          setErrorMsg("");
          displayAlert("success", "", resp.data.message);
          setTimeout(() => {
            dismissAlert();
          navigate("/confirmResetSend");
          }, 2500);
        }
      })
      .catch((error) => {
        logError();
        setFormErrors(error.response.data);
        setErrorMsg(error.response.data.message);
      });
  };
 
  return (
    <section className="container-fluid bg-main">
      <div className="container py-5 h-100 ">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col">
            <div className="card card_style border-0 h-100">
              <div className="row g-0">
                <div className="col-md-6 col-lg-6 d-none d-md-block bg-log">
                  <div className="pt-5 px-3">
                    <img src="./assets/imgs/logo.png"></img>
                    <h2 className="my-4 title white">
                      Suivez votre joueur pas à pas, partie par partie !
                    </h2>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 d-flex align-items-center">
                  <div className="card-body text-black ">
                    <div className="">
                      <h2 className="title black mt-4 mb-1 pb-1 text-center">
                        Content de te revoir !
                      </h2>
                      <h4 className="black mt-4 mb-5 pb-1">
                        Terminez ce que vous avez commencé, si vous avez oublier votre mot de passe 
                      </h4>
                    </div>
                    <div className="input-error p-2">
                      <span className="text-danger">
                        {errorMsg}
                      </span>
                    </div>
                    <div
                      className=" d-flex flex-column align-items-center"
                      onSubmit={handleSubmit}
                    >
                      <div className="form-outline text-black mb-4">
                        <label className="form-label ">Saisir votre email </label>
                        <input
                          type="email"
                          id="form2Example11"
                          className="form-control login-input"
                          placeholder="Email"
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      <div className="input-error">
                      <span className="text-danger">
                        {formerrors.email}
                      </span>
                      </div>
                    </div>
                      <div className="row text-center">
                        <div className="col-lg-6 col-sm-12 mb-3 mb-sm-0">
                          <Link to="/login">
                            <button
                              type="button"
                              className="btn btn-outline-secondary next_btn me-2 btn-sm btn-ipad"
                            >
                              Retour
                            </button>
                          </Link>
                        </div>
                        <div className="col-lg-6 col-sm-12 mb-3 mb-sm-0 log_style">
                          <button
                            className="btn white btn-login btn-ipad"
                            onClick={handleSubmit}
                          >
                            confirmer
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {alertModal}
    </section>
  );
};
export default ForgetPassword;
