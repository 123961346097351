import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import "bootstrap/dist/css/bootstrap.min.css";
import { useParams } from "react-router-dom";
import EmptyData from "../emptyData";
import axios from "axios";
import { Link } from "react-router-dom";

export default function VideoPlayerByMatch() {
  const { playerId } = useParams();
  const [playervideosByMatch, setplayervideosByMatch] = useState([]);

  const baseURL = process.env.REACT_APP_BASE_URL;
  const [currentVideo, setCurrentVideo] = useState();
  const [teamVideo, setTeamVideo] = useState();
  function getVideosByPlayerId() {
    axios.get(`${baseURL}/players/playersVideos/${playerId}`).then((resp) => {
      if (resp.data.error) return;
      setplayervideosByMatch(resp.data.data.videoMatchs);
      setCurrentVideo(resp.data.data.videoMatchs[0]);
      setTeamVideo(resp.data.data.videoMatchs);
    });
  }
  useEffect(() => {
    getVideosByPlayerId();
  }, []);
  const handleVideoClick = (video) => {
    setCurrentVideo(video);
  };
  return (
    <div className="container ml-0 mt-4">
      {playervideosByMatch?.length === 0 ? (
        <div className="row">
          <div className="col-lg-12">
            <EmptyData />
          </div>
        </div>
      ) : (
        <div className="container">
          <div className="row mb-5">
          <div className="col-md-8 col-12 ">
              <div className="video-player">
                <ReactPlayer
                  url={`${
                    baseURL +
                    "/competition/getFile" +
                    currentVideo.path.slice(1)
                  }`}
                  controls
                  playIcon={
                    <button className="btn">
                      <i className="fa-sharp fa-solid fa-circle-play button-play"></i>
                    </button>
                  }
                  width="96%"
                  height="auto"
                />
                <div>
                  <h2>{currentVideo.title.substring(0, 30) + ".."}</h2>
                  <div className="row">
                    <div className="col-5 text-nowrap">
                      <div className="overlay">
                        <Link
                          to={`/oneTeam/${currentVideo.calendarMatch.homeTeam?.id}`}
                        >
                          <img
                            src={`${baseURL}/team/getFile${currentVideo.calendarMatch.homeTeam?.logo.slice(
                              1
                            )}`}
                            className="videoMatch_Team"
                          />
                          <span className="team_name fw-bolder fs-md-6">
                            {currentVideo.calendarMatch.homeTeam?.name}
                          </span>
                        </Link>
                      </div>
                    </div>
                    <div className="col-2 text-center">VS</div>
                    <div className="col-5 text-end text-nowrap">
                      <div className="overlay">
                        <Link
                          to={`/oneTeam/${currentVideo.calendarMatch.awayTeam?.id}`}
                        >
                          <img
                            src={`${baseURL}/team/getFile${currentVideo.calendarMatch.awayTeam?.logo.slice(
                              1
                            )}`}
                            className="videoMatch_Team"
                          />
                          <span className="team_name fw-bolder fs-md-6 ">
                            {currentVideo.calendarMatch.awayTeam?.name}
                          </span>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-12 mt-md-0 mt-2">
              <div className="scrollable-container">
                <div className=" p-0 pe-1">
                  <div className="video-list">
                    {playervideosByMatch?.map((video, index) => (
                      <div
                        className="style-videos mb-3"
                        key={index}
                        onClick={() => handleVideoClick(video)}
                      >
                        <video
                          style={{
                            cursor: "pointer",
                          }}
                          className="style_video_list "
                          src={`${
                            baseURL +
                            "/competition/getFile" +
                            video.path.slice(1)
                          }`}
                          alt={video.title}
                        />
                        <div
                          className="overlay px-2"
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <p className="videoTitle fw-bold fs-6 pt-1">
                            {video.title.substring(0, 30) + ".."}
                          </p>
                          <p className="text-muted ">
                            {video.description.length > 100
                              ? `${video.description.slice(0, 100)}...`
                              : video.description}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
