import React from "react";
import "../index.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Link } from "react-router-dom";

export const Welcome = ({ setChildCompnent }) => {
  return (
    <section className="container-fluid bg-main">
      <div className="container py-5 h-100 ">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col">
            <div className="card card_style border-0 h-100">
              <div className="row g-0 row-card">
                <div className="col-md-6 col-lg-6 d-none d-md-block bg-log">
                  <div className="pt-5 px-3">
                    <img src="./assets/imgs/logo.png"></img>
                    <h2 className="my-4 title white">
                      La meilleure plateforme pour trouver le meilleur joueur !
                    </h2>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 d-flex align-items-center">
                  <div className="card-body text-black ">
                    <div className="">
                      <h2 className="title black mt-4 mb-1 pb-1 text-center">
                        Hey ! Content de te voir
                      </h2>
                    </div>

                    <div className="d-grid gap-2 col-6 mx-auto">
                      <Link to="/register">
                        <button
                          type="button"
                          className="btn btn-primary mt-5 mb-3 fs-5"
                        >
                          Créer un compte
                        </button>
                      </Link>
                      <Link to="/login">
                        <button
                          type="button"
                          className=" btn btn-light fs-5 buttonconection"
                        >
                          S'authentifier
                        </button>
                      </Link>
                    </div>
                    <div className="text-center pt-md-4 mx-md-4  mt-5">
                      <p className="text-black">
                        Vous n'avez pas de compte ?{" "}
                        <Link to="/register" className="btn-click">
                        Inscrivez-vous !
                        </Link>
                      </p>
                    </div>
                    <div className=" text-center px-md-4 mx-md-4  mt-2">
                      <a href="#">
                        <i className="fa-brands fa-facebook-f p-2 "></i>
                      </a>
                      <a href="#">
                        <i className="fa-brands fa-instagram p-2"></i>
                      </a>
                      <a href="#">
                        <i className="fa-brands fa-youtube p-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
