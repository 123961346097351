import React, { useEffect, useState } from "react";
import "react-phone-input-2/lib/style.css";
import { Link } from "react-router-dom";
import LoaderLoading from "../LoaderLoading";

const DisplayLikedCompetitions = ({ competitionLiked }) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setLoading(false);
  }, [competitionLiked]);

  return (
    <div>
      {loading ? (
        <LoaderLoading />
      ) : competitionLiked?.length === 0 ? (
        ""
      ) : (
        <div className="card style_liked_cards mt-2 me-1">
          <div className="card-title">
            <p className="style-title_liked ms-2 mt-2">Mes compétitions:</p>
          </div>
          {competitionLiked?.slice(0, 3).map((comp, key) => {
            return (
              <div className="row" key={key}>
                <Link
                  className="style_text_deco"
                  to={`/oneCompetition/${comp.competitionId}`}
                >
                  <div className=" cardFavorisPlayer px-3 py-2">
                    <div className="d-flex flex-nowrap align-items-center">
                      <div>
                        <Link
                          className="style_text_deco"
                          to={`/oneCompetition/${comp.competitionId}`}
                        >
                          <img
                            className="team-logo me-2 align-self-center"
                            src={
                              baseURL +
                              "/competition/getFile" +
                              comp.competition.logo.substring(1)
                            }
                          />
                        </Link>
                      </div>
                      <div>
                        <Link
                          className="textDeco"
                          to={`/oneCompetition/${comp.competitionId}`}
                        >
                          <h5 className="mx-2 mt-3 name_competitionsLiked">
                            {comp.competition.comp_name}
                          </h5>
                        </Link>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DisplayLikedCompetitions;
