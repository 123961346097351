import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const GlobalStatistics = ({ attack, tecnique, Tactic, deffence, creativity}) => {
  const [statsTable, setStatsTable] = useState([attack, tecnique, Tactic, deffence, creativity]);
 
  useEffect(() => {
  },[attack,deffence,creativity,Tactic,tecnique])
  useEffect(() => {
    setStatsTable([
      attack,
     tecnique,
     Tactic,
     deffence,
     creativity,
    ]);  
  }, [attack,deffence,creativity,Tactic,tecnique]);

  let state1 = {
    series: [
      {
        name: "Attaque",
        data: statsTable,
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "radar",
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1,
        },
      },
      stroke: {
        width: 2,
      },
      fill: {
        opacity: 0.1,
      },
      markers: {
        size: 0,
      },
      xaxis: {
        categories: ["ATT", "TEC", "TAC", "DEF", "CRE"],
      },
    },
  };

  return (
    <div>
      <div>
      <Chart
                options={state1.options}
                series={state1.series}
                type="radar"
                height={350}
              />
       
      </div>
     
    </div>
  );
};

export default GlobalStatistics;
