import Modal from "react-bootstrap/Modal";
import React, { useEffect, useState } from "react";
import useAlert from '../../hooks/alertHook';
import axios from 'axios';
const initFormErrors = {
  description: [""],
};
const UpdateObservation = ({commentaire,observationId,GetObservationsByPlayerId}) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [show, setShow] = useState(false);
  const handleShow = () =>{ 
      setShow(true);}
  const handleClose = () => {
      setShow(false);
  };
  const [maxLength, setmaxLength] = React.useState(255);
  const [description, setDescription] = useState(commentaire);
  const remainingChars = maxLength - description?.length;
  const [formErrors, setFormErrors] = useState(initFormErrors);

  const { alertModal, displayAlert, dismissAlert } = useAlert();

  function UpdateObservation() {
    axios
      .put(
        `${baseURL}/players/UpdateObservation/${observationId}` ,{
          description: description
        })
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        displayAlert("success", "", "Cette observation est modifiée avec succés!");
        setTimeout(() => {
          dismissAlert();
          handleClose();
          GetObservationsByPlayerId();
        }, 2500);
      })
      .catch((error) => { 
        setFormErrors(error.response.data);
      });
  }

  function handlclik (){
    let message = "Voulez-vous modifier cette observation ?";
    displayAlert("danger", "", message, "Confirmer", () => {
      UpdateObservation();
      dismissAlert();
    }, "cancel", dismissAlert)
  }
  return (
  <>
    <div>
      <button
      className="btn"
      type="button"
      variant="primary"
      onClick={handleShow}
    >
       <i className="fa-solid fa-pen-to-square"></i>
    </button>
    </div>
     <Modal
     size="lg"
     aria-labelledby="contained-modal-title-vcenter"
     centered
     show={show}
     onHide={handleClose}
 >
     <Modal.Header className="style_modalHeader">
         <div>
             <Modal.Title className="style_modalTitle">
                 Modifier Observation
             </Modal.Title>
         </div>
     </Modal.Header>
     <Modal.Body>
                        <div className="">
                            <div className="row mb-4">
                                <div className="col-lg-12 col-sm-12 mb-1">
                                    <textarea
                                        rows={4}
                                        cols={40}
                                        maxLength={maxLength}
                                        className="form-control"
                                        value={description}
                                        placeholder="ecrivez vos observations ..."
                                        onChange={(e) => setDescription(e.target.value) }
                                        required
                                    />
                                        <div className="input-error">
                    <span className="text-danger">{formErrors.description}</span>
                  </div>
                                    <div>{remainingChars} / {maxLength} characters</div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
     <Modal.Footer>
     <button
             type="button"
             className="btn btn-secondary"
             data-bs-dismiss="modal"
             onClick={handlclik}
         >
             Modifier
         </button> <button
             type="button"
             className="btn btn-secondary"
             data-bs-dismiss="modal"
             onClick={handleClose}
         >
             Fermer
         </button>
     </Modal.Footer>
 </Modal>
 {alertModal}
 </>
  )
}

export default UpdateObservation
