import React from "react";
import { useState, useEffect } from "react";
import { Carousel, Container, Row, Col, Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoaderLoading from "../../components/LoaderLoading";

const AdsCompetition = ({ adsCompetitions }) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [adsCompetition, setAdsCompetitions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(false);
  }, [adsCompetitions]);
  return (
    <Container className="px-2">
      {isLoading ? (
        <LoaderLoading />
      ) : (
        <Carousel>
          {adsCompetitions?.map((competition, key) => (
            <Carousel.Item key={key}>
              <Card className="style_ads">
                <Card.Body>
                  <Row>
                    <Col md={3} className="text-center mt-2">
                      <h5 className="text-white text-nowrap fw-bold ">
                      BRILLANT
                      </h5>
                      <h5 className="text-white text-nowrap fw-bold ">
                      CETTE
                      </h5>
                      <h5 className="text-white text-nowrap fw-bold ">
                      SEMAINE !
                      </h5>
                    </Col>

                    <Col md={5} className="text-center">
                      <h5 className="text-white text-start">
                        {competition.comp_name}
                      </h5>
                      <div className="row mt-4">
                        <div className="text-start">
                          <h6 className="text-white fs-6">
                            Slug :
                            <span className="fw-bold ms-2">
                              {competition.comp_slug}
                            </span>
                          </h6>
                          <h6 className="text-white fs-6">
                            Date Debut :
                            <span className="fw-bold ms-2">
                              {competition.date_debut}
                            </span>
                          </h6>

                          <h6 className="text-white">
                            Pays :
                            <span className="fw-bold">
                              {competition.Pays.name}
                            </span>
                          </h6>
                        </div>
                      </div>
                    </Col>
                    <Col md={4} className="text-center">
                      <div>
                        <img
                          src={`${
                            baseURL +
                            "/competition/getFile" +
                            competition.logo.substring(1)
                          }`}
                          className="imgADS"
                        />
                      </div>
                      <div className="pt-3">
                        <Link to={`/oneCompetition/${competition.id}`}>
                          <button
                            type="button"
                            className=" style_button_decouvrir"
                          >
                            Découvrir{" "}
                            <i className="fa-solid fa-arrow-right"></i>
                          </button>
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Carousel.Item>
          ))}
        </Carousel>
      )}
    </Container>
  );
};

export default AdsCompetition;
