import React, { useState, useContext, createContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import axios from "axios";
import { logError } from "../components/Toastconfig";
const baseURL = process.env.REACT_APP_BASE_URL;

const Login = ({ setIsLoggedIn }) => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const { user, setUser } = useContext(UserContext);
  const [errMsg, setErrMsg] = useState({
    email: "",
    password: "",
    status: "",
  });
  //to intialize error messages
  function initErrorMessage() {
    setErrMsg({
      email: "",
      password: "",
      status: "",
    });
  }
  useEffect(() => {
    if (user === undefined) return;
    navigate("/");
  });

  //hundelesubmit
  const handleSubmit = async (e) => {
    initErrorMessage();
    try {
      await axios
        .post(`${baseURL}/login`, { email, password })
        .then((response) => {
          setUser(response.data.data);
          setIsLoggedIn("yes");
        });
    } catch (error) {
      logError();
      if (error.response.data.message) {
        setErrMsg(error.response.data.message);
      } else {
        navigate("/home");
      }
    }
  };
  //to set email
  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };
  //to set password
  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleSubmit();
    }
  };
  return (
    <section className="container-fluid bg-main">
      <div className="container py-5 h-100 ">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col">
            <div className="card card_style border-0 h-100">
              <div className="row g-0">
                <div className="col-md-6 col-lg-6 d-none d-md-block bg-log">
                  <div className="pt-5 px-3">
                    <img src="./assets/imgs/logo.png"></img>
                    <h2 className="my-4 title white">
                      Suivez votre joueur pas à pas, partie par partie !
                    </h2>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 d-flex align-items-center">
                  <div className="card-body text-black ">
                    <div className="">
                      <h2 className="title black mt-4 mb-1 pb-1 text-center">
                        Content de te revoir !
                      </h2>
                      <h4 className="black mt-4 mb-5 pb-1">
                        Terminez ce que vous avez commencé, de nouvelles
                        statistiques sont ici
                      </h4>
                    </div>
                    <p className="text-danger" aria-live="assertive">
                      {errMsg.status != null && errMsg.status}
                    </p>
                    <div
                      className=" d-flex flex-column align-items-center"
                      onSubmit={handleSubmit}
                    >
                      <div className="form-outline text-black mb-4">
                        <label className="form-label ">Email </label>
                        <input
                          type="email"
                          id="form2Example11"
                          className="form-control login-input"
                          placeholder="Email"
                          onChange={onChangeEmail}
                          required
                        />
                        <p className="text-danger" aria-live="assertive">
                          {errMsg.email != null && errMsg.email}
                        </p>
                      </div>

                      <div className="form-outline text-black mb-4">
                        <label className="form-label">Mot de passe</label>
                        <input
                          type="password"
                          id="form2Example22"
                          autoComplete="off"
                          className="form-control login-input"
                          placeholder="Mot de passe"
                          onChange={onChangePassword}
                          required
                          onKeyDown={handleKeyDown}
                        />
                        <p className="text-danger" aria-live="assertive">
                          {errMsg.password != null && errMsg.password}
                        </p>
                      </div>

                      <div className="form-outline mb-4">
                        <p className="text-black">
                          Mot de passe ou email oublié ?
                          <Link to="/forgetPassword" className="btn-click">
                            Cliquez ici
                            </Link>
                        </p>
                      </div>

                      <div className="row text-center">
                        <div className="col-lg-6 col-sm-12 mb-3 mb-sm-0">
                          <Link to="/">
                            <button
                              type="button"
                              className="btn btn-outline-secondary next_btn me-2 btn-sm btn-ipad"
                            >
                              Retour
                            </button>
                          </Link>
                        </div>
                        <div className="col-lg-6 col-sm-12 mb-3 mb-sm-0 log_style">
                          <button
                            className="btn white btn-login btn-ipad"
                            onClick={handleSubmit}
                          >
                        S'authentifier
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="text-center pt-md-4 mx-md-4  mt-5">
                      <p className="text-black">
                        Vous n'avez pas de compte ?{" "}
                        <Link to="/register" className="btn-click">
                        Inscrivez-vous !
                        </Link>
                      </p>
                    </div>
                    <div className=" text-center px-md-4 mx-md-4  mt-2">
                      <a href="#">
                        <i className="fa-brands fa-facebook-f p-2 "></i>
                      </a>
                      <a href="#">
                        <i className="fa-brands fa-instagram p-2"></i>
                      </a>
                      <a href="#">
                        <i className="fa-brands fa-youtube p-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Login;
