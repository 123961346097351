import axios from "axios";
import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import EmptyData from "../emptyData";
import { logError } from "../Toastconfig";

const TransferHistory = (playerId) => {
  const baseURL = process.env.REACT_APP_BASE_URL;

  const [transfers, setTransfers] = useState([]);

  function getALLTransfers() {
    axios
      .get(`${baseURL}/transfers/player/${playerId.playerId}`)
      .then((res) => {
        setTransfers(res.data.data);
      })
      .catch((err) => {
        logError();
      });
  }

  useEffect(() => {
    getALLTransfers();
  }, []);
  return (
    <div>
      <div className="row mt-4 mx-0 ">
        <div className="table-responsive">
          <Table className="table" style={{ overflow: "Scroll" }}>
            <thead className="style_thead">
              <tr>
                <th className="style_tab style_th px-4 ">À domicile</th>
                <th className="style_tab style_th px-4">Type</th>
                <th className="style_tab style_th px-4">À l'extérieur</th>
                <th className="style_tab style_th  px-4 text-nowrap">
                  Date Debut Transfer
                </th>
                <th className="style_tab style_th  px-4 text-nowrap">
                  Date Fin Transfer
                </th>
              </tr>
            </thead>
            {transfers.length === 0 ? (
              <div className="row">
                <div className="col-lg-12">
                  <EmptyData />
                </div>
              </div>
            ) : (
              transfers.map((transfer) => (
                <>
                  <tbody className="my-4 style_tbody ">
                    <tr className="border-b style_tbody text-nowrap">
                      <td className="py-4 px-4 text-nowrap">
                        <div className="style_flex">
                          <img
                            className="ps-1 pe-2 teamImgSelected"
                            src={
                              baseURL +
                              "/team/getFile" +
                              transfer.team_fromLogo.substring(1)
                            }
                          ></img>
                          <span className="teamTransfer">
                            {transfer.team_from}
                          </span>
                        </div>
                      </td>
                      <td className="py-4 px-4 text-nowrap">{transfer.type}</td>
                      <td className="py-4  px-4 text-nowrap">
                        <div className="style_flex">
                          <img
                            className="ps-1 pe-2 teamImgSelected"
                            src={
                              baseURL +
                              "/team/getFile" +
                              transfer.team_toLogo.substring(1)
                            }
                          ></img>
                          <span className="teamTransfer">
                            {transfer.team_to}
                          </span>
                        </div>
                      </td>
                      <td className="py-4 px-4 text-nowrap text-start">
                        {transfer.date_debut_transfer}
                      </td>
                      <td className="py-4 px-4  text-nowrap text-start">
                        {transfer.date_fin_transfer}
                      </td>
                    </tr>
                  </tbody>
                </>
              ))
            )}
            {}
          </Table>
        </div>
      </div>
    </div>
  );
};

export default TransferHistory;
