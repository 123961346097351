import React from "react";

const Loader = () => {
  return (
    <div>
      <div className="text-center pt-5">
        <div className="processing-overlay">
          <div className="loader">
            <div className="loader-ball"></div>
            <div className="loader-shadow"></div>
            <div className="fs-5 fw-bold">Loading... </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
