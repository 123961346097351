import React from 'react'
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import useAlert from '../../hooks/alertHook';
const initFormErrors = {
    description: [""],
  };
export default function CreateObservation({playerId,GetObservationsByPlayerId}) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const [maxLength, setmaxLength] = React.useState(255);
    const { alertModal, displayAlert, dismissAlert } = useAlert();
    const [description, setDescription] = useState("");
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => {
        setDescription("");
        setShow(false);
    };
  const [formErrors, setFormErrors] = useState(initFormErrors);

    const remainingChars = maxLength - description.length;
    const handleSubmit = () => {
        axios
      .post(`${baseURL}/players/observations`, {
        description: description,
        playerId: playerId,
      })
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        displayAlert("success", "", resp.data.message);
        setTimeout(() => {
            GetObservationsByPlayerId();
            dismissAlert();
            handleClose();
        }, 2500);
      })
      .catch((error) => { 
        setFormErrors(error.response.data);
      });
    };
    return (
        <>
            <div className="">
                <button
                    className="btn btn-primary btn-sm"
                    type="button"
                    variant="primary"
                    onClick={handleShow}
                >
                    Ajouter une Observation
                </button>
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={show}
                    onHide={handleClose}
                >
                    <Modal.Header className="style_modalHeader">
                        <div>
                            <Modal.Title className="style_modalTitle">
                                Ajouter une Observation
                            </Modal.Title>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="">
                            <div className="row mb-4">
                                <div className="col-lg-12 col-sm-12 mb-1">
                                    <textarea
                                        rows={4}
                                        cols={40}
                                        maxLength={maxLength}
                                        className="form-control"
                                        placeholder="ecrivez vos observations ..."
                                        onChange={(e) => setDescription(e.target.value) }
                                        required
                                    />
                                     <div className="input-error">
                    <span className="text-danger">{formErrors.description}</span>
                  </div>
                                    <div>{remainingChars} / {maxLength} characters</div>
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                            onClick={handleClose}
                        >
                            Annuler
                        </button>
                        <button
                            type="button"
                            onClick={handleSubmit}
                            className="btn btn-primary"
                        >
                            Ajouter
                        </button>
                    </Modal.Footer>
                </Modal>
            </div>
            {alertModal}
        </>
    )
}
