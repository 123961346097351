import React from "react";

const NotAllowed = () => {
  return (
    <div className="mt-5">
      <div class="wrapper">
        <div class="section _404">
          <div class="container error w-container">
            <div class="error-wrapper">
              <div class="big-score">4</div>
              <img
                src="https://uploads-ssl.webflow.com/5b125a6e804c1f1811d564c3/5b1fbf7d1b1760fd3223d2fa_404-ball.png"
                data-w-id="e59a5b13-f461-223f-8a5b-c5588a8f8ff3"
                class="_404-ball"
                style={{
                  transform:
                    "translateX(0px) translateY(-3.404px) translateZ(0px) scaleX(1) scaleY(1) scaleZ(1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skewX(0deg) skewY(0deg)",
                  willChange: "transform",
                }}
              />
              <div class="big-score">3</div>
            </div>
            <div class="error-text">
              Sorry, this page was not allowed
              <br />
            </div>
            <a href="#" class="link goback">
              GO&nbsp;BACK
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotAllowed;
