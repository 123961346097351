import React, { useState } from "react";
import axios from "axios";
import "react-phone-input-2/lib/style.css";
import useAlert from "../../hooks/alertHook";

const StatusTeam = ({ team, getall,getOne }) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { alertModal, displayAlert, dismissAlert } = useAlert();
  const handleShow = async () => {
    displayAlert(
      "info",
      "",
      "voulez-vous changer le statut de l'équipe",
      "Confirmer",
      () => {
        const formData = new FormData();
        formData.append("name", team.isEnabled);
        axios
          .put(`${baseURL}/teams/enable/${team.id}`, formData)
          .then((resp) => {
            if (resp.data.error) return;
            if (resp.data.message)
            displayAlert("success","",resp.data.message);
            setTimeout(() => {
              dismissAlert();
           },2500);
           if (getOne) {
            getOne();
          }
          if (getall) {
            getall();
          }
        })
          .catch((error) => {});
      },
      "cancel",
      dismissAlert
    );
  };
  return (
    <>
      <div>
        <button type="button" className="btn btn-sm p-0" onClick={handleShow}>
          {team.isEnabled == "yes" ? (
            <i
              className=" fa-regular fa-eye"
              data-toggle="tooltip"
              data-placement="top"
              title="Désactiver Team"
            ></i>
          ) : (
            <i
              className="text-danger fa-regular fa-eye-slash"
              data-toggle="tooltip"
              data-placement="top"
              title="Activer Team"
            ></i>
          )}
        </button>
      </div>
      {alertModal}
    </>
  );
};

export default StatusTeam;
