import React from "react";
import { useState, useEffect } from "react";
import Deletenews from "../../components/news/Deletenews";
import Statusnews from "../../components/news/Statusnews";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { Link } from "react-router-dom";
import { logError } from "../../components/Toastconfig";
import EmptyData from "../../components/emptyData";
import LoaderLoading from "../../components/LoaderLoading";

export default function News() {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [newslist, setNewslist] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(function () {
    getallnews();
  }, []);

  function getallnews() {
    axios
      .get(`${baseURL}/news/all`)
      .then((res) => {
        setNewslist(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        logError();
        setIsLoading(false);
      });
  }

  return (
    <>
      <section className="pt-5 ">
        <div className="row mx-3">
          <div className="col-4">
            <h4 className="text-nowrap">Mes Actualités</h4>
          </div>
          <div className="col-8 px-3">
            <Link to="/admin/news/create/">
              <button
                className="btn btn-primary float-end btn-lg button-add"
                type="button"
                variant="primary"
              >
                {" "}
                +
              </button>
            </Link>
          </div>
        </div>
        <div className="row mt-4 mx-0 p-scroll">
          <div className="table-responsive">
            {isLoading ? (
              <LoaderLoading />
            ) : newslist.length === 0 ? (
              <div className="row">
                <div className="col-lg-12">
                  <EmptyData />
                </div>
              </div>
            ) : (
              <Table className="table">
                <thead className="style_thead">
                  <tr>
                    <th className="p-4 style_tab style_th text-nowrap">
                      Titre
                    </th>
                    <th className="p-4 style_tab style_th text-nowrap">
                      status
                    </th>
                    <th className="p-4 style_tab style_th text-nowrap">
                      date publication
                    </th>
                    <th className="p-4 style_tab style_th text-nowrap">
                      créé par
                    </th>
                    <th className="p-4 style_tab style_th text-center text-nowrap">
                      Actions
                    </th>
                  </tr>
                </thead>
                {newslist.map((news, key) => (
                  <tbody className="my-2 style_tbody" key={key}>
                    <tr className="border-b style_tbody text-nowrap">
                      <td className="px-4 text-nowrap ">
                        <Link
                          className="textDeco"
                          to={`/admin/news/details/${news.id}`}
                        >
                          <img
                            src={`${baseURL + "/new/getFile" + news.media}`}
                            className="rounded-circle"
                            width="35"
                            height="35"
                          />
                          <span className="ps-2">
                            {news.title.length > 40 ? (news.title.substring(0, 40) + "...") : news.title}
                          </span>
                        </Link>
                      </td>
                      <td className="px-4 text-nowrap ">
                        {news.ispublished ? "publiée" : "non publiée"}
                      </td>
                      <td className="px-4 text-nowrap ">
                        {news.createdat.substring(0, 10)}
                      </td>
                      <td className="px-4 text-nowrap ">{news.isOwner.name}</td>
                      <td className="px-4 d-flex justify-content-evenly">
                        <div>
                          {/* edit news */}

                          <Link to={`/admin/news/update/${news.id}`}>
                            {" "}
                            <button type="button" className="btn btn-update">
                              <i className="fa-solid fa-pen-to-square"></i>
                            </button>
                          </Link>
                        </div>
                        <div>
                          {/* change status */}
                          <Statusnews news={news} getallnews={getallnews} />
                        </div>
                        <div>
                          {/* delelte news */}
                          <Deletenews news={news} getallnews={getallnews} />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                ))}
              </Table>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
