import React, { createContext, useContext, useState } from "react";

export const ToggleContext = createContext();

export const TogglerContextAPI = ({ children }) => {
  const [isSideBarOpen, setIsSideBarOpen] = useState(true);
  const handleSideBar = (status) => {
    if (status) {
      setIsSideBarOpen(true);
    } else {
      setIsSideBarOpen(false);
    }
  };
  return (
    <ToggleContext.Provider value={{ isSideBarOpen, handleSideBar }}>
      {children}
    </ToggleContext.Provider>
  );
};

export const useCustomContext = () => useContext(ToggleContext);
