import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import "react-phone-input-2/lib/style.css";
import useAlert from "../../hooks/alertHook";
const BoostTeam = ({ TeamId }) => {
  const baseURL = process.env.REACT_APP_BASE_URL;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const today = new Date().toISOString().substr(0, 10);
  const tomorrow = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)
    .toISOString()
    .substr(0, 10);
  const [date_Debut_Boost, setDate_Debut_Boost] = useState(today);
  const [date_Fin_Boost, setDate_Fin_Boost] = useState(tomorrow);
  const [date_Debut_BoostError, setDate_Debut_BoostError] = useState(null);
  const [date_Fin_BoostError, setDate_Fin_BoostError] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const { alertModal, displayAlert, dismissAlert } = useAlert();

  const handleSubmit = () => {
    const data = {
      teamId: TeamId,
      date_Debut_Boost: date_Debut_Boost,
      date_Fin_Boost: date_Fin_Boost,
    };

    axios
      .post(`${baseURL}/teams/boost`, data)
      .then((resp) => {
        if (resp.data.message) {
          displayAlert("success","",resp.data.message);
          setTimeout(() => {
            dismissAlert();
         },2500);
          setShow(false);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          const errors = error.response.data;
          if (errors.message) {
            setErrorMessage(errors.message);
          } else {
            setErrorMessage("");
          }
          if (errors.date_Debut_Boost) {
            setDate_Debut_BoostError(errors.date_Debut_Boost);
          } else {
            setDate_Debut_BoostError("");
          }
          if (errors.date_Fin_Boost) {
            setDate_Fin_BoostError(errors.date_Fin_Boost);
          } else {
            setDate_Fin_BoostError("");
          }
        }
      });
  };

  return (
    <>
      <div>
        <button type="button" className="btn btn-sm p-0" onClick={handleShow}>
          <i
            className="bi bi-lightning-fill"
            title="Booster  équipe"
            style={{ color: "#000" }}
          ></i>
        </button>
      </div>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="style_modalHeader">
          <div>
            <Modal.Title className="style_modalTitle">
              Booster cette équipe
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="row mb-4">
              {errorMessage && <div className="style_err">{errorMessage}</div>}

              <div className="col-lg-6">
                <h6 className="fw-bold color_black"> Date debut </h6>
                <input
                  onChange={(e) => setDate_Debut_Boost(e.target.value)}
                  id="date_Debut_BoostError"
                  type="date"
                  value={date_Debut_Boost}
                  min={today}
                  name="date_Debut_BoostError"
                  className="form-control"
                  placeholder="Date debut Boost"
                />
                {date_Debut_BoostError && (
                  <div className="style_err">{date_Debut_BoostError}</div>
                )}
              </div>
              <div className="col-lg-6">
                <h6 className="fw-bold color_black">Date Fin </h6>
                <input
                  required
                  onChange={(e) => setDate_Fin_Boost(e.target.value)}
                  type="date"
                  value={date_Fin_Boost}
                  min={today}
                  id="date_Fin_BoostError"
                  name="date_Fin_BoostError"
                  className="form-control"
                  placeholder="Date debut Boost"
                />
                {date_Fin_BoostError && (
                  <div className="style_err">{date_Fin_BoostError}</div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleSubmit}>
            Booster
          </Button>
          <Button
            variant="secondary"
            className="style_modalTitle"
            onClick={handleClose}
          >
            Annuler
          </Button>
        </Modal.Footer>
      </Modal>
      {alertModal}
    </>
  );
};

export default BoostTeam;
