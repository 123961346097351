import React, { useEffect, useState } from "react";
import CreateObservation from "./CreateObservation";
import axios from "axios";
import useAlert from "../../hooks/alertHook";
import { Link } from "react-router-dom";
import DeleteObservations from "../../pages/players/DeleteObservations";
import UpdateObservation from "../../pages/players/UpdateObservation";

export default function PlayerObservations({ playerId, displaycreate }) {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [commentaires, setCommentaires] = React.useState([]);
  const { alertModal, displayAlert, dismissAlert } = useAlert();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(6);

  function GetObservationsByPlayerId() {
    if (playerId === undefined) return;
    axios
      .get(`${baseURL}/players/getObservationsByPlayerId/${playerId}`)
      .then((resp) => {
        if (resp.data.error) return;
        setCommentaires(resp.data.data.map((item) => item));
      });
  }
  useEffect(() => {
    GetObservationsByPlayerId();
  }, []);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = commentaires.slice(indexOfFirstItem, indexOfLastItem);
  function paginate(pageNumber) {
    setCurrentPage(pageNumber);
  }
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(commentaires.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  return (
    <div className="container ml-0 bg-white py-3">
      {displaycreate ? (
        <div className="row mb-2">
          <CreateObservation
            playerId={playerId}
            GetObservationsByPlayerId={GetObservationsByPlayerId}
          />
        </div>
      ) : (
        ""
      )}
      <div className="row py-3">
        {currentItems.map((commentaire, key) => {
          return (
            <div className="col-md-4 ">
              <div
                className="card border border-primary card-observation mb-3"
                style={{ maxWidth: "15rem" }}
              >
                <div className="card-header p-0">
                  <div className="row">
                    <div className="col-8 ms-2 cardobservation-title align-items-center">
                      {commentaire.CreatedBy.name}
                    </div>
                    <div className="col-2">
                      {commentaire.isOwner === "yes" ? (
                        <div className="row justify-content-end">
                          <div className="col-6 p-0">
                            <UpdateObservation
                              observationId={commentaire.observationId}
                              commentaire={commentaire.commentaire}
                              GetObservationsByPlayerId={
                                GetObservationsByPlayerId
                              }
                            />
                          </div>
                          <div className="col-6 p-0">
                            <DeleteObservations
                              observationId={commentaire.observationId}
                              GetObservationsByPlayerId={
                                GetObservationsByPlayerId
                              }
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
                <div className="p-1 card-text-truncate ">
                  <p className="card-text-truncate">
                    {commentaire.commentaire}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div
        className="pagination paginationObservation"
      >
        {pageNumbers.map((pageNumber) => (
          <span
            key={pageNumber}
            className={`page-number paginations ${
              pageNumber === currentPage ? "active" : ""
            }`}
            onClick={() => paginate(pageNumber)}
          >
            {pageNumber}
          </span>
        ))}
      </div>
      {alertModal}
    </div>
  );
}
