import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CardStatistics from "../statistics/CardStatistics";
import GoalsAssistsStats from "../statistics/GoalsAssistsStats";
import MatchesStatistics from "../statistics/MatchesStatistics";
import GlobalStatistics from "../statistics/GlobalStatistics";

const PlayerStats = ({
  startedgames,
  totalPlayed,
  assists,
  goals,
  deffence,
  Tactic,
  creativity,
  attack,
  tecnique,
}) => {
  return (
    <div className="container ml-0 ">
      <div className="row mb-3 ">
        <div className="d-md-flex justify-content-evenly">
          <div className="col-md-4 p-md-3   ">
            <div className="card playerStatsCard">
              <GlobalStatistics
                attack={attack}
                tecnique={tecnique}
                Tactic={Tactic}
                deffence={deffence}
                creativity={creativity}
              />
            </div>
          </div>
          <div className="col-md-4 p-md-3  py-2">
            <div className=" card card_cartons">
              <MatchesStatistics
                totalPlayed={totalPlayed}
                startedPlayGame={startedgames}
              />
            </div>
          </div>
          <div className="col-md-4 p-md-3 ">
            <div className=" card  card_cartons">
              <GoalsAssistsStats
                goals={goals}
                assists={assists}
                totalPlayed={totalPlayed}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayerStats;
