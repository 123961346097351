import React, { useContext, useState, useEffect } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { UserRegistrationContext } from "../pages/Register";
import ConfirmRegister from "./ConfirmRegister";
import RegisterFormStep1 from "./RegisterFormStep1";
import Welcome from "./RegisterFormStep1";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import RegisterType from "./RegisterType";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import useAlert from "../hooks/alertHook";
import Select from "react-select";

const baseURL = process.env.REACT_APP_BASE_URL;

const initFormErrors = {
  name: [""],
  adress: [""],
  phone: [""],
  password: [""],
  confirm_password: [""],
  email: [""],
  RoleId: [""],
};
const SimpleUser = ({ setChildComponent }) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const { alertModal, displayAlert, dismissAlert } = useAlert();

  const [name, setName] = React.useState("");
  const [errorMsg, setErrorMsg] = React.useState(initFormErrors);
  const [adress, setAdress] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [RoleId, setRoleId] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [confirm_password, setConfirmPassword] = React.useState("");
  const msg = "Ce champ est obligatoire";
  const msg1 = "Saisissez une valeur valide ";
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  const { newUser, setNewUser } = useContext(UserRegistrationContext);
  const [value, setValue] = useState();

  function validate() {
    const data = {
      name: name,
      adress: adress,
      phone: phone,
      email: email,
      RoleId: RoleId,
      password: password,
      confirm_password: confirm_password,
    };
    axios
      .post(`${baseURL}/singup`, data)
      .then((resp) => {
        setErrorMsg(initFormErrors);
        if (resp.data.error) return;
        displayAlert("success", "", resp.data);
        setTimeout(() => {
          dismissAlert();
          navigate("/login");
        }, 2500);
      })
      .catch((error) => {
        const errors = error.response.data;
        setErrorMsg(errors);
      });
  }
  const [roles, setroles] = useState([]);
  useEffect(() => {
    getpublicRoles();
  }, []);
  function getpublicRoles() {
    axios
      .get(`${baseURL}/roles/publicRole`)
      .then((res) => {
        res.data.data.map((item, index) => {
            return setroles((prevState) => [
              ...prevState,
              { value: item.id, label: item.name },
            ]);
        });
      })
      .catch((err) => {});
  }
  
  return (
    <section className="container-fluid bg-main">
      <div className="container py-5 h-100 ">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col">
            <div className="card card_style border-0 h-100">
              <div className="row g-0">
                <div className="col-md-6 col-lg-6 d-none d-md-block bg-log">
                  <div className="pt-5 px-3">
                    <img src="../../assets/imgs/logo.png"></img>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 d-flex align-items-center">
                  <div className="card-body text-black ">
                    <div className="">
                      <h3 className="title black mt-4 mb-1 pb-1 text-center">
                        Nous sommes ravis que vous nous rejoigniez !
                      </h3>
                      <h5 className="black mt-4 mb-5 pb-1">
                        Remplissons toutes les informations et configurons votre
                        compte, les joueurs que vous recherchez vous attendent !
                      </h5>
                    </div>

                    <div className="form-horizontal style_form"></div>
                    <div className="row mb-3">
                      <div className="col-lg-6">
                        <h6 className="fw-bold color_black float-start">Nom</h6>
                        <input
                          type="text"
                          placeholder="Nom et Prénom"
                          onChange={(e) => setName(e.target.value)}
                          className="form-control"
                          required
                        />
                        <div className="input-error">
                          <span className="text-danger"> {errorMsg.name}</span>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <h6 className="fw-bold color_black float-start">
                          Adresse
                        </h6>
                        <input
                          type="text"
                          placeholder="Adresse"
                          onChange={(e) => setAdress(e.target.value)}
                          className="form-control"
                          required
                        />
                        <div className="input-error">
                          <span className="text-danger">{errorMsg.adress}</span>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-6">
                        
                        <h6 className="fw-bold color_black float-start">
                          Email
                        </h6>
                        <input
                          type="email"
                          placeholder=" Email"
                          onChange={(e) => setEmail(e.target.value)}
                          className="form-control"
                          required
                        />
                        <div className="input-error">
                          <span className="text-danger"> {errorMsg.email}</span>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <h6 className="fw-bold color_black  text-start ">
                          Téléphone
                        </h6>
                        <PhoneInput
                          placeholder="Téléphone"
                          type="text"
                          onChange={(value, country, event) =>
                            setPhone(event.target.value)
                          }
                        />
                        <div className="input-error">
                          <span className="text-danger"> {errorMsg.phone}</span>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-6">
                        <h6 className="mb-2 fw-bold color_black text-start">
                          Role
                        </h6>
                        <Select
                          options={roles}
                          isSearchable
                          placeholder="roles"
                          onChange={(e) => setRoleId(e.value)}
                        />
                    <div className="input-error">
                      <span className="text-danger">{errorMsg.RoleId}</span>
                    </div>
                      </div>
                      <div className="col-lg-6">
                        <h6 className="fw-bold color_black float-start">
                          Mot de passe
                        </h6>
                        <input
                          required
                          onChange={(e) => setPassword(e.target.value)}
                          type="password"
                          name="Mot de passe"
                          className="form-control"
                          placeholder="Mot de passe"
                        />
                        <div className="input-error">
                          <span className="text-danger">
                            {errorMsg.password}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row mb-3">
                      <div className="col-lg-6">
                        <h6 className="fw-bold color_black float-start">
                          Confirmer Mot de passe
                        </h6>
                        <input
                          type="password"
                          placeholder="Confirmer mot de passe"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          className="form-control"
                          required
                        />
                        <div className="input-error">
                          <span className="text-danger">
                            {errorMsg.confirm_password}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="row ">
                      <div className="button-roww col-lg-6 col-sm-12 mb-3 mb-sm-0">
                        <button
                          type="button"
                          className="btn btn-outline-secondary style_butt mx-2"
                          onClick={() => {
                            setChildComponent(
                              <RegisterType
                                setChildComponent={setChildComponent}
                              />
                            );
                          }}
                        >
                          Retour
                        </button>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <button
                          onClick={validate}
                          type="button"
                          className="btn btn-primary style_butt mx-2"
                        >
                          Enregistrer
                        </button>
                      </div>
                    </div>
                    <div className="row d-flex d-none d-sm-flex justify-content-center mt-3">
                      <div className="col-3 style_flag px-0  ">
                        <img className="" src="./flag.png" alt="" />
                      </div>
                      <div className="col-6 style_padding_bar  mt-5 ">
                        <ProgressBar className="style_barr  " now={66} />
                      </div>
                      <div className="col-3 style_flagsecond">
                        <img src="./flag2.png" alt="" />
                      </div>
                    </div>
                    <div className="text-center pt-md-4 mx-md-4  mt-5">
                      <p className="text-black">
                        Vous avez déjà un compte? Cliquez ici pour{" "}
                        <Link to="/login" className="btn-click">
                        S'authentifier
                        </Link>
                      </p>
                    </div>
                    <div className=" text-center px-md-4 mx-md-4  mt-2">
                      <a href="#">
                        <i className="fa-brands fa-facebook-f p-2 "></i>
                      </a>
                      <a href="#">
                        <i className="fa-brands fa-instagram p-2"></i>
                      </a>
                      <a href="#">
                        <i className="fa-brands fa-youtube p-2"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {alertModal}
    </section>
  );
};

export default SimpleUser;
