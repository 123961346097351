import React from "react";
import emptyData from "../emptyData-fr.png";
const EmptyData = () => {
  return (
    <div className="text-center">
      <img src={emptyData} className="img_empty_data" alt="Deflated Ball" />
    </div>
  );
};

export default EmptyData;
