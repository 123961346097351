import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import { logError } from "../Toastconfig";
import useAlert from "../../hooks/alertHook";

// to initialize errors message
const initFormErrors = {
  name: [""],
  email: [""],
  adress: [""],
  CodePostal: [""],
  password: [""],
  type: [""],
  RoleId: [""],
  confirm_password: [""],
  siret_num: [""],
  file: [""],
};
export default function CreateUser({ getall, Roles }) {
  const baseURL = process.env.REACT_APP_BASE_URL;
  // modal props
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setname("");
    setemail("");
    setadress("");
    setCodePostal("");
    setconfirm_password("");
    setpassword("");
    setrole("");
    settype("");
    setsiret_num("");
    setErrorMessage(initFormErrors);
  };
  const uploadedImage = React.useRef(null);

  // user props
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [adress, setadress] = useState("");
  const [CodePostal, setCodePostal] = useState("");
  const [type, settype] = useState("");
  const [RoleId, setrole] = useState("");
  const [siret_num, setsiret_num] = useState("");
  const [avatar, setAvatar] = useState(null);
  const [confirm_password, setconfirm_password] = useState("");
  const [errorMessage, setErrorMessage] = useState(initFormErrors);
  const { alertModal, displayAlert, dismissAlert } = useAlert();

  function senData() {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("email", email);
    formData.append("password", password);
    formData.append("confirm_password", confirm_password);
    formData.append("adress", adress);
    formData.append("CodePostal", CodePostal);
    formData.append("type", type);
    formData.append("RoleId", RoleId);
    formData.append("siret_num", siret_num);
    formData.append("avatar", avatar);
    axios
      .post(`${baseURL}/users/create`, formData)
      .then((resp) => {
        setErrorMessage(initFormErrors);
        if (resp.data.error) return;
        displayAlert("success", "", resp.data.message);
        getall();
        setTimeout(() => {
          dismissAlert();
        }, 2500);
        getall();
        handleClose();
      })
      .catch((error) => {
        logError();
        const errors = error.response.data;
        setErrorMessage(errors);
      });
  }
  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Button
        className="btn btn-primary float-end btn-lg button-add"
        type="button"
        variant="primary"
        onClick={handleShow}
      >
        +
      </Button>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="style_modalHeader">
          <div>
            
            <Modal.Title className="title-modal">
              Créer un utilisateur
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body className="mt-3">
          <section className="">
            <div className="container">
              <div className="row">
                <div className="col-md-6 mb-3">
                  <div className="form-outline">
                    <label className="form-label" for="Name">
                      Nom :
                    </label>
                    <input
                      type="text"
                      placeholder="Nom"
                      className="form-control form-control"
                      onChange={(e) => setname(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">{errorMessage.name}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="form-outline">
                    <label className="form-label" for="Email">
                      Email :
                    </label>
                    <input
                      type="email"
                      placeholder="Email"
                      className="form-control form-control"
                      onChange={(e) => setemail(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">{errorMessage.email}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="form-outline">
                    <label className="form-label" for="Adresse">
                      Adresse :
                    </label>
                    <input
                      type="text"
                      placeholder="Adresse"
                      className="form-control form-control"
                      onChange={(e) => setadress(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">{errorMessage.adress}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="form-outline">
                    <label className="form-label" for="code_postale">
                      Code Postal :
                    </label>
                    <input
                      type="text"
                      id="code_postale"
                      placeholder="Code postal"
                      className="form-control form-control"
                      onChange={(e) => setCodePostal(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessage.CodePostal}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="form-outline">
                    <label className="form-label" for="Password">
                      Mot de passe :
                    </label>
                    <input
                      type="password"
                      id="password"
                      placeholder="Mot de passe"
                      className="form-control form-control"
                      onChange={(e) => setpassword(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessage.password}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <div className="form-outline">
                    <label className="form-label" for="Confirm_Password">
                      Confirmer mot de passe :
                    </label>
                    <input
                      type="password"
                      id="confirm_password"
                      placeholder="Confirmer mot de passe "
                      className="form-control form-control"
                      onChange={(e) => setconfirm_password(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {errorMessage.confirm_password}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <h6 className="mb-2 pb-1">Choisir un role : </h6>
                  <select
                    className="select select-input"
                    onChange={(e) => setrole(e.target.value)}
                    required
                  >
                    {/* the roles list  */}
                    <option value="">roles</option>
                    {Roles.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name.toLowerCase()}
                      </option>
                    ))}
                  </select>
                  <div className="input-error">
                    <span className="text-danger">{errorMessage.RoleId}</span>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <h6 className="mb-2 pb-1">Type : </h6>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="femaleGender"
                      value="personnel"
                      onChange={(e) => settype(e.target.value)}
                    />
                    <label className="form-check-label" for="personnel">
                      Personnel
                    </label>
                  </div>
                  <div className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="inlineRadioOptions"
                      id="maleGender"
                      value="professionel"
                      onChange={(e) => settype(e.target.value)}
                    />
                    <label className="form-check-label" for="professionel">
                      Professionel
                    </label>
                  </div>
                  <div className="input-error">
                    <span className="text-danger">{errorMessage.type}</span>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <h6 className="mb-2 pb-1">Avatar : </h6>

                  <input
                    required
                    id="avatar"
                    accept="image/*"
                    name="avatar"
                    type="file"
                    className="form-control"
                    onChange={(e) => {
                      handleImageUpload(e);
                      setAvatar(e.target.files[0]);
                    }}
                  />
                  <div className="input-error">
                    <span className="text-danger">{errorMessage.file}</span>
                  </div>
                </div>
                <div className="col-md-6 mb-3">
                  <img ref={uploadedImage} className="updateImg" />
                </div>
                {type === "professionel" ? (
                  <div className="col-md-6 mb-3">
                    <div className="form-outline">
                      <label className="form-label" for="Adresse">
                        Numéro SIRET :
                      </label>
                      <input
                        type="text"
                        id="adresse"
                        placeholder=" Numéro Siret"
                        value={siret_num != null ? siret_num : ""}
                        className="form-control form-control"
                        onChange={(e) => setsiret_num(e.target.value)}
                        required
                      />
                      <div className="input-error">
                        <span className="text-danger">
                          {errorMessage.siret_num}
                        </span>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </section>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center mb-4 border-0">
          <Button variant="primary" className="btn-modal" onClick={senData}>
            Enregistrer
          </Button>
          <Button
            variant="outline-secondary"
            className="btn-modal"
            onClick={handleClose}
          >
            Annuler
          </Button>
        </Modal.Footer>
      </Modal>
      {alertModal}
    </>
  );
}
