import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import ReactPlayer from "react-player";
import EmptyData from "../../components/emptyData";
import { useRef } from 'react'

export default function VideoPlayersPerMatchSimpleUser({
  matchId,
}) {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [currentVideo, setCurrentVideo] = useState([]);
  const [Matchvideos, setMatchvideos] = useState([]);
  const handleVideoClick = (video) => {
    setCurrentVideo(video);
  };
  function getMatchVideoss() {
    if (matchId === undefined) return;
    axios
      .get(`${baseURL}/calendarMatches/matchDetails/videosMatch/${matchId}`)
      .then((response) => {
        if (response.data.error) {
          return;
        }
        setMatchvideos(response.data.data);
        setCurrentVideo(response.data.data[0]);
      });
  }
  useEffect(() => {
    getMatchVideoss();
  }, []);
  const scrollRef = useRef();
  const videoPlayerRef = useRef();
  const handleScroll = () => {
    const videoPlayer = videoPlayerRef.current;
    if (videoPlayer) {
      const scrollPosition = window.pageYOffset;
      if (window.innerWidth <= 767 && scrollPosition > 0) {
        videoPlayer.style.position = 'fixed';
        videoPlayer.style.top = '16%';
        videoPlayer.style.width = '75%';
      }
       else {
        videoPlayer.style.position = 'relative';
        videoPlayer.style.top = 'auto';
        videoPlayer.style.width = '96%';
      }
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
    window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="row">
      {Matchvideos.length === 0 ? (
        <div className="row">
          <div className="col-lg-12 pt-2">
            <EmptyData />
          </div>
        </div>
      ) : (
        <div className="container pt-2">
          <div className="row py-5 ">
            <div className="col-md-8 col-lg-8 col-12">
            <div className="video-player" ref={videoPlayerRef}>
                <ReactPlayer
                  url={`${
                    baseURL + "/calendarMatches/getFile" + currentVideo.path
                  }`}
                  controls
                  playIcon={
                    <button className="btn">
                      <i className="fa-sharp fa-solid fa-circle-play button-play"></i>
                    </button>
                  }
                  width="99%"
                  height="auto"
                  paddingLeft="5px"
                  className="currentVideo"
                />
                <div className="hiddenVideos-title">
                  <p className="videoTitle fw-bold fs-4 ">
                    {currentVideo.title}
                  </p>
                  <p className="text-muted fs-6  ">
                    {currentVideo.description.length > 300
                      ? `${currentVideo.description.slice(0, 300)}...`
                      : currentVideo.description}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-4 ">
            <div className="scrollable-container" ref={scrollRef}>
                <div className=" p-0 pe-1">
                  <div className="video-list">
                    {Matchvideos.map((video, index) => (
                      <div
                        className="style-videos mb-3"
                        key={index}
                        onClick={() => handleVideoClick(video)}
                      >
                        <video
                          style={{
                            cursor: "pointer",
                          }}
                          className="style_video_list "
                          src={`${
                            baseURL + "/calendarMatches/getFile" + video.path
                          }`}
                          alt={video.title}
                        />
                        <div
                          className="overlay px-2"
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <p className="videoTitle fw-bold fs-6 pt-1 ">
                            {video.title}
                          </p>
                          <p className="text-muted  ">
                            {video.description.length > 100
                              ? `${video.description.slice(0, 100)}...`
                              : video.description}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}