import React from "react";
import Chart from "react-apexcharts";

const CardStatistics = ({ yellow, red }) => {
  let state = {
    series: [
      ((yellow * 100) / (yellow + red)).toFixed(0),
      ((red * 100) / (yellow + red)).toFixed(0),
    ],
    options: {
      chart: {
        height: 350,
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          dataLabels: {
            name: {
              fontSize: "18px",
            },
            value: {
              fontSize: "16px",
            },
            total: {
              show: true,
              label: "Cartons",
              formatter: function () {
                return "";
              },
            },
          },
        },
      },
      colors: ["#ffd400", "#ff0000"],
      labels: ["Carton Jaune", "Carton Rouge"],
    },
  };
  let state1 = {
    series: [
      {
        name: "Series 1",
        data: [80, 50, 30, 40, 100, 20],
      },
      {
        name: "Series 2",
        data: [20, 30, 40, 80, 20, 80],
      },
      {
        name: "Series 3",
        data: [44, 76, 78, 13, 43, 10],
      },
    ],
    options: {
      chart: {
        height: 350,
        type: "radar",
        dropShadow: {
          enabled: true,
          blur: 1,
          left: 1,
          top: 1,
        },
      },
      title: {
        text: "Radar Chart - Multi Series",
      },
      stroke: {
        width: 2,
      },
      fill: {
        opacity: 0.1,
      },
      markers: {
        size: 0,
      },
      xaxis: {
        categories: ["2011", "2012", "2013", "2014", "2015", "2016"],
      },
    },
  };

  return (
    <div>
      <div>
        <Chart
          options={state.options}
          series={state.series}
          type="radialBar"
          height={200}
        />
        <h6 className="style_stats_card_text margintop_text_cards_stats justify-content-center text-center ">
          {yellow} cartons jaune/{yellow + red}
        </h6>
        <h6 className="style_stats_card_text  justify-content-center text-center ">
          {red} cartons rouge/{yellow + red}
        </h6>
      </div>
     
    </div>
  );
};

export default CardStatistics;
