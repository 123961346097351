import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import { Link } from "react-router-dom";
import { logError } from "../../components/Toastconfig";
import { useParams } from "react-router-dom";
import PlayersOfMatchesSimpleUser from "./PlayersOfMatchesSimpleUser";
import VideoPlayersPerMatchSimpleUser from "./VideoPlayersPerMatchSimpleUser";
import StatsOfMatchsSimpleUser from "./StatsOfMatchsSimpleUser";
import NewsMatchSimpleUser from "./NewsMatchSimpleUser";

const MatchDetailsSimpleUser = () => {
  const { matchId } = useParams();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [matche, setMatch] = useState([]);

  const [homeTeamData, setHomeTeamData] = useState();
  const [awayTeamData, setAwayTeamData] = useState();
  function getMatchById() {
    axios
      .get(`${baseURL}/calendarMatches/${matchId}`)
      .then((res) => {
        setMatch(res.data.data);
      })
      .catch((err) => {
        logError();
      });
  }
  function GetMatchScoreById() {
    axios
      .get(`${baseURL}/calendarMatches/matchstats/${matchId}`)
      .then((resp) => {
        if (resp.data.error) return;
        const MatchStatsData = resp.data.data;
        MatchStatsData?.map((item) => {
          if (item.status === "home") {
            setHomeTeamData(item);
          } else {
            setAwayTeamData(item);
          }
        });
      });
  }
  useEffect(() => {
    getMatchById();
    GetMatchScoreById();
  }, []);
  const [activeTab, setActiveTab] = useState(1);
  return (
    <>
      <div className="justify-content-center d-flex">
      <div className="card create-statsPlayer-style p-1 ">
          <div className="row my-2 ">
            <div className="col-lg-5 col-4 text-center">
              <Link to={`/oneTeam/${matche.teamHome?.id}`}>
                <img
                  src={`${baseURL}/team/getFile${matche.teamHome?.logo.slice(
                    1
                  )}`}
                  className="img-thumbnail"
                />
              </Link>
              <Link to={`/oneTeam/${matche.teamHome?.id}`}>
                <div className="team_name fw-bolder fs-md-6">
                  {matche.teamHome?.name}
                </div>
              </Link>
            </div>
            <div className="col-lg-2 col-4 text-center">
              <div className=" team_date text-nowrap text-muted">
                {matche.date ? matche.date : "Date indisponible"}
              </div>
              <p className=" pt-1 fw-bold team_score text-nowrap">
                {homeTeamData != null && homeTeamData?.goals}
                {""} - {""}
                {awayTeamData != null && awayTeamData?.goals}
              </p>
            </div>
            <div className="col-lg-5 col-4 text-center">
              <Link to={`/oneTeam/${matche.teamAway?.id}`}>
                <img
                  src={`${baseURL}/team/getFile${matche.teamAway?.logo.slice(
                    1
                  )}`}
                  className="img-thumbnail"
                />
              </Link>
              <Link to={`/oneTeam/${matche.teamAway?.id}`}>
                <div className="team_name fw-bolder fs-md-6">
                  {matche.teamAway?.name}
                </div>
              </Link>
            </div>
          </div>
          <div className="mt-5">
      <div className="d-flex justify-content-center">
        <div className="col-12">
          <div className="bg-white ">
            <div className="row">
              <div className="col-3">
      <div className="d-flex justify-content-center">
                <button
              className={`nav-activetabs w-100 ${activeTab === 1 ? 'activetabs' : ''}`}
              onClick={() => setActiveTab(1)}
                >
                  <div className="d-flex flex-column lh-lg">
                    <i className="fa-sharp fa-solid fa-signal"></i>
                    <span className="tabsMatch">Statistic</span>
                  </div>
                </button>
              </div>
              </div>
              <div className="col-3">
      <div className="d-flex justify-content-center">
                <button
              className={`nav-activetabs ${activeTab === 2 ? 'activetabs' : ''}`}
              onClick={() => setActiveTab(2)}
                >
                  <img
                    width="22"
                    height="22"
                    src="./assets/imgs/stadium.png"
                  />
                  <p className="tabsMatch mb-0">Line-Ups</p>
                </button>
              </div>
              </div>
              <div className="col-3">
      <div className="d-flex justify-content-center">
                <button
              className={`nav-activetabs ${activeTab === 3 ? 'activetabs' : ''}`}
              onClick={() => setActiveTab(3)}
                >
                  <div className="d-flex flex-column lh-lg">
                    <i className="fa-solid fa-video"></i>
                    <span className="tabsMatch">Vidéos</span>
                  </div>
                </button>
              </div>
              </div>
              <div className="col-3">
      <div className="d-flex justify-content-center">

                <button
              className={`nav-activetabs ${activeTab === 4 ? 'activetabs' : ''}`}
              onClick={() => setActiveTab(4)}
                >
                  <div className="d-flex flex-column lh-lg">
                    <i className="fa-regular fa-newspaper"></i>
                    <span className="tabsMatch">Actualités</span>
                  </div>
                </button>
              </div>
              </div>
            </div>
            <div className="card  p-1">
              <div className="tab-content">
                {activeTab === 1 && (
                  <div className="content_stat">
                    <StatsOfMatchsSimpleUser  />
                  </div>
                )}
                {activeTab === 2 && (
                  <div className="content_stat">
                    <PlayersOfMatchesSimpleUser matchId={matchId} />
                  </div>
                )}
                {activeTab === 3 && (
                  <div className="content_stat">
                    <VideoPlayersPerMatchSimpleUser matchId={matchId} />
                  </div>
                )}
                {activeTab === 4 && (
                  <div className="content_stat">
                    <NewsMatchSimpleUser matchId={matchId} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
        </div>
      </div>
    </>
  );
};

export default MatchDetailsSimpleUser;

