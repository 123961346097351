import React, { Fragment, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import Button from "react-bootstrap/Button";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { logError } from "../Toastconfig";
import useAlert from "../../hooks/alertHook";
const initFormErrors = {
  file: [""],
};
const EditTeam = ({ team,getOne,getall }) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const emailRegex = /^\S+@\S+\.\S+$/;
  const [formerrors, setFormErrors] = React.useState(initFormErrors);

  const handleShowModal = () => setShowModal(true);
  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const [logo, setLogo] = useState(team.logo);
  const [name, setName] = useState(team.name);
  const [acronyme, setAcronyme] = useState(team.acronyme);
  const [country, setCountry] = useState(team.country);
  const today = new Date().toISOString().split('T')[0];

  const [agent_Phone, setAgent_Phone] = useState(team.agent_Phone);
  const [agent_Email, setAgent_Email] = useState(team.agent_Email);
  const [stade_Name, setStade_Name] = useState(team.stade_Name);
  const [isPartner, setisPartner] = useState(team.isPartner);
  const [teamId, setTeamId] = useState(team.id);
  const [partnerShip_Date, setpartnerShip_Date] = useState(team.partnerShip_Date || today);

  const { alertModal, displayAlert, dismissAlert } = useAlert();

  const updateOne = async () => {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("acronyme", acronyme);
      formData.append("country", country);
      formData.append("logo", logo);
      formData.append("agent_Phone", agent_Phone);
      formData.append("agent_Email", agent_Email);
      formData.append("stade_Name", stade_Name);
      formData.append("isPartner", isPartner);
      if (isPartner === "yes") {
        formData.append("partnerShip_Date", partnerShip_Date);
      } else {
        formData.append("partnerShip_Date", null);
      }
      axios
        .put(`${baseURL}/teams/${teamId}`, formData)
        .then((resp) => {
        setFormErrors(initFormErrors);
          if (resp.data.error) return;
          if (resp.data.message)
            displayAlert("success", "", resp.data.message);
          setTimeout(() => {
            dismissAlert();
          }, 2500);
          if (getOne) {
            getOne();
          }
          if (getall) {
            getall();
          }
          setShowModal(false);
        })
        .catch((error) => {
        setFormErrors(error.response.data);
        });
  };

  const [countries, setCountris] = useState([]);
  //  get ALL teams funtion
  useEffect(function () {
    axios
      .get(`${baseURL}/countries/read`)
      .then((res) => {
        setCountris(res.data);
      })
      .catch((err) => {
        logError();
      });
  }, []);

  return (
    <>
      <div className="w-100">
        <button
          type="button"
          className="btn btn-sm p-0"
          onClick={handleShowModal}
        >
          <i className="fa-solid fa-pen-to-square" title="Modifier Team"></i>
        </button>
      </div>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showModal}
        onHide={handleCloseModal}
      >
        <Modal.Header className="style_modalHeader">
          <div>
            <Modal.Title className="style_modalTitle">
              Modifier équipe
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="form-horizontal">
            <div className="row mb-4">
              <div className="col-lg-6">
                <h6 className="fw-bold color_black"> Nom d'équipe</h6>
                <input
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  id="name"
                  type="text"
                  name="name"
                  className="form-control"
                  placeholder="Nom d'équipe"
                />
                <div className="input-error">
                  <span className="text-danger">{formerrors.name}</span>
                </div>{" "}
              </div>
              <div className="col-lg-6">
                <h6 className="fw-bold color_black">Acronyme</h6>
                <input
                  required
                  onChange={(e) => setAcronyme(e.target.value)}
                  value={acronyme}
                  type="text"
                  id="acronyme"
                  name="acronyme"
                  className="form-control"
                  placeholder="Acronyme"
                />
                <div className="input-error">
                  <span className="text-danger">{formerrors.acronyme}</span>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-6">
                <h6 className="fw-bold color_black">Pays</h6>
                <select
                  className="form-control"
                  id="country"
                  name="country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                >
                  <option value=""> Pays</option>
                  {countries.map((country, key) => (
                    <option value={country.id} key={key}>
                      {/* {country.flag} */}
                      {country.name}
                    </option>
                  ))}
                </select>
                <div className="input-error">
                  <span className="text-danger">{formerrors.country}</span>
                </div>
              </div>
              <div className="col-lg-6">
                <h6 className="fw-bold color_black">Stade Nom</h6>
                <input
                  required
                  onChange={(e) => setStade_Name(e.target.value)}
                  value={stade_Name}
                  className="form-control"
                  placeholder="Stade Nom"
                />
                <div className="input-error">
                  <span className="text-danger">{formerrors.stade_Name}</span>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-6">
                <h6 className="fw-bold color_black">Agent Téléphone</h6>
                <PhoneInput
                  placeholder="Agent Téléphone"
                  type="text"
                  value={agent_Phone}
                  onChange={(value, country, event) =>
                    setAgent_Phone(event.target.value)
                  }
                />
                <div className="input-error">
                  <span className="text-danger">{formerrors.agent_Phone}</span>
                </div>
              </div>
              <div className="col-lg-6">
                <h6 className="fw-bold color_black">Agent Email</h6>
                <input
                  required
                  onChange={(e) => setAgent_Email(e.target.value)}
                  value={agent_Email}
                  name="agent_Email"
                  className="form-control"
                  placeholder="Agent Email "
                />
                <div className="input-error">
                  <span className="text-danger">{formerrors.agent_Email}</span>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <div className="col-lg-6">
                <h6 className="fw-bold color_black">Logo</h6>
                <input
                  required
                  onChange={(e) => setLogo(e.target.files[0])}
                  id="logo"
                  accept="image/*"
                  name="logo"
                  type="file"
                  className="form-control"
                  placeholder="Logo"
                />
                <div>
                  <img
                    src={`${
                      baseURL + "/team/getFile/" + team.logo.substring(1)
                    }`}
                    className="updateImg"
                  />
                </div>
                <div className="input-error">
                  <span className="text-danger">{formerrors.file}</span>
                </div>
              </div>
              <div className="col-lg-6">
                <h6 className="fw-bold color_black"> Partenaire</h6>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    onChange={(e) => setisPartner(e.target.value)}
                    name="isPartner"
                    value="yes"
                    checked={isPartner === "yes"}
                  />
                  <label className="form-check-label" for="yes">
                    Oui
                  </label>
                </div>
                <div className="form-check form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    onChange={(e) => {
                      setisPartner(e.target.value);
                    }}
                    name="isPartner"
                    value="no"
                    checked={isPartner === "no"}
                  />
                  <label className="form-check-label" for="no">
                    Non
                  </label>
                </div>
                <div className="input-error">
                  <span className="text-danger">{formerrors.isPartner}</span>
                </div>
                {isPartner === "yes" && (
                  <div className="row mb-3">
                    <div className="col-lg-6">
                      <h6 className="fw-bold color_black">Date Parteneriat</h6>
                      <input
                        type="date"
                        className="form-control"
                        id="date"
                        placeholder="Abréviation de compétition"
                        onChange={(e) => setpartnerShip_Date(e.target.value)}
                        value={partnerShip_Date}
                      />
                      <div className="input-error">
                        <span className="text-danger">
                          {formerrors.partnerShip_Date}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-primary" onClick={updateOne}>
            Modifier équipe
          </Button>
          <Button variant="outline-secondary" onClick={handleCloseModal}>
            Annuler
          </Button>
        </Modal.Footer>
      </Modal>
      {alertModal}
    </>
  );
};

export default EditTeam;
