import React, { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useNavigate } from "react-router-dom";
import useAlert from "../../hooks/alertHook";
import { Link } from "react-router-dom";

const initFormErrors = {
  title: [""],
  content: [""],
  file: [""],
  mediasource: [""],
  contry: [""],
};

export default function CreateNews({ getallnews }) {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [title, settitle] = useState("");
  const [media, setmedia] = useState("");
  const [content, setContent] = useState("");
  const [formErrors, setFormErrors] = useState(initFormErrors);
  const navigate = useNavigate();
  const { alertModal, displayAlert, dismissAlert } = useAlert();

  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    settitle("");
    setContent("");
    setmedia("");
    setFormErrors(initFormErrors);
  };

  const uploadedImage = React.useRef(null);

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  function sendData() {
    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    formData.append("media", media);

    axios
      .post(`${baseURL}/news/create`, formData)
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        displayAlert("success", "", resp.data.message);
        setTimeout(() => {
          dismissAlert();
       },2500);
        setTimeout(() => {
          navigate("/admin/news");
        }, 2500);
      })
      .catch((error) => {
        setFormErrors(error.response.data);
      });
  }
  const handleSubmit = () => {
    sendData();
  };

  const toolbarOptions = [
    ["bold", "italic", "underline", "strike"],
    ["blockquote", "code-block"],

    [{ header: 1 }, { header: 2 }],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ script: "sub" }, { script: "super" }],
    [{ indent: "-1" }, { indent: "+1" }],
    [{ direction: "rtl" }],
    [{ size: ["small", false, "large", "huge"] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],

    [{ color: [] }, { background: [] }],
    ["image", "link"],
    [{ font: [] }],
    [{ align: [] }],

    ["clean"],
  ];

  return (
    <>
      <div className="m-4">
        <div className="card">
          <div className="card-body">
            <div className="row mb-1">
              <div className="col-lg-6">
                <h6>Titre</h6>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Titre"
                  onChange={(e) => settitle(e.target.value)}
                  required
                />
                <div className="input-error">
                  <span className="text-danger">{formErrors.title}</span>
                </div>
              </div>
              <div className="col-lg-6">
                {" "}
                <h6>Couverture </h6>
                <input
                  required
                  type="file"
                  className="form-control"
                  placeholder="actualité media"
                  onChange={(e) => {
                    handleImageUpload(e);
                    setmedia(e.target.files[0]);
                  }}
                />
                <div className="input-error">
                  <span className="text-danger">{formErrors.file}</span>
                </div>
              </div>
            </div>
            <div className="row mb-1">
              <div className="col-lg-6">
                <img
                  ref={uploadedImage}
                  style={{
                    weight: "30%",
                    width: "40%",
                  }}
                />
              </div>
            </div>
            <div className="mb-3">
              <h6>Contenu </h6>
              <ReactQuill
                theme="snow"
                modules={{
                  toolbar: toolbarOptions,
                }}
                value={content}
                placeholder="Contenu..."
                onChange={(value) => {
                  setContent(value);
                }}
              />
                  <div className="input-error">
                  <span className="text-danger">{formErrors.content}</span>
                </div>
            </div>
            <div>
              <Link to="/admin/news" className="btn btn-secondary">
                {" "}
                Annuler
              </Link>
              <button
                type="button"
                onClick={handleSubmit}
                className="btn btn-primary ms-2"
              >
                Enregistrer
              </button>
            </div>
          </div>
        </div>
      </div>
      {alertModal}
    </>
  );
}
