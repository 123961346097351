import React, { useState, useEffect } from "react";
import axios from "axios";
import Select from "react-select";
import useAlert from "../../hooks/alertHook";

const initFormErrors = {
  comp_name: [""],
  comp_slug: [""],
  country: [""],
  date_debut: [""],
  file: [""],
  type: [""],
};
const CreateCompetitions = ({
  setcreateCompetitionsModalFromChild,
  getAllCompetitions,
}) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { alertModal, displayAlert, dismissAlert } = useAlert();
  const [competitions, setCompetitions] = React.useState();
  const [formerrors, setFormErrors] = React.useState(initFormErrors);
  const uploadedImage = React.useRef(null);

  const [comp_name, setComp_name] = React.useState("");
  const [type, setType] = React.useState("championnat");
  const [comp_slug, setComp_slug] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [date_debut, setDate_debut] = React.useState(
    new Date().toISOString().substr(0, 10)
  );
  const [logo, setLogo] = React.useState("");
  function sendData() {
    const formData = new FormData();
    formData.append("comp_name", comp_name);
    formData.append("comp_slug", comp_slug);
    formData.append("country", country);
    formData.append("date_debut", date_debut);
    formData.append("logo", logo);
    formData.append("type", type);

    axios
      .post(`${baseURL}/competitions/create`, formData)
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        displayAlert("success", "", resp.data.message);
        setTimeout(() => {
          dismissAlert();
        }, 2500);
        getAllCompetitions();
        setcreateCompetitionsModalFromChild();
      })
      .catch((error) => {
        setFormErrors(error.response.data);
      });
  }

  const handleImageUpload = (e) => {
    const [file] = e.target.files;
    if (file) {
      const reader = new FileReader();
      const { current } = uploadedImage;
      current.file = file;
      reader.onload = (e) => {
        current.src = e.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const [countries, setCountries] = React.useState([]);
  function getCountries() {
    axios.get(`${baseURL}/countries/allCountries`).then((resp) => {
      if (resp.data.error) return;
      resp.data.data.map((item, index) => {
        return setCountries((prevState) => [
          ...prevState,
          { value: item.id, label: item.name },
        ]);
      });
    });
  }
  useEffect(() => {
    getCountries();
  }, []);

  return (
    <>
      <div>
        <div
          className="modal modal1 fade show style_block"
          id="role_modal"
          tabIndex="-1"
          aria-modal="true"
          role="dialog"
        >
          <div className="modal-dialog ">
            <div className="modal-content ">
              <div>
                <div className="row pb-4 pt-4 style_header_modal m-0">
                  <div className="col-lg-11">
                    <h3 className="text-center text-white ">
                      Création de competition
                    </h3>
                  </div>
                </div>
              </div>
              <div className="modal-body ">
                <div className="row mb-4">
                  <div className="col-lg-6 mb-2">
                    <h6>Nom de compétition</h6>
                    <input
                      type="text"
                      className="form-control"
                      id="role_name"
                      placeholder="Nom de compétition"
                      onChange={(e) => setComp_name(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {formerrors.comp_name}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6 mb2">
                    <h6>Abréviation de compétition</h6>
                    <input
                      type="text"
                      className="form-control"
                      id="role_name"
                      placeholder="Abréviation de compétition"
                      onChange={(e) => setComp_slug(e.target.value)}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {formerrors.comp_slug}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-6">
                    <h6>Pays</h6>
                    <Select
                      options={countries}
                      isSearchable
                      placeholder="pays"
                      onChange={(e) => setCountry(e.value)}
                    />
                    <div className="input-error">
                      <span className="text-danger">{formerrors.country}</span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <h6>Date de début de compétition</h6>
                    <input
                      type="date"
                      className="form-control"
                      id="role_name"
                      placeholder="Abréviation de compétition"
                      onChange={(e) => setDate_debut(e.target.value)}
                      value={date_debut}
                      required
                    />
                    <div className="input-error">
                      <span className="text-danger">
                        {formerrors.date_debut}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-6">
                    <h6>Type</h6>
                    <select
                      name="type"
                      value={type}
                      onChange={(e) => setType(e.target.value)}
                      className="form-control"
                    >
                      <option value="championnat">Championnat</option>
                      <option value="coupe">Coupe</option>
                    </select>
                    <div className="input-error">
                      <span className="text-danger">{formerrors.type}</span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <h6>Logo</h6>
                    <input
                      required
                      type="file"
                      className="form-control"
                      id="role_name"
                      placeholder="Logo de compétition"
                      onChange={(e) => {
                        handleImageUpload(e);
                        setLogo(e.target.files[0]);
                      }}
                    />
                    <div className="input-error">
                      <span className="text-danger">{formerrors.file}</span>
                    </div>
                  </div>
                </div>
                <div className="row mb-4">
                  <div className="col-lg-6">
                    <img className="updateImg" ref={uploadedImage} />
                  </div>
                </div>
                <div className="input-error">
                  <span className="text-danger">{formerrors.p}</span>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                  onClick={() => setcreateCompetitionsModalFromChild("")}
                >
                  Annuler
                </button>
                <button
                  type="button"
                  onClick={sendData}
                  className="btn btn-primary"
                >
                  Enregistrer
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-backdrop fade show"></div>
      </div>
      {alertModal}
    </>
  );
};

export default CreateCompetitions;
