import React, { useState, useEffect } from "react";
import axios from "axios";
import { logError } from "../components/Toastconfig";
import Table from "react-bootstrap/Table";
import UpdateBoostedCompetition from "../components/competitions/UpdateBoostedCompetition";
import useAlert from "../hooks/alertHook";

const CardCompetitions = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [CardCompetitionss, setCardCompetitions] = useState();
  const { alertModal, displayAlert, dismissAlert } = useAlert();

  useEffect(function () {
    getAllCompetitions();
  }, []);
  function getAllCompetitions() {
    axios
      .get(`${baseURL}/competitions/getAllCompetitionsBoosted`)
      .then((res) => {
        setCardCompetitions(res.data);
      })
      .catch((err) => {
        logError();
      });
  }
  async function updateStatus_competition(id) {
    displayAlert("danger", "", "Voulez-vous confirmer l'annulation de boost?", "Supprimer", () => {
      axios
      .get(`${baseURL}/competitions/updateBoostedCompetitionStatus/${id}`)
      .then((resp) => {
        if (resp.data.error) return;
        displayAlert("sucess","","Le boost de competition est annulé");
        setTimeout(() => {
          dismissAlert();
          getAllCompetitions();
       },2500);
      })
      .catch((error) => {
        dismissAlert();
        logError();
        displayAlert("danger", "", error.response.data.message);
      });
    }, "cancel", dismissAlert);
  }
  
  return (
    <section className="pt-5 ">
      <div className="row mx-0">
        <div className="col-4">
          <h4 className="text-nowrap">Les Compétitions Boostées</h4>
        </div>
      </div>
      <section>
        <div className="row mt-4 mx-0">
          <div className="table-responsive ">
            <Table className="table" responsive="sm" hover>
              <thead className="style_thead">
                <tr>
                  {" "}
                  <th className="py-4 style_tab style_th "></th>
                  <th className="py-4 style_tab style_th text-nowrap">
                    Nom Des Compétitions Boostées
                  </th>
                  <th className="py-4 style_tab style_th text-nowrap">
                    Date de début boost
                  </th>
                  <th className="py-4 style_tab style_th text-nowrap">
                    Date De fin boost
                  </th>
                  <th className="py-4 style_tab style_th text-nowrap">
                    Status
                  </th>
                  <th className="py-4 style_tab style_th text-nowrap">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className=" style_tbody">
                {CardCompetitionss?.map((competition, key) => (
                  <tr key={key} className="border-b style_tbody text-nowrap">
                    <td>
                      <img
                        className="rounded-circle"
                        width="35"
                        height="35"
                        src={`${
                          baseURL +
                          "/competition/getFile" +
                          competition.competition.logo.substring(1)
                        }`}
                        alt="Image Title"
                      />
                    </td>
                    <td className="py-4 text-nowrap Boosted">
                      {competition.competition.comp_name}
                    </td>
                    <td className="py-4 text-nowrap">
                      {competition.date_Debut_Boost}
                    </td>
                    <td className="py-4 text-nowrap">
                      {competition.date_Fin_Boost}
                    </td>
                    <td className="py-4">
                      {competition.status == "in_progress" ? (
                        <div className="badge p-2 text-center status_prog">
                          En Cours
                        </div>
                      ) : competition.status == "canceled" ? (
                        <div className="badge p-2 text-center status_cancel">
                          Annulé
                        </div>
                      ) : (
                        <div
                          className="badge p-2 text-center status_expired "
                        >
                          expiré
                        </div>
                      )}
                    </td>
                    <td className="py-4 text-nowrap ">
                      {competition.status == "canceled"  || competition.status === "expired"   ? (
                        ""
                      ) : (
                        <div className="d-flex ">
                          <UpdateBoostedCompetition
                            CompetitionId={competition.competition.id}
                            competition={competition}
                          />
                          <button
                            className="btn btn-update"
                            onClick={() => {
                              updateStatus_competition(competition.id);
                            }}
                          >
                            <i
                              class="bi bi-slash-circle"
                              title="canceled boosted competition"
                            ></i>
                          </button>
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </section>
      {alertModal}
    </section>
  );
};

export default CardCompetitions;
