import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { logError } from "../../components/Toastconfig";
import EmptyData from "../../components/emptyData";

const NewsMatchSimpleUser = ({matchId}) => {
  const baseURL = process.env.REACT_APP_BASE_URL;

  useEffect(() => {
    getallnews();
  }, []);

  const [newslist, setNewslist] = useState([]);

  function getallnews() {
    axios
    .get(`${baseURL}/calendarMatches/matchDetails/allNews/${matchId}`)
    .then((res) => {
        setNewslist(res.data.data);
      })
      .catch((err) => {
        logError();
      });
  }

  return (
    <>
    <div className="row card-body">
  {newslist.length === 0 ? (
    <EmptyData />
  ) : (
    newslist.map((news, key) => {
      // function to calculate the time difference for news create
      const now = new Date();
      const createdAt = new Date(news.createdat);
      const diffInMilliseconds = now - createdAt;
      let relativeTime;

      if (diffInMilliseconds < 60000) {
        relativeTime = "À l'instant";
      } else if (diffInMilliseconds < 3600000) {
        const minutes = Math.floor(diffInMilliseconds / 60000);
        relativeTime = `${minutes} ${minutes === 1 ? "minute" : "minutes"} auparavant`;
      } else if (diffInMilliseconds < 86400000) {
        const hours = Math.floor(diffInMilliseconds / 3600000);
        relativeTime = `${hours} ${hours === 1 ? "heure" : "heures"} auparavant`;
      } else {
        const days = Math.floor(diffInMilliseconds / 86400000);
        relativeTime = `${days} ${days === 1 ? "jour" : "jours"} auparavant`;
      }
      return (
        <div className="col-md-6 col-12 mb-3 d-grid align-items-center shadow-effect" key={key}>
          <div className="row d-flex align-items-center justify-content-center">
            <div className="col-4">
              <Link to={`/oneCompetition/matches/matchDetails/newDetails/${news.id}`} className="noTextDecoration">
                <img className="w-75" src={`${baseURL}/competition/getFile${news.media}`} alt="News Image" />
              </Link>
            </div>
            <div className="col-8">
              <Link to={`/oneCompetition/matches/matchDetails/newDetails/${news.id}`} className="noTextDecoration text-dark">
                <h5 className="hoverUnderline">
                  {news.title.length > 40 ? `${news.title.slice(0, 40)}...` : news.title}
                </h5>
                <p>{relativeTime}</p>
              </Link>
            </div>
          </div>
        </div>
      );
    })
  )}
</div>
    </>
  );
};

export default NewsMatchSimpleUser;