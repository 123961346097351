import { UserContext } from "../context/UserContext";
import { useContext } from "react";

export function useHasPermission() {
  const { user } = useContext(UserContext);
  const checkPermissions = (permission) => {
    return user && user.permissions?.includes(permission) === true;
  }
   return {checkPermissions};
}
