import React from "react";
import Table from "react-bootstrap/Table";
import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Select from "react-select";
import EditTransfer from "../../components/transfers/EditTransfer";
import { logError } from "../Toastconfig";
import Modal from "react-bootstrap/Modal";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { toast } from "react-toastify";
import useAlert from "../../hooks/alertHook";
const initFormErrors = {
  team_to: [""],
  team_from: [""],
  type: [""],
  date_debut_transfer: [""],
  date_fin_transfer: [""],
};
const CreateTransfer = (playerId) => {
  const baseURL = process.env.REACT_APP_BASE_URL;

  const [date_debut_transfer, setDate_debut_transfer] = useState(
    new Date().toISOString().substr(0, 10)
  );
  const [date_fin_transfer, setDate_fin_transfer] = useState(
    new Date().toISOString().substr(0, 10)
  );
  const today = new Date().toISOString().substr(0, 10);
  const [team_from, setTeam_from] = useState(null);
  const [team_to, setTeam_to] = useState(null);
  const [type, setType] = useState(null);
  const [player, setPlayer] = useState(playerId);
  const [teams, setTeams] = useState([]);
  const [currentTeam, setCurrentTeam] = useState([]);
  const { alertModal, displayAlert, dismissAlert } = useAlert();
  const [errorMessage, setErrorMessage] = useState(initFormErrors);
  const [errorMsg, setErrorMsg] = useState();

  const handleSubmit = (event) => {
    {
      const data = {
        team_from: team_from?.toString(),
        team_to: team_to,
        type: type,
        date_debut_transfer: date_debut_transfer,
        date_fin_transfer: date_fin_transfer,
        player: player.playerId,
      };
      axios
        .post(`${baseURL}/transfers/create`, data)
        .then((resp) => {
          setErrorMessage(initFormErrors);
          if (resp.data.error) return;
          displayAlert("success", "", resp.data.message);
          setTimeout(() => {
            dismissAlert();
          }, 2500);
          window.location.reload();
          getALLTransfers();
          setSelectedTeamFrom(null);
          setSelectedTeamTo(null);
          setSelectedType(null);
        })
        .catch((error) => {
          logError();
          const errors = error.response.data;
          setErrorMessage(errors);
          setErrorMsg(errors.message);
        });
    }
  };
  const [team_fromName, setTeam_fromName] = useState("");
  const [team_fromLogo, setTeam_fromLogo] = useState("");
  function GetPlayerById() {
    if (playerId === undefined) return;
    axios.get(`${baseURL}/players/${playerId.playerId}`).then((resp) => {
      if (resp.data.error) return;
      const PlayerData = resp.data.data;
      setCurrentTeam(PlayerData.currentTeam);
      const lastTeam = PlayerData.currentTeam;
      setTeam_from(lastTeam.id);
      setTeam_fromName(lastTeam.name);
      setTeam_fromLogo(lastTeam.logo);
    });
  }

  useEffect(() => {
    GetPlayerById();
  }, []);
  const [transfers, setTransfers] = useState([]);

  function getALLTransfers() {
    axios
      .get(`${baseURL}/transfers/player/${playerId.playerId}`)
      .then((res) => {
        setTransfers(res.data.data);
      })
      .catch((err) => {
        logError();
      });
  }

  useEffect(() => {
    getALLTransfers();
  }, [playerId.playerId]);

  useEffect(function getALL() {
    axios
      .get(`${baseURL}/teams/read`)
      .then((res) => {
        setTeams(res.data.data);
      })
      .catch((err) => {
        logError();
      });
  }, []);
  const [selectedTeamFrom, setSelectedTeamFrom] = useState(team_from);
  const [selectedTeamTo, setSelectedTeamTo] = useState(team_to);

  const [options, setTeamOptions] = useState(
    teams
      .map((team) => ({
        value: team.id,
        label: team.name,
        logo: baseURL + "/team/getFile" + team.logo,
      }))
      .concat({
        label: "Ajouter équipe ",
        value: "createTeam",
      })
  );
  useEffect(() => {
    setTeamOptions(
      teams
        .map((team) => ({
          value: team.id,
          label: team.name,
          logo: baseURL + "/team/getFile" + team.logo,
        }))
        .concat({
          label: "Ajouter équipe",
          value: "createTeam",
        })
    );
  }, [teams]);

  const [selectedType, setSelectedType] = useState(type);

  const optionsTypes = [
    { value: "transfer", label: "Transfert" },
    { value: "loan", label: "Loan" },
    { value: "freeTransfer", label: "Free Transfer" },
  ];
  const [showCreateTeam, setShowCreateTeam] = useState(false);

  const handleTeamToChange = (selectedTeamTo) => {
    if (selectedTeamTo.value === "createTeam") {
      setShowCreateTeam(true);
    } else {
      setTeam_to(selectedTeamTo.value.toString());
      setSelectedTeamTo(selectedTeamTo);
      setShowCreateTeam(false);
    }
  };

  const handleCreateTeamClose = () => {
    setShowCreateTeam(false);
  };

  // code for createTeamTransfer
  const emailRegex = /^\S+@\S+\.\S+$/;

  const [logo, setLogo] = useState(null);
  const [name, setName] = useState(null);
  const [acronyme, setAcronyme] = useState(null);
  const [country, setCountry] = useState(null);
  const [agent_Phone, setAgent_Phone] = useState(null);
  const [agent_Email, setAgent_Email] = useState(null);
  const [stade_Name, setStade_Name] = useState(null);
  const [isPartner, setisPartner] = useState("no");
  const [partnerShip_Date, setpartnerShip_Date] = useState(
    new Date().toISOString().substr(0, 10)
  );
  const [logoError, setLogoError] = useState(null);
  const [nameError, setNameError] = useState(null);
  const [acronymeError, setAcronymeError] = useState(null);
  const [countryError, setCountryError] = useState(null);
  const [agent_PhoneError, setAgent_PhoneError] = useState(null);
  const [agent_EmailError, setAgent_EmailError] = useState(null);
  const [stade_NameError, setStade_NameError] = useState(null);
  const [isPartnerError, setisPartnerError] = useState(null);
  const [partnerShip_DateError, setpartnerShip_DateError] = useState(null);

  const handleSubmitt = () => {
    if (!name) {
      setNameError("Le champ nom est requis");
    } else {
      setNameError("");
    }
    if (!acronyme) {
      setAcronymeError(" Le champ acronyme est requis");
    } else {
      setAcronymeError("");
    }
    if (!country) {
      setCountryError("Le champ pays est requis");
    } else {
      setCountryError("");
    }
    if (!agent_Phone) {
      setAgent_PhoneError(" Le champ téléphone d'agent  est requis");
    } else {
      setAgent_PhoneError("");
    }
    if (!stade_Name) {
      setStade_NameError("Le champ nom du stade est requis");
    } else {
      setStade_NameError("");
    }
    if (!isPartner) {
      setisPartnerError("Le champ partenaire  est requis");
    } else {
      setisPartnerError("");
    }
    if (!agent_Email) {
      setAgent_EmailError("Le champ email de l'agent est requis");
    } else {
      setAgent_EmailError("");
    }
    if (!emailRegex.test(agent_Email)) {
      setAgent_EmailError("Le champ email de l'agent est invalide");
    }
    {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("acronyme", acronyme);
      formData.append("country", country);
      formData.append("logo", logo);
      formData.append("agent_Phone", agent_Phone);
      formData.append("agent_Email", agent_Email);
      formData.append("stade_Name", stade_Name);
      formData.append("isPartner", isPartner);
      if (isPartner === "yes") {
        formData.append("partnerShip_Date", partnerShip_Date);
      }
      axios
        .post(`${baseURL}/teams/create`, formData)
        .then((resp) => {
          if (resp.data.error) return;
          if (resp.data.message) displayAlert("success", "", resp.data.message);
          setTimeout(() => {
            dismissAlert();
          }, 2500);
          axios.get(`${baseURL}/teams/read`).then((res) => {
            setTeams(res.data.data);
          });
          setShowCreateTeam(false);
        })
        .catch((error) => {});
    }
  };

  const [countries, setCountris] = useState([]);
  //  get ALL countries funtion
  useEffect(function () {
    axios
      .get(`${baseURL}/countries/read`)
      .then((res) => {
        setCountris(res.data);
      })
      .catch((err) => {
        logError();
      });
  }, []);

  return (
    <div className="table_transfer">
      <div id="no-more-tables">
        <table className="table bg-white ">
          <thead className="style_thead">
            <tr>
              <th className="style_tab style_th p-3 text-nowrap">À domicile</th>
              <th className="style_tab style_th p-3">Type</th>
              <th className="style_tab style_th p-3 text-nowrap">À l'extérieur</th>
              <th className="style_tab style_th p-3">Date Debut Transfer</th>
              <th className="style_tab style_th p-3">Date Fin Transfer</th>
              <th className="style_tab style_th p-3">Action</th>
            </tr>
          </thead>
          <>
            <tbody className="my-4 style_tbody text-nowrap">
              {transfers.map((transfer, key) => (
                <EditTransfer transfer={transfer} key={key} />
              ))}
              <tr className="border-b style_tbody text-nowrap">
                <td className=" text-nowrap">
                  <Select
                    id="Team_from"
                    isDisabled={true}
                    name="Team_from"
                    options={options}
                    value={options.filter(
                      (option) => option.value === team_from
                    )}
                    getOptionLabel={(e) => (
                      <div className="style_flex">
                        <img className="teamImgSelected" src={e.logo} />
                        <span className="selectedTeam">{e.label}</span>
                      </div>
                    )}
                  />

                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.team_from}
                    </span>
                  </div>
                </td>
                <td className=" text-nowrap">
                  <Select
                    id="Team_from"
                    name="Team_from"
                    options={optionsTypes}
                    value={selectedType}
                    placeholder="Type"
                    onChange={(selectedType) => {
                      setType(selectedType.value.toString());
                      setSelectedType(selectedType);
                    }}
                  ></Select>
                  <div className="input-error">
                    <span className="text-danger">{errorMessage.type}</span>
                  </div>
                </td>
                <td className=" text-nowrap">
                  <Select
                    id="Team_to"
                    name="Team_to"
                    options={options}
                    getOptionLabel={(e) => (
                      <>
                        {e.component ? (
                          e.component
                        ) : (
                          <div className="style_flex">
                            <img
                              className="pe-1 teamImgSelected"
                              src={e.logo}
                            ></img>
                            <span style={{ marginTop: 3 }}>{e.label}</span>
                          </div>
                        )}
                      </>
                    )}
                    value={selectedTeamTo}
                    placeholder="Equipe à l'extérieur"
                    onChange={handleTeamToChange}
                  />
                  {showCreateTeam && (
                    <Modal show={showCreateTeam} onHide={handleCreateTeamClose}>
                      <Modal.Header className="style_modalHeader ">
                        <div>
                          <Modal.Title className="style_modalTitle">
                            Créer équipe
                          </Modal.Title>
                        </div>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="">
                          <div className="row mb-4">
                            <div className="col-lg-6">
                              <h6 className="fw-bold color_black">
                                Nom d'équipe
                              </h6>
                              <input
                                onChange={(e) => setName(e.target.value)}
                                id="name"
                                type="text"
                                name="name"
                                className="form-control"
                                placeholder="Nom d'équipe"
                              />
                              {nameError && (
                                <div className="style_err">{nameError}</div>
                              )}
                            </div>
                            <div className="col-lg-6">
                              <h6 className="fw-bold color_black">Acronyme</h6>
                              <input
                                required
                                onChange={(e) => setAcronyme(e.target.value)}
                                type="text"
                                id="acronyme"
                                name="acronyme"
                                className="form-control"
                                placeholder="Acronyme"
                              />
                              {acronymeError && (
                                <div className="style_err">{acronymeError}</div>
                              )}
                            </div>
                          </div>
                          <div className="row mb-4">
                            <div className="col-lg-6">
                              <h6 className="fw-bold color_black">Pays</h6>
                              <select
                                className="form-control"
                                id="country"
                                name="country"
                                value={country}
                                onChange={(e) => setCountry(e.target.value)}
                              >
                                <option value="">Pays</option>
                                {countries.map((country, key) => (
                                  <option value={country.id} key={key}>
                                    {country.name}
                                  </option>
                                ))}
                              </select>
                              {countryError && (
                                <div className="style_err">{countryError}</div>
                              )}
                            </div>
                            <div className="col-lg-6">
                              <h6 className="fw-bold color_black">Stade Nom</h6>
                              <input
                                required
                                onChange={(e) => setStade_Name(e.target.value)}
                                name="stade_Name"
                                className="form-control"
                                placeholder="Stade Nom"
                              />
                              {stade_NameError && (
                                <div className="style_err">
                                  {stade_NameError}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row mb-4">
                            <div className="col-lg-6">
                              <h6 className="fw-bold color_black">
                                Agent téléphone
                              </h6>
                              <PhoneInput
                                className="style_phone_comp"
                                placeholder="Agent téléphone"
                                type="text"
                                onChange={(value, country, event) =>
                                  setAgent_Phone(event.target.value)
                                }
                              />
                              {agent_PhoneError && (
                                <div className="style_err">
                                  {agent_PhoneError}
                                </div>
                              )}
                            </div>
                            <div className="col-lg-6">
                              <h6 className="fw-bold color_black">
                                Agent Email
                              </h6>
                              <input
                                required
                                onChange={(e) => setAgent_Email(e.target.value)}
                                name="agent_Email"
                                className="form-control"
                                placeholder="Agent Email"
                              />
                              {agent_EmailError && (
                                <div className="style_err">
                                  {agent_EmailError}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row mb-4">
                            <div className="col-lg-6">
                              <h6 className="fw-bold color_black">
                                Partenaire
                              </h6>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  onChange={(e) => setisPartner(e.target.value)}
                                  name="inlineRadioOptions"
                                  value="yes"
                                  checked={isPartner === "yes"}
                                />
                                <label className="form-check-label" for="yes">
                                  Oui
                                </label>
                              </div>
                              <div className="form-check form-check-inline">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  onChange={(e) => setisPartner(e.target.value)}
                                  name="inlineRadioOptions"
                                  value="no"
                                  checked={isPartner === "no"}
                                />
                                <label className="form-check-label" for="no">
                                  Non
                                </label>
                              </div>
                              {isPartnerError && (
                                <div className="style_err">
                                  {isPartnerError}
                                </div>
                              )}
                              {isPartner === "yes" && (
                                <div className="row mb-4">
                                  <div className="col-lg-6">
                                    <h6 className="fw-bold color_black">
                                      Date Parteneriat
                                    </h6>
                                    <input
                                      type="date"
                                      className="form-control"
                                      id="date"
                                      placeholder="PartnerShip Date"
                                      onChange={(e) =>
                                        setpartnerShip_Date(e.target.value)
                                      }
                                      value={partnerShip_Date}
                                      required
                                    />

                                    {partnerShip_DateError && (
                                      <div className="style_err">
                                        {partnerShip_DateError}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                            <div className="col-lg-6">
                              <h6 className="fw-bold color_black">Logo</h6>
                              <input
                                required
                                onChange={(e) => setLogo(e.target.files[0])}
                                id="logo"
                                accept="image/*"
                                name="logo"
                                type="file"
                                className="form-control"
                                placeholder="Logo"
                              />
                              {logoError && (
                                <div className="style_err">{logoError}</div>
                              )}
                            </div>
                          </div>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="primary" onClick={handleSubmitt}>
                          Créer équipe
                        </Button>
                        <Button
                          variant="secondary"
                          className="style_modalTitle"
                          onClick={handleCreateTeamClose}
                        >
                          Annuler
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  )}

                  <div className="input-error">
                    <span className="text-danger">{errorMessage.team_to}</span>
                  </div>
                </td>

                <td className="text-nowrap">
                  <input
                    type="date"
                    className="form-control"
                    id="date"
                    value={date_debut_transfer}
                    min={today}
                    placeholder="Date Debut Transfer"
                    onChange={(e) => setDate_debut_transfer(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.date_debut_transfer}
                    </span>
                  </div>
                </td>
                <td className="text-nowrap">
                  <input
                    type="date"
                    value={date_fin_transfer}
                    className="form-control"
                    id="date"
                    placeholder="Date Fin Transfer"
                    onChange={(e) => setDate_fin_transfer(e.target.value)}
                    required
                  />
                  <div className="input-error">
                    <span className="text-danger">
                      {errorMessage.date_fin_transfer}
                    </span>
                  </div>
                </td>
                <td className=""></td>
              </tr>
            </tbody>
          </>
        </table>
        <div className="input-error">
          <span className="text-danger text-nowrap">{errorMsg}</span>
        </div>
      </div>
      <div className="col-lg-2 text-center pb-1">
        <Button variant="primary" className="" onClick={handleSubmit}>
          Ajouter
        </Button>
      </div>
      {alertModal}
    </div>
  );
};

export default CreateTransfer;
