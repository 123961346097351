import React from "react";

const ConfirmResetSend = () => {
  return (
    <section className="container-fluid bg-main">
      <div className="container py-5 h-100 ">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col">
            <div className="card card_style border-0 h-100">
              <div className="row g-0">
                <div className="col-md-6 col-lg-6 d-none d-md-block bg-log">
                  <div className="pt-5 px-3">
                    <img src="./assets/imgs/logo.png"></img>
                    <h2 className="my-4 title white">
                      Suivez votre joueur pas à pas, partie par partie !
                    </h2>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 d-flex align-items-center">
                  <div className="card-body text-black ">
                    <div className="">
                      <h2 className="title black mt-4 mb-1 pb-1 text-center">
                        Content de te revoir !
                      </h2>
                    </div>
                    
                    <div
                      className=" d-flex flex-column align-items-center"
                    >
                      <div className="form-outline text-black mb-4">
                        <label className="form-label ">Merci de bien vouloir vérifier votre boîte de réception pour consulter l'email de réinitialisation.</label>
                    </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
export default ConfirmResetSend;
