import React from "react";
import { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import axios from "axios";
import useAlert from "../../hooks/alertHook";
import Select from "react-select";
import ReactPlayer from "react-player";
import { useRef } from 'react'

const initFormErrors = {
  title: [""],
  description: [""],
  video: [""],
  selectedPlayersTH: [""],
  selectedPlayersTA: [""],
};
export default function VideoPlayersPerMatch({
  matchId,
  playerTeamH,
  playerTeamA,
}) {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { alertModal, displayAlert, dismissAlert } = useAlert();
  const [video, setvideo] = useState("");
  const [title, setTitle] = useState("");
  const [playerrId, setPlayerId] = useState();

  const [description, setdescription] = useState("");
  const [formErrors, setFormErrors] = useState(initFormErrors);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setvideo("");
    setTitle("");
    setdescription("");
    setSelectedPlayersTA("");
    setSelectedPlayersTH("");
    setPlayerId("");
    setFormErrors(initFormErrors);
    setShow(false);
  };
  const handleSubmit = () => {
    sendData();
  };

  const [selectedPlayersTH, setSelectedPlayersTH] = useState([]);
  const [selectedPlayersTA, setSelectedPlayersTA] = useState([]);
  const [currentVideo, setCurrentVideo] = useState([]);
  function sendData() {
    const playersIds = [...selectedPlayersTH, ...selectedPlayersTA].map(
      (player) => {
        return player.value;
      }
    );
    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("path", video);
    formData.append("playersIds", JSON.stringify(playersIds));
    formData.append("matchId", matchId);

    axios
      .post(
        `${baseURL}/calendarMatches/matchDetails/uploadvideoMatch`,
        formData
      )
      .then((resp) => {
        setFormErrors(initFormErrors);
        if (resp.data.error) return;
        displayAlert("success", "", resp.data.message);
        setTimeout(() => {
          dismissAlert();
        }, 2500);
        getMatchVideoss();
        handleClose();
      })
      .catch((error) => {
        setFormErrors(error.response.data);
      });
  }
  const [Matchvideos, setMatchvideos] = useState([]);
  const handleVideoClick = (video) => {
    setCurrentVideo(video);
  };
  function getMatchVideoss() {
    if (matchId === undefined) return;
    axios
      .get(`${baseURL}/calendarMatches/matchDetails/videosMatch/${matchId}`)
      .then((response) => {
        if (response.data.error) {
          return;
        }
        setMatchvideos(response.data.data);
        setCurrentVideo(response.data.data[0]);
      });
  }
  useEffect(() => {
    getMatchVideoss();
  }, []);
  const scrollRef = useRef();
  const videoPlayerRef = useRef();
  const handleScroll = () => {
    const videoPlayer = videoPlayerRef.current;
    if (videoPlayer) {
      const scrollPosition = window.pageYOffset;
      if (window.innerWidth <= 767 && scrollPosition > 0) {
        videoPlayer.style.position = 'fixed';
        videoPlayer.style.top = '16%';
        videoPlayer.style.width = '75%';
      } else {
        videoPlayer.style.position = 'relative';
        videoPlayer.style.top = 'auto';
        videoPlayer.style.width = '96%';
      }
    }
  };
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="row">
      {Matchvideos.length === 0 ? (
        <div className="row">
          <div className="col-lg-12 pt-2">
            <div class="image-upload ">
              <div class="h-100">
                <div class="dplay-tbl p-5">
                  <div class="dplay-tbl-cell">
                    <i class="fa fa-cloud-upload" onClick={handleShow}></i>
                    <h5>
                      <b>Télécharger une Vidéo</b>
                    </h5>
                  </div>
                </div>
                <Modal
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  show={show}
                  onHide={handleClose}
                >
                  <Modal.Header className="style_modalHeader">
                    <div>
                      <Modal.Title className="style_modalTitle">
                        Télécharger Vidéo
                      </Modal.Title>
                    </div>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="">
                      <div className="row mb-4">
                        <div className="col-lg-6 col-sm-12">
                          <h6>Titre</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Titre"
                            onChange={(e) => setTitle(e.target.value)}
                            required
                          />
                          <div className="input-error">
                            <span className="text-danger">
                              {formErrors.title}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-6 col-sm-12">
                          <h6>Video</h6>
                          <input
                            required
                            type="file"
                            className="form-control"
                            placeholder="video"
                            onChange={(e) => {
                              setvideo(e.target.files[0]);
                            }}
                          />
                          <div className="input-error">
                            <span className="text-danger">
                              {formErrors.file}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-lg-6 col-sm-12">
                          <h6>Description</h6>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="description"
                            onChange={(e) => setdescription(e.target.value)}
                            required
                          />
                          <div className="input-error">
                            <span className="text-danger">
                              {formErrors.description}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-4">
                        <div className="col-6">
                          <h6 className="mb-2 pb-1">
                            Joueurs d'équipe à Domicile :{" "}
                          </h6>
                          <Select
                            value={selectedPlayersTH}
                            onChange={(selectedOptions) => {
                              setSelectedPlayersTH(selectedOptions);
                              setPlayerId(selectedOptions.map((e) => e.value));
                            }}
                            options={playerTeamH.map((player, index) => ({
                              label: `${player.firstName.toLowerCase()} ${player.lastName.toLowerCase()}`,
                              value: player.id,
                            }))}
                            placeholder="Select players "
                            id="matchId"
                            name="matchId"
                            autoFocus
                            isClearable
                            isSearchable
                            isMulti
                            required
                          />
                        </div>
                        <div className="col-6">
                          <h6 className="mb-2 pb-1">
                            Joueurs d'équipe à l'extérieur :{" "}
                          </h6>
                          <Select
                            value={selectedPlayersTA}
                            onChange={(selectedOptions) => {
                              setSelectedPlayersTA(selectedOptions);
                              setPlayerId(selectedOptions.map((e) => e.value));
                            }}
                            options={playerTeamA.map((player, index) => ({
                              label: `${player.firstName.toLowerCase()} ${player.lastName.toLowerCase()}`,
                              value: player.id,
                            }))}
                            placeholder="Select players "
                            id="matchId"
                            name="matchId"
                            autoFocus
                            isClearable
                            isSearchable
                            isMulti
                            required
                          />
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                      onClick={handleClose}
                    >
                      Annuler
                    </button>
                    <button
                      type="button"
                      onClick={handleSubmit}
                      className="btn btn-primary"
                    >
                      Enregistrer
                    </button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container pt-2">
          <div className="row py-5 ">
            <div className="col-md-8 col-lg-8 col-12">
            <div className="video-player" ref={videoPlayerRef} style={{ zIndex: 1 }}>
                <ReactPlayer
                  url={`${
                    baseURL + "/calendarMatches/getFile" + currentVideo.path
                  }`}
                  controls
                  playIcon={
                    <button className="btn">
                      <i className="fa-sharp fa-solid fa-circle-play button-play"></i>
                    </button>
                  }
                  width="99%"
                  height="auto"
                  paddingLeft="5px"
                />
                <div className="hiddenVideos-title">
                  <p className="videoTitle fw-bold fs-4">
                    {currentVideo.title}
                  </p>
                  <p className="text-muted fs-6 ">
                    {currentVideo.description.length > 300
                      ? `${currentVideo.description.slice(0, 300)}...`
                      : currentVideo.description}
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-lg-4 ">
            <div className="scrollable-container" ref={scrollRef}>
                <div className=" p-0 pe-1">
                  <div class="image-upload ">
                    <div class="h-100">
                      <div class="dplay-tbl p-5">
                        <div class="dplay-tbl-cell">
                          <i
                            class="fa fa-cloud-upload"
                            onClick={handleShow}
                          ></i>
                          <h5>
                            <b>Télécharger une Vidéo</b>
                          </h5>
                        </div>
                      </div>
                      <Modal
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={show}
                        onHide={handleClose}
                      >
                        <Modal.Header className="style_modalHeader">
                          <div>
                            <Modal.Title className="style_modalTitle">
                              Télécharger Vidéo
                            </Modal.Title>
                          </div>
                        </Modal.Header>
                        <Modal.Body>
                          <div className="">
                            <div className="row mb-4">
                              <div className="col-lg-6 col-sm-12">
                                <h6>Titre</h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Titre"
                                  onChange={(e) => setTitle(e.target.value)}
                                  required
                                />
                                <div className="input-error">
                                  <span className="text-danger">
                                    {formErrors.title}
                                  </span>
                                </div>
                              </div>
                              <div className="col-lg-6 col-sm-12">
                                <h6>Video</h6>
                                <input
                                  required
                                  type="file"
                                  className="form-control"
                                  placeholder="video"
                                  onChange={(e) => {
                                    setvideo(e.target.files[0]);
                                  }}
                                />
                                <div className="input-error">
                                  <span className="text-danger">
                                    {formErrors.file}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="row mb-4">
                              <div className="col-lg-6 col-sm-12">
                                <h6>Description</h6>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="description"
                                  onChange={(e) =>
                                    setdescription(e.target.value)
                                  }
                                  required
                                />
                                <div className="input-error">
                                  <span className="text-danger">
                                    {formErrors.description}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="row mb-4">
                              <div className="col-6">
                                <h6 className="mb-2 pb-1">
                                  Joueurs d'équipe à Domicile :{" "}
                                </h6>
                                <Select
                                  value={selectedPlayersTH}
                                  onChange={(selectedOptions) => {
                                    setSelectedPlayersTH(selectedOptions);
                                    setPlayerId(
                                      selectedOptions.map((e) => e.value)
                                    );
                                  }}
                                  options={playerTeamH.map((player, index) => ({
                                    label: `${player.firstName.toLowerCase()} ${player.lastName.toLowerCase()}`,
                                    value: player.id,
                                  }))}
                                  placeholder="Select players "
                                  id="matchId"
                                  name="matchId"
                                  autoFocus
                                  isClearable
                                  isSearchable
                                  isMulti
                                  required
                                />
                              </div>
                              <div className="col-6">
                                <h6 className="mb-2 pb-1">
                                  Joueurs d'équipe à l'extérieur :{" "}
                                </h6>
                                <Select
                                  value={selectedPlayersTA}
                                  onChange={(selectedOptions) => {
                                    setSelectedPlayersTA(selectedOptions);
                                    setPlayerId(
                                      selectedOptions.map((e) => e.value)
                                    );
                                  }}
                                  options={playerTeamA.map((player, index) => ({
                                    label: `${player.firstName.toLowerCase()} ${player.lastName.toLowerCase()}`,
                                    value: player.id,
                                  }))}
                                  placeholder="Select players "
                                  id="matchId"
                                  name="matchId"
                                  autoFocus
                                  isClearable
                                  isSearchable
                                  isMulti
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </Modal.Body>
                        <Modal.Footer>
                          <button
                            type="button"
                            className="btn btn-secondary"
                            data-bs-dismiss="modal"
                            onClick={handleClose}
                          >
                            Annuler
                          </button>
                          <button
                            type="button"
                            onClick={handleSubmit}
                            className="btn btn-primary"
                          >
                            Enregistrer
                          </button>
                        </Modal.Footer>
                      </Modal>
                    </div>
                  </div>
                  <div className="video-list">
                    {Matchvideos.map((video, index) => (
                      <div
                        className="style-videos mb-3"
                        key={index}
                        onClick={() => handleVideoClick(video)}
                      >
                        <video
                          style={{
                            cursor: "pointer",
                          }}
                          className="style_video_list "
                          src={`${
                            baseURL + "/calendarMatches/getFile" + video.path
                          }`}
                          alt={video.title}
                        />
                        <div
                          className="overlay px-2"
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          <p className="videoTitle fw-bold fs-6 pt-1">
                            {video.title}
                          </p>
                          <p className="text-muted ">
                            {video.description.length > 100
                              ? `${video.description.slice(0, 100)}...`
                              : video.description}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {alertModal}
    </div>
  );
}
