import React, { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import "react-phone-input-2/lib/style.css";
import { Link } from "react-router-dom";
import Select from "react-select";
import { logError } from "../../components/Toastconfig";
import EmptyData from "../../components/emptyData";
import LoaderLoading from "../../components/LoaderLoading";

const AllJourneysOfSimpleUser = ({ CompetitionId }) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [journeys, setJourneys] = useState([]);
  const [selectedJourney, setSelectedJourney] = useState();
  const [isLoading, setIsLoading] = useState(true);

  function getALLJourneys() {
    axios
      .get(`${baseURL}/calendarMatches/competitions/${CompetitionId}/journeys`)
      .then((res) => {
        setJourneys(res.data.data);
        setSelectedJourney(res.data.data[0]);
      })
      .catch((err) => {
        logError();
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  useEffect(() => {
    getALLJourneys();
  }, []);
  const handleJourneyChange = (selectedOption) => {
    const selectedJourney = journeys.find(
      (journey) => journey.id === selectedOption.value
    );
    setSelectedJourney(selectedJourney);
  };
  return (
    <>
      {isLoading ? (
        <LoaderLoading />
      ) : (
        <div>
          <div className="row">
            <div className="col-12 col-md-3">
              {journeys.length > 0 && (
                <>
                  <label className="text-muted fw-bolder">
                    <small>Numéro de journée</small>
                  </label>
                  <Select
                    options={journeys.map((journey) => ({
                      value: journey.id,
                      label: `Journée ${journey.journey}`,
                    }))}
                    value={{
                      value: selectedJourney?.id,
                      label: `Journée ${selectedJourney?.journey}`,
                    }}
                    onChange={handleJourneyChange}
                  />
                </>
              )}
            </div>
          </div>
          <div className="">
            {selectedJourney &&
            selectedJourney.journeyMatch &&
            selectedJourney.journeyMatch.length > 0 ? (
              <div className="bg-color">
                {selectedJourney.journeyMatch
                  .sort((a, b) => {
                    if (a.date === null) {
                      return 1;
                    } else if (b.date === null) {
                      return -1;
                    } else {
                      return new Date(a.date) - new Date(b.date); // sort by date ascending
                    }
                  })
                  .map((match) => (
                    <div className="row text-nowrap bg-white my-2 p-3 d-flex justify-content-center">
                      <div className="col-lg-5 col-sm-12 row my-2">
                        <div className="col-md-3 col-sm-12 text-center ">
                          <p>journée {selectedJourney.journey}</p>
                        </div>
                        <div className="col-md-3 col-sm-5  text-center">
                          <Link
                            className="style_text_deco"
                            to={`/oneTeam/${match.homeTeam.id}`}
                          >
                            <img
                              width="33"
                              height="33"
                              src={`${baseURL}/team/getFile${match.homeTeam.logo.slice(
                                1
                              )}`}
                              className="me-3"
                            />
                            <span className="currentTeamName">
                              {match.homeTeam.acronyme}
                            </span>
                          </Link>
                        </div>
                        <div className="col-md-2 col-sm-2  text-center">
                          <p className="mx-2 pt-1">-</p>
                        </div>
                        <div className="col-md-4 col-sm-5  text-center">
                          <Link
                            className="style_text_deco"
                            to={`/oneTeam/${match.awayTeam.id}`}
                          >
                            <img
                              width="33"
                              height="33"
                              src={`${baseURL}/team/getFile${match.awayTeam.logo.slice(
                                1
                              )}`}
                              className="me-3"
                            />
                            <span className="currentTeamName">
                              {match.awayTeam.acronyme}
                            </span>
                          </Link>
                        </div>
                      </div>
                      <div class="col-lg-3 col-sm-12  text-center my-2 ">
                        <img
                          width="29"
                          height="29"
                          src="./assets/imgs/stadium_icon.png"
                          className="me-3"
                        />
                        <span>
                          {match.homeTeam.stade_Name.length > 9
                            ? `${match.homeTeam.stade_Name.slice(0, 9)}...`
                            : match.homeTeam.stade_Name}
                        </span>
                      </div>
                      <div className="col-lg-3 col-sm-12 my-2 ">
                        <div className=" d-flex justify-content-center">
                          <div className="">
                            <i
                              class="fa-solid fa-calendar-days fa-xl"
                              style={{ color: "#adb1b8" }}
                            ></i>
                          </div>
                          <div className="">
                            <span className="ms-2">
                              {match.date ? match.date : "Date indisponible"}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-1 text-center my-2">
                        <Link
                          to={`/oneCompetition/matches/matchDetails/${match.id}`}
                        >
                          <button type="button" className="btn p-0 m-0">
                            <i
                              className="fa-solid fa-circle-info"
                              title="Details match"
                            ></i>
                          </button>
                        </Link>
                      </div>
                    </div>
                  ))}
              </div>
            ) : (
              <div className="ms-2">
                <EmptyData />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AllJourneysOfSimpleUser;
