import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { logError } from "../../components/Toastconfig";
import EmptyData from "../../components/emptyData";
import LoaderLoading from "../../components/LoaderLoading";

export default function NewsOfSimpleUser() {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const [newslist, setNewslist] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(function () {
    getallnews();
  }, []);

  function getallnews() {
    axios
      .get(`${baseURL}/news/allNews`)
      .then((res) => {
        setNewslist(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        logError();
        setIsLoading(false);
      });
  }

  return (
    <>
      <section className="p-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-4">
              <h4 className="text-nowrap">Mes Actualités</h4>
            </div>
          </div>
          <div className="row mt-4 mx-0 p-0">
            {isLoading ? (
    <LoaderLoading/>
            ) : newslist.length === 0 ? (
              <div className="row">
                <div className="col-lg-12">
                  <EmptyData />
                </div>
              </div>
            ) : (<div className="row my-3">
              {newslist.map((news, key) => (
                  <div className="col-md-6 col-12 mb-3" key={key}>
                    <div className="card post-card">
                      <Link
                        className="postcard__img_link"
                        style={{ textDecoration: "none" }}
                        to={`/news/details/${news.id}`}
                      >
                        <div className="" >
                          <div className="row no-gutters">
                            <div className="col-md-4">
                              <img src={`${baseURL + "/new/getFile" + news.media}`} className="card-img post-img" alt="..." />
                            </div>
                            <div className="col-md-8">
                              <div className="card-body">
                                <h4 className="card-title post-title">
                                <a style={{ color: "#fff" }} href="#">
                              {news.title.length > 40 ? (news.title.substring(0, 40) + "...") : news.title}
                                </a>
                                </h4>
                                <div className=" card-text d-flex text-nowrap post-text "> {news.createdat.substring(0, 10)} </div>
                                <div className=" card-text d-flex text-nowrap post-text ">
                                  <span className="pe-1">créé par : </span>
                                  <span className=" ">{news.isOwner.name}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                ))
              }
            </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
