import React from "react";
import axios from "axios";
import { useState, useEffect } from "react";
import AffectTeam from "../components/Teams/AffectTeam";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { logError } from "../components/Toastconfig";
import useAlert from "../hooks/alertHook";
import EmptyData from "../components/emptyData";
import EditTeam from "../components/Teams/EditTeam";
import StatusTeam from "../components/Teams/StatusTeam";
import BoostTeam from "../components/Teams/BoostTeam";
import LoaderLoading from "../components/LoaderLoading";

export default function OneTeam() {
  const [team, setTeam] = useState([]);
  const [isLiked, setIsLiked] = useState([]);
  const { id } = useParams();
  const baseURL = process.env.REACT_APP_BASE_URL;
  const { alertModal, displayAlert, dismissAlert } = useAlert();
  const dataPos = {
    GoalKeeper: "GK",
    Back_Right: "AD",
    Back_left: "AG",
    Libero_Left: "LG",
    Libero_Right: "LD",
    defensive_midfielder: "MDF",
    Right_Offensive_midfielder: "MOD",
    Left_Offensive_midfielder: "MOG",
    Right_Winger: "AID",
    Left_Winger: "AIG",
    Center_forward: "AV",
  };
  const [isLoading, setIsLoading] = useState(true);

  function getOne() {
    axios
      .get(`${baseURL}/teams/${id}`)
      .then((res) => {
        setTeam(res.data.data);
        setIsLiked(res.data.data.isLiked);
        setIsLoading(false);
      })
      .catch((err) => {
        logError();
        setIsLoading(false);
      });
  }
  useEffect(() => {
    getOne();
  }, [id]);

  async function likeDislike(id, isLiked) {
    let message =
      isLiked == "yes"
        ? "voulez vous supprimer ce joueur de la liste des favories?"
        : "voulez vous ajouter ce joueur à la liste des favories";
    let status = isLiked == "yes" ? "danger" : "info";
    displayAlert(
      status,
      "",
      message,
      "Confirmer",
      () => {
        axios
          .post(`${baseURL}/teams/like`, {
            teamId: id,
          })
          .then((resp) => {
            let usersInfo = resp.data;
            getOne();
            dismissAlert();
          });
      },
      "cancel",
      dismissAlert
    );
  }
  const [journeys, setJourneys] = useState([]);
  function getALLJourneysByteamId() {
    axios
      .get(`${baseURL}/calendarMatches/teams/${id}`)
      .then((res) => {
        setJourneys(res.data.data);
      })
      .catch((err) => {
        logError();
      });
  }
  useEffect(() => {
    getALLJourneysByteamId();
  }, [id]);

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const sortedJourneys = journeys.sort(
    (a, b) => a.journeyMatch.journey - b.journeyMatch.journey
  );
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = sortedJourneys.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(sortedJourneys.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  const [showPlayers, setShowPlayers] = useState(true); // State to control the visibility of Players and Matches content
  const togglePlayers = () => {
    setShowPlayers(true);
  };

  const toggleMatches = () => {
    setShowPlayers(false);
  };
  return (
    <>
      <section className="p-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xxl-7 col-sm-12 col-lg-12">
              {isLoading ? (
                <LoaderLoading />
              ) : (
                <div className="row">
                  <div className="col-lg-4 col-sm-4 ">
                    <img
                      src={`${baseURL + "/team/getFile" + team.logo}`}
                      className="img-team"
                      width="100%"
                      height="100%"
                    />
                  </div>
                  <div className="col-lg-8 col-sm-8 ">
                    <div className="row">
                      <div className="col-lg-9 col-sm-12">
                        {" "}
                        <h4 className="small-team d-flex align-items-center">
                          {team.name}
                          <h6>
                            <span className="badge bg-primary ms-2 ">
                              {team.acronyme}
                            </span>
                          </h6>

                          <button
                            className="btn text-primary"
                            onClick={() => {
                              likeDislike(id, isLiked);
                            }}
                          >
                            {isLiked == "yes" ? (
                              <i className="fa-solid fa-heart like_icon "></i>
                            ) : (
                              <i className="fa-regular fa-heart dislike_icon"></i>
                            )}
                          </button>
                        </h4>
                      </div>
                      <div className="col-lg-3 col-sm-12">
                        <div className="row mb-lg-0 mb-2">
                          <div className="col-lg-4 col-2">
                            <EditTeam team={team} getOne={getOne} />
                          </div>
                          <div className="col-lg-4 col-2">
                            <StatusTeam team={team}  getOne={getOne}/>{" "}
                          </div>
                          <div className="col-lg-4 col-2">
                            {" "}
                            <BoostTeam TeamId={team.id} />{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                    <hr></hr>
                    <div className="col-lg-6">
                      <div className="mb-2 small-team">
                        <label className="fw-bold small-team">
                          Stade Nom :{" "}
                        </label>
                        {team.stade_Name.length > 9
                          ? `${team.stade_Name.slice(0, 9)}...`
                          : team.stade_Name}
                      </div>
                      <div className="mb-2 small-team">
                        <label className="fw-bold small-team">Pays : </label>
                        {team.countryname}
                      </div>
                      <div className="mb-2 small-team d-flex align-items-center">
                        <label className="fw-bold me-2 text-nowrap">Compétitions :</label>
                        <span className="d-flex align-items-center">
                          {team.competition?.map((comp, key) => {
                            return (
                              <Link
                                style={{ textDecoration: "none" }}
                                to={`/admin/oneCompetition/${comp.id}`}
                                key={key}
                                className="me-2"
                              >
                                <img
                                  width="33"
                                  height="33"
                                  key={key}
                                  src={`${
                                    baseURL +
                                    "/competition/getFile" +
                                    comp.logo.slice(1)
                                  }`}
                                ></img>
                              </Link>
                            );
                          })}
                        </span>
                        <span className="ms-2">
                          <AffectTeam team={id} getOne={getOne} />
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>

          {isLoading ? (
            <LoaderLoading />
          ) : (
            <>
              <div class="pt-3">
                <div className="d-flex justify-content-start align-items-center">
                  <div class=" pb-2">
                    <button
                      type="button"
                      class={`btn ${
                        showPlayers ? "btn-primary" : "btn-outline-primary"
                      }`}
                      data-number="1"
                      onClick={togglePlayers}
                    >
                      Joueurs
                    </button>
                  </div>
                  <div class=" pb-2 ms-3">
                    <button
                      type="button"
                      class={`btn ${
                        !showPlayers ? "btn-primary" : "btn-outline-primary"
                      }`}
                      data-number="1"
                      onClick={toggleMatches}
                    >
                      Matchs
                    </button>
                  </div>
                </div>
              </div>
              {showPlayers && (
                <div className=" mt-2 mx-0">
                  <div className="table-responsive ">
                    <table className="table bg-white table-sm">
                      <thead className="style_thead">
                        <tr>
                          <th className="style_tab style_th text-nowrap">
                            Squad
                          </th>
                          <th className="style_tab style_th text-nowrap">
                            Pays
                          </th>
                          <th className="style_tab style_th text-nowrap">
                            Génerations
                          </th>
                          <th className="style_tab style_th text-nowrap">
                            Positions
                          </th>
                          <th className="style_tab style_th text-nowrap">
                            Pied préféré
                          </th>
                        </tr>
                      </thead>
                      {team.players?.length === 0 ? (
                        <tbody className="my-4 style_tbody">
                          <tr>
                            <td colSpan={5}>
                              <EmptyData />
                            </td>
                          </tr>
                        </tbody>
                      ) : (
                        <tbody className="my-4 style_tbody">
                          {team.players?.map((player, key) => (
                            <tr className="border-b style_tbody" key={key}>
                              <td className="p-4 text-nowrap">
                                <Link
                                  style={{ textDecoration: "none" }}
                                  to={`/admin/player/details/${player.id}`}
                                >
                                  <img
                                    src={`${baseURL}/players/getFile${player.avatar.slice(
                                      1
                                    )}`}
                                    className="rounded-circle"
                                    width="35"
                                    height="35"
                                  />
                                  <span className="ms-2 text-nowrap playerName">
                                    {player.lastName} {player.firstName}
                                  </span>
                                </Link>
                              </td>
                              <td className="p-4 text-nowrap">
                                {player.countryname}
                              </td>
                              <td className="p-4 text-nowrap ">
                                {player.birthday.slice(0, 4)}
                              </td>
                              <td className="p-4 text-nowrap">
                                <>
                                  {JSON.parse(player.positions).map(
                                    (item, key) => {
                                      return (
                                        <span
                                          className="badge bg-secondary me-1"
                                          key={key}
                                        >
                                          {dataPos[item]}
                                        </span>
                                      );
                                    }
                                  )}
                                </>
                              </td>
                              <td className="px-2 py-4 text-nowrap">
                                {player.preferedfoot === "left"
                                  ? "gauche"
                                  : player.preferedfoot === "right"
                                  ? "droite"
                                  : "les deux"}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </table>
                  </div>
                </div>
              )}
              {!showPlayers && (
                <div>
                  <div className="">
                    {currentItems.length > 0 ? (
                      currentItems.map((match, index) => (
                        <div className="row text-nowrap bg-white my-2 p-3 d-flex justify-content-center">
                          <div className="col-lg-6 col-sm-12 row my-2">
                            <div className="col-md-3 col-sm-12 text-center ">
                              <p>journée {match.journeyMatch.journey}</p>
                            </div>
                            <div className="col-md-3 col-sm-5  text-center">
                              <Link
                                className="style_text_deco"
                                to={`/admin/oneTeam/${match.teamHome.id}`}
                              >
                                <img
                                  width="33"
                                  height="33"
                                  src={`${baseURL}/team/getFile${match.teamHome.logo.slice(
                                    1
                                  )}`}
                                  className="me-3"
                                />
                                <span className="currentTeamName">
                                  {match.teamHome.acronyme}
                                </span>
                              </Link>
                            </div>
                            <div className="col-md-2 col-sm-2  text-center">
                              <p className="mx-2 pt-1">-</p>
                            </div>

                            <div className="col-md-4 col-sm-5  d-flex justify-content-center">
                              <Link
                                className="style_text_deco"
                                to={`/admin/oneTeam/${match.teamAway.id}`}
                              >
                                <img
                                  width="33"
                                  height="33"
                                  src={`${baseURL}/team/getFile${match.teamAway.logo.slice(
                                    1
                                  )}`}
                                  className="me-3"
                                />
                                <span className="currentTeamName">
                                  {match.teamAway.acronyme}
                                </span>
                              </Link>
                            </div>
                          </div>
                          <div class="col-lg-3 col-sm-12 text-center text-lg-start my-2 ">
                            <img
                              width="29"
                              height="29"
                              src="./assets/imgs/stadium_icon.png"
                              className="me-3"
                            />
                            <span>
                              {match.teamHome.stade_Name.length > 9
                                ? `${match.teamHome.stade_Name.slice(0, 9)}...`
                                : match.teamHome.stade_Name}
                            </span>
                          </div>
                          <div className="col-lg-3 col-sm-12 my-2 text-center text-lg-start">
                            <i
                              class="fa-solid fa-calendar-days fa-xl"
                              style={{ color: "#adb1b8" }}
                            ></i>
                            <span className="ms-2">
                              {match.date ? match.date : "Date indisponible"}
                            </span>
                          </div>
                        </div>
                      ))
                    ) : (
                      <EmptyData />
                    )}
                  </div>
                  <ul className="pagination">
                    {pageNumbers.map((number) => (
                      <span
                        key={number}
                        className={`page-number paginations ${
                          number === currentPage ? "active" : ""
                        }`}
                        onClick={() => setCurrentPage(number)}
                      >
                        {number}
                      </span>
                    ))}
                  </ul>
                </div>
              )}
            </>
          )}
        </div>
      </section>
      {alertModal}
    </>
  );
}
