import React, { useContext, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import { UserRegistrationContext } from "../pages/Register";
import ConfirmRegister from "./ConfirmRegister";
import RegisterFormStep1 from "./RegisterFormStep1";
import Welcome from "./RegisterFormStep1";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Link } from "react-router-dom";

const initFormErrors = {
  rcv: [""],
  tva_number: [""],
  siren: [""],
  siret: [""],
};
const RegisterFormStep2 = ({ setChildComponent }) => {
  const msgSiret = "Le champ siret est requis";
  const msgSiren = "Le champ siren est requis";
  const msgTva = "Le champ tva  est requis";
  const msgRcv = "Le champ rcv est requis";
  const msgValide = "Veuillez saisir une valeur numérique valide";
   const [errorMessage, setErrorMessage] = useState(initFormErrors);
  const { newUser, setNewUser } = useContext(UserRegistrationContext);
  const [value, setValue] = useState();
  function validate() {
    let error = false;
    setErrorMessage(initFormErrors);
    const numericRegex = /^[0-9]+$/;
    if (newUser.rcv === "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        rcv: msgRcv,
      }));
    } else if (!numericRegex.test(newUser.rcv)) {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        rcv: msgValide,
      }));
    }
  
    if (newUser.siren === "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        siren: msgSiren,
      }));
    } else if (!numericRegex.test(newUser.siren)) {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        siren: msgValide,
      }));
    }
  
    if (newUser.tva_number === "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        tva_number: msgTva,
      }));
    } else if (!numericRegex.test(newUser.tva_number)) {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        tva_number: msgValide,
      }));
    }
  
    if (newUser.siret === "") {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        siret: msgSiret,
      }));
    } else if (!numericRegex.test(newUser.siret)) {
      error = true;
      setErrorMessage((prevState) => ({
        ...prevState,
        siret: msgValide,
      }));
    }
  
    if (!error) {
      setChildComponent(<ConfirmRegister setChildComponent={setChildComponent} />);
    }
  };
  return (
    <section className="container-fluid bg-main">
      <div className="container py-5 h-100 ">
        <div className="row d-flex justify-content-center align-items-center h-100">
          <div className="col">
            <div className="card card_style border-0 h-100">
              <div className="row g-0">
                <div className="col-md-6 col-lg-6 d-none d-md-block bg-log">
                  <div className="pt-5 px-3">
                    <img
                      className="style_img"
                      src="../../assets/imgs/logo.png"
                      alt="logo"
                      width="150"
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 d-flex align-items-center">
                  <div className="card-body text-black ">
                    <div className="">
                      <h3 className="title black mt-4 mb-1 pb-1 text-center">
                        Nous sommes ravis que vous nous rejoigniez !
                      </h3>
                      <h5 className="black mt-4 mb-5 pb-1">
                        Remplissons toutes les informations et configurons votre
                        compte, les joueurs que vous recherchez vous attendent !
                      </h5>
                    </div>
                    <div className="form-horizontal style_form">
                      <div className="row mb-3">
                        <div className="col-lg-6">
                          <h6 className="fw-bold color_black float-start">
                            Siret
                          </h6>

                          <input
                            value={newUser.siret}
                            onChange={(e) => {
                              setNewUser((prevState) => ({
                                ...prevState,
                                siret: e.target.value,
                              }));
                            }}
                            required
                            type="text"
                            className="form-control"
                            placeholder="Siret"
                          />
                          <div className="input-error">
                            <span className="text-danger">
                              {errorMessage.siret}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <h6 className="fw-bold color_black float-start">
                            Siren
                          </h6>
                          <input
                            required
                            value={newUser.siren}
                            onChange={(e) => {
                              setNewUser((prevState) => ({
                                ...prevState,
                                siren: e.target.value,
                              }));
                            }}
                            type="text"
                            name="Siren"
                            className="form-control"
                            placeholder="siren"
                          />
                          <div className="input-error">
                            <span className="text-danger">{errorMessage.siren}</span>
                          </div>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <div className="col-lg-6">
                          <h6 className="fw-bold color_black float-start">
                            Tva 
                          </h6>
                          <input
                            required
                            value={newUser.tva_number}
                            onChange={(e) => {
                              setNewUser((prevState) => ({
                                ...prevState,
                                tva_number: e.target.value,
                              }));
                            }}
                            type="text"
                            name="lastName"
                            className="form-control"
                            placeholder="tva"
                          />
                          <div className="input-error">
                            <span className="text-danger">
                              {errorMessage.tva_number}
                            </span>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <h6 className="fw-bold color_black float-start">
                            Rcv
                          </h6>
                          <input
                            type="text"
                            placeholder="rcv"
                            value={newUser.rcv}
                            onChange={(e) => {
                              setNewUser((prevState) => ({
                                ...prevState,
                                rcv: e.target.value,
                              }));
                            }}
                            className="form-control"
                            required
                          />
                          <div className="input-error">
                            <span className="text-danger">
                              {errorMessage.rcv}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="button-roww col-lg-6 col-sm-12 mb-3 mb-sm-0">
                          <button
                            type="button"
                            className="btn btn-outline-secondary style_butt mx-2"
                            onClick={() => {
                              setChildComponent(
                                <RegisterFormStep1
                                  setChildComponent={setChildComponent}
                                />
                              );
                            }}
                          >
                            Retour
                          </button>
                        </div>
                        <div className="col-sm-12 col-md-6">
                          <button
                            onClick={validate}
                            type="button"
                            className="btn btn-primary style_butt mx-2"
                          >
                            Next
                          </button>
                        </div>
                      </div>
                      <div className="row d-flex d-none d-sm-flex justify-content-center mt-3">
                        <div className="col-3 px-0  style_flagone_registerstep2  ">
                          <img className="" src="./flag.png" alt="" />
                        </div>
                        <div className="col-6 style_padding_bar  mt-5 ">
                          <ProgressBar className="style_barr  " now={66} />
                        </div>
                        <div className="col-3 style_flagsecond_registerstep2 ">
                          <img src="./flag2.png" alt="" />
                        </div>
                      </div>
                      <div className="row mt-5">
                        <div className="col-lg-12 text-center">
                          <span className="signin-link">
                            Vous avez déjà un compte? Cliquez ici pour{" "}
                            <Link to="/login" className="btn-click">
                            S'authentifier
                            </Link>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RegisterFormStep2;
